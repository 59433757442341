export default function clearSessionCookies() {
    // clears the given cookie for the given domain
    const clearCookie = (cookieKey, domain) => {
        document.cookie = `${cookieKey}=; path=/; domain=.${domain}; expires=${new Date(
            0,
        ).toUTCString()}`;
    };

    // get the current hostname
    let host = window.location.hostname;

    // do that 2 times so if running on QAS or DEV it'll clear cookies for PRD as well
    for (let i = 0; i < 2; i++) {
        // remove first part of the domain (e.g. 'app.qas.allpays.com.br' => 'qas.allpays.com.br')
        const parts = host.split('.');
        parts.shift();
        host = parts.join('.');

        // clears both coookies
        clearCookie('SessionId', host);
        clearCookie('UserId', host);
    }
}
