import * as actionTypes from '../actions/actionTypes';

const initialState = {
    contacts: [],
    contact: null,
    roles: [],
    accesses: [],
    loadingDetails: true,
    loadingList: true,
    contactsCount: 0,
    emailSent: false,
};

const setContacts = (state, action) => {
    return {
        ...state,
        contacts: action.contacts,
        loadingList: false,
    };
};

const setContactsCount = (state, action) => {
    return {
        ...state,
        contactsCount: action.contactsCount,
    };
};

const setContactsRequest = (state, action) => {
    return {
        ...state,
        contacts: action.contacts,
        loadingList: true,
    };
};

const setRoles = (state, action) => {
    return {
        ...state,
        roles: action.roles,
        loadingDetails: false,
    };
};

const setSendPassword = (state) => {
    return {
        ...state,
        emailSent: true,
        loadingDetails: false,
    };
};

const setContactDetails = (state, action) => {
    return {
        ...state,
        contact: action.contact,
        accesses: action.contact.accesses,
        loadingDetails: false,
        emailSent: false,
    };
};

const setContactDetailsPost = (state, action) => {
    return {
        ...state,
        contact: action.contact,
        loadingDetails: false,
        contacts: [],
    };
};

const setContactDetailsNew = (state) => {
    return {
        ...state,
        contact: undefined,
        accesses: [],
        loadingDetails: false,
        emailSent: false,
    };
};

const setContactDetailsRequest = (state) => {
    return {
        ...state,
        contact: undefined,
        loadingDetails: true,
        emailSent: false,
    };
};

const setContactAccess = (state, action) => {
    const accesses = action.accesses.slice();

    return {
        ...state,
        accesses,
        loadingDetails: false,
    };
};

const setUploadAttachmentRequest = (state) => {
    return {
        ...state,
        loadingAttachment: true,
    };
};

const setUploadAttachment = (state) => {
    return {
        ...state,
        loadingAttachment: false,
        attachmentFileId: `${state.attachmentFileId}`,
    };
};

const setUploadAttachmentFailure = (state) => {
    return {
        ...state,
        loadingAttachment: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CONTACTS_GET_LIST_REQUEST:
            return setContactsRequest(state, action);

        case actionTypes.CONTACTS_GET_LIST_FAILURE:
        case actionTypes.CONTACTS_GET_LIST_SUCCESS:
            return setContacts(state, action);

        case actionTypes.CONTACTS_GET_COUNT_SUCCESS:
            return setContactsCount(state, action);

        case actionTypes.CONTACT_GET_DETAILS_SUCCESS:
            return setContactDetails(state, action);

        case actionTypes.CONTACT_POST_SUCCESS:
        case actionTypes.CONTACT_POST_FAILURE:
            return setContactDetailsPost(state, action);

        case actionTypes.CONTACT_GET_DETAILS_REQUEST:
        case actionTypes.CONTACT_POST_REQUEST:
            return setContactDetailsRequest(state, action);

        case actionTypes.CONTACT_GET_DETAILS_NEW:
            return setContactDetailsNew(state, action);

        case actionTypes.ROLES_GET_LIST_SUCCESS:
            return setRoles(state, action);

        case actionTypes.CONTACT_ADD_ACCESS:
            return setContactAccess(state, action);

        case actionTypes.CONTACT_SEND_PASSWORD_SUCCESS:
            return setSendPassword(state, action);

        case actionTypes.CONTACT_UPLOAD_ATTACHMENT_REQUEST:
            return setUploadAttachmentRequest(state, action);

        case actionTypes.CONTACT_UPLOAD_ATTACHMENT_SUCCESS:
            return setUploadAttachment(state, action);

        case actionTypes.CONTACT_UPLOAD_ATTACHMENT_FAILURE:
            return setUploadAttachmentFailure(state, action);

        default:
            return state;
    }
};

export default reducer;
