import { toast } from 'react-toastify';
import axios from 'axios';
import * as actionTypes from './actionTypes';

export const initClaims = (accessType) => {
    if (!accessType)
        return (dispatch) =>
            dispatch({
                type: actionTypes.CLAIMS_GET_LIST_SUCCESS,
                claims: [],
            });

    return (dispatch) => {
        dispatch({
            type: actionTypes.CLAIMS_GET_LIST_REQUEST,
        });
        axios
            .get(`/claims?accessType=${accessType}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.CLAIMS_GET_LIST_SUCCESS,
                    claims: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.CLAIMS_GET_LIST_FAILURE,
                    error,
                });
                toast.error(
                    `Ocorreu um erro ao carregar a lista de claims. ${
                        error.response.data
                    }`,
                );
            });
    };
};
