import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    transactionsByMethodPayment: {
        id: 'dashboard.transactionsByMethodPayment',
        defaultMessage: 'Transactions By Method Payment',
    },
});
