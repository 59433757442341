import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'financialdocuments.title',
        defaultMessage: 'Documentos Financeiros',
    },

    titleFinancialDocument: {
        id: 'financialdocuments.titleFinancialDocument',
        defaultMessage: 'Documento financeiro {entity}',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
});

export default messages;
