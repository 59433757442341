import React from 'react';

const ContractorIcon = ({
    width = '26',
    height = '30',
    viewBox = '0 0 36 30',
    fill = 'none',
    style = '',
}) => (
    <svg
        width={width}
        height={height}
        fill={fill}
        viewBox={viewBox}
        style={style}
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M30.8 8.40001H5.2C3.43269 8.40001 2 9.8327 2 11.6V27.6C2 29.3673 3.43269 30.8 5.2 30.8H30.8C32.5673 30.8 34 29.3673 34 27.6V11.6C34 9.8327 32.5673 8.40001 30.8 8.40001Z'
            stroke='black'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M24.4 30.8V5.2C24.4 4.35131 24.0629 3.53737 23.4627 2.93726C22.8626 2.33714 22.0487 2 21.2 2H14.8C13.9513 2 13.1374 2.33714 12.5373 2.93726C11.9371 3.53737 11.6 4.35131 11.6 5.2V30.8'
            stroke='black'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default ContractorIcon;
