import * as actionTypes from '../actions/actionTypes';

const initialState = {
    claims: [],
};

const setClaims = (state, action) => {
    return {
        ...state,
        claims: action.claims,
        loading: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLAIMS_GET_LIST_SUCCESS:
            return setClaims(state, action);
        default:
            return state;
    }
};

export default reducer;
