import * as actionTypes from '../actions/actionTypes';

const initialState = {
    collections: [],
    collection: null,
    loadingDetails: true,
    loadingList: true,
    loadingButton: false,
    paymentLink: '',
    accessLink: '',
};

const setCollections = (state, action) => {
    return {
        ...state,
        collections: action.collections,
        loadingList: false,
    };
};

const setCollectionsCount = (state, action) => {
    return {
        ...state,
        collectionsCount: action.collectionsCount,
    };
};

const setCollectionsRequest = (state, action) => {
    return {
        ...state,
        collections: action.collections,
        loadingList: true,
    };
};

const setCopyLinkPostRequest = (state) => {
    return {
        ...state,
        loadingList: true,
    };
};

const setCopyLinkPost = (state, action) => {
    return {
        ...state,
        paymentLink: action.paymentLink,
        loadingList: false,
    };
};

const setResendMailPostRequest = (state) => {
    return {
        ...state,
        loadingList: true,
    };
};
const setResendMailPost = (state) => {
    return {
        ...state,
        loadingList: false,
    };
};

const setCollectionDetails = (state, action) => {
    return {
        ...state,
        collection: action.collection,
        loadingDetails: false,
    };
};

const setCollectionDetailsPost = (state, action) => {
    return {
        ...state,
        collection: action.collection,
        loadingDetails: false,
        collections: [],
    };
};

const setCollectionLoading = (state) => {
    return {
        ...state,
        loadingDetails: false,
    };
};

const setCollectionDetailsNew = (state) => {
    return {
        ...state,
        collection: undefined,
        loadingDetails: false,
    };
};

const setCollectionDetailsRequest = (state) => {
    return {
        ...state,
        collection: undefined,
        loadingDetails: true,
    };
};

const setInstallmentsRequest = (state) => {
    return {
        ...state,
        loadingButton: true,
        accessLink: '',
    };
};

const setInstallmentsClean = (state) => {
    return {
        ...state,
        loadingButton: false,
        accessLink: '',
    };
};

const setInstallmentsSuccess = (state, action) => {
    return {
        ...state,
        loadingButton: false,
        accessLink: action.accessLink,
    };
};

const setUploadAttachmentRequest = (state) => {
    return {
        ...state,
        loadingAttachment: true,
    };
};

const setUploadAttachment = (state) => {
    return {
        ...state,
        loadingAttachment: false,
        attachmentFileId: `${state.attachmentFileId}`,
    };
};

const setUploadAttachmentFailure = (state) => {
    return {
        ...state,
        loadingAttachment: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COLLECTIONS_GET_LIST_REQUEST:
            return setCollectionsRequest(state, action);

        case actionTypes.COLLECTIONS_GET_LIST_FAILURE:
        case actionTypes.COLLECTIONS_GET_LIST_SUCCESS:
            return setCollections(state, action);

        case actionTypes.COLLECTION_PUT_SUCCESS:
        case actionTypes.COLLECTION_GET_DETAILS_FAILURE:
        case actionTypes.COLLECTION_GET_DETAILS_SUCCESS:
            return setCollectionDetails(state, action);

        case actionTypes.COLLECTION_POST_SUCCESS:
        case actionTypes.COLLECTION_POST_FAILURE:
            return setCollectionDetailsPost(state, action);

        case actionTypes.COLLECTION_PUT_FAILURE:
            return setCollectionLoading(state, action);

        case actionTypes.COLLECTION_POST_REQUEST:
        case actionTypes.COLLECTION_GET_DETAILS_REQUEST:
            return setCollectionDetailsRequest(state, action);

        case actionTypes.COLLECTION_GET_DETAILS_NEW:
            return setCollectionDetailsNew(state, action);

        case actionTypes.COLLECTIONS_GET_COUNT_SUCCESS:
            return setCollectionsCount(state, action);

        case actionTypes.COLLECTION_UPLOAD_ATTACHMENT_REQUEST:
            return setUploadAttachmentRequest(state, action);

        case actionTypes.COLLECTION_UPLOAD_ATTACHMENT_SUCCESS:
            return setUploadAttachment(state, action);

        case actionTypes.COLLECTION_UPLOAD_ATTACHMENT_FAILURE:
            return setUploadAttachmentFailure(state, action);

        case actionTypes.COLLECTION_COPY_LINK_REQUEST:
            return setCopyLinkPostRequest(state, action);

        case actionTypes.COLLECTION_COPY_LINK_FAILURE:
        case actionTypes.COLLECTION_COPY_LINK_SUCCESS:
            return setCopyLinkPost(state, action);

        case actionTypes.INSTALLMENTS_CREATE_REQUEST:
            return setInstallmentsRequest(state, action);

        case actionTypes.INSTALLMENTS_CREATE_CLEAN:
            return setInstallmentsClean(state, action);

        case actionTypes.INSTALLMENTS_CREATE_FAILURE:
        case actionTypes.INSTALLMENTS_CREATE_SUCCESS:
            return setInstallmentsSuccess(state, action);

        case actionTypes.COLLECTION_POST_EMAIL_REQUEST:
            return setResendMailPostRequest(state, action);
        case actionTypes.COLLECTION_POST_EMAIL_FAILURE:
        case actionTypes.COLLECTION_POST_EMAIL_SUCCESS:
            return setResendMailPost(state);

        default:
            return state;
    }
};

export default reducer;
