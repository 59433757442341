/* eslint-disable no-empty-pattern */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Container, Row, Col, FormGroup } from 'reactstrap';
import messages from './RoleDetailsPage.intl';
import * as actions from '../../store/actions/index';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import CheckBoxList from '../../components/CheckBoxList/CheckBoxList';
import EvtSelect from '../../components/EvtSelect/EvtSelect';
import { AuthContext } from '../../context/AuthContext';
import Checkbox from '../../components/CheckBox/CheckBox';

class RoleDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        selectedAllowedRoles: [],
        selectedContractor: null,
        hasGotClaims: false,
        isAdmin: false,
        isClient: false,
        checkAll: false,
        label: 'Marcar todas',
    };

    componentDidMount() {
        if (this.props.match.params.id === 'new') {
            this.props.onInitCleanRole();
            this.props.onInitContractorDetails('new');
        } else {
            this.props.onInitRolesDetails(this.props.match.params.id);
        }
    }

    componentDidUpdate(prevProps) {
        const { role, contractor, session } = this.props;
        if (role && role !== prevProps.role) {
            const allowedRoles = role.allowedRoles || [];
            const selectedAllowedRoles = allowedRoles.map((r) => {
                return { label: r.name, value: r.roleId };
            });

            this.setState({
                selectedAllowedRoles,
            });

            this.context.hasClaim('contractor-view') &&
                this.props.onInitContractorDetails(
                    this.props.role.contractorId,
                );

            this.setState({
                isAdmin: role.claims.some(
                    (element) => element.value === 'role-level-full',
                ),

                isClient: role.claims.some(
                    (element) => element.value === 'role-level-client',
                ),
            });
        }

        if (
            contractor?.contractorId &&
            this.state.hasGotClaims !== contractor?.contractorId &&
            this.props.match.params.id !== 'new'
        ) {
            if (this.state.isClient) {
                this.props.onInitClaims(8);
            } else if (this.state.isAdmin) {
                this.props.onInitClaims(1);
            } else {
                this.props.onInitClaims(2);
            }

            this.setState({ hasGotClaims: contractor.contractorId });
        } else if (!contractor && this.state.hasGotClaims !== '') {
            if (
                this.props.match.params.id === 'new' &&
                !session?.contractor?.isSystem
            ) {
                this.props.onInitClaims(2);
                this.props.onSetSelectedContractor({
                    contractorId: session?.contractor?.contractorId,
                    displayName: session?.contractor?.displayName,
                });
                this.props.onInitRolesByContractor(
                    session?.contractor?.contractorId,
                );
            } else {
                this.props.onInitClaims();
            }
            this.setState({ hasGotClaims: '' });
        }

        if (
            contractor !== prevProps.contractor &&
            this.props.match.params.id !== 'new'
        ) {
            this.setState({
                selectedContractor: contractor,
            });
        }
    }

    distinct = (arr, prop) => {
        return arr.reduce((a, d) => {
            if (!a.includes(d[prop])) {
                a.push(d[prop]);
            }
            return a;
        }, []);
    };

    handleClaimChange = (e) => {
        const value = this.props.checkedClaims[e.target.id];

        if (value) {
            this.props.onChangeSelectedClaim(e.target.id, false);
        } else {
            this.props.onChangeSelectedClaim(e.target.id, true);
        }
    };

    handleRoleLevelChange = (e) => {
        this.props.onCleanRoleLevels();

        const value = this.props.checkedRoleLevel[e.target.id];

        if (value) {
            this.props.onChangeSelectedRoleLevel(e.target.id, false);
        } else {
            this.props.onChangeSelectedRoleLevel(e.target.id, true);
        }
    };

    handleSubmit = (e, values) => {
        const { checkedClaims, checkedRoleLevel } = this.props;
        const { selectedAllowedRoles, isAdmin } = this.state;
        let accessType;
        let type;

        if (!isAdmin) {
            switch (values.accessType) {
                case 'client':
                    accessType = 8;
                    type = 1;
                    break;
                default:
                    accessType = 2;
                    type = 0;
                    break;
            }
            values.type = type;
            values.accessType = accessType;
        }

        const claims = [];

        for (const keyClaim in checkedClaims) {
            claims.push(keyClaim);
        }

        for (const keyRole in checkedRoleLevel) {
            claims.push(keyRole);
        }

        const allowedRoles = selectedAllowedRoles?.map((sar) => {
            return {
                roleId: sar.value,
                name: sar.label,
            };
        });

        const finalValue = {
            ...values,
            claims,
            allowedRoles,
        };

        if (this.props.match.params.id === 'new') {
            this.props.onCreateRole(finalValue);
        } else {
            finalValue.roleId = this.props.match.params.id;
            this.props.onUpdateRole(finalValue);
        }
    };

    handleCheckAllChange = (e) => {
        if (this.state.checkAll) {
            this.setState({
                label: 'Desmarcar todas',
                checkAll: false,
            });
            this.props.onUncheckAllClaims();
        } else {
            this.setState({
                label: 'Marcar todas',
                checkAll: true,
            });
            this.props.onCheckAllClaims(e.target.checked);
        }
    };

    handleAllowedRolesSelectChange = (e) => {
        this.setState({
            selectedAllowedRoles: e,
        });
    };

    handleContractorChange = (selectedContractor) => {
        if (selectedContractor) {
            const accessTypeClaim = selectedContractor.data?.isSystem ? 1 : 2;
            this.props.onInitClaims(accessTypeClaim);

            this.props.onSetSelectedContractor({
                contractorId: selectedContractor.value,
                displayName: selectedContractor.label,
            });

            this.setState(
                {
                    selectedAllowedRoles: [],
                    selectedContractor: selectedContractor.data,
                },
                () => {
                    this.props.onInitRolesByContractor(
                        selectedContractor.value,
                    );
                },
            );
        }
    };

    handleRoleTypeChange = (selectedRoleType) => {
        let accessType;
        if (!this.state.isAdmin) {
            switch (selectedRoleType.target.value) {
                case 'client':
                    accessType = '8';
                    break;
                default:
                    accessType = '2';
                    break;
            }
        }
        this.props.onInitClaims(accessType);
        this.props.onUncheckAllClaims();
        this.setState({
            hasGotClaims: this.props.contractor.contractorId,
            checkAll: false,
        });
    };

    render() {
        const id = this.props.match.params.id;

        const { selectedContractor } = this.state;
        const { intl, loadingDetails, session } = this.props;

        const role = this.props.role || {};
        let claims = this.props.claims || [];

        const contractorsOption = new Map();
        contractorsOption.set(
            this.props.contractor && this.props.contractor.contractorId,
            this.props.contractor && this.props.contractor.displayName,
        );

        const levels = claims.filter((m) => m.policy === 'RoleLevel');
        claims = claims.filter((m) => m.policy !== 'RoleLevel');

        const canCreateRole =
            this.context.hasClaim('role-create') && id === 'new';

        const canUpdateRole =
            this.context.hasClaim('role-update') && id !== 'new';

        const isReadOnly = !(canUpdateRole || canCreateRole);

        const showAccessType =
            (!selectedContractor?.isSystem && selectedContractor !== null) ||
            !session?.contractor?.isSystem;

        const showContractorBox = session?.contractor?.isSystem;

        const options = [
            <option key='contractor' value='contractor'>
                {intl.formatMessage(messages.company)}
            </option>,
            <option key='client' value='client'>
                {intl.formatMessage(messages.client)}
            </option>,
        ];

        // const rolesContractor = this.props.rolesContractor || [];
        // const allowedRoles = rolesContractor.map((r) => {
        //     return { label: r.name, value: r.roleId };
        // });

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/roles',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/roles/:id',
                            name: intl.formatMessage(messages.titleRole, {
                                entity: this.props.role && this.props.role.name,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.titleRole, {
                                entity: '',
                            })}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content roles-form'>
                    <Loading loading={loadingDetails} />
                    <AvForm
                        onValidSubmit={(e, values) => {
                            const valuesSubmit = showContractorBox
                                ? { ...values }
                                : {
                                      ...values,
                                      contractorId: session?.contractorId,
                                  };
                            this.handleSubmit(e, valuesSubmit);
                        }}
                    >
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <AvField
                                        label={intl.formatMessage(
                                            messages.name,
                                        )}
                                        type='text'
                                        name='name'
                                        id='name'
                                        value={role.name || ''}
                                        errorMessage=' '
                                        required
                                        disabled={isReadOnly}
                                    />
                                </FormGroup>
                            </Col>
                            {showContractorBox && (
                                <Col md={3}>
                                    <EvtSelect
                                        name='contractorId'
                                        label={intl.formatMessage(
                                            messages.company,
                                        )}
                                        options={contractorsOption}
                                        url='/Contractors'
                                        labelKey='displayName'
                                        valueKey='contractorId'
                                        handleSelectedOptionChange={
                                            this.handleContractorChange
                                        }
                                        key={role && role.contractorId}
                                        async
                                        disabled={
                                            this.props.match.params.id !== 'new'
                                        }
                                        required
                                        option={
                                            this.props.contractor && {
                                                selectedValueOption:
                                                    this.props.contractor &&
                                                    this.props.contractor
                                                        .contractorId,
                                                selectedLabelOption:
                                                    this.props.contractor &&
                                                    this.props.contractor
                                                        .displayName,
                                            }
                                        }
                                        disable={isReadOnly}
                                    />
                                </Col>
                            )}
                            {showAccessType && (
                                <Col md={3}>
                                    <FormGroup>
                                        <AvField
                                            label={intl.formatMessage(
                                                messages.roleOptions,
                                            )}
                                            type='select'
                                            name='accessType'
                                            id='accessType'
                                            value={
                                                this.state.isClient
                                                    ? 'client'
                                                    : 'contractor'
                                            }
                                            errorMessage=' '
                                            required
                                            onChange={this.handleRoleTypeChange}
                                        >
                                            {options}
                                        </AvField>
                                    </FormGroup>
                                </Col>
                            )}
                        </Row>
                        <Row className='mt-5'>
                            <Col xs={12}>
                                <div style={{ marginBottom: '10px' }}>
                                    <Checkbox
                                        label={this.state.label}
                                        checked={this.state.checkAll}
                                        onChange={this.handleCheckAllChange}
                                        isReadOnly={isReadOnly}
                                    />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <CheckBoxList
                                    items={levels}
                                    checked={this.props.checkedRoleLevel}
                                    groupby={this.distinct(levels, 'entity')}
                                    name='CheckBoxAccess'
                                    handleChange={this.handleRoleLevelChange}
                                    isReadOnly={isReadOnly}
                                    color={
                                        this.props.session.contractor
                                            .primaryColor
                                    }
                                />
                                <CheckBoxList
                                    items={claims}
                                    checked={this.props.checkedClaims}
                                    groupby={this.distinct(claims, 'entity')}
                                    name='CheckBoxClaims'
                                    handleChange={this.handleClaimChange}
                                    isReadOnly={isReadOnly}
                                    color={
                                        this.props.session.contractor
                                            .primaryColor
                                    }
                                />
                            </Col>
                        </Row>
                        <footer>
                            <Row className='mt-3'>
                                <Col xs={12}>
                                    {!isReadOnly && (
                                        <button className='btn-submit'>
                                            {intl.formatMessage(
                                                messages.submitButton,
                                            )}
                                        </button>
                                    )}
                                </Col>
                            </Row>
                        </footer>
                    </AvForm>
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        checkedRoleLevel: state.roleReducer.checkedRoleLevel || [],
        checkedClaims: state.roleReducer.checkedClaims || [],
        checkedRoles: state.roleReducer.checkedRoles || [],
        role: state.roleReducer.role,
        rolesContractor: state.roleReducer.rolesContractor || [],
        roles: state.roleReducer.roles || [],
        claims: state.claimReducer.claims || [],
        contractors: state.contractorReducer.contractors || [],
        loadingDetails: state.roleReducer.loadingDetails,
        levels: state.roleReducer.roleLevels || [],
        contractor: state.contractorReducer.contractor,
        session: state.authenticationReducer.session,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitRolesByContractor: (contractorId) =>
            dispatch(actions.initRolesByContractor(contractorId)),
        onInitClaims: (accessType) => dispatch(actions.initClaims(accessType)),
        onInitContractors: () => dispatch(actions.initContractors()),
        onCreateRole: (values) => dispatch(actions.createRole(values)),
        onInitRolesDetails: (roleId) =>
            dispatch(actions.initRolesDetails(roleId)),
        onUpdateRole: (values) => dispatch(actions.updateRole(values)),
        onChangeSelectedUserRole: (userRole, checked) =>
            dispatch(actions.changeSelectedUserRole(userRole, checked)),
        onChangeSelectedClaim: (claim, checked) =>
            dispatch(actions.changeSelectedClaim(claim, checked)),
        onChangeSelectedRoleLevel: (level, checked) =>
            dispatch(actions.changeSelectedRoleLevel(level, checked)),
        onInitCleanRole: () => dispatch(actions.initCleanRole()),
        onCleanRoleLevels: () => dispatch(actions.cleanRoleLevels()),
        onCheckAllClaims: (checked) =>
            dispatch(actions.checkAllClaims(checked)),
        onSetSelectedContractor: (selectedContractor) =>
            dispatch(actions.setSelectedContractor(selectedContractor)),
        onInitContractorDetails: (id) =>
            dispatch(actions.initContractorDetails(id)),
        onUncheckAllClaims: () => dispatch(actions.uncheckAllClaims()),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(RoleDetailsPage),
);
