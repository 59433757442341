import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'parameters.title',
        defaultMessage: 'Parâmetros',
    },

    titleParameter: {
        id: 'parameters.titleParameter',
        defaultMessage: 'Parâmetro {entity}',
    },

    subtitle: {
        id: 'parameters.subtitle',
        defaultMessage: ' Parâmetro(s) encontrado(s)',
    },

    parameterName: {
        id: 'parameters.parameterName',
        defaultMessage: 'Nome Variável',
    },

    parameterDescription: {
        id: 'parameters.parameterDescription',
        defaultMessage: 'Descrição Variável',
    },

    parameterLow: {
        id: 'parameters.parameterLow',
        defaultMessage: ' Valor Variável',
    },

    parameterValue: {
        id: 'transactions.valueTransaction',
        defaultMessage: 'Valor',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    qtdInvoices: {
        id: 'home.qtdInvoices',
        defaultMessage: 'NF',
    },

    unavailableParameter: {
        id: 'parameters.unavailableParameter',
        defaultMessage: 'Parâmetro indisponível',
    },

    documentDate: {
        id: 'maps.documentDate',
        defaultMessage: 'Atualizado em',
    },
});

export default messages;
