import * as actionTypes from '../actions/actionTypes';

const initialState = {
    transactions: [],
    transaction: null,
    loadingDetails: true,
    loadingList: true,
    paymentLink: '',
};

const setTransactions = (state, action) => {
    return {
        ...state,
        transactions: action.transactions,
        loadingList: false,
    };
};

const setTransactionsCount = (state, action) => {
    return {
        ...state,
        transactionsCount: action.transactionsCount,
    };
};

const setTransactionsRequest = (state, action) => {
    return {
        ...state,
        transactions: action.transactions,
        loadingList: true,
    };
};

const setTransactionDetails = (state, action) => {
    return {
        ...state,
        transaction: action.transaction,
        loadingDetails: false,
    };
};

const setTransactionDetailsPost = (state, action) => {
    return {
        ...state,
        transaction: action.transaction,
        loadingDetails: false,
        transactions: [],
    };
};

const setResendMailPostRequest = (state) => {
    return {
        ...state,
        loadingList: true,
    };
};

const setResendMailPost = (state) => {
    return {
        ...state,
        loadingList: false,
    };
};

const setCopyLinkPostRequest = (state) => {
    return {
        ...state,
        loadingList: true,
    };
};

const setCopyLinkPost = (state, action) => {
    return {
        ...state,
        paymentLink: action.paymentLink,
        loadingList: false,
    };
};

const setTransactionLoading = (state) => {
    return {
        ...state,
        loadingDetails: false,
    };
};

const setTransactionDetailsNew = (state) => {
    return {
        ...state,
        transaction: undefined,
        loadingDetails: false,
    };
};

const setTransactionDetailsRequest = (state) => {
    return {
        ...state,
        transaction: undefined,
        loadingDetails: true,
    };
};

const setLoadingExportRequest = (state) => {
    return {
        ...state,
        loadingExport: true,
    };
};

const setLoadingExport = (state, action) => {
    return {
        ...state,
        loadingExport: action.loadingExport,
    };
};

const setUploadAttachmentRequest = (state) => {
    return {
        ...state,
        loadingAttachment: true,
    };
};

const setUploadAttachment = (state) => {
    return {
        ...state,
        loadingAttachment: false,
        attachmentFileId: `${state.attachmentFileId}`,
    };
};

const setUploadAttachmentFailure = (state) => {
    return {
        ...state,
        loadingAttachment: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TRANSACTIONS_GET_LIST_REQUEST:
            return setTransactionsRequest(state, action);

        case actionTypes.TRANSACTION_GET_EXPORT_REQUEST:
            return setLoadingExportRequest(state, action);

        case actionTypes.TRANSACTIONS_GET_LIST_FAILURE:
        case actionTypes.TRANSACTIONS_GET_LIST_SUCCESS:
            return setTransactions(state, action);

        case actionTypes.TRANSACTION_GET_EXPORT_SUCCESS:
        case actionTypes.TRANSACTION_GET_EXPORT_FAILURE:
            return setLoadingExport(state, action);

        case actionTypes.TRANSACTION_PUT_SUCCESS:
        case actionTypes.TRANSACTION_GET_DETAILS_FAILURE:
        case actionTypes.TRANSACTION_GET_DETAILS_SUCCESS:
            return setTransactionDetails(state, action);

        case actionTypes.TRANSACTION_POST_SUCCESS:
        case actionTypes.TRANSACTION_POST_FAILURE:
            return setTransactionDetailsPost(state, action);

        case actionTypes.TRANSACTION_PUT_FAILURE:
            return setTransactionLoading(state, action);

        case actionTypes.TRANSACTION_POST_REQUEST:
        case actionTypes.TRANSACTION_GET_DETAILS_REQUEST:
            return setTransactionDetailsRequest(state, action);

        case actionTypes.TRANSACTION_GET_DETAILS_NEW:
            return setTransactionDetailsNew(state, action);

        case actionTypes.TRANSACTIONS_GET_COUNT_SUCCESS:
            return setTransactionsCount(state, action);

        case actionTypes.TRANSACTION_COPY_LINK_REQUEST:
            return setCopyLinkPostRequest(state, action);

        case actionTypes.TRANSACTION_COPY_LINK_FAILURE:
        case actionTypes.TRANSACTION_COPY_LINK_SUCCESS:
            return setCopyLinkPost(state, action);
        case actionTypes.TRANSACTION_POST_EMAIL_REQUEST:
            return setResendMailPostRequest(state, action);
        case actionTypes.TRANSACTION_POST_EMAIL_FAILURE:
        case actionTypes.TRANSACTION_POST_EMAIL_SUCCESS:
            return setResendMailPost(state);

        case actionTypes.TRANSACTION_UPLOAD_ATTACHMENT_REQUEST:
            return setUploadAttachmentRequest(state, action);

        case actionTypes.TRANSACTION_UPLOAD_ATTACHMENT_SUCCESS:
            return setUploadAttachment(state, action);

        case actionTypes.TRANSACTION_UPLOAD_ATTACHMENT_FAILURE:
            return setUploadAttachmentFailure(state, action);

        default:
            return state;
    }
};

export default reducer;
