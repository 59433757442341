/* eslint-disable  */
import { toast } from 'react-toastify';
import axios from 'axios';
import { push } from 'connected-react-router';
import * as actionTypes from './actionTypes';

export const initClients = (filter = '') => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLIENTS_GET_LIST_REQUEST,
        });

        axios
            .get(`/Clients${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.CLIENTS_GET_LIST_SUCCESS,
                    clients: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.CLIENTS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};
export const initClientDetails = (clientId) => {
    if (clientId === 'new') {
        return (dispatch) => {
            dispatch({
                type: actionTypes.CLIENT_GET_DETAILS_NEW,
            });
        };
    }
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLIENT_GET_DETAILS_REQUEST,
        });

        axios
            .get(`/Clients/${clientId}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.CLIENT_GET_DETAILS_SUCCESS,
                    client: response.data,
                });
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.CLIENT_GET_DETAILS_FAILURE,
                    error,
                });
                console.log(error);
            });
    };
};

export const createClient = (values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLIENT_POST_REQUEST,
        });

        axios
            .post(`/Clients`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.CLIENT_POST_SUCCESS,
                });
                toast.success('Cliente criado com sucesso');
                dispatch(push('/clients'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.CLIENT_POST_FAILURE,
                    error,
                });
                toast.error(
                    `Ocorreu um erro ao cadastrar esse cliente. ${
                        error.response.data
                    }`,
                );
            });
    };
};

export const updateClient = (clientId, values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLIENT_PUT_REQUEST,
        });

        axios
            .put(`/Clients/${clientId}`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.CLIENT_PUT_SUCCESS,
                });
                toast.success('Cliente alterado com sucesso');
                dispatch(push('/clients'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.CLIENT_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const deleteClient = (clientId) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLIENT_DELETE_REQUEST,
        });

        axios
            .delete(`/Clients/${clientId}`)
            .then(function() {
                dispatch({
                    type: actionTypes.CLIENT_DELETE_SUCCESS,
                });
                toast.success('Cliente desativado com sucesso');
                dispatch(push('/clients'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.CLIENT_DELETE_FAILURE,
                    error,
                });
            });
    };
};

export const initClientsCount = (filter) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.CLIENTS_GET_COUNT_REQUEST,
        });
        await axios
            .get(`/Clients/Count${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.CLIENTS_GET_COUNT_SUCCESS,
                    clientsCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.CLIENTS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};
