import React from 'react';

import { injectIntl } from 'react-intl';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import messages from './ContactsDetailsForm.intl';

// import { AuthContext } from '../../context/AuthContext';
import DetailSection from '../DetailSection/DetailSection';

import { handleCpfCnpj } from '../../utils/handleCpfCnpj';

function ContactDetailsForm(props) {
    const { intl, canCreateContact, canUpdateContact } = props;

    const contact = props.contact || {};

    const isReadOnly = !(canCreateContact || canUpdateContact);

    // const context = useContext(AuthContext);

    return (
        <AvForm onValidSubmit={props.handleSubmit}>
            <DetailSection title='Identificação do Contato'>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.id)}
                                type='text'
                                name='contactId'
                                id='contactId'
                                value={contact.contactId}
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.name)}
                                type='text'
                                name='contactName'
                                id='contactName'
                                value={contact.contactName}
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>
            <DetailSection title='Identificação da Empresa'>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.customerId)}
                                type='text'
                                name='customerId'
                                id='customerId'
                                value={contact.customerId}
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.customerCpfCnpj,
                                )}
                                type='text'
                                name='customerCnpj'
                                id='customerCnpj'
                                value={handleCpfCnpj(contact.clientCpfCnpj)}
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>
            <DetailSection title='Dados Adicionais'>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.email)}
                                type='text'
                                name='contactEmail'
                                id='contactEmail'
                                value={contact.contactEmail}
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.phoneNumber)}
                                type='text'
                                name='phoneNumber'
                                id='phoneNumber'
                                value={contact.phoneNumber}
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.contactSource,
                                )}
                                type='text'
                                name='contactSource'
                                id='contactSource'
                                value={
                                    contact.contactSource === 'I'
                                        ? 'Interface'
                                        : 'Manual'
                                }
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>
        </AvForm>
    );
}

export default injectIntl(ContactDetailsForm);
