import * as actionTypes from '../actions/actionTypes';

const initialState = {
    feeMessage: null,
    loadingDetails: false,
    loadingList: false,
    contractors: [],
};

const setFeeMessagesType = (state, action) => {
    return {
        ...state,
        feeMessagesTypes: action.feeMessagesTypes,
        loadingList: false,
    };
};

const setFeeMessageDetails = (state, action) => {
    return {
        ...state,
        feeMessage: action.feeMessage,
        loadingDetails: false,
    };
};

const setFeeMessageDetailsPost = (state, action) => {
    return {
        ...state,
        feeMessage: action.feeMessage,
        loadingDetails: false,
        feeMessages: [],
    };
};

const setFeeMessageLoading = (state) => {
    return {
        ...state,
        loadingDetails: false,
    };
};

const setFeeMessageDetailsNew = (state) => {
    return {
        ...state,
        feeMessage: undefined,
        loadingDetails: false,
    };
};

const setFeeMessageDetailsRequest = (state) => {
    return {
        ...state,
        feeMessage: undefined,
        loadingDetails: true,
    };
};

const setContractors = (state, action) => {
    return {
        ...state,
        contractors: action.contractors,
        loadingList: false,
    };
};

const setContractorsRequest = (state, action) => {
    return {
        ...state,
        contractors: action.contractors,
        loadingList: true,
    };
};

const setFeeMessageTypeRequest = (state, action) => {
    return {
        ...state,
        feePayableTypes: action.feePayableTypes,
        loadingList: true,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FEE_MESSAGES_GET_TYPES_REQUEST:
            return setFeeMessageTypeRequest(state, action);

        case actionTypes.FEE_MESSAGES_GET_TYPES_SUCCESS:
            return setFeeMessagesType(state, action);

        case actionTypes.FEE_MESSAGE_PUT_SUCCESS:
        case actionTypes.FEE_MESSAGE_GET_DETAILS_FAILURE:
        case actionTypes.FEE_MESSAGE_GET_DETAILS_SUCCESS:
            return setFeeMessageDetails(state, action);

        case actionTypes.FEE_MESSAGE_POST_SUCCESS:
        case actionTypes.FEE_MESSAGE_POST_FAILURE:
            return setFeeMessageDetailsPost(state, action);

        case actionTypes.FEE_MESSAGE_PUT_FAILURE:
            return setFeeMessageLoading(state, action);

        case actionTypes.FEE_MESSAGE_POST_REQUEST:
        case actionTypes.FEE_MESSAGE_GET_DETAILS_REQUEST:
            return setFeeMessageDetailsRequest(state, action);

        case actionTypes.FEE_MESSAGE_GET_DETAILS_NEW:
            return setFeeMessageDetailsNew(state, action);

        case actionTypes.FEE_MESSAGE_CONTRACTORS_GET_LIST_REQUEST:
            return setContractorsRequest(state, action);

        case actionTypes.FEE_MESSAGE_CONTRACTORS_GET_LIST_FAILURE:
        case actionTypes.FEE_MESSAGE_CONTRACTORS_GET_LIST_SUCCESS:
            return setContractors(state, action);

        default:
            return state;
    }
};

export default reducer;
