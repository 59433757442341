import axios from 'axios';
import * as actionTypes from './actionTypes';

export const initAuditLogsCount = (filter = '') => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.AUDIT_LOGS_GET_COUNT_REQUEST,
        });

        await axios
            .get(`/AuditLogs/Count${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.AUDIT_LOGS_GET_COUNT_SUCCESS,
                    auditLogsCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.AUDIT_LOGS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initAuditLogs = (filter = '') => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.AUDIT_LOGS_GET_LIST_REQUEST,
        });

        axios
            .get(`/AuditLogs${filter}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.AUDIT_LOGS_GET_LIST_SUCCESS,
                    auditLogs: response.data,
                });
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.AUDIT_LOGS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initTechnicalLogsCount = (filter = '') => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.TECHNICAL_LOGS_GET_COUNT_REQUEST,
        });

        await axios
            .get(`/TechnicalLogs/Count${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.TECHNICAL_LOGS_GET_COUNT_SUCCESS,
                    technicalLogsCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.TECHNICAL_LOGS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initTechnicalLogs = (filter = '') => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.TECHNICAL_LOGS_GET_LIST_REQUEST,
        });

        axios
            .get(`/TechnicalLogs${filter}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.TECHNICAL_LOGS_GET_LIST_SUCCESS,
                    technicalLogs: response.data,
                });
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.TECHNICAL_LOGS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initTransactionLogsError = (filter = '') => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.TRANSACTION_LOGS_ERROR_GET_REQUEST,
        });

        await axios
            .get(
                `/IntegrationLog/searchIntegrationErrorLogCollectionsAndTransactions${filter}`,
            )
            .then((response) => {
                dispatch({
                    type: actionTypes.TRANSACTION_LOGS_ERROR_GET_SUCCESS,
                    transactionLogsError: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.TRANSACTION_LOGS_ERROR_GET_FAILURE,
                    error,
                });
            });
    };
};

export const initTransactionLogsErrorCount = (filter = '') => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.TRANSACTION_LOGS_ERROR_GET_COUNT_REQUEST,
        });

        axios
            .get(`/IntegrationLog/Count${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.TRANSACTION_LOGS_ERROR_GET_COUNT_SUCCESS,
                    transactionLogsErrorCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.TRANSACTION_LOGS_ERROR_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};
