import React, { Component } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { ToastContainer, toast } from 'react-toastify';
import { ChakraProvider } from '@chakra-ui/react';
import routes from './routes/routes';
import LoginPage from './containers/LoginPage/LoginPage';
import NotFoundPage from './containers/NotFoundPage/NotFoundPage';
import Layout from './components/Layout/Layout';
import './components/LoadingV2/styles.css';
import * as icons from './utils/fontAwesomeIcons';
import CSSReset from './cssReset';

library.add(icons);

class App extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.error !== prevProps.error) {
            this.props.error && toast.error(this.props.error);
        }
    }

    render() {
        const session = this.props.session || {};
        const claims = session.claims || [];

        const filteredRoutes = routes.filter((route) => {
            return claims.find(
                (claim) => claim.value === route.claim || route.claim === '',
            );
        });

        return (
            <>
                <Switch>
                    <Route
                        path='/login'
                        name='LoginPage'
                        component={LoginPage}
                    />
                    <Redirect push from='/' exact to='/home' />
                    <Layout>
                        <ChakraProvider resetCSS={false}>
                            <CSSReset />
                            <Switch>
                                {filteredRoutes.map((route) => {
                                    return (
                                        <Route
                                            exact
                                            path={route.path}
                                            name={route.name}
                                            component={route.component}
                                            key={route.name}
                                        />
                                    );
                                })}
                                <Route component={NotFoundPage} />
                            </Switch>
                        </ChakraProvider>
                    </Layout>
                </Switch>
                <ToastContainer autoClose={2000} />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.globalReducer.error,
        session: state.authenticationReducer.session,
    };
};

export default withRouter(connect(mapStateToProps)(App));
