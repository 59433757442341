import * as actionTypes from '../actions/actionTypes';

const initialState = { 'page path': 1 };

const reducer = (state = initialState, action) => {
    const path = action.payload?.path;
    switch (action.type) {
        case actionTypes.UI_CONTEXT_PAGE_UPDATE: {
            const pageNumber = state[path].pageNumber || [];
            pageNumber[action.payload.index] = action.payload.pageNumber || 0;
            return {
                ...state,
                [path]: {
                    ...state[path],
                    index: action.payload.index,
                    pageNumber,
                },
            };
        }
        case actionTypes.UI_CONTEXT_ORDENATION_UPDATE:
            return {
                ...state,
                [path]: {
                    ...state[path],
                    ordenation: action.payload.ordenation,
                },
            };
        case actionTypes.UI_CONTEXT_FILTER_UPDATE:
            if (action.payload.index === '2') return { ...state };
            return {
                ...state,
                [path]: {
                    ...state[path],
                    index: action.payload.index,
                    filter: action.payload.filter,
                    pageNumber: action.payload.pageNumber,
                    ordenation: action.payload.ordenation,
                },
            };
        case actionTypes.UI_CONTEXT_FILTER_RESET:
            if (
                state[path] &&
                state[path].filter &&
                typeof state[path].pageNumber !== 'undefined'
            )
                return {
                    ...state,
                    [path]: {
                        ...state[path],
                        filter: undefined,
                        pageNumber: 0,
                        ordenation: undefined,
                    },
                };
            return {
                ...state,
                [path]: {
                    ...state[path],
                    filter: undefined,
                },
            };

        case actionTypes.CHANGE_TAB_TARIFICATIONS: {
            return {
                ...state,
                [path]: {
                    ...state[path],
                    index: action.payload.index,
                },
            };
        }
        default:
            return state;
    }
};

export default reducer;
