import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend,
} from 'recharts';
import { injectIntl } from 'react-intl';
import messages from './TransactionConversion.intl';

const TransactionConversion = (props) => {
    const { intl } = props;
    const colors = props.colors;
    const data = props.data.data.data.map((data) => {
        const tratada = { date: data.date };
        for (let i = 0; i < data.values.length; i++) {
            tratada[data.values[i].name] = data.values[i].value;
        }
        return tratada;
    });
    const values = props.data.data.dataValueNames.reverse();
    const tickFormatterY = (number) => {
        if (number > 1000000000) {
            return `${(number / 1000000000).toString()}B`;
        }
        if (number > 1000000) {
            return `${(number / 1000000).toString()}M`;
        }
        if (number > 1000) {
            return `${(number / 1000).toString()}K`;
        }
        return number.toString();
    };
    return (
        <>
            <div className='text'>
                <h1>{intl.formatMessage(messages.transactionConversion)}</h1>
            </div>

            <div className='chart chart-line'>
                <ResponsiveContainer
                    width={window.screen.width / 3}
                    height={500}
                >
                    <AreaChart
                        width={400}
                        height={500}
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis tick={{ fontSize: 13 }} dataKey='date' />
                        <YAxis tickFormatterY={tickFormatterY} />
                        <Tooltip />
                        <Legend />
                        {values.map((value, index) => {
                            return (
                                <Area
                                    key={value}
                                    name={value}
                                    type='monotone'
                                    dataKey={value}
                                    stackId='1'
                                    stroke={colors[index]}
                                    fill={colors[index]}
                                />
                            );
                        })}
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </>
    );
};

export default injectIntl(withRouter(TransactionConversion));
