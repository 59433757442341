/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const tabs = (props) => {
    return (
        <div>
            <Nav tabs>
                {props.tabs.map((data, index) => {
                    const isActive = props.activeTab === index;
                    if (data.disabled) {
                        return;
                    }

                    return (
                        <NavItem key={index}>
                            <NavLink
                                disabled={data.disabled}
                                className={classnames({
                                    active: isActive,
                                })}
                                onClick={(e) => props.handleTabClick(e, index)}
                                key={index}
                            >
                                {data.name}
                            </NavLink>
                        </NavItem>
                    );
                })}
            </Nav>
            <TabContent activeTab={props.activeTab} className='mt-3'>
                {props.tabs.map((data, index) => {
                    const isActive = props.activeTab === index;
                    if (!isActive) return <></>;

                    return (
                        <TabPane tabId={index} key={index}>
                            {data.component}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
};

export default tabs;
