import React from 'react';
import { injectIntl } from 'react-intl';
import JSONTree from 'react-json-tree';

import Modal from '../Modal/Modal';

const jsonTreeModal = (props) => {
    const theme = {
        scheme: 'monokai',
        author: 'wimer hazenberg (http://www.monokai.nl)',
        base00: '#272822',
        base01: '#383830',
        base02: '#49483e',
        base03: '#75715e',
        base04: '#a59f85',
        base05: '#f8f8f2',
        base06: '#f5f4f1',
        base07: '#f9f8f5',
        base08: '#f92672',
        base09: '#fd971f',
        base0A: '#f4bf75',
        base0B: '#a6e22e',
        base0C: '#a1efe4',
        base0D: '#66d9ef',
        base0E: '#ae81ff',
        base0F: '#cc6633',
    };

    return (
        <div className='modal-json'>
            <Modal
                title='Log'
                isOpen={props.isOpen}
                handleToggle={props.handleToggle}
                hasFooter={false}
                size='xl'
            >
                <div className='modal-label'>
                    <JSONTree
                        expandAll
                        data={props.data}
                        hideRoot
                        shouldExpandNode={() => true}
                        theme={{
                            extend: !props.darkTheme && theme,
                            valueLabel: {
                                textDecoration: 'underline',
                            },
                            nestedNodeLabel: (
                                { style },
                                nodeType,
                                expanded,
                            ) => ({
                                style: {
                                    ...style,
                                    textTransform: expanded
                                        ? 'uppercase'
                                        : style.textTransform,
                                },
                            }),
                        }}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default injectIntl(jsonTreeModal);
