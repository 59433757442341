import * as actionTypes from '../actions/actionTypes';

const initialState = {};

const setGeneralSettings = (state, action) => {
    return {
        ...state,
        settings: action.settings,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SETTINGS_CHANGE_PASSWORD_SUCCESS:
            return null;

        case actionTypes.SETTINGS_GENERAL_GET_SUCCESS:
        case actionTypes.SETTINGS_GENERAL_PUT_SUCCESS:
            return setGeneralSettings(state, action);

        default:
            return state;
    }
};

export default reducer;
