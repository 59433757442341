import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    newPasswordLabelText: {
        id: 'settings.newPasswordLabelText',
        defaultMessage: 'Nova senha',
    },

    confirmPasswordLabelText: {
        id: 'settings.confirmPasswordLabelText',
        defaultMessage: 'Confirmar senha',
    },

    passwordDoesNotMatchValidationText: {
        id: 'settings.passwordDoesNotMatchValidationText',
        defaultMessage: 'As senhas não são iguais',
    },

    paymentTitle: {
        id: 'paymentTitle',
        defaultMessage: 'Pagamento',
    },

    emailTitle: {
        id: 'emailTitle',
        defaultMessage: 'Email',
    },

    smsTitle: {
        id: 'smsTitle',
        defaultMessage: 'SMS',
    },

    lockPixPayment: {
        id: 'lockPixPayment',
        defaultMessage: 'Bloquear pagamentos para pix',
    },

    groupCollectionsForTransactions: {
        id: 'groupCollectionsForTransactions',
        defaultMessage: 'Agrupar collections em uma integração',
    },

    isActiveEmail: {
        id: 'email.isActive',
        defaultMessage: 'Permitir envio de notificações',
    },

    maxMontlySendedEmailNumber: {
        id: 'email.maxMontlySendedEmailNumber',
        defaultMessage: 'Máximo envio de emails mensalmente',
    },

    allowSendEmailOnlyForThisDomains: {
        id: 'email.allowSendEmailOnlyForThisDomains',
        defaultMessage: 'Domínios aceitos para envio de e-mails',
    },

    allowSendEmailOnlyOverdueCollections: {
        id: 'email.allowSendEmailOnlyOverdueCollections',
        defaultMessage:
            'Restringir emails de cobrança para recebíveis vencidos',
    },

    isActiveSms: {
        id: 'sms.isActive',
        defaultMessage: 'Permitir envio de notificações',
    },

    maxMontlySendedSmsNumber: {
        id: 'sms.maxMontlySendedSmsNumber',
        defaultMessage: 'Máximo envio de SMS mensalmente',
    },

    allowSendSMSOnlyForThisNumber: {
        id: 'sms.allowSendSMSOnlyForThisNumber',
        defaultMessage: 'Números aceitos para envio de SMS',
    },
});

export default messages;
