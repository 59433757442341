import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Container } from 'reactstrap';

import Tabs from '../../components/Tabs/Tabs';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import messages from './LogListPage.intl';
import AuditLogsTable from '../AuditLogsTable/AuditLogsTable';
import TechnicalLogsTable from '../TechnicalLogsTable/TechnicalLogsTable';

import { AuthContext } from '../../context/AuthContext';
import TransactionsIntegrationErrors from '../TransactionsIntegrationErrors/TransactionsIntegrationErrors';

class LogListPage extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
        };
    }

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }
    };

    render() {
        const { intl } = this.props;

        const canViewAuditLog = this.context.hasClaim('audit-log-view');
        const canViewTechnicalLog = this.context.hasClaim('technical-log-view');
        const index = this.state.activeTab;

        const tabs = [
            canViewAuditLog && {
                name: 'Audit',
                component: <AuditLogsTable />,
            },

            canViewTechnicalLog && {
                name: 'Technical',
                component: <TechnicalLogsTable />,
            },

            {
                name: 'Erros de Integração',
                component: <TransactionsIntegrationErrors />,
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/contractors',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={
                                tabs.filter((tab) => typeof tab === 'object')[
                                    index
                                ].name
                            }
                        />
                    </section>
                </header>
                <section className='content-middle bg-content'>
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={tabs.filter((tab) => typeof tab === 'object')}
                    />
                </section>
            </Container>
        );
    }
}

export default injectIntl(LogListPage);
