import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from './PendingOrdersCounterWidget.intl';

import { AuthContext } from '../../../context/AuthContext';

function PendingOrdersCounterWidget(props) {
    const context = useContext(AuthContext);

    const { intl } = props;
    const clickable = context.hasClaim('order-view');

    function handleClick() {
        if (!props.readOnly && clickable) {
            props.history.push('/orders');
        }
    }

    return (
        <div onClick={handleClick} className={clickable ? 'clickable' : ''}>
            <FontAwesomeIcon icon='file' className='mr-5 c02' size='2x' />
            <h1>{props.data ? props.data : 0}</h1>
            <p>{intl.formatMessage(messages.pendingOrdersCounter)}</p>
        </div>
    );
}

export default injectIntl(withRouter(PendingOrdersCounterWidget));
