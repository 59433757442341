import { injectIntl } from 'react-intl';
import React from 'react';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import allpaysLogo from '../../assets/images/allpaysLogo.jpg';

function CardField(props) {
    const { image, title, subtitle, message } = props;
    const { handleDelete, handleGetProps } = props;
    return (
        <Card
            style={{
                display: 'flex',
                margin: 15,
                width: 350,
                position: 'relative',
            }}
        >
            <CardMedia
                component='img'
                style={{ width: 150, cursor: 'pointer', margin: 'auto' }}
                image={image || allpaysLogo}
                onClick={handleGetProps}
            />
            <Box>
                <CardContent
                    sx={{ flex: '1 0 auto' }}
                    style={{ position: 'relative', width: 200 }}
                >
                    <Typography component='div' variant='h5' title={title}>
                        {title.length > 17
                            ? `${title.substr(0, 14)}...`
                            : title}

                        {handleDelete && (
                            <span
                                className='closeButton'
                                onClick={handleDelete}
                            >
                                ×
                            </span>
                        )}
                    </Typography>
                    <Typography
                        variant='subtitle1'
                        color='text.secondary'
                        component='div'
                    >
                        {subtitle.length > 24
                            ? `${subtitle.substr(0, 20)}...`
                            : subtitle}
                    </Typography>
                </CardContent>
                {message && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            pl: 1,
                            pb: 1,
                        }}
                    >
                        <p>{message}</p>
                    </Box>
                )}
            </Box>
        </Card>
    );
}

export default injectIntl(CardField);
