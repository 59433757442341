import React, { Component } from 'react';

import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from './UserListPage.intl';
import * as actions from '../../store/actions/index';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import EvtTable, {
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import { filterPropertyTypes } from '../../utils/enums';
import { AuthContext } from '../../context/AuthContext';

class UserListPage extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
        query: '',
    };

    reloadUsersList = () => {
        const showContractorFilter = this.context.hasClaim('role-level-full');
        const { query } = this.state;
        this.props.onInitUsersCount(query);
        this.props.onInitUsers(query);
        if (showContractorFilter) {
            this.props.onInitContractors();
        }
    };

    handleRowClick = (e, id) => {
        this.props.history.push(`/users/${id}`);
    };

    handleNewClick = () => {
        this.props.history.push(`/users/new`);
    };

    handleGetTrProps = (state, rowInfo) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: () =>
                this.props.history.push(`/users/${rowInfo.original.userId}`),
        };
    };

    handleRefreshButtonClick = () => {
        this.reloadUsersList();
    };

    handleFiltersChange = (filters) => {
        this.setState({
            filters,
        });
    };

    handleEvTableStateChange = (query) => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadUsersList();
            },
        );
    };

    render() {
        const { intl, loadingList, users, usersCount } = this.props;
        const { filters } = this.state;
        const contractors = this.props.contractors.map((item) => {
            return {
                key: item.contractorId,
                value: item.displayName,
            };
        });

        const newUserButtonEnabled =
            this.context.hasClaim('user-create') &&
            this.context.hasClaim('role-view');
        const showContractorFilter = this.context.hasClaim('role-level-full');
        const properties = [
            {
                key: 'name',
                value: intl.formatMessage(messages.name),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'emailAddress',
                value: intl.formatMessage(messages.email),
                type: filterPropertyTypes.TEXT,
            },
        ];

        if (showContractorFilter) {
            properties.push({
                key: 'contractorId',
                value: intl.formatMessage(messages.company),
                type: filterPropertyTypes.ENUM,
                enums: contractors,
            });
        }

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.name)}</span>
                    </div>
                ),
                accessor: 'name',
                Cell: (row) => {
                    const id = row.index + 1;
                    return (
                        <div className='customer-container'>
                            <span className={`color-number color-number-${id}`}>
                                {row.value.substr(0, 1).toUpperCase()}
                            </span>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                minWidth: calculateColumnWidth(
                    users,
                    'name',
                    intl.formatMessage(messages.name),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.email)}</span>
                    </div>
                ),
                accessor: 'emailAddress',
                minWidth: calculateColumnWidth(
                    users,
                    'emailAddress',
                    intl.formatMessage(messages.email),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.company)}</span>
                    </div>
                ),
                accessor: 'contractorName',
                minWidth: calculateColumnWidth(
                    users,
                    'contractorName',
                    intl.formatMessage(messages.company),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.function)}</span>
                    </div>
                ),
                accessor: 'roleName',
                minWidth: calculateColumnWidth(
                    users,
                    'roleName',
                    intl.formatMessage(messages.function),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.createdAt)}</span>
                    </div>
                ),
                id: 'createdAt',
                accessor: (d) =>
                    new Date(d.createdAt).toLocaleString(navigator.language),
                minWidth: 80,
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/users',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={intl.formatMessage(messages.subtitle, {
                                length: this.props.usersCount,
                            })}
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>

                        {newUserButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleOnlyUser,
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={users}
                        length={usersCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'createdAt',
                                desc: true,
                            },
                        ]}
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contractors: state.contractorReducer.contractors || [],
        users: state.userReducer.users || [],
        usersCount: state.userReducer.usersCount || 0,
        loadingList: state.userReducer.loadingList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitUsers: (filter) => dispatch(actions.initUsers(filter)),
        onInitUsersCount: (filter) => dispatch(actions.initUsersCount(filter)),
        onInitContractors: () =>
            dispatch(actions.initContractors('?skip=0&take=100')),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(UserListPage),
);
