import React, { useState } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import { injectIntl } from 'react-intl';
import messages from './ChangePasswordForm.intl';
import PasswordStrengthMeter from '../PasswordStrengthMeter/PasswordStrengthMeter';

const ChangePasswordForm = (props) => {
    const {
        intl,
        contractorColor,
        handleBackClickToogle,
        handleChangePasswordFormSubmit,
    } = props;
    const [password, setPassword] = useState('');

    return (
        <AvForm onValidSubmit={handleChangePasswordFormSubmit}>
            <Row style={{ marginBottom: 10 }}>
                <Col xs={12}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(
                                messages.newPasswordLabelText,
                            )}
                            type='password'
                            name='newPassword'
                            id='newPassword'
                            onChange={(e) => setPassword(e.target.value)}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(
                                messages.confirmPasswordLabelText,
                            )}
                            type='password'
                            name='confirmNewPassword'
                            id='confirmNewPassword'
                            errorMessage=' '
                            required
                            validate={{
                                match: {
                                    value: 'newPassword',
                                    errorMessage: intl.formatMessage(
                                        messages.passwordDoesNotMatchValidationText,
                                    ),
                                },
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <PasswordStrengthMeter password={password} />

            <button
                style={{ backgroundColor: contractorColor }}
                className='login-btn'
                type='submit'
            >
                {intl.formatMessage(messages.buttonChangeText)}
            </button>
            <button
                style={{ color: contractorColor }}
                className='btn-link btnForgotPassword'
                type='button'
                onClick={handleBackClickToogle}
            >
                {intl.formatMessage(messages.returnButton)}
            </button>
        </AvForm>
    );
};

export default injectIntl(ChangePasswordForm);
