import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    monthByMonthVolumeChartActivities: {
        id: 'dashboard.monthByMonthVolumeChartActivities',
        defaultMessage: 'Atividades',
    },
    monthByMonthVolumeChartTotalInvoices: {
        id: 'dashboard.monthByMonthVolumeChartTotalInvoices',
        defaultMessage: 'notas emitidas',
    },
});
