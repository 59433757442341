import React from 'react';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TooltipItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false,
        };
    }

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    };

    render() {
        return (
            <span>
                {this.props.icon && (
                    <FontAwesomeIcon
                        className='ml-2'
                        icon={this.props.icon}
                        id={`Tooltip-${this.props.id}`}
                    />
                )}
                <Tooltip
                    placement='top'
                    isOpen={this.state.tooltipOpen}
                    target={`Tooltip-${this.props.id}`}
                    toggle={this.toggle}
                >
                    {this.props.message}
                </Tooltip>
            </span>
        );
    }
}

export default TooltipItem;
