import React from 'react';
import ReactTable from 'react-table';
import { injectIntl } from 'react-intl';
import messages from './GenericWidgetTable.intl';
import { AuthContext } from '../../../context/AuthContext';

class GenericWidgetTable extends React.Component {
    static contextType = AuthContext;

    render() {
        const { intl, data } = this.props;

        const attributes = this.props.attributes || {};

        const columnsWidget = attributes.columns || [];

        const columns = [];

        columnsWidget.map((column) => {
            const columnHeader = {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages[column.columnName])}
                        </span>
                    </div>
                ),
                accessor: column.columnName,
                sortable: false,

                Cell: (row) => {
                    switch (column.columnType) {
                        case 'datetime':
                            return new Date(row.value).toLocaleString(
                                navigator.language,
                            );

                        case 'date':
                            return new Date(row.value).toLocaleDateString(
                                navigator.language,
                            );

                        case 'decimal':
                            return row.value.toFixed(2).replace('.', ',');

                        default:
                            return row.value;
                    }
                },
            };

            return columns.push(columnHeader);
        });

        return (
            <>
                <div className='mt-5'>
                    <ReactTable
                        showPagination={false}
                        resizable
                        manual
                        columns={columns}
                        data={data.data}
                        minRows={0}
                        defaultPageSize={5}
                        noDataText={intl.formatMessage(messages.noDataText)}
                    />
                </div>
            </>
        );
    }
}

export default injectIntl(GenericWidgetTable);
