/* eslint-disable no-empty-pattern */
/* eslint-disable no-empty */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Container } from 'reactstrap';
import * as actions from '../../store/actions/index';
import Header from '../../components/Header/Header';
import FinancialDocumentDetailsForm from '../../components/FinancialDocumentsDetailsForm/FinancialDocumentsDetailsForm';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './FinancialDocumentDetailsPage.intl';
import { AuthContext } from '../../context/AuthContext';

class FinancialDocumentDetailsPage extends Component {
    static contextType = AuthContext;

    componentDidMount() {
        this.props.onInitFinancialDocumentDetails(this.props.match.params.id);
    }

    render() {
        const { intl, loadingDetails } = this.props;

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/financialdocuments',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/financialdocuments/:id',
                            name: intl.formatMessage(
                                messages.titleFinancialDocument,
                                {
                                    entity:
                                        this.props.financialdocument &&
                                        this.props.financialdocument.name,
                                },
                            ),
                            active: true,
                        },
                    ]}
                />
                <header className='row'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(
                                messages.titleFinancialDocument,
                                {
                                    entity: '',
                                },
                            )}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content roles-form'>
                    <Loading loading={loadingDetails} />
                    <FinancialDocumentDetailsForm
                        key={this.props.financialdocument}
                        financialDocument={this.props.financialDocument}
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        financialDocument: state.financialDocumentsReducer.financialDocument,
        loadingDetails: state.financialDocumentsReducer.loadingDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitFinancialDocumentDetails: (financialdocumentId) =>
            dispatch(actions.initFinancialDocumentDetails(financialdocumentId)),
        onCreateFinancialDocument: (values) =>
            dispatch(actions.createFinancialDocument(values)),
        onUpdateFinancialDocument: (financialdocumentId, values) =>
            dispatch(
                actions.updateFinancialDocument(financialdocumentId, values),
            ),
        onDeleteFinancialDocument: (financialdocumentId) =>
            dispatch(actions.deleteFinancialDocument(financialdocumentId)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(FinancialDocumentDetailsPage),
);
