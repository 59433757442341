import React from 'react';

const TransactionIcon = ({
    width = '26',
    height = '30',
    viewBox = '0 0 26 30',
    fill = 'none',
    style = '',
}) => (
    <svg
        width={width}
        height={height}
        fill={fill}
        viewBox={viewBox}
        style={style}
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.045 6.98794C16.091 8.39994 18.75 11.7999 18.75 14.8629C18.75 17.9699 15.389 20.4879 12 20.4879C8.611 20.4879 5.25 17.9699 5.25 14.8629C5.25 11.7999 7.909 8.39994 9.955 6.98794L8.4 3.28094C8.27339 3.0599 8.24638 2.79555 8.32568 2.55347C8.40498 2.31138 8.58315 2.11424 8.816 2.01094C10.8827 1.33245 13.1123 1.33245 15.179 2.01094C15.4127 2.11328 15.592 2.31 15.6723 2.55219C15.7526 2.79438 15.7263 3.05926 15.6 3.28094L14.045 6.98794Z'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M9.955 6.98792H14.045'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M4.5 19.7379C1.5 19.7379 0.75 16.7379 0.75 14.6239C0.839709 12.1079 2.18384 9.80411 4.33 8.48792L3.066 6.66492C2.90723 6.43565 2.88877 6.13722 3.01808 5.89014C3.1474 5.64306 3.40313 5.48813 3.682 5.48792H6'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M10.329 16.3319C10.7684 16.9094 11.4619 17.2364 12.187 17.2079C13.326 17.2079 14.25 16.5149 14.25 15.6599C14.25 14.8049 13.326 14.1139 12.187 14.1139C11.048 14.1139 10.125 13.4209 10.125 12.5659C10.125 11.7109 11.049 11.0189 12.187 11.0189C12.9121 10.9895 13.6058 11.3162 14.045 11.8939'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12.187 17.2079V18.2379'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12.187 9.98792V11.0189'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M19.5 19.7379C22.5 19.7379 23.25 16.7379 23.25 14.6239C23.1603 12.1079 21.8162 9.80411 19.67 8.48792L20.934 6.66492C21.0928 6.43565 21.1112 6.13722 20.9819 5.89014C20.8526 5.64306 20.5969 5.48813 20.318 5.48792H18'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default TransactionIcon;
