import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    referenceDocument: {
        id: 'financialdocuments.referenceDocument',
        default: 'Documento',
    },

    dueDate: {
        id: 'financialdocuments.dueDate',
        defaultMessage: 'Data vencimento',
    },

    documentDate: {
        id: 'financialdocuments.documentDate',
        defaultMessage: 'Data documento',
    },

    documentValue: {
        id: 'financialdocuments.documentValue',
        defaultMessage: 'Valor documento',
    },

    statusDescription: {
        id: 'financialdocuments.statusDescription',
        defaultMessage: 'Status',
    },

    company: {
        id: 'financialdocuments.company',
        defaultMessage: 'Empresa',
    },

    documentNumber: {
        id: 'financialdocuments.documentNumber',
        defaultMessage: 'Número do documento',
    },

    documentType: {
        id: 'financialdocuments.documentType',
        defaultMessage: 'Tipo documento',
    },

    collectionIdRef: {
        id: 'financialdocuments.collectionIdRef',
        defaultMessage: 'Id recebível ref.',
    },

    contractorId: {
        id: 'financialdocuments.contractorId',
        defaultMessage: 'ID contratante',
    },

    fiscalYear: {
        id: 'financialdocuments.fiscalYear',
        defaultMessage: 'Ano documento',
    },

    clientCpfCnpj: {
        id: 'financialdocuments.clientCpfCnpj',
        defaultMessage: 'CPF/CNPJ',
    },

    clientErpId: {
        id: 'financialdocuments.clientErpId',
        defaultMessage: 'Id ERP',
    },

    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },

    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },
});

export default messages;
