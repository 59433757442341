import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    name: {
        id: 'contacts.name',
        defaultMessage: 'Nome',
    },

    email: {
        id: 'contacts.email',
        defaultMessage: 'Email',
    },

    id: {
        id: 'contacts.id',
        defaultMessage: 'Id contato',
    },

    customerId: {
        id: 'contacts.customerId',
        defaultMessage: 'Id Cliente',
    },

    phoneNumber: {
        id: 'contacts.phoneNumber',
        defaultMessage: 'Telefone',
    },

    contactType: {
        id: 'contacts.contactType',
        defaultMessage: 'Tipo do Contato',
    },

    contactSource: {
        id: 'contacts.contactSource',
        defaultMessage: 'Manual/Interface',
    },

    customerCpfCnpj: {
        id: 'contacts.customerCpfCnpj',
        defaultMessage: 'CPF/CNPJ',
    },

    role: {
        id: 'contacts.role',
        defaultMessage: 'Função',
    },

    company: {
        id: 'contacts.company',
        defaultMessage: 'Contratante',
    },

    password: {
        id: 'contacts.password',
        defaultMessage: 'Senha',
    },

    passwordDoesNotMatch: {
        id: 'contacts.passwordDoesNotMatch',
        defaultMessage: 'Senha diferente',
    },

    confirmPassword: {
        id: 'contacts.confirmPassword',
        defaultMessage: 'Confirmar Senha',
    },

    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },

    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },

    titleMap: {
        id: 'access.title',
        defaultMessage: 'Selecione o acesso desejado',
    },

    contractorName: {
        id: 'contractors.titleOnlyContractor',
        defaultMessage: 'Contratante',
    },

    roleName: {
        id: 'roles.name',
        defaultMessage: 'Perfil',
    },

    newAccess: {
        id: 'contacts.newAccess',
        defaultMessage: 'Novo acesso',
    },

    newPasswordButtonText: {
        id: 'contacts.newPasswordButtonText',
        defaultMessage: 'Nova senha',
    },
    blocked: {
        id: 'contacts.blocked',
        defaultMessage: 'Usuário bloqueado',
    },
    systemContact: {
        id: 'contacts.systemContact',
        defaultMessage: 'Usuário de sistema',
    },
});

export default messages;
