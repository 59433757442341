import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'transactions.title',
        defaultMessage: 'Recebimento',
    },

    titleTransaction: {
        id: 'transactions.titleTransaction',
        defaultMessage: 'Recebimento {entity}',
    },

    subtitle: {
        id: 'transactions.subtitle',
        defaultMessage: 'Recebimento(s) encontrado(s)',
    },

    transactionCode: {
        id: 'transactions.transactionCode',
        defaultMessage: 'ID Recebível',
    },

    id: {
        id: 'transactions.id',
        defaultMessage: 'Id',
    },

    source: {
        id: 'collections.source',
        defaultMessage: 'Origem',
    },

    transactionItemCode: {
        id: 'transactions.transactionItemCode',
        defaultMessage: 'Nr. Documento ERP',
    },

    transactionEnterprise: {
        id: 'transactions.transactionEnterprise',
        defaultMessage: 'Empresa',
    },

    paymentMethodDetail: {
        id: 'transactions.paymentMethodDetail',
        defaultMessage: 'Detalhe Metódo Pagamento',
    },

    operatorId: {
        id: 'transactions.operatorId',
        defaultMessage: 'Adquirente',
    },

    transactionServiceSheetCode: {
        id: 'transactions.transactionServiceSheetCode',
        defaultMessage: 'Ano Documento',
    },

    transactionpaymentMethod: {
        id: 'transactions.transactionpaymentMethod',
        defaultMessage: 'Método de Pagamento',
    },

    PaymentMethodDetail: {
        id: 'transactions.PaymentMethodDetail',
        defaultMessage: 'Detalhe Método de Pagamento',
    },

    comissionFeeValue: {
        id: 'transactions.comissionFeeValue',
        defaultMessage: 'Tarifa Adquirente',
    },

    transactionDescription: {
        id: 'fields.description',
        defaultMessage: 'Descrição',
    },

    transactionValue: {
        id: 'transactions.transactionValue',
        defaultMessage: 'Valor pago',
    },

    proposedValue: {
        id: 'transactions.proposedValue',
        defaultMessage: 'Valor pago',
    },

    totalValue: {
        id: 'transactions.totalValue',
        defaultMessage: 'Valor proposta',
    },

    client: {
        id: 'transactions.client',
        defaultMessage: 'Cliente',
    },

    clientNumber: {
        id: 'transactions.clientNumber',
        defaultMessage: 'Id Cliente',
    },

    clientName: {
        id: 'transactions.clientName',
        defaultMessage: 'Nome do cliente',
    },

    clientDocumentNumber: {
        id: 'transactions.clientDocumentNumber',
        defaultMessage: 'Documento do cliente',
    },

    selectedCollectionIds: {
        id: 'transaction.selectedCollectionIds',
        defaultMessage: 'Recebível',
    },

    referenceDocument: {
        id: 'transaction.referenceDocument',
        defaultMessage: 'Doc. Referencia',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    qtdInvoices: {
        id: 'home.qtdInvoices',
        defaultMessage: 'NF',
    },

    unavailableTransaction: {
        id: 'transactions.unavailableTransaction',
        defaultMessage: 'Recebimento indisponível',
    },

    payDate: {
        id: 'transactions.payDate',
        defaultMessage: 'Data de pagamento',
    },

    createDate: {
        id: 'transactions.createDate',
        defaultMessage: 'Data criação',
    },

    payTime: {
        id: 'transactions.payTime',
        defaultMessage: 'Hora de pagamento',
    },

    documentDate: {
        id: 'maps.documentDate',
        defaultMessage: 'Atualizado em',
    },
    modalTitle: {
        id: 'collections.modalTitle',
        defaultMessage: 'Enviar email de cobrança',
    },
    yesText: {
        id: 'collections.yesText',
        defaultMessage: 'Enviar',
    },
    noText: {
        id: 'collections.noText',
        defaultMessage: 'Cancelar',
    },
    alternativeEmail: {
        id: 'collections.alternativeEmail',
        defaultMessage: 'E-mail alternativo',
    },
});

export default messages;
