import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    filterButton: {
        id: 'global.filterButton',
        defaultMessage: 'Filtrar',
    },

    restoreButton: {
        id: 'global.restoreButton',
        defaultMessage: 'Restaurar',
    },

    filterBy: {
        id: 'global.filterBy',
        defaultMessage: 'Filtrar por',
    },

    operator: {
        id: 'global.operator',
        defaultMessage: 'Operador',
    },

    value: {
        id: 'global.value',
        defaultMessage: 'Valor',
    },

    equalTo: {
        id: 'comparisonOperators.equalTo',
        defaultMessage: 'igual a',
    },

    notEqualTo: {
        id: 'comparisonOperators.notEqualTo',
        defaultMessage: 'não é igual a',
    },

    greaterThan: {
        id: 'comparisonOperators.greaterThan',
        defaultMessage: 'maior que',
    },

    greaterThanOrEqualTo: {
        id: 'comparisonOperators.greaterThanOrEqualTo',
        defaultMessage: 'maior ou igual a',
    },

    lessThan: {
        id: 'comparisonOperators.lessThan',
        defaultMessage: 'menor que',
    },

    lessThanOrEqualTo: {
        id: 'comparisonOperators.lessThanOrEqualTo',
        defaultMessage: 'menor ou igual a',
    },

    like: {
        id: 'comparisonOperators.like',
        defaultMessage: 'contém',
    },
    addFilter: {
        id: 'global.addFilter',
        defaultMessage: 'Adicionar filtro',
    },
    removeFilter: {
        id: 'global.removeFilter',
        defaultMessage: 'Remover filtro',
    },
});

export default messages;
