import React, { Component } from 'react';

import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from './RoleListPage.intl';
import * as actions from '../../store/actions/index';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import EvtTable from '../../components/EvtTable/EvtTable';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import { filterPropertyTypes } from '../../utils/enums';
import { AuthContext } from '../../context/AuthContext';

class RoleListPage extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
        query: '',
    };

    reloadRolesList = () => {
        const showContractorFilter = this.context.hasClaim('role-level-full');
        const { query } = this.state;
        this.props.onInitRolesCount(query);
        this.props.onInitRoles(query);
        if (showContractorFilter) {
            this.props.onInitContractors();
        }
    };

    handleNewClick = () => {
        this.props.history.push(`/roles/new`);
    };

    handleGetTrProps = (state, rowInfo) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: () =>
                this.props.history.push(`/roles/${rowInfo.original.id}`),
        };
    };

    handleRefreshButtonClick = () => {
        this.reloadRolesList();
    };

    handleFiltersChange = (filters) => {
        this.setState({
            filters,
        });
    };

    handleEvTableStateChange = (query) => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadRolesList();
            },
        );
    };

    render() {
        const { intl, loadingList, roles, rolesCount } = this.props;
        const { filters } = this.state;
        const contractors = this.props.contractors.map((item) => {
            return {
                key: item.contractorId,
                value: item.displayName,
            };
        });

        const newRoleButtonEnabled = this.context.hasClaim('role-create');
        const showContractorFilter = this.context.hasClaim('role-level-full');
        const properties = [
            {
                key: 'name',
                value: intl.formatMessage(messages.name),
                type: filterPropertyTypes.TEXT,
            },
        ];

        if (showContractorFilter) {
            properties.push({
                key: 'contractorId',
                value: intl.formatMessage(messages.company),
                type: filterPropertyTypes.ENUM,
                enums: contractors,
            });
        }

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.name)}</span>
                    </div>
                ),
                accessor: 'name',
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.company)}</span>
                    </div>
                ),
                accessor: 'contractorName',
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/roles',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={intl.formatMessage(messages.subtitle, {
                                length: this.props.rolesCount,
                            })}
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>

                        {newRoleButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleOnlyRole,
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={roles}
                        length={rolesCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'createdAt',
                                desc: true,
                            },
                        ]}
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contractors: state.contractorReducer.contractors || [],
        roles: state.roleReducer.roles || [],
        rolesCount: state.roleReducer.rolesCount || 0,
        loadingList: state.roleReducer.loadingList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitRoles: (filter) => dispatch(actions.initRoles(filter)),
        onInitRolesCount: (filter) => dispatch(actions.initRolesCount(filter)),
        onInitContractors: () =>
            dispatch(actions.initContractors('?skip=0&take=100')),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(RoleListPage),
);
