import * as actionTypes from '../actions/actionTypes';

const initialState = {
    fileId: null,
    file: null,
};

const setFileId = (state, action) => {
    return {
        fileId: action.fileId,
    };
};

const setFile = (state, action) => {
    return {
        ...state,
        file: action.file,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FILES_CREATE_SUCCESS:
            return setFileId(state, action);
        case actionTypes.FILES_GET_SUCCESS:
            return setFile(state, action);
        default:
            return state;
    }
};

export default reducer;
