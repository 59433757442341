import * as actionTypes from '../actions/actionTypes';

const initialState = {
    users: [],
    user: null,
    roles: [],
    accesses: [],
    loadingDetails: true,
    loadingList: true,
    usersCount: 0,
    emailSent: false,
    contractorsId: [],
};

const setUsers = (state, action) => {
    return {
        ...state,
        users: action.users,
        loadingList: false,
    };
};

const setUsersCount = (state, action) => {
    return {
        ...state,
        usersCount: action.usersCount,
    };
};

const setUsersRequest = (state, action) => {
    return {
        ...state,
        users: action.users,
        loadingList: true,
    };
};

const setRoles = (state, action) => {
    return {
        ...state,
        roles: action.roles,
        loadingDetails: false,
    };
};

const setSendPassword = (state) => {
    return {
        ...state,
        emailSent: true,
        loadingDetails: false,
    };
};

const setUserDetails = (state, action) => {
    return {
        ...state,
        user: action.user,
        accesses: action.user.accesses,
        contractorsId: action.user.accesses.map((x) => x.contractorId),
        loadingDetails: false,
        emailSent: false,
    };
};

const setUserDetailsPost = (state, action) => {
    return {
        ...state,
        user: action.user,
        loadingDetails: false,
        users: [],
    };
};

const setUserDetailsNew = (state) => {
    return {
        ...state,
        user: undefined,
        accesses: [],
        loadingDetails: false,
        emailSent: false,
        contractorsId: [],
    };
};

const setUserDetailsRequest = (state) => {
    return {
        ...state,
        user: undefined,
        loadingDetails: true,
        emailSent: false,
        contractorsId: [],
    };
};

const setUserAccess = (state, action) => {
    const accesses = action.accesses.slice();

    return {
        ...state,
        accesses,
        loadingDetails: false,
        contractorsId: action.contractorsId,
    };
};

const setUserAccessInitial = (state) => {
    return {
        ...state,
        contractorsId: [],
        loadingDetails: true,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USERS_GET_LIST_REQUEST:
            return setUsersRequest(state, action);

        case actionTypes.USERS_GET_LIST_FAILURE:
        case actionTypes.USERS_GET_LIST_SUCCESS:
            return setUsers(state, action);

        case actionTypes.USERS_GET_COUNT_SUCCESS:
            return setUsersCount(state, action);

        case actionTypes.USER_GET_DETAILS_SUCCESS:
            return setUserDetails(state, action);

        case actionTypes.USER_POST_SUCCESS:
        case actionTypes.USER_POST_FAILURE:
            return setUserDetailsPost(state, action);

        case actionTypes.USER_GET_DETAILS_REQUEST:
        case actionTypes.USER_POST_REQUEST:
        case actionTypes.USER_PUT_REQUEST:
            return setUserDetailsRequest(state, action);

        case actionTypes.USER_GET_DETAILS_NEW:
            return setUserDetailsNew(state, action);

        case actionTypes.ROLES_GET_LIST_SUCCESS:
            return setRoles(state, action);

        case actionTypes.USER_ADD_ACCESS:
            return setUserAccess(state, action);

        case actionTypes.USER_ADD_ACCESS_INITIAL:
            return setUserAccessInitial(state, action);

        case actionTypes.USER_SEND_PASSWORD_SUCCESS:
            return setSendPassword(state, action);

        default:
            return state;
    }
};

export default reducer;
