import * as actionTypes from '../actions/actionTypes';

const initialState = {
    financialDocuments: [],
    financialDocument: null,
    financialDocumentsCount: 0,
    loadingDetails: true,
    loadingList: true,
    redirect: false,
    query: '',
};

const setFinancialDocuments = (state, action) => {
    return {
        ...state,
        financialDocuments: action.financialdocuments,
        loadingList: false,
    };
};

const setFinancialDocumentsRequest = (state) => {
    return {
        ...state,
        loadingList: true,
    };
};

const setCard = (state, action) => {
    return { ...state, redirect: action.bool };
};

const setCardQuery = (state, action) => {
    return { ...state, query: action.query };
};

const setFinancialDocumentsCount = (state, action) => {
    return {
        ...state,
        financialDocumentsCount: action.financialDocumentsCount,
    };
};

const setFinancialDocumentDetails = (state, action) => {
    return {
        ...state,
        financialDocument: action.financialdocument,
        loadingDetails: false,
    };
};

const setFinancialDocumentDetailsPost = (state, action) => {
    return {
        ...state,
        financialDocument: action.financialDocument,
        loadingDetails: false,
        financialDocuments: [],
    };
};

const setFinancialDocumentDetailsNew = (state) => {
    return {
        ...state,
        financialDocument: undefined,
        loadingDetails: false,
    };
};

const setFinancialDocumentDetailsRequest = (state) => {
    return {
        ...state,
        financialDocument: undefined,
        loadingDetails: true,
        emailSent: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FINANCIAL_DOCUMENTS_GET_LIST_REQUEST:
            return setFinancialDocumentsRequest(state, action);

        case actionTypes.FINANCIAL_DOCUMENTS_GET_LIST_FAILURE:
        case actionTypes.FINANCIAL_DOCUMENTS_GET_LIST_SUCCESS:
            return setFinancialDocuments(state, action);

        case actionTypes.FINANCIAL_DOCUMENT_GET_DETAILS_SUCCESS:
            return setFinancialDocumentDetails(state, action);

        case actionTypes.FINANCIAL_DOCUMENT_POST_SUCCESS:
        case actionTypes.FINANCIAL_DOCUMENT_POST_FAILURE:
            return setFinancialDocumentDetailsPost(state, action);

        case actionTypes.FINANCIAL_DOCUMENT_GET_DETAILS_REQUEST:
        case actionTypes.FINANCIAL_DOCUMENT_POST_REQUEST:
            return setFinancialDocumentDetailsRequest(state, action);

        case actionTypes.FINANCIAL_DOCUMENT_GET_DETAILS_NEW:
            return setFinancialDocumentDetailsNew(state, action);

        case actionTypes.FINANCIAL_DOCUMENT_CARD:
            return setCard(state, action);

        case actionTypes.FINANCIAL_DOCUMENT_CARD_QUERY:
            return setCardQuery(state, action);

        case actionTypes.FINANCIAL_DOCUMENTS_GET_COUNT_SUCCESS:
            return setFinancialDocumentsCount(state, action);

        default:
            return state;
    }
};

export default reducer;
