import React from 'react';

const UserIcon = ({
    width = '26',
    height = '30',
    viewBox = '0 0 36 40',
    fill = 'none',
    style = '',
}) => (
    <svg
        width={width}
        height={height}
        fill={fill}
        viewBox={viewBox}
        style={style}
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M25.2727 28.1818V25.2727C25.2727 23.7296 24.6597 22.2498 23.5686 21.1586C22.4775 20.0675 20.9976 19.4545 19.4545 19.4545H7.81818C6.2751 19.4545 4.79523 20.0675 3.70411 21.1586C2.61299 22.2498 2 23.7296 2 25.2727V28.1818'
            stroke='black'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M13.6364 13.6364C16.8497 13.6364 19.4545 11.0315 19.4545 7.81818C19.4545 4.60489 16.8497 2 13.6364 2C10.4231 2 7.81818 4.60489 7.81818 7.81818C7.81818 11.0315 10.4231 13.6364 13.6364 13.6364Z'
            stroke='black'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M34 28.1818V25.2727C33.999 23.9836 33.57 22.7313 32.7802 21.7125C31.9904 20.6936 30.8845 19.9659 29.6364 19.6436'
            stroke='black'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M23.8182 2.1891C25.0697 2.50954 26.179 3.23739 26.9711 4.25792C27.7633 5.27844 28.1932 6.53358 28.1932 7.82547C28.1932 9.11735 27.7633 10.3725 26.9711 11.393C26.179 12.4135 25.0697 13.1414 23.8182 13.4618'
            stroke='black'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default UserIcon;
