import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';
import Sidebar from '../Sidebar/Sidebar';
import Loading from '../LoadingV2/index';

import { AuthProvider } from '../../context/AuthContext';

import layoutCSS from './Layout.css';

class Layout extends Component {
    constructor(props) {
        super(props);

        const sideBarState = localStorage.getItem('SIDE_BAR_STATE');

        this.state = {
            isMenuCollapsed: sideBarState === 'true',
        };

        this.hasBootstrappedCSS = false;
        this.toogleCollapseMenu = this.toogleCollapseMenu.bind(this);
    }

    componentDidMount() {
        this.props.onInitCurrentSession();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.session !== this.props.session) {
            const accesses = this.props.accesses || {};

            if (!accesses || accesses.length === 0) {
                this.props.onLogout();
            }
        }
    }

    handleAccessChange = () => {
        this.props.onInitCurrentSession();
    };

    handleLogoutClick = () => {
        this.props.onLogout();
    };

    toogleCollapseMenu = () => {
        localStorage.setItem('SIDE_BAR_STATE', !this.state.isMenuCollapsed);

        this.setState({
            ...this.state,
            isMenuCollapsed: !this.state.isMenuCollapsed,
        });
    };

    bootstrapCSSandLogoIfNeeded() {
        const session = this.props.session || {};
        const contractor = session.contractor || {};
        // replace all '$primary-color' for the contractor's primary color
        this.bootstrapCSS(
            layoutCSS.split('$primary-color').join(contractor.primaryColor),
        );
    }

    bootstrapCSS(css) {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = css;
        document.getElementsByTagName('head')[0].appendChild(style);
        this.hasBootstrappedCSS = true;
    }

    render() {
        const { session, loading } = this.props;

        const contractor = session && session.contractor;

        let logoUrl = '';
        let logoMiniUrl = '';

        if (contractor) {
            logoUrl = `${window.API_URI}/Files/${contractor.normalLogoFileId}`;
            logoMiniUrl = `${window.API_URI}/Files/${
                contractor.smallLogoFileId
            }`;
            this.bootstrapCSSandLogoIfNeeded();
            this.bootstrapCSS();
        }

        return loading || !session ? (
            <Loading loading />
        ) : (
            <section className='system'>
                <Sidebar
                    handleLogoutClick={this.handleLogoutClick}
                    logoSrc={logoUrl}
                    logoMiniSrc={logoMiniUrl}
                    isMenuCollapsed={this.state.isMenuCollapsed}
                    username={session.user?.name}
                    contractor={session.contractor?.displayName}
                    hasClaim={session.hasClaim}
                    collapseMenu={this.toogleCollapseMenu}
                    handleAccessChange={this.handleAccessChange}
                />
                <main
                    className={`main-content ${
                        this.state.isMenuCollapsed ? 'small' : ''
                    }`}
                >
                    <AuthProvider session={session}>
                        {this.props.children}
                    </AuthProvider>
                </main>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.authenticationReducer.loading,
        session: state.authenticationReducer.session,
        accesses: state.authenticationReducer.accesses,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => dispatch(actions.logout()),
        onInitCurrentSession: () => dispatch(actions.getCurrentSession()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);
