import { toast } from 'react-toastify';
import axios from 'axios';
import { push } from 'connected-react-router';
import * as actionTypes from './actionTypes';

export const initCollections = (filter) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.COLLECTIONS_GET_LIST_REQUEST,
        });

        axios
            .get(`/collections${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.COLLECTIONS_GET_LIST_SUCCESS,
                    collections: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.COLLECTIONS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initCollectionsCount = (filter) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.COLLECTIONS_GET_COUNT_REQUEST,
        });
        await axios
            .get(`/Collections/Count${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.COLLECTIONS_GET_COUNT_SUCCESS,
                    collectionsCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.COLLECTIONS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initCollectionDetails = (collectionId) => {
    return (dispatch) => {
        if (collectionId === 'new') {
            dispatch({
                type: actionTypes.COLLECTION_GET_DETAILS_NEW,
            });
        } else {
            dispatch({
                type: actionTypes.COLLECTION_GET_DETAILS_REQUEST,
            });

            axios
                .get(`/Collections/${collectionId}`)
                .then(function(response) {
                    dispatch({
                        type: actionTypes.COLLECTION_GET_DETAILS_SUCCESS,
                        collection: response.data,
                    });
                })
                .catch(function(error) {
                    dispatch({
                        type: actionTypes.COLLECTION_GET_DETAILS_FAILURE,
                        error,
                    });
                });
        }
    };
};

export const createCollection = (values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.COLLECTION_POST_REQUEST,
        });

        axios
            .post(`/Collections`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.COLLECTION_POST_SUCCESS,
                    collectionId: response.data,
                });

                toast.success('Pedido criado com sucesso');
                dispatch(push('/collections'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.COLLECTION_POST_FAILURE,
                    error,
                });
            });
    };
};

export const updateCollection = (collectionId, values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.COLLECTION_PUT_REQUEST,
        });

        axios
            .put(`/Collections/${collectionId}`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.COLLECTION_PUT_SUCCESS,
                });
                toast.success('Pedido alterado com sucesso');
                dispatch(push('/collections'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.COLLECTION_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const installmentsCreate = (values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.INSTALLMENTS_CREATE_REQUEST,
        });

        axios
            .post(`/Collections/SplitBilling`, { ...values })
            .then((response) => {
                dispatch({
                    type: actionTypes.INSTALLMENTS_CREATE_SUCCESS,
                    accessLink: response?.data.accessLink,
                });
                toast.success('Parcelamento criado com sucesso');
                dispatch(push('/collections'));
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.INSTALLMENTS_CREATE_FAILURE,
                    error,
                });
            });
    };
};

export const installmentsClean = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.INSTALLMENTS_CREATE_CLEAN,
        });
    };
};

export const uploadCollectionAttachment = (file) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.COLLECTION_UPLOAD_ATTACHMENT_REQUEST,
        });

        const data = new FormData();
        data.append('file', file);

        axios
            .post(`/Collections/PostExcel`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(() => {
                dispatch({
                    type: actionTypes.COLLECTION_UPLOAD_ATTACHMENT_SUCCESS,
                });

                toast.success('As collections foram atualizadas com sucesso.');
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.COLLECTION_UPLOAD_ATTACHMENT_FAILURE,
                    error,
                });
                console.log(error);
            });
    };
};

export const collectionCopyLink = (collectionId) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.COLLECTION_COPY_LINK_REQUEST,
        });
        axios
            .get(`/Collections/${collectionId}/PaymentLink`)
            .then((response) => {
                dispatch({
                    type: actionTypes.COLLECTION_COPY_LINK_SUCCESS,
                    paymentLink: response.data,
                });
                toast.success('Link copiado com sucesso');
            })
            .catch(
                () => {
                    dispatch({
                        type: actionTypes.COLLECTION_COPY_LINK_FAILURE,
                        paymentLink: '',
                    });
                },
                function() {
                    toast.success('Erro ao obter o link');
                },
            );
    };
};

export const collectionResendEmail = (collectionId, email) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.COLLECTION_POST_EMAIL_REQUEST,
        });
        email
            ? axios
                  .post(
                      `/Collections/resendmail/${collectionId}?email=${email}`,
                  )
                  .then(function() {
                      dispatch({
                          type: actionTypes.COLLECTION_POST_EMAIL_SUCCESS,
                      });
                      toast.success('E-mail enviado com sucesso');
                  })
                  .catch(
                      (error) => {
                          dispatch({
                              type: actionTypes.COLLECTION_POST_EMAIL_FAILURE,
                              error,
                          });
                      },
                      function() {
                          toast.success('Erro ao enviar e-mail');
                      },
                  )
            : axios
                  .post(`/Collections/resendmail/${collectionId}`)
                  .then(function() {
                      dispatch({
                          type: actionTypes.COLLECTION_POST_EMAIL_SUCCESS,
                      });
                      toast.success('E-mail enviado com sucesso');
                  })
                  .catch(
                      (error) => {
                          dispatch({
                              type: actionTypes.COLLECTION_POST_EMAIL_FAILURE,
                              error,
                          });
                      },
                      function() {
                          toast.success('Erro ao enviar e-mail');
                      },
                  );
    };
};
