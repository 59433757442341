import React, { useState, useEffect, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { Row, Col, FormGroup } from 'reactstrap';

import messages from './ParameterDetailsForm.intl';
import { AuthContext } from '../../context/AuthContext';
import DetailSection from '../DetailSection/DetailSection';

function ParameterDetailsForm(props) {
    const context = useContext(AuthContext);

    const { intl, isNew } = props;

    const parameter = props.parameter || {};

    const canCreateParameter = context.hasClaim('parameter-create') && isNew;
    const canUpdateParameter = context.hasClaim('parameter-update') && !isNew;
    const isReadOnly = !(canCreateParameter || canUpdateParameter);
    const [, setContractorId] = useState(null);
    const [, setShowContractorsSelect] = useState(false);

    useEffect(() => {
        const { contractorId } = context;

        if (context.claims.length === 0) return;

        if (context.hasClaim('role-level-full')) {
            setShowContractorsSelect(true);
        } else if (context.hasClaim('role-level-contractor')) {
            setShowContractorsSelect(false);
            setContractorId(contractorId);
        }
    }, [context]);

    useEffect(() => {
        if (parameter.parameterId) {
            setContractorId(parameter.contractorId);
        }
    }, [parameter]);

    const contractorsOption = new Map();
    contractorsOption.set(
        parameter && parameter.contractorId,
        parameter && parameter.contractorName,
    );

    return (
        <DetailSection title='Detalhes do Parâmetro'>
            <AvForm onValidSubmit={props.handleSubmit}>
                <Row>
                    <Col lg={6} md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.name)}
                                type='text'
                                name='name'
                                id='name'
                                value={parameter.name || ''}
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>{' '}
                    <Col lg={6} md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.low)}
                                type='text'
                                name='low'
                                id='low'
                                value={parameter.low || ''}
                                disabled={isReadOnly}
                                errorMessage=' '
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.description)}
                                type='text'
                                name='description'
                                id='description'
                                value={parameter.description || ''}
                                disabled={isReadOnly}
                                errorMessage=' '
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12}>
                        {!isReadOnly && (
                            <button
                                type='submit'
                                className='btn-submit'
                                disabled={props.loading}
                            >
                                {intl.formatMessage(messages.submitButton)}
                            </button>
                        )}
                    </Col>
                </Row>
            </AvForm>
        </DetailSection>
    );
}

export default injectIntl(ParameterDetailsForm);
