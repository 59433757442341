import * as actionTypes from '../actions/actionTypes';

const initialState = {
    parameters: [],
    parameter: null,
    loadingDetails: true,
    loadingList: true,
};

const setParameters = (state, action) => {
    return {
        ...state,
        parameters: action.parameters,
        loadingList: false,
    };
};

const setParametersCount = (state, action) => {
    return {
        ...state,
        parametersCount: action.parametersCount,
    };
};

const setParametersRequest = (state, action) => {
    return {
        ...state,
        parameters: action.parameters,
        loadingList: true,
    };
};

const setParameterDetails = (state, action) => {
    return {
        ...state,
        parameter: action.parameter,
        loadingDetails: false,
    };
};

const setParameterDetailsPost = (state, action) => {
    return {
        ...state,
        parameter: action.parameter,
        loadingDetails: false,
        parameters: [],
    };
};

const setParameterLoading = (state) => {
    return {
        ...state,
        loadingDetails: false,
    };
};

const setParameterDetailsNew = (state) => {
    return {
        ...state,
        parameter: undefined,
        loadingDetails: false,
    };
};

const setParameterDetailsRequest = (state) => {
    return {
        ...state,
        parameter: undefined,
        loadingDetails: true,
    };
};

const setUploadAttachmentRequest = (state) => {
    return {
        ...state,
        loadingAttachment: true,
    };
};

const setUploadAttachment = (state) => {
    return {
        ...state,
        loadingAttachment: false,
        attachmentFileId: `${state.attachmentFileId}`,
    };
};

const setUploadAttachmentFailure = (state) => {
    return {
        ...state,
        loadingAttachment: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PARAMETERS_GET_LIST_REQUEST:
            return setParametersRequest(state, action);

        case actionTypes.PARAMETERS_GET_LIST_FAILURE:
        case actionTypes.PARAMETERS_GET_LIST_SUCCESS:
            return setParameters(state, action);

        case actionTypes.PARAMETER_PUT_SUCCESS:
        case actionTypes.PARAMETER_GET_DETAILS_FAILURE:
        case actionTypes.PARAMETER_GET_DETAILS_SUCCESS:
            return setParameterDetails(state, action);

        case actionTypes.PARAMETER_POST_SUCCESS:
        case actionTypes.PARAMETER_POST_FAILURE:
            return setParameterDetailsPost(state, action);

        case actionTypes.PARAMETER_PUT_FAILURE:
            return setParameterLoading(state, action);

        case actionTypes.PARAMETER_POST_REQUEST:
        case actionTypes.PARAMETER_GET_DETAILS_REQUEST:
            return setParameterDetailsRequest(state, action);

        case actionTypes.PARAMETER_GET_DETAILS_NEW:
            return setParameterDetailsNew(state, action);

        case actionTypes.PARAMETERS_GET_COUNT_SUCCESS:
            return setParametersCount(state, action);

        case actionTypes.PARAMETER_UPLOAD_ATTACHMENT_REQUEST:
            return setUploadAttachmentRequest(state, action);

        case actionTypes.PARAMETER_UPLOAD_ATTACHMENT_SUCCESS:
            return setUploadAttachment(state, action);

        case actionTypes.PARAMETER_UPLOAD_ATTACHMENT_FAILURE:
            return setUploadAttachmentFailure(state, action);

        default:
            return state;
    }
};

export default reducer;
