import { toast } from 'react-toastify';
import axios from 'axios';
import { push } from 'connected-react-router';
import * as actionTypes from './actionTypes';

export const initTransactions = (filter) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.TRANSACTIONS_GET_LIST_REQUEST,
        });

        axios
            .get(`/transactions?${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.TRANSACTIONS_GET_LIST_SUCCESS,
                    transactions: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.TRANSACTIONS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initTransactionsCount = (filter) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.TRANSACTIONS_GET_COUNT_REQUEST,
        });
        await axios
            .get(`/Transactions/Count?${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.TRANSACTIONS_GET_COUNT_SUCCESS,
                    transactionsCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.TRANSACTIONS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initTransactionDetails = (transactionId) => {
    return (dispatch) => {
        if (transactionId === 'new') {
            dispatch({
                type: actionTypes.TRANSACTION_GET_DETAILS_NEW,
            });
        } else {
            dispatch({
                type: actionTypes.TRANSACTION_GET_DETAILS_REQUEST,
            });

            axios
                .get(`/Transactions/${transactionId}`)
                .then(function(response) {
                    dispatch({
                        type: actionTypes.TRANSACTION_GET_DETAILS_SUCCESS,
                        transaction: response.data,
                    });
                })
                .catch(function(error) {
                    dispatch({
                        type: actionTypes.TRANSACTION_GET_DETAILS_FAILURE,
                        error,
                    });
                });
        }
    };
};

export const createTransaction = (values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.TRANSACTION_POST_REQUEST,
        });

        axios
            .post(`/Transactions`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.TRANSACTION_POST_SUCCESS,
                    transactionId: response.data,
                });

                toast.success('Pedido criado com sucesso');
                dispatch(push('/transactions'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.TRANSACTION_POST_FAILURE,
                    error,
                });
            });
    };
};

export const updateTransaction = (transactionId, values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.TRANSACTION_PUT_REQUEST,
        });

        axios
            .put(`/Transactions/${transactionId}`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.TRANSACTION_PUT_SUCCESS,
                });
                toast.success('Pedido alterado com sucesso');
                dispatch(push('/transactions'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.TRANSACTION_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const downloadCsv = ({ valueFrom, valueTo }) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.TRANSACTION_GET_EXPORT_REQUEST,
        });
        // Convertendo horário local para horário UTC
        const startPayDate = valueFrom
            ? new Date(`${valueFrom}T00:00:00.000`).toISOString()
            : '';
        const endPayDate = valueTo
            ? new Date(`${valueTo}T23:59:59.999`).toISOString()
            : '';
        axios
            .get(
                `/transactions/ExportData?startPayDate=${startPayDate}&endPayDate=${endPayDate}`,
            )
            .then(function() {
                dispatch({
                    type: actionTypes.TRANSACTION_GET_EXPORT_SUCCESS,
                    loadingExport: false,
                });
                window.location.href = `${
                    window.API_URI
                }/transactions/ExportData?startPayDate=${startPayDate}&endPayDate=${endPayDate}`;
                toast.success('Arquivo baixado com sucesso');
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.TRANSACTION_GET_EXPORT_FAILURE,
                    loadingExport: false,
                    error,
                });
            });
    };
};

export const transactionCopyLink = (transactionId) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.TRANSACTION_COPY_LINK_REQUEST,
        });
        axios
            .get(`/Transactions/${transactionId}/PaymentLink`)
            .then((response) => {
                dispatch({
                    type: actionTypes.TRANSACTION_COPY_LINK_SUCCESS,
                    paymentLink: response.data,
                });
                toast.success('Link copiado com sucesso');
            })
            .catch(
                () => {
                    dispatch({
                        type: actionTypes.TRANSACTION_COPY_LINK_FAILURE,
                        paymentLink: '',
                    });
                },
                function() {
                    toast.success('Erro ao obter o link');
                },
            );
    };
};

export const transactionResendEmail = (transactionId, email) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.TRANSACTION_POST_EMAIL_REQUEST,
        });
        email
            ? axios
                  .post(
                      `/Transactions/resendmail/${transactionId}?email=${email}`,
                  )
                  .then(function() {
                      dispatch({
                          type: actionTypes.TRANSACTION_POST_EMAIL_SUCCESS,
                      });
                      toast.success('E-mail enviado com sucesso');
                  })
                  .catch(
                      (error) => {
                          dispatch({
                              type: actionTypes.TRANSACTION_POST_EMAIL_FAILURE,
                              error,
                          });
                      },
                      function() {
                          toast.success('Erro ao enviar e-mail');
                      },
                  )
            : axios
                  .post(`/Transactions/resendmail/${transactionId}`)
                  .then(function() {
                      dispatch({
                          type: actionTypes.TRANSACTION_POST_EMAIL_SUCCESS,
                      });
                      toast.success('E-mail enviado com sucesso');
                  })
                  .catch(
                      (error) => {
                          dispatch({
                              type: actionTypes.TRANSACTION_POST_EMAIL_FAILURE,
                              error,
                          });
                      },
                      function() {
                          toast.success('Erro ao enviar e-mail');
                      },
                  );
    };
};

export const uploadTransactionAttachment = (file) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.TRANSACTION_UPLOAD_ATTACHMENT_REQUEST,
        });

        const data = new FormData();
        data.append('file', file);

        axios
            .post(`/Collections/PostExcelCollectionsAndTransactions`, data)
            .then(() => {
                dispatch({
                    type: actionTypes.TRANSACTION_UPLOAD_ATTACHMENT_SUCCESS,
                });

                toast.success('As transactions foram atualizadas com sucesso.');
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.TRANSACTION_UPLOAD_ATTACHMENT_FAILURE,
                    error,
                });
                console.log(error);
            });
    };
};
