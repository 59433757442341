export function paymentOperatorParse(type) {
    switch (type) {
        case 1:
            return 'PagSeguro';
        case 2:
            return 'Mercado Pago';
        case 3:
            return 'PicPay';
        case 4:
            return 'EBanx';
        case 5:
            return 'Ame Digital';
        case 6:
            return 'Rede';
        case 7:
            return 'Cielo';
        case 9:
            return 'GetNet';
        case 10:
            return 'BACEN';
        case 11:
            return 'Itaú';
        case 12:
            return 'Santander';
        case 99:
            return 'General';
        default:
            return type;
    }
}
