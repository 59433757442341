import React from 'react';

import { injectIntl } from 'react-intl';

import { Row, Col, FormGroup } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import messages from './BillingOfficeDetailsForm.intl';
import CardFieldForm from '../CardFieldForm/CardFieldForm';

function BillingOfficeDetailsForm(props) {
    const {
        intl,
        readOnly,
        handleToggleModal,
        loading,
        contractorList,
        isSystem,
    } = props;
    const billingOffice = props.billingOffice || {};

    return (
        <AvForm onValidSubmit={props.handleSubmit}>
            <Row>
                <Col>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.name)}
                            type='text'
                            name='name'
                            id='name'
                            value={billingOffice.name || ''}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.displayName)}
                            type='text'
                            name='displayName'
                            id='displayName'
                            value={billingOffice.displayName || ''}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.email)}
                            type='email'
                            name='emailAddress'
                            id='email'
                            value={billingOffice.emailAddress || ''}
                            errorMessage=' '
                            required
                            validate={{
                                pattern: {
                                    value:
                                        '^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
                                    errorMessage: intl.formatMessage(
                                        messages.invalidEmail,
                                    ),
                                },
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.cnpj)}
                            type='text'
                            name='cnpj'
                            id='cnpj'
                            value={billingOffice.cnpj || ''}
                            errorMessage=' '
                            validate={{
                                pattern: {
                                    value:
                                        '^([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}|[0-9]{11}|[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}|[0-9]{11}|[0-9]{14})$',
                                    errorMessage: intl.formatMessage(
                                        messages.invalidCNPJ,
                                    ),
                                },
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <AvField
                        label={intl.formatMessage(messages.primaryColorText)}
                        type='color'
                        name='primaryColor'
                        id='primaryColor'
                        disabled={readOnly}
                        value={billingOffice.primaryColor || ''}
                    />
                </Col>
            </Row>
            {isSystem && (
                <CardFieldForm
                    loading={props.loading}
                    handleToggleModal={props.handleToggleModal}
                    cardsDefault={
                        contractorList &&
                        contractorList.map((contractor) => {
                            return {
                                id: contractor.contractorId,
                                title: contractor.displayName,
                                subtitle: contractor.documentNumber1,
                                logoFileId: contractor.normalLogoFileId,
                            };
                        })
                    }
                    cards={
                        billingOffice.contractors &&
                        billingOffice.contractors.map((contractor) => {
                            return {
                                id: contractor.id,
                                title: contractor.displayName,
                                subtitle: contractor.documentNumber1,
                                logoFileId: contractor.normalLogoFileId,
                            };
                        })
                    }
                    name='contractorsId'
                    titleTopic='Contratantes'
                    titleModal='Contratante'
                    handleGetProps={(id) => window.open(`/contractors/${id}`)}
                />
            )}
            <Row className='mt-3'>
                <Col xs={12}>
                    <br />
                    <div className='mt-3'>
                        <button
                            type='submit'
                            className='btn-submit'
                            disabled={loading}
                        >
                            {intl.formatMessage(messages.saveButton)}
                        </button>
                        {readOnly && (
                            <button
                                type='button'
                                className='btn-delete'
                                onClick={handleToggleModal}
                            >
                                {intl.formatMessage(messages.deleteButton)}
                            </button>
                        )}
                    </div>
                </Col>
            </Row>
        </AvForm>
    );
}

export default injectIntl(BillingOfficeDetailsForm);
