import axios from 'axios';
import * as actionTypes from './actionTypes';

export const initWidgets = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.WIDGETS_GET_REQUEST,
        });

        return axios
            .get('/Kpi')
            .then((response) => {
                dispatch({
                    type: actionTypes.WIDGETS_GET_SUCCESS,
                    widgets: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.WIDGETS_GET_FAILURE,
                    error,
                });
            });
    };
};

export const initWidget = (widgetId, url, days) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.WIDGET_GET_REQUEST,
        });

        return axios
            .get(`${url}${days}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.WIDGET_GET_SUCCESS,
                    payload: { widgetId, data: response.data },
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.WIDGET_GET_FAILURE,
                    error,
                });
            });
    };
};

export const generateColors = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.COLORS_GET_SUCCESS,
            colors: [
                '#3a64fd',
                '#3afdd3',
                '#3ac6fd',
                '#d33afd',
                '#713afd',
                '#723afd',
            ],
        });
    };
};
export const initFinancialDocumentsCards = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FINANCIAL_DOCUMENTS_CARDS_GET_REQUEST,
        });

        return axios
            .get('/Kpi/financialdocuments')
            .then((response) => {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENTS_CARDS_GET_SUCCESS,
                    cards: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENTS_CARDS_GET_FAILURE,
                    error,
                });
            });
    };
};
