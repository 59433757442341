import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'contractors.title',
        defaultMessage: 'Contratantes',
    },

    titleContractor: {
        id: 'contractors.titleContractor',
        defaultMessage: 'Contratante {entity}',
    },

    registrationInfo: {
        id: 'contractors.registrationInfo',
        defaultMessage: 'Dados Cadastrais',
    },

    deleteContractor: {
        id: 'contractors.deleteContractor',
        defaultMessage: 'Excluir Contratante',
    },

    name: {
        id: 'global.name',
        defaultMessage: 'Nome',
    },

    cnpj: {
        id: 'global.cnpj',
        defaultMessage: 'CPF/CNPJ',
    },

    email: {
        id: 'global.email',
        defaultMessage: 'E-mail',
    },

    city: {
        id: 'global.city',
        defaultMessage: 'Cidade',
    },

    state: {
        id: 'global.state',
        defaultMessage: 'Estado',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
});

export default messages;
