/* eslint-disable  */
import { toast } from 'react-toastify';
import axios from 'axios';
import { push } from 'connected-react-router';
import * as actionTypes from './actionTypes';

export const initFeeMessageDetails = (feeMessageId) => {
    return (dispatch) => {
        if (feeMessageId === 'new') {
            return (dispatch) => {
                dispatch({
                    type: actionTypes.FEE_MESSAGE_GET_DETAILS_NEW,
                });
            };
        } else {
            dispatch({
                type: actionTypes.FEE_MESSAGE_GET_DETAILS_REQUEST,
            });

            axios
                .get(`/feemessagerule/${feeMessageId}`)
                .then(function(response) {
                    dispatch({
                        type: actionTypes.FEE_MESSAGE_GET_DETAILS_SUCCESS,
                        feeMessage: response.data,
                    });
                })
                .catch(function(error) {
                    dispatch({
                        type: actionTypes.FEE_MESSAGE_GET_DETAILS_FAILURE,
                        error,
                    });
                });
        }
    };
};

export const initFeeMessagesTypes = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FEE_MESSAGES_GET_TYPES_REQUEST,
        });
        axios.get(`/feemessagerule/messagetype`).then((response) => {
            dispatch({
                type: actionTypes.FEE_MESSAGES_GET_TYPES_SUCCESS,
                feeMessagesTypes: response.data,
            });
        });
    };
};

export const initContractorsFeeMessage = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FEE_MESSAGE_CONTRACTORS_GET_LIST_REQUEST,
        });

        axios
            .get(`/Contractors?sort=-createdAt&skip=0&take=100`)
            .then((response) => {
                dispatch({
                    type: actionTypes.FEE_MESSAGE_CONTRACTORS_GET_LIST_SUCCESS,
                    contractors: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.FEE_MESSAGE_CONTRACTORS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const createFeeMessage = (values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FEE_MESSAGE_POST_REQUEST,
        });

        axios
            .post(`/feemessagerule`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.FEE_MESSAGE_POST_SUCCESS,
                });
                toast.success('Taxa de evento criada com sucesso');
                dispatch(push('/tarifications?index=1'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.FEE_MESSAGE_POST_FAILURE,
                    error,
                });

                let errorMessage = '';
                if (error.response) {
                    errorMessage = error.response.data;
                } else if (error.request) {
                    errorMessage = error.request;
                } else {
                    errorMessage = error.message;
                }
                toast.error(
                    `Ocorreu um erro ao criar a taxa de evento. ${errorMessage}`,
                );
            });
    };
};

export const updateFeeMessage = (values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FEE_MESSAGE_PUT_REQUEST,
        });
        axios
            .put(`/feemessagerule`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.FEE_MESSAGE_PUT_SUCCESS,
                });
                toast.success('Taxa de evento alterada com sucesso');
                dispatch(push('/tarifications?index=1'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.FEE_MESSAGE_PUT_FAILURE,
                    error,
                });
                let errorMessage = '';
                if (error.response) {
                    errorMessage = error.response.data;
                } else if (error.request) {
                    errorMessage = error.request;
                } else {
                    errorMessage = error.message;
                }
                toast.error(
                    `Ocorreu um erro ao atualizar a taxa de evento. ${errorMessage}`,
                );
            });
    };
};

export const deleteFeeMessage = (feeMessageId) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FEE_MESSAGE_DELETE_REQUEST,
        });

        axios
            .delete(`/feemessagerule/${feeMessageId}`)
            .then(function() {
                dispatch({
                    type: actionTypes.FEE_MESSAGE_DELETE_SUCCESS,
                });
                toast.success('Taxa de evento excluída com sucesso');
                dispatch(push('/tarifications?index=1'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.FEE_MESSAGE_DELETE_FAILURE,
                    error,
                });
            });
    };
};
