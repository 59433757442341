/* eslint-disable consistent-return */
import React from 'react';

import UserIcon from './UserIcon';
import ProfileIcon from './ProfileIcon';
import LogIcon from './LogIcon';
import SettingsIcon from './SettingsIcon';
import LogoutIcon from './LogoutIcon';
import HomeIcon from './HomeIcon';
import ContractorIcon from './ContractorIcon';
import CollectionIcon from './CollectionIcon';
import ContactIcon from './ContactIcon';
import ParameterIcon from './ParameterIcon';
import TransactionIcon from './TransactionIcon';

const Icon = (props) => {
    switch (props.name) {
        case 'home':
            return <HomeIcon {...props} />;
        case 'contacts':
            return <ContactIcon {...props} />;
        case 'contractors':
            return <ContractorIcon {...props} />;
        case 'collections':
            return <CollectionIcon {...props} />;
        case 'transactions':
            return <TransactionIcon {...props} />;
        case 'parameters':
            return <ParameterIcon {...props} />;
        case 'users':
            return <UserIcon {...props} />;
        case 'profiles':
            return <ProfileIcon {...props} />;
        case 'logs':
            return <LogIcon {...props} />;
        case 'settings':
            return <SettingsIcon {...props} />;
        case 'logout':
            return <LogoutIcon {...props} />;
        default:
    }
};

export default Icon;
