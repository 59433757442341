export const apiErrorCodes = [
    {
        errorCode: 1001,
        id: 'apiErrors.company.documentNumberRequired',
        defaultMessage: 'Número do documento é obrigatório',
    },
    {
        errorCode: 1002,
        id: 'apiErrors.company.companyRequired',
        defaultMessage: 'Nome da empresa é obrigarótio',
    },
    {
        errorCode: 1003,
        id: 'apiErrors.company.displayNameRequired',
        defaultMessage: 'Nome fantasia é obrigatório',
    },
    {
        errorCode: 1004,
        id: 'apiErrors.company.emailRequired',
        defaultMessage: 'Endereço de e-mail é obrigatório',
    },
    {
        errorCode: 1005,
        id: 'apiErrors.company.invalidDocumentNumber',
        defaultMessage: 'Numero do documento inválido',
    },
    {
        errorCode: 1006,
        id: 'apiErrors.company.addressRequired',
        defaultMessage: 'Endereço obrigatório',
    },
    {
        errorCode: 1007,
        id: 'apiErrors.company.cityRequired',
        defaultMessage: 'Nome da cidade é obrigatório',
    },
    {
        errorCode: 1008,
        id: 'apiErrors.company.neighborhoodRequired',
        defaultMessage: 'Bairro é obrigatório',
    },
    {
        errorCode: 1009,
        id: 'apiErrors.company.addressNumberRequired',
        defaultMessage: 'Número do endereço é obrigatório',
    },
    {
        errorCode: 1010,
        id: 'apiErrors.company.zipcodeRequired',
        defaultMessage: 'CEP do endereço é obrigatório',
    },
    {
        errorCode: 1011,
        id: 'apiErrors.company.stateRequired',
        defaultMessage: 'Estado é obrigatório',
    },
    {
        errorCode: 1012,
        id: 'apiErrors.company.streetNameRequired',
        defaultMessage: 'Nome da rua é obrigatório',
    },
    {
        errorCode: 2001,
        id: 'apiErrors.contractor.documentNumberUsed',
        defaultMessage: 'CNPJ em uso por outro contratante',
    },
    {
        errorCode: 2002,
        id: 'apiErrors.contractor.contractorRequired',
        defaultMessage: 'Contratante é obrigatório',
    },
    {
        errorCode: 2003,
        id: 'apiErrors.contractor.contractorNotFound',
        defaultMessage: 'Contratante não encontrado',
    },
    {
        errorCode: 2004,
        id: 'apiErrors.contractor.contractorWrongUser',
        defaultMessage: 'Contratante não pertence ao usuário',
    },

    {
        errorCode: 4001,
        id: 'apiErrors.map.mapNotFoundContractor',
        defaultMessage: 'Mapeamento não encontrado para o contrato',
    },
    {
        errorCode: 4002,
        id: 'apiErrors.map.mapWrongContractor',
        defaultMessage: 'Mapeamento não pertence ao contrato',
    },
    {
        errorCode: 4003,
        id: 'apiErrors.map.duplicatedMap',
        defaultMessage: 'Mapeamento duplicado',
    },
    {
        errorCode: 4004,
        id: 'apiErrors.map.mapNotFound',
        defaultMessage: 'Mapeamento não encontrado',
    },
    {
        errorCode: 4005,
        id: 'apiErrors.map.mapAlreadyExists',
        defaultMessage: 'Mapeamento já existe',
    },
    {
        errorCode: 4006,
        id: 'apiErrors.map.mapCantBeNull',
        defaultMessage: 'Mapeamento não pode ser nulo',
    },
    {
        errorCode: 4007,
        id: 'apiErrors.map.mapRequired',
        defaultMessage: 'Mapeamento é obrigatório',
    },
    {
        errorCode: 5001,
        id: 'apiErrors.transaction.documentCodeRequired',
        defaultMessage: 'Código do documento é obrigatório',
    },
    {
        errorCode: 5002,
        id: 'apiErrors.transaction.documentItemCodeRequired',
        defaultMessage: 'Código do item do  documento é obrigatório',
    },
    {
        errorCode: 5003,
        id: 'apiErrors.transaction.dueDateRequired',
        defaultMessage: 'Data de vencimento é obrigatório',
    },
    {
        errorCode: 5004,
        id: 'apiErrors.transaction.valueGreater',
        defaultMessage: 'Valor da transação deve ser maior que zero',
    },
    {
        errorCode: 5005,
        id: 'apiErrors.transaction.currencyRequired',
        defaultMessage: 'Moeda é obrigatória',
    },
    {
        errorCode: 5006,
        id: 'apiErrors.transaction.transactionNotFound',
        defaultMessage: 'Transação não existe',
    },
    {
        errorCode: 5007,
        id: 'apiErrors.transaction.transactionAlreadyExists',
        defaultMessage: 'Transação já existe',
    },
    {
        errorCode: 5008,
        id: 'apiErrors.transaction.transactionWrongUser',
        defaultMessage: 'Transação não pertence ao usuário',
    },
    {
        errorCode: 6001,
        id: 'apiErrors.order.orderCodeRequired',
        defaultMessage: 'Código do pedido é obrigatório',
    },
    {
        errorCode: 6002,
        id: 'apiErrors.order.orderItemCodeRequired',
        defaultMessage: 'Código do item do pedido é obrigatório',
    },
    {
        errorCode: 6003,
        id: 'apiErrors.order.sheetCodeRequired',
        defaultMessage: 'Código da folha é obrigatório',
    },
    {
        errorCode: 6004,
        id: 'apiErrors.order.sheetItemCodeRequired',
        defaultMessage: 'Código do item da folha é obrigatório',
    },
    {
        errorCode: 6005,
        id: 'apiErrors.order.descriptionRequired',
        defaultMessage: 'Descrição é obrigatória',
    },
    {
        errorCode: 6006,
        id: 'apiErrors.order.amountRequired',
        defaultMessage: 'Quantidade é obrigatória',
    },
    {
        errorCode: 6007,
        id: 'apiErrors.order.measureRequired',
        defaultMessage: 'Unidade de medida é obrigatória',
    },
    {
        errorCode: 6008,
        id: 'apiErrors.order.valueRequired',
        defaultMessage: 'Valor é obrigatório',
    },
    {
        errorCode: 6009,
        id: 'apiErrors.order.currencyRequired',
        defaultMessage: 'Moeda é obrigatória',
    },
    {
        errorCode: 6010,
        id: 'apiErrors.order.orderNotFound',
        defaultMessage: 'Pedido não encontrado',
    },
    {
        errorCode: 6011,
        id: 'apiErrors.order.orderAlreadyExists',
        defaultMessage: 'Pedido já existe',
    },
    {
        errorCode: 6012,
        id: 'apiErrors.order.orderIdRequired',
        defaultMessage: 'ID do pedido é obrigatório',
    },
    {
        errorCode: 6013,
        id: 'apiErrors.order.orderWrongUser',
        defaultMessage: 'Pedido não pertence ao usuário logado',
    },
    {
        errorCode: 6014,
        id: 'apiErrors.order.orderIsRequired',
        defaultMessage: 'É obrigatório informar um número de pedido',
    },
    {
        errorCode: 7001,
        id: 'apiErrors.file.fileNotFound',
        defaultMessage: 'Arquivo não encotrado',
    },
    {
        errorCode: 7002,
        id: 'apiErrors.file.fileDeletionFailed',
        defaultMessage: 'Não foi possível deletar o arquivo.',
    },
    {
        errorCode: 7003,
        id: 'apiErrors.file.fileTooBig',
        defaultMessage: 'O arquivo excedeu o limite de 4 megabytes.',
    },
    {
        errorCode: 7004,
        id: 'apiErrors.file.fileUnsupportedImageType',
        defaultMessage: 'Tipo de imagem não suportado.',
    },
    {
        errorCode: 7005,
        id: 'apiErrors.file.fileUnsupportedDimensions',
        defaultMessage:
            'As dimensões da imagem deve ser entre 40x40 e 3200x3200.',
    },
    {
        errorCode: 8001,
        id: 'apiErrors.mapItem.mapItemNotFound',
        defaultMessage: 'Item do mapeamento não encontrado',
    },
    {
        errorCode: 8002,
        id: 'apiErrors.mapItem.fileAlreadyExists',
        defaultMessage: 'Arquivo já foi adicionado',
    },
    {
        errorCode: 9001,
        id: 'apiErrors.mapAction.actionNotFound',
        defaultMessage: 'Ação do mapeamento não encontrada',
    },

    {
        errorCode: 11000,
        id: 'apiErrors.user.emailRequired',
        defaultMessage: 'E-mail é obrigatório',
    },
    {
        errorCode: 11001,
        id: 'apiErrors.user.nameRequired',
        defaultMessage: 'Nome é obrigatório',
    },
    {
        errorCode: 11002,
        id: 'apiErrors.user.userAlreadyCreated',
        defaultMessage: 'Usuário já cadastrado',
    },
    {
        errorCode: 11003,
        id: 'apiErrors.user.loggedUserNotFound',
        defaultMessage: 'Usuário logado não encontrado',
    },
    {
        errorCode: 11011,
        id: 'apiErrors.user.emailAlreadyDuplicate',
        defaultMessage: 'Email já cadastrado',
    },
    {
        errorCode: 11004,
        id: 'apiErrors.user.userWithoutAccess',
        defaultMessage: 'Usuário não tem acesso a essa funcionalidade',
    },
    {
        errorCode: 11005,
        id: 'apiErrors.user.userNotFound',
        defaultMessage: 'Usuário não encontrado',
    },
    {
        errorCode: 11006,
        id: 'apiErrors.role.cannotBeDuplicated',
        defaultMessage:
            'Não é permitido salvar/associar dois perfis para o mesmo contratante',
    },
    {
        errorCode: 11007,
        id: 'apiErrors.user.userInvalidCredentials',
        defaultMessage:
            'Usuário/Senha inválidos ou você não tem acesso no sistema',
    },
    {
        errorCode: 11008,
        id: 'apiErrors.user.userInvalidPassword',
        defaultMessage: 'Senha inválida',
    },
    {
        errorCode: 11009,
        id: 'apiErrors.user.userPasswordAlreadyUsed',
        defaultMessage: 'Senha atual não pode ser utilizada.',
    },
    {
        errorCode: 11010,
        id: 'apiErrors.user.userSystemContractors',
        defaultMessage:
            'Usuário de sistema só pode ter acesso a um contratante.',
    },
    {
        errorCode: 11011,
        id: 'apiErrors.user.userSystemClaims',
        defaultMessage:
            'Ocorreu um erro na gravação dos níveis de acesso do usuário.',
    },
    {
        errorCode: 11012,
        id: 'apiErrors.user.userSystemClaims',
        defaultMessage:
            '"Usuário de sistema só pode ter acesso a um contratante.',
    },
    {
        errorCode: 12000,
        id: 'apiErrors.role.roleNotFound',
        defaultMessage: 'Perfil não encontrado',
    },
    {
        errorCode: 12001,
        id: 'apiErrors.role.roleNameRequired',
        defaultMessage: 'Nome do perfil é obrigatório',
    },
    {
        errorCode: 12003,
        id: 'apiErrors.role.roleExists',
        defaultMessage: 'Perfil já cadastrado',
    },

    {
        errorCode: 12004,
        id: 'apiErrors.role.claimNotInformed',
        defaultMessage: 'Nenhuma permissão informada',
    },
    {
        errorCode: 12005,
        id: 'apiErrors.role.claimNotFound',
        defaultMessage: 'Permissão não encontrada',
    },
    {
        errorCode: 12006,
        id: 'apiErrors.user.updateUserError',
        defaultMessage: 'Erro ao atualizar usuário',
    },
];
