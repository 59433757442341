/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as actions from '../../store/actions/index';

import messages from './TransactionsIntegrationErrors.intl';

import EvtFilter from '../../components/EvtFilter/EvtFilter';
import EvtTable from '../../components/EvtTable/EvtTable';

import { filterPropertyTypes } from '../../utils/enums';

class TransactionsIntegrationErrors extends Component {
    state = {
        activeTab: 0,
        filters: [],
        query: '',
    };

    reloadTransactionLogs = () => {
        const { query } = this.state;
        this.props.onInitTransactionLogsErrorCount(query);
        this.props.onInitTransactionLogsError(query);
    };

    handleFiltersChange = (filters) => {
        this.setState({
            filters,
        });
    };

    handleEvTableStateChange = (query) => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadTransactionLogs();
            },
        );
    };

    handleRefreshButtonClick = () => {
        this.reloadTransactionLogs();
    };

    render() {
        const {
            intl,
            loadingTransactionLogsError,
            transactionLogsError,
            transactionLogsErrorCount,
        } = this.props;
        const { filters } = this.state;

        const properties = [
            {
                key: 'createdAt',
                value: intl.formatMessage(messages.createdAt),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'collectionId',
                value: intl.formatMessage(messages.collectionCode),
                type: filterPropertyTypes.TEXT,
            },
        ];

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.collectionCode)}
                        </span>
                    </div>
                ),
                accessor: 'collectionId',
                maxWidth: 320,
                Cell: (row) => row.value,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.createdAt)}</span>
                    </div>
                ),
                accessor: 'createdAt',
                maxWidth: 220,
                Cell: (row) => {
                    return new Date(row.value).toLocaleString(
                        navigator.language,
                    );
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.description)}</span>
                    </div>
                ),
                accessor: 'error',
                sortable: false,
                Cell: (row) => row.value,
            },
        ];

        return (
            <>
                <header className='table-screen'>
                    <section className='title' />
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingTransactionLogsError}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingTransactionLogsError
                                    ? 'loading'
                                    : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                    </section>
                </header>
                <EvtTable
                    filters={filters}
                    columns={columns}
                    data={transactionLogsError}
                    length={transactionLogsErrorCount}
                    pageSize={10}
                    handleStateChange={this.handleEvTableStateChange}
                    handleGetTrProps={this.handleGetTrProps}
                    loading={loadingTransactionLogsError}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        transactionLogsError: state.logReducer.transactionLogsError || [],
        transactionLogsErrorCount: state.logReducer.transactionLogsErrorCount,
        loadingTransactionLogsError:
            state.logReducer.loadingTransactionLogsError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTransactionLogsError: (filters) =>
            dispatch(actions.initTransactionLogsError(filters)),
        onInitTransactionLogsErrorCount: (filters) =>
            dispatch(actions.initTransactionLogsErrorCount(filters)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TransactionsIntegrationErrors),
);
