import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    name: {
        id: 'detailsTab.name',
        defaultMessage: 'Nome',
    },
    cnpj: {
        id: 'detailsTab.cnpj',
        defaultMessage: 'CPF/CNPJ',
    },
    billing: {
        id: 'detailsTab.billing',
        defaultMessage: 'Emails para envio do faturamento',
    },
    street: {
        id: 'detailsTab.street',
        defaultMessage: 'Endereço',
    },
    number: {
        id: 'detailsTab.number',
        defaultMessage: 'Número',
    },
    complement: {
        id: 'detailsTab.complement',
        defaultMessage: 'Complemento',
    },
    neighborhood: {
        id: 'detailsTab.neighborhood',
        defaultMessage: 'Bairro',
    },
    domain: {
        id: 'detailsTab.domain',
        defaultMessage: 'Dominio',
    },
    city: {
        id: 'detailsTab.city',
        defaultMessage: 'Cidade',
    },

    state: {
        id: 'detailsTab.state',
        defaultMessage: 'Estado',
    },
    postalCode: {
        id: 'detailsTab.postalCode',
        defaultMessage: 'CEP',
    },
    email: {
        id: 'detailsTab.email',
        defaultMessage: 'E-mail',
    },
    invalidCNPJ: {
        id: 'global.invalidCNPJ',
        defaultMessage: 'CPF ou CNPJ inválido',
    },
    invalidEmail: {
        id: 'global.invalidEmail',
        defaultMessage: 'Email inválido',
    },
    invalidPostalCode: {
        id: 'global.invalidPostalCode',
        defaultMessage: 'CEP inválido',
    },
    companyName: {
        id: 'detailsTab.companyName',
        defaultMessage: 'Razão Social',
    },
    displayName: {
        id: 'detailsTab.displayName',
        defaultMessage: 'Nome Fantasia',
    },
    primaryColorText: {
        id: 'detailsTab.primaryColorText',
        defaultMessage: 'Cor primária',
    },
    secondaryColorText: {
        id: 'detailsTab.secondaryColorText',
        defaultMessage: 'Cor secundária',
    },
});

export default messages;
