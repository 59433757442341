import axios from 'axios';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import * as actionTypes from './actionTypes';

export const initBillingOfficers = (filter) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.BILLINGOFFICE_GET_LIST_REQUEST,
        });

        axios
            .get(`/BillingOffice${filter || '?sort=-createdAt&skip=0&take=10'}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_GET_LIST_SUCCESS,
                    billingOffices: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initBillingOfficersByContractId = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.BILLINGOFFICE_GET_LIST_REQUEST,
        });

        axios
            .get(`/billingoffice/getallbillingofficesbycontractorid`)
            .then((response) => {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_GET_LIST_SUCCESS,
                    billingOffices: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initBillingOfficerDetail = (id) => {
    if (id === 'new') {
        return (dispatch) => {
            dispatch({
                type: actionTypes.BILLINGOFFICE_GET_DETAILS_NEW,
            });
        };
    }
    return (dispatch) => {
        dispatch({
            type: actionTypes.BILLINGOFFICE_GET_DETAILS_REQUEST,
        });

        axios
            .get(`/BillingOffice/${id}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_GET_DETAILS_SUCCESS,
                    billingOffice: response.data,
                });
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_GET_DETAILS_FAILURE,
                    error,
                });
            });
    };
};

export const initBillingOfficersCount = (query) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.BILLINGOFFICE_GET_COUNT_REQUEST,
        });

        await axios
            .get(
                `/BillingOffice/Count${query ||
                    '?sort=-createdAt&skip=0&take=10'}`,
            )
            .then((response) => {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_GET_COUNT_SUCCESS,
                    billingOfficesCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_GET_COUNT_REQUEST,
                    error,
                });
            });
    };
};

export const createBillingOffice = (values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.BILLINGOFFICE_POST_REQUEST,
        });

        axios
            .post(`/BillingOffice`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_POST_SUCCESS,
                    contractorId: response.data,
                });

                toast.success('Escritório de cobranças criado com sucesso');
                dispatch(push('/contractors?index=1'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_POST_FAILURE,
                    error,
                });
            });
    };
};

export const uploadBillingOfficeNormalLogo = (id, file) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.BILLINGOFFICE_UPLOAD_NORMAL_LOGO_REQUEST,
        });

        const data = new FormData();
        data.append('logo', file);

        axios
            .put(`/BillingOffice/${id}/Logo`, data)
            .then(() => {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_UPLOAD_NORMAL_LOGO_SUCCESS,
                });

                toast.success(
                    'O logo do escritório de cobrança foi atualizado com sucesso.',
                );
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_UPLOAD_NORMAL_LOGO_FAILURE,
                    error,
                });
            });
    };
};

export const uploadBillingOfficeSmallLogo = (id, file) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.BILLINGOFFICE_UPLOAD_SMALL_LOGO_REQUEST,
        });

        const data = new FormData();
        data.append('smallLogo', file);

        axios
            .put(`/BillingOffice/${id}/SmallLogo`, data)
            .then(() => {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_UPLOAD_SMALL_LOGO_SUCCESS,
                });

                toast.success(
                    'O logo pequeno do escritório de cobranças foi atualizado com sucesso.',
                );
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_UPLOAD_SMALL_LOGO_FAILURE,
                    error,
                });
            });
    };
};

export const updateBillingOffice = (id, values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.BILLINGOFFICE_PUT_REQUEST,
        });

        axios
            .put(`/BillingOffice/${id}`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_PUT_SUCCESS,
                });
                toast.success('Escritório de cobranças alterado com sucesso');
                dispatch(push('/contractors?index=1'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const deleteBillingOffice = (id) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.BILLINGOFFICE_DELETE_REQUEST,
        });

        axios
            .delete(`/BillingOffice/${id}`)
            .then(function() {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_DELETE_SUCCESS,
                });
                toast.success('Escritório de cobranças excluído com sucesso');
                dispatch(push('/contractors?index=1'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.BILLINGOFFICE_DELETE_FAILURE,
                    error,
                });
            });
    };
};

export const initBillingOfficeDetails = (contractorId) => {
    return (dispatch) => {
        if (contractorId === 'new') {
            dispatch({
                type: actionTypes.BILLINGOFFICE_GET_DETAILS_NEW,
            });
        } else {
            dispatch({
                type: actionTypes.BILLINGOFFICE_GET_DETAILS_REQUEST,
            });

            axios
                .get(`/BillingOffice/${contractorId}`)
                .then(function(response) {
                    dispatch({
                        type: actionTypes.BILLINGOFFICE_GET_DETAILS_SUCCESS,
                        contractor: response.data,
                    });
                })
                .catch(function(error) {
                    dispatch({
                        type: actionTypes.BILLINGOFFICE_GET_DETAILS_FAILURE,
                        error,
                    });
                });
        }
    };
};
