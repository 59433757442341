import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import { Row, Col, FormGroup, Container } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import CardField from '../CardField/CardField';
import Modal from '../Modal/Modal';

import messages from './CardFieldForm.intl';

const CardFieldForm = (props) => {
    const {
        intl,
        cardsDefault,
        cards,
        loading,
        titleModal,
        titleTopic,
        name,
        handleGetProps,
    } = props;
    const [showModal, setShowModal] = useState(false);
    const [newCardId, setNewCardId] = useState();
    const [dataCard, setDataCard] = useState([]);

    useEffect(() => {
        setDataCard(cards || []);
    }, [cards]);

    const handleModal = () => {
        setShowModal(!showModal);
    };

    const onInputModalChange = (event) => {
        setNewCardId(event.target.value);
    };

    const onClickButtonSave = () => {
        const isNewCard =
            dataCard.filter((x) => newCardId && x.id === newCardId).length ===
            0;

        if (newCardId && isNewCard)
            setDataCard([
                ...dataCard,
                cardsDefault.filter((x) => x.id === newCardId)[0],
            ]);

        setNewCardId('');
        handleModal();
    };

    const handleDelete = (id) => {
        setDataCard(dataCard.filter((x) => x.id !== id));
    };

    return (
        <>
            <Row style={{ marginTop: 50 }}>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <p
                        style={{
                            color: '#A9A9A9',
                            fontSize: '25px',
                            paddingBottom: '15px',
                            paddingLeft: '10px',
                            borderBottom: '3px solid #A9A9A9',
                            marginBottom: '30px',
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '5px',
                        }}
                    >
                        {titleTopic}
                        <button
                            className='btn-primary'
                            type='button'
                            onClick={handleModal}
                        >
                            +
                        </button>
                    </p>
                </div>
            </Row>
            <Row>
                {dataCard &&
                    dataCard.map((info, index) => {
                        return (
                            <>
                                <AvField
                                    key={index}
                                    name={`${name}[${index}]`}
                                    value={info.id}
                                    hidden
                                />
                                <CardField
                                    image={
                                        info.logoFileId &&
                                        `${window.API_URI}/Files/${
                                            info.logoFileId
                                        }`
                                    }
                                    title={info.title}
                                    subtitle={info.subtitle}
                                    handleDelete={() => handleDelete(info.id)}
                                    handleGetProps={() =>
                                        handleGetProps(info.id)
                                    }
                                />
                            </>
                        );
                    })}
            </Row>
            <Modal
                title={`Selecione um ${titleModal}`}
                isOpen={showModal}
                handleToggle={handleModal}
            >
                <AvForm>
                    <Container fluid>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <AvField
                                        id='selectItem'
                                        type='select'
                                        name='selectItem'
                                        onChange={onInputModalChange}
                                    >
                                        <option> </option>
                                        {cardsDefault &&
                                            cardsDefault.map((data) => {
                                                return (
                                                    <option
                                                        key={data.id}
                                                        value={data.id}
                                                        hidden={
                                                            dataCard &&
                                                            dataCard.find(
                                                                (item) =>
                                                                    item.id ===
                                                                    data.id,
                                                            )
                                                        }
                                                    >
                                                        {data.title}
                                                    </option>
                                                );
                                            })}
                                    </AvField>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col xs={12}>
                                <button
                                    type='button'
                                    className='btn-submit'
                                    disabled={loading}
                                    onClick={onClickButtonSave}
                                >
                                    {intl.formatMessage(messages.saveButton)}
                                </button>
                            </Col>
                        </Row>
                    </Container>
                </AvForm>
            </Modal>
        </>
    );
};

export default injectIntl(CardFieldForm);
