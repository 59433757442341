import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'contacts.title',
        defaultMessage: 'Usuários',
    },

    subtitle: {
        id: 'contacts.subtitle',
        defaultMessage: ' {length} usuário(s) encontrado(s)',
    },

    titleOnlyContact: {
        id: 'contacts.titleOnlyContact',
        defaultMessage: 'Usuário',
    },

    name: {
        id: 'contacts.name',
        defaultMessage: 'Nome',
    },

    email: {
        id: 'contacts.email',
        defaultMessage: 'Email',
    },

    contactId: {
        id: 'contacts.id',
        defaultMessage: 'Id Contato',
    },

    customerId: {
        id: 'contacts.customerId',
        defaultMessage: 'Id Cliente',
    },

    customerCnpj: {
        id: 'contacts.customerCnpj',
        defaultMessage: 'CPF/CNPJ Cliente',
    },

    function: {
        id: 'contacts.role',
        defaultMessage: 'Função',
    },

    password: {
        id: 'contacts.password',
        defaultMessage: 'Senha',
    },

    role: {
        id: 'contacts.role',
        defaultMessage: 'Função',
    },

    phoneNumber: {
        id: 'contacts.phoneNumber',
        defaultMessage: 'Telefone',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
});

export default messages;
