import React from 'react';

export const TopicTitle = ({ title }) => {
    return (
        <div style={{ width: '100%', marginBottom: 10 }}>
            <p
                style={{
                    color: '#A9A9A9',
                    fontSize: '25px',
                    paddingBottom: '15px',
                    paddingLeft: '10px',
                    borderBottom: '3px solid #A9A9A9',
                    marginBottom: '30px',
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                }}
            >
                {title}
            </p>
        </div>
    );
};
