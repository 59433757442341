import Cookies from 'js-cookie';
import { formatErrorMessage } from '../../utils/apiErrorMessages/apiErrorMessages';

const initialState = {
    error: null,
    loading: false,
};

const reducer = (state = initialState, action) => {
    const { type } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

    const sessionId = Cookies.get('SessionId');

    if (!matches) return state;

    const [, , requestState] = matches;

    const error = action.error || {};
    const response = error.response || {};
    const data = response.data || {};

    const errorCode = data.errorCode;

    const formattedMessage = formatErrorMessage(errorCode, data);

    const errorMessage =
        requestState === 'FAILURE' &&
        (sessionId ||
            type === 'SESSION_FAILURE' ||
            type === 'USER_CHANGE_PASSWORD_FAILURE') &&
        formattedMessage;

    return {
        ...state,
        loading: requestState === 'REQUEST',
        error: errorMessage,
    };
};

export default reducer;
