// Dependencies
import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { FormGroup } from 'reactstrap';
import messages from '../../containers/LoginPage/LoginPage.intl';

export const LoginForm = (props) => {
    const {
        intl,
        contractorColor,
        handleLoginFormSubmit,
        handleForgotPasswordToogle,
    } = props;

    return (
        <AvForm onValidSubmit={handleLoginFormSubmit}>
            <FormGroup>
                <AvField
                    label={intl.formatMessage(messages.username)}
                    type='text'
                    name='username'
                    id='username'
                    errorMessage={intl.formatMessage(messages.invalidUsername)}
                    required
                />
            </FormGroup>

            <FormGroup>
                <AvField
                    label={intl.formatMessage(messages.password)}
                    type='password'
                    name='password'
                    id='password'
                    errorMessage=' '
                    required
                />
            </FormGroup>

            <div className='btns-login'>
                <button
                    style={{ backgroundColor: contractorColor }}
                    className='login-btn'
                    type='submit'
                >
                    {intl.formatMessage(messages.loginButton)}
                </button>

                <button
                    style={{ color: contractorColor }}
                    className='btn-link btnForgotPassword'
                    type='button'
                    onClick={handleForgotPasswordToogle}
                >
                    {intl.formatMessage(messages.forgotPassword)}
                </button>
            </div>
        </AvForm>
    );
};

export default injectIntl(LoginForm);
