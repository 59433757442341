/* eslint-disable consistent-return */
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from './ContactsListPage.intl';
import * as actions from '../../store/actions/index';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import EvtTable, {
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import { filterPropertyTypes } from '../../utils/enums';
import { AuthContext } from '../../context/AuthContext';
import { handleCpfCnpj } from '../../utils/handleCpfCnpj';

class ContactListPage extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
        query: '',
    };

    reloadContactsList = () => {
        const { query } = this.state;
        this.props.oninitContactsCount(query);
        this.props.oninitContacts(query);
    };

    handleRowClick = (e, id) => {
        this.props.history.push(`/contacts/${id}`);
    };

    handleNewClick = () => {
        this.props.history.push(`/contacts/new`);
    };

    handleGetTrProps = (state, rowInfo) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: () =>
                this.props.history.push(`/contacts/${rowInfo.original.id}`),
        };
    };

    handleRefreshButtonClick = () => {
        this.reloadContactsList();
    };

    handleFiltersChange = (filters) => {
        this.setState({
            filters,
        });
    };

    handleEvTableStateChange = (query) => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadContactsList();
            },
        );
    };

    render() {
        const { intl, loadingList, contacts, contactsCount } = this.props;
        const { filters } = this.state;
        const newContactButtonEnabled = this.context.hasClaim(
            'external-user-create',
        );

        const properties = [
            {
                key: 'contactId',
                value: intl.formatMessage(messages.contactId),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'customerId',
                value: intl.formatMessage(messages.customerId),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'clientCpfCnpj',
                value: intl.formatMessage(messages.customerCnpj),
                type: filterPropertyTypes.TEXT,
                map: (input) => input.replace(/[^0-9]/g, ''),
            },
            {
                key: 'contactName',
                value: intl.formatMessage(messages.name),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'phoneNumber',
                value: intl.formatMessage(messages.phoneNumber),
                type: filterPropertyTypes.TEXT,
                map: (input) => input.replace(/[^0-9]/g, ''),
            },
            {
                key: 'contactEmail',
                value: intl.formatMessage(messages.email),
                type: filterPropertyTypes.TEXT,
            },
        ];

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.contactId)}</span>
                    </div>
                ),
                accessor: 'contactId',
                minWidth: calculateColumnWidth(
                    contacts,
                    'contactId',
                    intl.formatMessage(messages.contactId),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.customerId)}</span>
                    </div>
                ),
                accessor: 'customerId',
                minWidth: calculateColumnWidth(
                    contacts,
                    'customerId',
                    intl.formatMessage(messages.customerId),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.customerCnpj)}</span>
                    </div>
                ),
                accessor: 'clientCpfCnpj',
                Cell: (row) => handleCpfCnpj(row.value),
                minWidth: calculateColumnWidth(
                    contacts,
                    'clientCpfCnpj',
                    intl.formatMessage(messages.customerCnpj),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.name)}</span>
                    </div>
                ),
                accessor: 'contactName',
                Cell: (row) => {
                    const id = row.index + 1;
                    return (
                        <div className='customer-container'>
                            <span className={`color-number color-number-${id}`}>
                                {row.value.substr(0, 1).toUpperCase()}
                            </span>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                minWidth: calculateColumnWidth(
                    contacts,
                    'contactName',
                    intl.formatMessage(messages.name),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.phoneNumber)}</span>
                    </div>
                ),
                accessor: 'phoneNumber',
                minWidth: calculateColumnWidth(
                    contacts,
                    'phoneNumber',
                    intl.formatMessage(messages.phoneNumber),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.email)}</span>
                    </div>
                ),
                accessor: 'contactEmail',
                minWidth: calculateColumnWidth(
                    contacts,
                    'contactEmail',
                    intl.formatMessage(messages.email),
                ),
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/contacts',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={intl.formatMessage(messages.subtitle, {
                                length: this.props.contactsCount,
                            })}
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>

                        {newContactButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.importButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleOnlyContact,
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={contacts}
                        length={contactsCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'createdAt',
                                desc: true,
                            },
                        ]}
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contacts: state.contactReducer.contacts || [],
        contactsCount: state.contactReducer.contactsCount || 0,
        loadingList: state.contactReducer.loadingList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        oninitContacts: (filter) => dispatch(actions.initContacts(filter)),
        oninitContactsCount: (filter) =>
            dispatch(actions.initContactsCount(filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ContactListPage),
);
