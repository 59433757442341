import { BeatHorizontalCard } from '@evt/beat';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import * as actions from '../../../store/actions/index';

function checkColor(title) {
    let color;
    switch (title) {
        case 'Documentos em aberto':
            color = '#FFD700';
            break;
        case 'Documentos vencidos':
            color = '#FA8072';
            break;
        case 'Documentos a vencer':
            color = '#3CB371';
            break;
        case 'Documentos quitados':
            color = '#6495ED';
            break;
        default:
            break;
    }
    return color;
}

const Card = (props) => (
    <div
        style={{
            cursor: 'pointer',
            margin: 10,
            backgroundColor: '#fff',
        }}
        onClick={() => {
            props.resetCurrentFilter('/financialDocuments');
            props.onInitFinancialDocuments(props.filter);
            props.onInitFinancialDocumentsCount(props.filter);
            props.onInitCard(true);
            props.onInitCardQuery(props.filter);
        }}
    >
        {props.redirect && <Redirect to='/financialDocuments' />}
        <BeatHorizontalCard
            width={350}
            title={props.title}
            secondaryText={props.secondaryText}
            textBody={props.textBody}
            footerContents={
                <div
                    style={{
                        background: checkColor(props.title),
                        width: 290,
                        height: 20,
                        borderRadius: 10,
                    }}
                />
            }
        />
    </div>
);

const mapDispatchToProps = (dispatch) => {
    return {
        onInitFinancialDocuments: (filter) =>
            dispatch(actions.initFinancialDocuments(filter)),
        onInitCard: (bool) => dispatch(actions.setCard(bool)),
        onInitFinancialDocumentsCount: (filter) =>
            dispatch(actions.initFinancialDocumentsCount(filter)),
        resetCurrentFilter: (...args) =>
            dispatch(actions.resetCurrentFilter(...args)),
        onInitCardQuery: (query) => dispatch(actions.setCardQuery(query)),
    };
};
const mapStateToProps = (state) => {
    return {
        redirect: state.financialDocumentsReducer.redirect,
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Card),
);
