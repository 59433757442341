import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { AuthContext } from '../../context/AuthContext';

import * as actions from '../../store/actions/index';

import Header from '../../components/Header/Header';
import Tabs from '../../components/Tabs/Tabs';
import messages from './SettingsPage.intl';
import {
    SettingsPassword,
    // GeneralSettings,
} from '../../components/SettingsOptions';

class SettingsPage extends Component {
    static contextType = AuthContext;

    state = {
        activeTab: 0,
    };

    componentDidMount() {
        this.props.onInitConfigurationGeneralSettings(
            this.context.hasClaim('setting-general-view'),
        );
    }

    handleChangePasswordFormSubmit = (e, values) => {
        this.props.onChangePassword(
            values.newPassword,
            values.confirmNewPassword,
        );
    };

    handleUpdateSettingsFromSubmit = (e, values) => {
        if (values.email.maxMontlySendedEmailNumber === '')
            values.email.maxMontlySendedEmailNumber = null;

        if (values.email.allowSendEmailOnlyForThisDomains === '')
            values.email.allowSendEmailOnlyForThisDomains = null;

        if (values.sms.maxMontlySendedSmsNumber === '')
            values.sms.maxMontlySendedSmsNumber = null;

        if (values.sms.allowSendSMSOnlyForThisNumber === '')
            values.sms.allowSendSMSOnlyForThisNumber = null;

        this.props.onUpdateSettingsFromSubmit(values);
    };

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }
    };

    render() {
        const { intl /* settings */ } = this.props;
        // const canViewSettingGeneral = this.context.hasClaim(
        //     'setting-general-view',
        // );
        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: 'Dashboard',
                            active: false,
                        },
                        {
                            path: '/settings',
                            name: 'Configurações',
                            active: true,
                        },
                    ]}
                />
                <header>
                    <section className='title'>
                        <Header title={intl.formatMessage(messages.title)} />
                    </section>
                </header>
                <section className='content-middle bg-content mt-2'>
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={[
                            {
                                name:
                                    messages.passwordSettingsTitle
                                        .defaultMessage,
                                component: (
                                    <SettingsPassword
                                        intl={intl}
                                        handleChangePasswordFormSubmit={
                                            this.handleChangePasswordFormSubmit
                                        }
                                    />
                                ),
                            },
                            // canViewSettingGeneral && {
                            //     name:
                            //         messages.generalSettingsTitle
                            //             .defaultMessage,
                            //     component: (
                            //         <GeneralSettings
                            //             settings={settings}
                            //             handleUpdateSettingsFromSubmit={
                            //                 this.handleUpdateSettingsFromSubmit
                            //             }
                            //         />
                            //     ),
                            // },
                        ]}
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.settingsReducer.settings,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChangePassword: (newPassword, confirmNewPassword) =>
            dispatch(
                actions.changePassword({
                    newPassword,
                    confirmNewPassword,
                }),
            ),
        onUpdateSettingsFromSubmit: (values) =>
            dispatch(actions.updateSettingsFromSubmit(values)),
        onInitConfigurationGeneralSettings: (hasClaim) =>
            dispatch(actions.initConfigurationGeneralSettings(hasClaim)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(SettingsPage),
);
