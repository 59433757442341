import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    collectionCode: {
        id: 'collections.collectionCode',
        defaultMessage: 'Código Recebível',
    },

    collectionItemCode: {
        id: 'collections.collectionItemCode',
        defaultMessage: 'Código Item Recebível',
    },

    collectionServiceSheetCode: {
        id: 'collections.collectionServiceSheetCode',
        defaultMessage: 'Código Folha',
    },

    collectionServiceSheetItemCode: {
        id: 'collections.collectionServiceSheetItemCode',
        defaultMessage: 'Código Item Folha',
    },

    integrationId: {
        id: 'collections.collectionIntegrationId',
        defaultMessage: 'Id. Integração',
    },

    accountingDocumentNumber: {
        id: 'collections.collectionAccountingDocumentNumber',
        defaultMessage: 'Nr. Documento ERP',
    },

    collectionId: {
        id: 'collections.collectionCollectionId',
        defaultMessage: 'Id. Recebível',
    },

    enterprise: {
        id: 'collections.collectionEnterprise',
        defaultMessage: 'Id. Empresa',
    },

    fiscalYear: {
        id: 'collections.collectionFiscalYear',
        defaultMessage: 'Ano Documento',
    },

    issuedAccountingLine: {
        id: 'collections.collectionIssuedAccountingLine',
        defaultMessage: 'Sequencial Documento',
    },

    documentType: {
        id: 'collections.collectionDocumentType',
        defaultMessage: 'Tipo Documento',
    },

    companyCnpj: {
        id: 'collections.companyCnpj',
        defaultMessage: 'Empresa',
    },

    clientNumber: {
        id: 'collections.collectionClientNumber',
        defaultMessage: 'Id Cliente',
    },

    clientName: {
        id: 'collections.collectionClientName',
        defaultMessage: 'Nome Cliente',
    },

    clientEmails: {
        id: 'collections.collectionClientEmails',
        defaultMessage: 'E-mail',
    },

    clientPhoneNumbers: {
        id: 'collections.collectionClientPhoneNumbers',
        defaultMessage: 'Nr. Telefone',
    },

    documentDate: {
        id: 'collections.collectionDocumentDate',
        defaultMessage: 'Data Emissão',
    },

    dueDate: {
        id: 'collections.collectionDueDate',
        defaultMessage: 'Vencimento',
    },

    daysInArrears: {
        id: 'collections.collectionDaysInArrears',
        defaultMessage: 'Dias em Atraso',
    },

    billValue: {
        id: 'collections.collectionBillValue',
        defaultMessage: 'Valor original',
    },

    billAdjustedValue: {
        id: 'collections.collectionBillAdjustedValue',
        defaultMessage: 'Valor documento(com correções)',
    },

    billDischargeValue: {
        id: 'collections.collectionBillDischargeValue',
        defaultMessage: 'Valor proposta',
    },

    totalBillDischarge: {
        id: 'collections.collectionTotalBillDischarge',
        defaultMessage: 'Valor da proposta com quitação total',
    },

    referenceDocument: {
        id: 'collections.referenceDocument',
        defaultMessage: 'Número Referência',
    },

    cpfCnpj: {
        id: 'collections.cpfCnpj',
        defaultMessage: 'CPF/CNPJ',
    },

    collectionAmount: {
        id: 'collections.amount',
        defaultMessage: 'Quantidade',
    },

    measureUnit: {
        id: 'collections.measureUnit',
        defaultMessage: 'Measure Unit',
    },

    value: {
        id: 'global.value',
        defaultMessage: 'Total do Recebível',
    },

    currency: {
        id: 'global.currency',
        defaultMessage: 'Currency',
    },
    unitValue: {
        id: 'global.unitValue',
        defaultMessage: 'Valor Unitário',
    },

    isAvailable: {
        id: 'global.isAvailable',
        defaultMessage: 'Disponível',
    },
    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },
    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },
    company: {
        id: 'roles.company',
        defaultMessage: 'Contratante',
    },
});

export default messages;
