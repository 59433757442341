import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';

import * as actions from '../../store/actions/index';

import Tabs from '../../components/Tabs/Tabs';
import Header from '../../components/Header/Header';

import ParameterDetailsForm from '../../components/ParameterDetailsForm/ParameterDetailsForm';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './ParameterDetailsPage.intl';
import { AuthContext } from '../../context/AuthContext';
import ParameterFileForm from '../../components/ParameterFileForm/ParameterFileForm';

class ParameterDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        activeTab: 0,
        showModal: false,
    };

    componentDidMount() {
        this.props.onInitParameterDetails(this.props.match.params.id);
    }

    handleNewAttachmentSaveButtonClick = (file) => {
        this.props.onUploadParameterAttachment(file);
        this.props.history.push(`/parameters`);
    };

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }
    };

    handleSubmit = (event, values) => {
        if (this.props.loadingDetails) return;

        if (!values.contractorId) {
            const { contractorId } = this.context;
            values.contractorId = contractorId;
        }

        if (this.props.match.params.id === 'new') {
            this.props.onCreateParameter(values);
        } else {
            this.props.onUpdateParameter(this.props.match.params.id, values);
        }
    };

    handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handleContractorsChange = (selectedContractor) => {
        this.setState({
            selectedContractor: {
                selectedLabelOption: selectedContractor.label,
                selectedValueOption: selectedContractor.value,
            },
        });
    };

    render() {
        const { intl, loadingDetails, loadingAttachment } = this.props;

        const isNew = this.props.match.params.id === 'new';

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/parameters',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/parameters/:id',
                            name: intl.formatMessage(messages.titleParameter, {
                                entity:
                                    this.props.parameter &&
                                    this.props.parameter.parameterDescription,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.titleParameter, {
                                entity: '',
                            })}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content parameterDetails'>
                    <Loading loading={loadingDetails} />
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={
                            isNew
                                ? [
                                      {
                                          name: 'Importar Arquivo',
                                          component: (
                                              <ParameterFileForm
                                                  key='filesTab'
                                                  handleNewAttachmentSaveButtonClick={
                                                      this
                                                          .handleNewAttachmentSaveButtonClick
                                                  }
                                                  loadingAttachment={
                                                      loadingAttachment
                                                  }
                                              />
                                          ),
                                      },
                                  ]
                                : [
                                      {
                                          name: intl.formatMessage(
                                              messages.registrationInfo,
                                          ),
                                          component: (
                                              <ParameterDetailsForm
                                                  parameter={
                                                      this.props.parameter
                                                  }
                                                  handleSubmit={
                                                      this.handleSubmit
                                                  }
                                                  handleContractorsChange={
                                                      this
                                                          .handleContractorsChange
                                                  }
                                                  isNew={isNew}
                                                  loading={
                                                      this.props.loadingDetails
                                                  }
                                                  selectedContractor={
                                                      this.state
                                                          .selectedContractor
                                                  }
                                              />
                                          ),
                                      },
                                  ]
                        }
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        parameter: state.parameterReducer.parameter,
        loadingDetails: state.parameterReducer.loadingDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitParameterDetails: (parameterId) =>
            dispatch(actions.initParameterDetails(parameterId)),
        onUpdateParameter: (parameterId, values) =>
            dispatch(actions.updateParameter(parameterId, values)),
        onCreateParameter: (values) =>
            dispatch(actions.createParameter(values)),
        onUploadParameterAttachment: (file) =>
            dispatch(actions.uploadParameterAttachment(file)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ParameterDetailsPage),
);
