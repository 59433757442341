import React from 'react';
import { injectIntl } from 'react-intl';

import messages from './MonthlyConsumptionCounterWidget.intl';

const MonthlyConsumptionCounterWidget = (props) => {
    const { intl } = props;
    const value = props.data ? props.data : 0;
    const cssPie = `c100 p${value}`;
    return (
        <>
            <div className={cssPie}>
                <span>{value}%</span>
                <div className='slice'>
                    <div className='bar' />
                    <div className='fill' />
                </div>
            </div>
            <p className='text-center'>
                {intl.formatMessage(messages.monthlyConsumptionCounter)}
            </p>
        </>
    );
};

export default injectIntl(MonthlyConsumptionCounterWidget);
