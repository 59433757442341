/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { injectIntl } from 'react-intl';
import * as actions from '../../store/actions/index';
import Widget from './Widget/Widget';
import Header from '../../components/Header/Header';
import messages from './Dashboard.intl';
import { AuthContext } from '../../context/AuthContext';
import Card from '../../components/BeatCard/FinancialDocumentsCard/index';

const ResponsiveGridLayout = WidthProvider(Responsive);

class Dashboard extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            mode: 'static',
            widgets: [],
            days: '7',
            options: 'none',
        };

        this.handleGridLayoutChange = this.handleGridLayoutChange.bind(this);
    }

    componentDidMount() {
        if (this.context.hasClaim('widget-view')) {
            const color = this.context.contractor.primaryColor;

            const quantidade = 4;
            this.props.onInitWidgets();
            this.props.onGenerateColors(color, quantidade);
        }

        this.context.hasClaim('financial-document-view') &&
            this.props.oninitFinancialDocumentsCards();
    }

    componentDidUpdate(prevProps) {
        const widgets = this.props.widgets;

        if (prevProps.widgets !== this.props.widgets) {
            const localStorageLayout = this.getFromLocalStorage('layout');
            const originalLayout =
                JSON.parse(JSON.stringify(localStorageLayout || '')) || [];
            const newWidgets = widgets.map((w) => {
                const layout = originalLayout.find((l) => l.i === w.widgetId);
                let widgetLayout = layout || w.layout;

                widgetLayout = widgetLayout || {
                    i: w.widgetId,
                    ...w.layout,
                };

                return {
                    ...w,
                    layout: {
                        ...widgetLayout,
                        isDraggable: false,
                        isResizable: false,
                    },
                };
            });

            this.setState({
                widgets: newWidgets,
            });
        }
    }

    saveToLocalStorage(key, value) {
        if (global.localStorage) {
            global.localStorage.setItem(
                'DASHBOARD',
                JSON.stringify({
                    [key]: value,
                }),
            );
        }
    }

    getFromLocalStorage(key) {
        let ls = {};
        if (global.localStorage) {
            try {
                ls = JSON.parse(global.localStorage.getItem('DASHBOARD')) || {};
            } catch (e) {
                return null;
            }
        }
        return ls[key];
    }

    handleGridLayoutChange = () => {
        let layout;
        switch (true) {
            case window.screen.width < 1300:
                layout = [
                    { w: 6, h: 4, x: 0, y: 0, i: '5d713678562183c8bd8bf3f8' },
                    { w: 3, h: 4, x: 0, y: 4, i: '5d713678562183c8bd8bf3f9' },
                    { w: 3, h: 4, x: 4, y: 8, i: '5d713678562183c8bd8bf3f10' },
                    { w: 3, h: 4, x: 0, y: 12, i: '5d713678562183c8bd8bf3f11' },
                    { w: 3, h: 4, x: 4, y: 16, i: '5d713678562183c8bd8bf3f12' },
                    { w: 3, h: 4, x: 0, y: 20, i: '5d713678562183c8bd8bf3f13' },
                    { w: 3, h: 4, x: 4, y: 24, i: '5d713678562183c8bd8bf3f14' },
                    { w: 3, h: 4, x: 0, y: 28, i: '5d713678562183c8bd8bf3f15' },
                    { w: 3, h: 4, x: 4, y: 32, i: '5d713678562183c8bd8bf3f16' },
                ];
                break;

            case window.screen.width < 1500:
                layout = [
                    { w: 10, h: 4, x: 0, y: 0, i: '5d713678562183c8bd8bf3f8' },
                    {
                        w: 5,
                        h: 4,
                        x: 0,
                        y: 4,
                        i: '5d713678562183c8bd8bf3f9',
                    },
                    {
                        w: 5,
                        h: 4,
                        x: 5,
                        y: 8,
                        i: '5d713678562183c8bd8bf3f10',
                    },
                    {
                        w: 5,
                        h: 4,
                        x: 0,
                        y: 12,
                        i: '5d713678562183c8bd8bf3f11',
                    },
                    {
                        w: 5,
                        h: 4,
                        x: 5,
                        y: 16,
                        i: '5d713678562183c8bd8bf3f12',
                    },
                    {
                        w: 5,
                        h: 4,
                        x: 0,
                        y: 20,
                        i: '5d713678562183c8bd8bf3f13',
                    },
                    {
                        w: 5,
                        h: 4,
                        x: 5,
                        y: 24,
                        i: '5d713678562183c8bd8bf3f14',
                    },
                    {
                        w: 5,
                        h: 4,
                        x: 0,
                        y: 28,
                        i: '5d713678562183c8bd8bf3f15',
                    },
                    {
                        w: 5,
                        h: 4,
                        x: 5,
                        y: 32,
                        i: '5d713678562183c8bd8bf3f16',
                    },
                ];
                break;

            default:
                layout = [
                    { w: 12, h: 4, x: 0, y: 0, i: '5d713678562183c8bd8bf3f8' },
                    { w: 6, h: 4, x: 0, y: 4, i: '5d713678562183c8bd8bf3f9' },
                    { w: 6, h: 4, x: 6, y: 8, i: '5d713678562183c8bd8bf3f10' },
                    { w: 6, h: 4, x: 0, y: 12, i: '5d713678562183c8bd8bf3f11' },
                    { w: 6, h: 4, x: 6, y: 16, i: '5d713678562183c8bd8bf3f12' },
                    { w: 6, h: 4, x: 0, y: 20, i: '5d713678562183c8bd8bf3f13' },
                    { w: 6, h: 4, x: 6, y: 24, i: '5d713678562183c8bd8bf3f14' },
                    { w: 6, h: 4, x: 0, y: 28, i: '5d713678562183c8bd8bf3f15' },
                    { w: 6, h: 4, x: 6, y: 32, i: '5d713678562183c8bd8bf3f16' },
                ];
                break;
        }

        this.saveToLocalStorage('layout', layout);
    };

    handleSelectClick = () => {
        if (this.state.options === 'none') {
            this.setState({ ...this.state, options: 'flex' });
        } else {
            this.setState({ ...this.state, options: 'none' });
        }
    };

    handleEditSaveDashboardBtnClick = () => {
        const { mode } = this.state;

        if (mode === 'static') {
            const widgets = this.state.widgets.map((w) => {
                return {
                    ...w,
                    layout: {
                        ...w.layout,
                        isDraggable: true,
                        isResizable: true,
                    },
                };
            });

            this.setState({
                mode: 'edit',
                widgets,
            });
        } else {
            const localStorageLayout = this.getFromLocalStorage('layout');
            const originalLayout = JSON.parse(
                JSON.stringify(localStorageLayout),
            );

            const widgets = this.state.widgets.map((w) => {
                const layout = originalLayout.find((l) => l.i === w.widgetId);
                const widgetLayout = layout || w.layout;

                return {
                    ...w,
                    layout: {
                        ...widgetLayout,
                        isDraggable: false,
                        isResizable: false,
                    },
                };
            });

            this.setState({
                mode: 'static',
                widgets,
            });
        }
    };

    handleRadioSubmit = (day) => {
        this.setState({
            ...this.state,
            days: day,
            options: 'none',
        });

        this.props.onInitWidgets();
    };

    render() {
        const claims = this.context.claims.map((claim) => {
            return claim.value;
        });

        let showMenu = 0;
        if (this.context.hasClaim('widget-view')) {
            for (let i = 0; i < claims.length; i++) {
                if (claims[i].includes('widget')) {
                    showMenu++;
                }
                if (claims[i].includes('widget-view')) {
                    showMenu--;
                }
            }
        }

        const { intl } = this.props;
        const { widgets, mode, days } = this.state;
        const readOnly = mode === 'edit';
        const Cards = this.props.cards.map((card) => {
            return (
                <Card
                    key={card.widgetName}
                    title={card.widgetName}
                    textBody={card.text}
                    filter={card.filter}
                />
            );
        });

        const layout = widgets.map((w) => {
            return {
                i: w.widgetId,
                ...w.layout,
            };
        });
        const daysOptions = [
            {
                description: '7 dias',
                value: '7',
                key: '7',
            },
            {
                description: '30 dias',
                value: '30',
                key: '30',
            },
            {
                description: '60 dias',
                value: '60',
                key: '60',
            },
            {
                description: '90 dias',
                value: '90',
                key: '90',
            },
        ];

        return (
            <div>
                <Header
                    title={intl.formatMessage(messages.headerTitle)}
                    subtitle={intl.formatMessage(messages.headerSubtitle)}
                />
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                    }}
                >
                    {this.context.hasClaim('financial-document-view') && Cards}
                </div>
                {showMenu > 0 && (
                    <>
                        <button
                            style={{
                                display: 'block',
                                margin: 10,
                                marginBottom: 0,
                                width: 165,
                                backgroundColor: '#FFFFFF',
                                color: '#0052CC',
                                borderRadius: '8px 8px 0px 0px',
                            }}
                            onClick={() => {
                                this.handleSelectClick();
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                }}
                            >
                                {days} dias
                                <FontAwesomeIcon icon='chevron-down' />
                            </div>
                        </button>
                        <div
                            style={{
                                display: this.state.options,
                                width: 165,
                                backgroundColor: '#FFFFFF',

                                marginLeft: 10,
                            }}
                        >
                            <ul
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    boxShadow: '1px 7px 10px 1px grey',
                                    width: '165px',
                                    borderRadius: '0px 0px 8px 8px',
                                    margin: 0,
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: '#FFFFFF',
                                        height: 50,
                                        padding: 15,
                                        borderRadius: '8px 8px 0px 0px',
                                    }}
                                >
                                    <span style={{}}>Períodos</span>
                                </div>
                                <hr
                                    style={{
                                        margin: 0,
                                        borderTop: '0.5px solid #E0E0E0',
                                    }}
                                />
                                {daysOptions.map((day) => {
                                    return (
                                        <li
                                            className='li'
                                            onClick={() => {
                                                this.handleRadioSubmit(
                                                    day.value,
                                                );
                                            }}
                                            style={{
                                                listStyle: 'none',
                                                cursor: 'pointer',
                                            }}
                                            key={day.value}
                                        >
                                            <div
                                                style={{
                                                    height: 30,
                                                    padding: '5px 5px 5px 35px',
                                                }}
                                            >
                                                {day.description}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </>
                )}
                <ResponsiveGridLayout
                    compactType='vertical'
                    layout={layout}
                    onLayoutChange={this.handleGridLayoutChange}
                    className='dashboard-grid'
                >
                    {widgets.map((widget) => {
                        let classWidget = 'widget ';
                        if (widget.class !== '') {
                            classWidget += `${widget.class}-widget`;
                        }

                        return (
                            <div
                                key={`${widget.widgetId}_${mode}`}
                                data-grid={widget.layout}
                                className={classWidget}
                            >
                                <Widget
                                    {...widget}
                                    colors={this.props.colors}
                                    days={days}
                                    readOnly={readOnly}
                                />
                            </div>
                        );
                    })}
                </ResponsiveGridLayout>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        widgets: state.widgetReducer.widgets,
        colors: state.widgetReducer.colors || [
            '#3a64fd',
            '#3afdd3',
            '#3ac6fd',
            '#723afd',
        ],
        cards: state.widgetReducer.cards,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitWidgets: () => dispatch(actions.initWidgets()),
        onGenerateColors: (color, quantidade) =>
            dispatch(actions.generateColors(color, quantidade)),
        oninitFinancialDocumentsCards: () =>
            dispatch(actions.initFinancialDocumentsCards()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(Dashboard));
