/* eslint-disable react/jsx-no-bind */
import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

const defaultButton = (props) => <button {...props}>{props.children}</button>;
const spanButton = (props) => <span {...props}>{props.children}</span>;

class Pagination extends React.Component {
    static propTypes = {
        pages: PropTypes.number,
        page: PropTypes.number,
        PageButtonComponent: PropTypes.any,
        onPageChange: PropTypes.func,
        previousText: PropTypes.string,
        nextText: PropTypes.string,
    };

    constructor(props) {
        super();

        this.changePage = this.changePage.bind(this);

        this.state = {
            visiblePages: this.getVisiblePages(null, props.pages),
        };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.pages !== nextProps.pages) {
            this.setState({
                visiblePages: this.getVisiblePages(
                    typeof this.props.uiContext[window.location.pathname] !==
                        'undefined' &&
                        typeof this.props.uiContext[window.location.pathname]
                            .pageNumber !== 'undefined'
                        ? this.props.uiContext[window.location.pathname]
                              .pageNumber + 1
                        : 0,
                    nextProps.pages,
                ),
            });
        }

        // this.changePage(nextProps.page + 1);
    }

    filterPages = (visiblePages, totalPages) => {
        return visiblePages.filter((page) => page <= totalPages);
    };

    getVisiblePages = (page, total) => {
        if (total < 7) {
            return this.filterPages([1, 2, 3, 4, 5, 6], total);
        }
        if (page % 5 >= 0 && page > 4 && page + 2 < total) {
            return [1, page - 1, page, page + 1, total];
        }
        if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
            return [1, total - 3, total - 2, total - 1, total];
        }
        return [1, 2, 3, 4, 5, total];
    };

    changePage(page) {
        const index =
            new URLSearchParams(window.location.search).get('index') || 0;

        const activePage = this.props.page + 1;

        if (page === activePage) {
            return;
        }

        const visiblePages = this.getVisiblePages(page, this.props.pages);

        this.setState({
            visiblePages: this.filterPages(visiblePages, this.props.pages),
        });

        this.props.onPageChange(page - 1);
        this.props.onPageUpdate(page - 1, window.location.pathname, index);
    }

    render() {
        const { PageButtonComponent = defaultButton } = this.props;
        const { PageButtonSpan = spanButton } = this.props;
        const { visiblePages } = this.state;
        const activePage = this.props.page + 1;

        return (
            <div
                className={
                    visiblePages.length > 0 ? 'Table__pagination' : 'hide'
                }
            >
                <div className='Table__prevPageWrapper'>
                    <PageButtonComponent
                        className='Table__pageButton'
                        onClick={() => {
                            if (activePage === 1) return;
                            this.changePage(activePage - 1);
                        }}
                        disabled={activePage === 1}
                    >
                        {this.props.previousText}
                    </PageButtonComponent>
                </div>
                <div className='Table__visiblePagesWrapper'>
                    {visiblePages.map((page, index, array) => {
                        return (
                            <PageButtonComponent
                                key={page}
                                className={
                                    array[index - 1] + 2 < page
                                        ? 'Table__pageButton span-dots'
                                        : activePage === page
                                        ? 'Table__pageButton Table__pageButton--active'
                                        : 'Table__pageButton'
                                }
                                onClick={this.changePage.bind(null, page)}
                                disabled={activePage === page}
                            >
                                <PageButtonSpan
                                    className={
                                        array[index - 1] + 2 < page
                                            ? 'dots'
                                            : 'hide'
                                    }
                                >
                                    ...
                                </PageButtonSpan>
                                {array[index - 1] + 2 < page ? `${page}` : page}
                            </PageButtonComponent>
                        );
                    })}
                </div>
                <div className='Table__nextPageWrapper'>
                    <PageButtonComponent
                        className='Table__pageButton'
                        onClick={() => {
                            if (activePage === this.props.pages) return;
                            this.changePage(activePage + 1);
                        }}
                        disabled={activePage === this.props.pages}
                    >
                        {this.props.nextText}
                    </PageButtonComponent>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        uiContext: state.uiContextReducer,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPageUpdate: (...args) => dispatch(actions.updateCurrentPage(...args)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Pagination);
