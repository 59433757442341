import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { AuthContext } from '../../../context/AuthContext';
import * as actions from '../../../store/actions/index';

import PendingOrdersCounterWidget from '../PendingOrdersCounterWidget/PendingOrdersCounterWidget';
import FinancialVolumeCounterWidget from '../FinancialVolumeCounterWidget/FinancialVolumeCounterWidget';
import MonthlyConsumptionCounterWidget from '../MonthlyConsumptionCounterWidget/MonthlyConsumptionCounterWidget';
import MonthByMonthVolumeChart from '../MonthByMonthVolumeChart/MonthByMonthVolumeChart';
import MonthlyVolumeByClientChart from '../MonthlyVolumeByClientChart/MonthlyVolumeByClientChart';
import GenericWidgetTable from '../GenericWidgetTable/GenericWidgetTable';
import BillingVolumeChart from '../BillingVolumeChart/BillingVolumeChart';
import TransactionsByMethodPayment from '../TransactionsByMethodPayment/TransactionsByMethodPayment';
import ValuesByMethodPayment from '../ValuesByMethodPayment/ValuesByMethodPayment';
import TransactionsByPaymentOperator from '../TransactionsByPaymentOperator/TransactionsByPaymentOperator';
import ValuesByPaymentOperator from '../ValuesByPaymentOperator/ValuesByPaymentOperator';
import TransactionConversion from '../TransactionConversion/TransactionConversion';
import ValueConversion from '../ValueConversion/ValueConversion';
import ValueConversionPercent from '../ValueConversionPercent/ValueConversionPercent';
import TransactionConversionPercent from '../TransactionConversionPercent/TransactionConversionPercent';
import Loading from '../../../components/LoadingV2';

const widgetTypes = {
    pendingOrdersCounter: 'pending-orders-counter',
    financialVolumeCounter: 'financial-volume-counter',
    monthlyConsumptionCounter: 'monthly-consuption-counter',
    monthByMonthVolumeChart: 'month-by-month-volume-chart',
    monthlyVolumeByClientChart: 'monthly-volume-by-client-chart',
    paymentsDueListWidget: 'payments-due-list',
    billingVolumeChart: 'billing-volume-chart',
    transactionMethodPayment: 'transaction-method-payment',
    valuesMethodPayment: 'values-method-payment',
    transactionPaymentOperator: 'transaction-payment-operator',
    valuesPaymentOperator: 'values-payment-operator',
    transactionConversion: 'transactions-conversion',
    TransactionConversionPercent: 'transactions-conversion-percent',
    valueConversion: 'values-conversion',
    ValueConversionPercent: 'values-conversion-percent',
};

class Widget extends Component {
    static contextType = AuthContext;

    componentDidMount() {
        const { widgetId, url, days } = this.props;
        this.props.onInitWidget(widgetId, url, days);
    }

    render() {
        const { widgetType, readOnly } = this.props;
        let widget;
        switch (widgetType) {
            case widgetTypes.pendingOrdersCounter:
                if (this.context.hasClaim()) {
                    widget = (
                        <PendingOrdersCounterWidget
                            {...this.props.data}
                            readOnly={readOnly}
                        />
                    );
                }
                break;
            case widgetTypes.financialVolumeCounter:
                widget = (
                    <FinancialVolumeCounterWidget
                        {...this.props.data}
                        readOnly={readOnly}
                    />
                );
                break;

            case widgetTypes.monthlyConsumptionCounter:
                widget = (
                    <MonthlyConsumptionCounterWidget
                        {...this.props.data}
                        readOnly={readOnly}
                    />
                );
                break;
            case widgetTypes.monthByMonthVolumeChart:
                widget = (
                    <MonthByMonthVolumeChart
                        {...this.props.data}
                        readOnly={readOnly}
                    />
                );
                break;

            case widgetTypes.monthlyVolumeByClientChart:
                widget = (
                    <MonthlyVolumeByClientChart
                        {...this.props.data}
                        readOnly={readOnly}
                    />
                );
                break;

            case widgetTypes.paymentsDueListWidget:
            case widgetTypes.pendingDocumentsListWidget:
                widget = (
                    <GenericWidgetTable {...this.props} readOnly={readOnly} />
                );
                break;

            case widgetTypes.billingVolumeChart:
                if (this.context.hasClaim('widget-billing-volume-chart-view')) {
                    widget = (
                        <BillingVolumeChart
                            {...this.props}
                            readOnly={readOnly}
                        />
                    );
                }
                break;
            case widgetTypes.transactionMethodPayment:
                if (
                    this.context.hasClaim(
                        'widget-transactions-by-method-payment-view',
                    )
                ) {
                    widget = (
                        <TransactionsByMethodPayment
                            {...this.props}
                            readOnly={readOnly}
                        />
                    );
                }
                break;
            case widgetTypes.valuesMethodPayment:
                if (
                    this.context.hasClaim(
                        'widget-values-by-method-payment-view',
                    )
                ) {
                    widget = (
                        <ValuesByMethodPayment
                            {...this.props}
                            readOnly={readOnly}
                        />
                    );
                }
                break;

            case widgetTypes.transactionPaymentOperator:
                if (
                    this.context.hasClaim(
                        'widget-transactions-by-payment-operator-view',
                    )
                ) {
                    widget = (
                        <TransactionsByPaymentOperator
                            {...this.props}
                            readOnly={readOnly}
                        />
                    );
                }
                break;

            case widgetTypes.valuesPaymentOperator:
                if (
                    this.context.hasClaim(
                        'widget-values-by-payment-operator-view',
                    )
                ) {
                    widget = (
                        <ValuesByPaymentOperator
                            {...this.props}
                            readOnly={readOnly}
                        />
                    );
                }
                break;

            case widgetTypes.transactionConversion:
                if (
                    this.context.hasClaim('widget-transaction-conversion-view')
                ) {
                    widget = (
                        <TransactionConversion
                            {...this.props}
                            readOnly={readOnly}
                        />
                    );
                }
                break;

            case widgetTypes.TransactionConversionPercent:
                if (
                    this.context.hasClaim(
                        'widget-transaction-conversion-percent-view',
                    )
                ) {
                    widget = (
                        <TransactionConversionPercent
                            {...this.props}
                            readOnly={readOnly}
                        />
                    );
                }
                break;
            case widgetTypes.valueConversion:
                if (this.context.hasClaim('widget-value-conversion-view')) {
                    widget = (
                        <ValueConversion {...this.props} readOnly={readOnly} />
                    );
                }
                break;
            case widgetTypes.ValueConversionPercent:
                if (
                    this.context.hasClaim(
                        'widget-value-conversion-percent-view',
                    )
                ) {
                    widget = (
                        <ValueConversionPercent
                            {...this.props}
                            readOnly={readOnly}
                        />
                    );
                }
                break;

            default:
                break;
        }

        return <>{this.props.data ? widget : <Loading loading />}</>;
    }
}

// {/* <h2>{intl.formatMessage(messages.loadingText)}</h2> */}

const mapStateToProps = (state, props) => {
    const dataArray = state.widgetReducer.dataArray;
    const data = dataArray.find((d) => d.widgetId === props.widgetId);

    return {
        data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitWidget: (widgetId, url, days) =>
            dispatch(actions.initWidget(widgetId, url, days)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(Widget));
