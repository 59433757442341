import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Container, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FiCopy, FiMail, FiShoppingCart } from 'react-icons/fi';
import { MdOutlineCallSplit } from 'react-icons/md';
import {
    IoArchiveOutline,
    IoServerOutline,
    IoDocumentsOutline,
} from 'react-icons/io5';
import { Icon } from '@chakra-ui/react';

import messages from './CollectionListPage.intl';
import * as actions from '../../store/actions/index';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import EvtTable from '../../components/EvtTable/EvtTable';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import { filterPropertyTypes } from '../../utils/enums';
import { AuthContext } from '../../context/AuthContext';
import BeatMeatBallMenu from '../../components/BeatMeatBallMenu';
import TooltipItem from '../../components/Tooltip/Tooltip';
import { handleCpfCnpj } from '../../utils/handleCpfCnpj';
import ModalCollections from '../../components/ModalCollections';
import { modalTypes } from '../../components/Modal/Modal';

class CollectionListPage extends Component {
    // eslint-disable-next-line react/static-property-placement
    static contextType = AuthContext;

    state = {
        filters: [],
        showSendEmailModal: false,
        showInstallmentsModal: false,
    };

    componentDidUpdate() {
        if (!this.props.loadingButton && this.props.accessLink) {
            this.handleInstallmentsToggleModal();
            this.props.onInstallmentsClean();
            this.reloadCollectionsList();
        }
    }

    reloadCollectionsList = () => {
        const { query } = this.state;

        this.props.onInitCollections(query);
        this.props.onInitCollectionsCount(query);
    };

    handleGetTrProps = (state, rowInfo) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: () =>
                this.props.history.push(`/collections/${rowInfo.original.id}`),
        };
    };

    handleNewClick = () => {
        this.props.history.push(`/collections/new`);
    };

    handleRefreshButtonClick = () => {
        this.reloadCollectionsList();
    };

    handleFiltersChange = (filters) => {
        this.setState({
            filters,
        });
    };

    handleEvTableStateChange = (query) => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadCollectionsList();
            },
        );
    };

    handleSendEmailToggleModal = () => {
        this.setState({
            showSendEmailModal: !this.state.showSendEmailModal,
        });
    };

    handleInstallmentsToggleModal = () => {
        this.setState({
            showInstallmentsModal: !this.state.showInstallmentsModal,
        });
    };

    handleSendEmailButtonClick = (event, values) => {
        values.alternativeEmail !== ''
            ? this.props.onResendMail(
                  this.state.collectionId,
                  values.alternativeEmail,
              )
            : this.props.onResendMail(this.state.collectionId);
        this.handleSendEmailToggleModal();
    };

    handleInstallmentsSubimit = (event, values) => {
        event.preventDefault();
        this.props.onInstalmmentsSubmit({
            ...values,
            id: this.state.collectionId,
        });
    };

    handleCopyLinkClick = (collectionId) => {
        this.props.onCopyLink(collectionId);
        const interval = setInterval(() => {
            if (!this.props.loadingList && this.props.paymentLink) {
                const textField = document.createElement('textarea');

                textField.innerText = this.props.paymentLink;

                document.body.appendChild(textField);

                textField.select();
                document.execCommand('copy');

                textField.remove();
                clearInterval(interval);
            }
        }, 100);
    };

    render() {
        const {
            intl,
            loadingList,
            collections,
            collectionsCount,
            loadingButton,
        } = this.props;
        const { filters } = this.state;

        const newCollectionButtonEnabled = this.context.hasClaim(
            'collection-create',
        );

        const canResendEmail = this.context.hasClaim('transaction-resend-mail');

        const properties = [
            {
                key: 'accountingDocumentNumber',
                value: intl.formatMessage(messages.collectionItemCode),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'referenceDocument',
                value: intl.formatMessage(messages.referenceDocument),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'clientName',
                value: intl.formatMessage(messages.clientName),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'clientNumber',
                value: intl.formatMessage(messages.clientNumber),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'clientCpfCnpj',
                value: intl.formatMessage(messages.clientCpfCnpj),
                type: filterPropertyTypes.TEXT,
                map: (input) => input.replace(/[^0-9]/g, ''),
            },
            {
                key: 'collectionId',
                value: intl.formatMessage(messages.collectionId),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'documentDate',
                value: intl.formatMessage(messages.documentDate),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'daysInArrears',
                value: intl.formatMessage(messages.daysInArrears),
                type: filterPropertyTypes.NUMBER,
            },
            {
                key: 'dueDate',
                value: intl.formatMessage(messages.dueDate),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'billAdjustedValue',
                value: intl.formatMessage(messages.billAdjustedValue),
                type: filterPropertyTypes.NUMBER,
                map: (input) => input.replace(',', '.'),
            },
            {
                key: 'billDischargeValue',
                value: intl.formatMessage(messages.billDischargeValue),
                type: filterPropertyTypes.NUMBER,
                map: (input) => input.replace(',', '.'),
            },
            {
                key: 'PaymentStatus',
                value: intl.formatMessage(messages.status),
                type: filterPropertyTypes.ENUM,
                enums: [
                    { key: 0, value: 'Pendente' },
                    { key: 1, value: 'Parcial' },
                    { key: 2, value: 'Pago' },
                ],
            },
            {
                key: 'source',
                value: intl.formatMessage(messages.source),
                type: filterPropertyTypes.ENUM,
                enums: [
                    { key: 0, value: 'Pagamento Online' },
                    { key: 1, value: 'Link de pagamento' },
                    { key: 2, value: 'SAP' },
                    { key: 3, value: 'Integração de Arquivo' },
                    { key: 4, value: 'Gerado por contrato' },
                ],
            },
        ];

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.collectionId)}</span>
                    </div>
                ),
                accessor: 'collectionId',
                minWidth: 150,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.source)}</span>
                    </div>
                ),
                maxWidth: 60,
                accessor: 'source',
                style: {
                    cursor: 'default',
                },
                Cell: (row) => {
                    let icon;
                    switch (row.value) {
                        case 0:
                            icon = (
                                <>
                                    <Icon
                                        as={FiShoppingCart}
                                        w={6}
                                        h={6}
                                        id='Tooltip-creditCard'
                                    />
                                    <TooltipItem
                                        id='creditCard'
                                        message='Pagamento Online'
                                    />
                                </>
                            );
                            break;
                        case 1:
                            icon = (
                                <>
                                    <Icon
                                        as={IoServerOutline}
                                        w={6}
                                        h={6}
                                        id='Tooltip-paymentLink'
                                    />
                                    <TooltipItem
                                        id='paymentLink'
                                        message='Link de Pagamento'
                                    />
                                </>
                            );
                            break;
                        case 2:
                            icon = (
                                <>
                                    <Icon
                                        as={IoServerOutline}
                                        w={6}
                                        h={6}
                                        id='Tooltip-sap'
                                    />
                                    <TooltipItem id='sap' message='SAP' />
                                </>
                            );
                            break;
                        case 3:
                            icon = (
                                <>
                                    <Icon
                                        as={IoArchiveOutline}
                                        w={6}
                                        h={6}
                                        id='Tooltip-achiveIntegration'
                                    />
                                    <TooltipItem
                                        id='achiveIntegration'
                                        message='Integração de arquivo'
                                    />
                                </>
                            );
                            break;
                        case 4:
                            icon = (
                                <>
                                    <Icon
                                        as={IoDocumentsOutline}
                                        w={6}
                                        h={6}
                                        id='Tooltip-contract-billing'
                                    />
                                    <TooltipItem
                                        id='contract-billing'
                                        message='Gerado por contrato'
                                    />
                                </>
                            );
                            break;
                        default:
                            icon = <></>;
                    }
                    return icon;
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.clientName)}</span>
                    </div>
                ),
                Cell: (row) => {
                    const { clientName, clientCpfCnpj } = row.original;

                    const document = handleCpfCnpj(clientCpfCnpj);

                    if (clientName) {
                        return `${clientName} / ${document}`;
                    }

                    return `${document}`;
                },
                minWidth: 210,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.referenceDocument)}
                        </span>
                    </div>
                ),
                accessor: 'referenceDocument',
                minWidth: 110,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.documentDate)}</span>
                    </div>
                ),
                accessor: 'documentDate',
                Cell: (row) => {
                    const date = new Date(row.value).toLocaleDateString(
                        navigator.language,
                    );
                    return date;
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.dueDate)}</span>
                    </div>
                ),
                accessor: 'dueDate',
                Cell: (row) => {
                    const date = new Date(row.value).toLocaleDateString(
                        navigator.language,
                    );
                    return date;
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.daysInArrears)}
                        </span>
                    </div>
                ),
                accessor: 'daysInArrears',
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.collectionValue)}
                        </span>
                    </div>
                ),
                accessor: 'billAdjustedValue',
                Cell: (props) =>
                    new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(props.value),

                sortable: false,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.billDischargeValue)}
                        </span>
                    </div>
                ),
                accessor: 'billDischargeValue',
                Cell: (props) =>
                    new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(props.value),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.status)}</span>
                    </div>
                ),
                accessor: 'paymentStatus',
                Cell: (row) => {
                    let rowValue;
                    const paymentStatus = row.value;

                    const rowStyle = {
                        fontSize: '0.8rem',
                        color: '#ffffff',
                    };

                    switch (paymentStatus) {
                        case 1:
                            rowValue = (
                                <>
                                    <Badge
                                        style={rowStyle}
                                        color='primary'
                                        pill
                                    >
                                        Parcial
                                    </Badge>
                                </>
                            );
                            break;
                        case 2:
                            rowValue = (
                                <>
                                    <Badge
                                        style={rowStyle}
                                        color='success'
                                        pill
                                    >
                                        Paga
                                    </Badge>
                                </>
                            );
                            break;
                        case 0:
                            rowValue = (
                                <>
                                    <Badge
                                        style={rowStyle}
                                        color='warning'
                                        pill
                                    >
                                        Pendente
                                    </Badge>
                                </>
                            );
                            break;
                        default:
                            break;
                    }

                    return rowValue;
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{}</span>
                    </div>
                ),
                accessor: 'isPayed',
                style: { overflow: 'visible', textAlignLast: 'right' },
                maxWidth: 70,
                Cell: (row) => {
                    const ItemsBeatMenu = [
                        canResendEmail
                            ? {
                                  label: 'Reenviar link de pagamento',
                                  icon: <FiMail />,
                                  onClick: (e) => {
                                      this.setState({
                                          showSendEmailModal: true,
                                          collectionId:
                                              row.original.collectionId,
                                      });
                                      e.stopPropagation();
                                  },
                              }
                            : null,
                        {
                            label: 'Copiar link de pagamento',
                            icon: <FiCopy />,
                            onClick: (e) => {
                                this.handleCopyLinkClick(
                                    row.original.collectionId,
                                );
                                e.stopPropagation();
                            },
                        },
                        this.context.hasClaim('collection-installments') &&
                        row.original.paymentStatus !== 1
                            ? {
                                  label: 'Criar Parcelamento',
                                  icon: <MdOutlineCallSplit />,
                                  onClick: (e) => {
                                      this.setState({
                                          showInstallmentsModal: true,
                                          collectionId: row.original.id,
                                      });
                                      e.stopPropagation();
                                  },
                              }
                            : null,
                    ];
                    return (
                        <>
                            {row.original.source !== 0 && !row.value && (
                                <BeatMeatBallMenu
                                    items={ItemsBeatMenu.filter(
                                        (item) => item !== null,
                                    )}
                                />
                            )}
                        </>
                    );
                },
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/collections',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={
                                this.props.collectionsCount +
                                intl.formatMessage(messages.subtitle)
                            }
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                        {newCollectionButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.importButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleCollection,
                                        {
                                            entity: '',
                                        },
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={collections}
                        length={collectionsCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                    />
                </section>
                <ModalCollections
                    title={intl.formatMessage(messages.modalTitle)}
                    isOpen={this.state.showSendEmailModal}
                    key='newPasswordConfirmationModal'
                    handleToggle={this.handleSendEmailToggleModal}
                    type={modalTypes.SUCCESS}
                    intl={intl}
                    messages={messages}
                    handleSendEmailButtonClick={this.handleSendEmailButtonClick}
                    handleSendEmailToggleModal={this.handleSendEmailToggleModal}
                />
                <ModalCollections
                    title={intl.formatMessage(messages.modalInstallmentsTitle)}
                    isOpen={this.state.showInstallmentsModal}
                    key='newInstallmentsModal'
                    handleToggle={this.handleInstallmentsToggleModal}
                    intl={intl}
                    messages={messages}
                    handleInstallmentsSubimit={this.handleInstallmentsSubimit}
                    collections={collections.filter(
                        (coll) => coll.id === this.state.collectionId,
                    )}
                    loadingButton={loadingButton}
                    createInstallments
                />
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        collections: state.collectionReducer.collections || [],
        collectionsCount: state.collectionReducer.collectionsCount || 0,
        loadingList: state.collectionReducer.loadingList,
        paymentLink: state.collectionReducer.paymentLink,
        loadingButton: state.collectionReducer.loadingButton,
        accessLink: state.collectionReducer.accessLink,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitCollections: (filter) =>
            dispatch(actions.initCollections(filter)),
        onInitCollectionsCount: (filter) =>
            dispatch(actions.initCollectionsCount(filter)),
        onCopyLink: (collectionId) =>
            dispatch(actions.collectionCopyLink(collectionId)),
        onResendMail: (collectionId, email) =>
            dispatch(actions.collectionResendEmail(collectionId, email)),
        onInstalmmentsSubmit: (values) =>
            dispatch(actions.installmentsCreate(values)),
        onInstallmentsClean: () => dispatch(actions.installmentsClean()),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(CollectionListPage),
);
