/* eslint-disable  */
import { toast } from 'react-toastify';
import axios from 'axios';
import { push } from 'connected-react-router';
import * as actionTypes from './actionTypes';

export const initFinancialDocuments = (filter = '') => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FINANCIAL_DOCUMENTS_GET_LIST_REQUEST,
        });

        axios
            .get(`/financialDocuments${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENTS_GET_LIST_SUCCESS,
                    financialdocuments: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENTS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};
export const initFinancialDocumentDetails = (financialdocumentId) => {
    if (financialdocumentId === 'new') {
        return (dispatch) => {
            dispatch({
                type: actionTypes.FINANCIAL_DOCUMENT_GET_DETAILS_NEW,
            });
        };
    }
    return (dispatch) => {
        dispatch({
            type: actionTypes.FINANCIAL_DOCUMENT_GET_DETAILS_REQUEST,
        });

        axios
            .get(`/FinancialDocuments/${financialdocumentId}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENT_GET_DETAILS_SUCCESS,
                    financialdocument: response.data,
                });
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENT_GET_DETAILS_FAILURE,
                    error,
                });
                console.log(error);
            });
    };
};

export const createFinancialDocument = (values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FINANCIAL_DOCUMENT_POST_REQUEST,
        });

        axios
            .post(`/FinancialDocuments`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENT_POST_SUCCESS,
                });
                toast.success('Documento financeiro criado com sucesso');
                dispatch(push('/financialdocuments'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENT_POST_FAILURE,
                    error,
                });
            });
    };
};

export const updateFinancialDocument = (financialdocumentId, values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FINANCIAL_DOCUMENT_PUT_REQUEST,
        });

        axios
            .put(`/FinancialDocuments/${financialdocumentId}`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENT_PUT_SUCCESS,
                });
                toast.success('Documento financeiro alterado com sucesso');
                dispatch(push('/financialdocuments'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENT_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const deleteFinancialDocument = (financialdocumentId) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FINANCIAL_DOCUMENT_DELETE_REQUEST,
        });

        axios
            .delete(`/FinancialDocuments/${financialdocumentId}`)
            .then(function() {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENT_DELETE_SUCCESS,
                });
                toast.success('Documento financeiro deletado com sucesso');
                dispatch(push('/financialdocuments'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENT_DELETE_FAILURE,
                    error,
                });
            });
    };
};

export const initFinancialDocumentsCount = (filter) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.FINANCIAL_DOCUMENTS_GET_COUNT_REQUEST,
        });
        await axios
            .get(`/financialdocuments/Count${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENTS_GET_COUNT_SUCCESS,
                    financialDocumentsCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.FINANCIAL_DOCUMENTS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const setCard = (bool) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FINANCIAL_DOCUMENT_CARD,
            bool: bool,
        });
    };
};

export const setCardQuery = (query) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FINANCIAL_DOCUMENT_CARD_QUERY,
            query: query,
        });
    };
};
