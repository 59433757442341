import React from 'react';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Col, FormGroup, Row } from 'reactstrap';
import { CircularProgress } from '@material-ui/core';
import Modal from '../Modal/Modal';

const ModalCollections = (props) => {
    const {
        title,
        isOpen,
        key,
        handleToggle,
        type,
        intl,
        messages,
        handleSendEmailButtonClick,
        handleInstallmentsSubimit,
        handleSendEmailToggleModal,
        createInstallments,
        collections,
        loadingButton,
    } = props;

    const collection = collections && collections[0];

    return (
        <Modal
            title={title}
            isOpen={isOpen}
            key={key}
            handleToggle={handleToggle}
            type={type}
            size={createInstallments ? 'lg' : ''}
        >
            {!createInstallments ? (
                <>
                    <span>
                        Informe um email caso queria enviar a cobrança para um
                        destinatário específico e ignorar os contatos
                        cadastrados para esse cliente
                    </span>
                    <AvForm onValidSubmit={handleSendEmailButtonClick}>
                        <Row>
                            <Col lg={12} md={12}>
                                <FormGroup>
                                    <AvField
                                        label={intl.formatMessage(
                                            messages.alternativeEmail,
                                        )}
                                        type='text'
                                        name='alternativeEmail'
                                        id='alternativeEmail'
                                        value=''
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button type='submit' className='btn-submit'>
                                    {intl.formatMessage(messages.yesText)}
                                </button>
                            </Col>
                            <Col>
                                <button
                                    type='button'
                                    className='btn-delete'
                                    onClick={handleSendEmailToggleModal}
                                >
                                    {intl.formatMessage(messages.noText)}
                                </button>
                            </Col>
                        </Row>
                    </AvForm>
                </>
            ) : (
                <>
                    <AvForm onValidSubmit={handleInstallmentsSubimit}>
                        <Row>
                            <Col lg={4} md={4}>
                                <FormGroup>
                                    <AvField
                                        label={intl.formatMessage(
                                            messages.clientName,
                                        )}
                                        type='text'
                                        name='clientName'
                                        id='clientName'
                                        value={
                                            collection?.clientName ||
                                            'Não registrado'
                                        }
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={4}>
                                <FormGroup>
                                    <AvField
                                        label={intl.formatMessage(
                                            messages.clientCpfCnpj,
                                        )}
                                        type='text'
                                        name='clientCpfCnpj'
                                        id='clientCpfCnpj'
                                        value={
                                            collection?.clientCpfCnpj ||
                                            'Não registrado'
                                        }
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={4}>
                                <FormGroup>
                                    <AvField
                                        label={intl.formatMessage(
                                            messages.clientPhoneNumbers,
                                        )}
                                        type='text'
                                        name='clientPhoneNumbers'
                                        id='clientPhoneNumbers'
                                        value={
                                            collection?.clientPhoneNumbers ||
                                            'Não registrado'
                                        }
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={6}>
                                <FormGroup>
                                    <AvField
                                        label={intl.formatMessage(
                                            messages.expireAt,
                                        )}
                                        type='date'
                                        name='expireAt'
                                        id='expireAt'
                                        value={
                                            collection?.dueDate &&
                                            collection?.dueDate.replace(
                                                /T.*/,
                                                '',
                                            )
                                        }
                                        disabled={loadingButton}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={6} md={6}>
                                <FormGroup>
                                    <AvField
                                        label={intl.formatMessage(
                                            messages.value,
                                        )}
                                        type='number'
                                        name='value'
                                        id='value'
                                        value={collection?.billAdjustedValue}
                                        disabled={loadingButton}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button
                                    type='submit'
                                    className='btn-submit'
                                    disabled={loadingButton}
                                >
                                    {loadingButton ? (
                                        <CircularProgress
                                            style={{
                                                color: 'white',
                                                width: 25,
                                                height: 25,
                                            }}
                                        />
                                    ) : (
                                        intl.formatMessage(
                                            messages.confirmButton,
                                        )
                                    )}
                                </button>
                            </Col>
                        </Row>
                    </AvForm>
                </>
            )}
        </Modal>
    );
};

export default ModalCollections;
