import React from 'react';
import { Line as LineChart } from 'react-chartjs';
import { injectIntl } from 'react-intl';

import messages from './MonthByMonthVolumeChart.intl';

const MonthByMonthVolumeChart = (props) => {
    const { data, intl } = props;

    const config = {
        labels: [
            intl.formatMessage(messages.january),
            intl.formatMessage(messages.february),
            intl.formatMessage(messages.march),
            intl.formatMessage(messages.april),
            intl.formatMessage(messages.may),
            intl.formatMessage(messages.june),
            intl.formatMessage(messages.july),
            intl.formatMessage(messages.august),
            intl.formatMessage(messages.september),
            intl.formatMessage(messages.october),
            intl.formatMessage(messages.november),
            intl.formatMessage(messages.december),
        ],
        datasets: [
            {
                label: 'My First dataset',
                fillColor: 'rgba(151,187,205,0.2)',
                strokeColor: 'rgba(151,187,205,1)',
                pointColor: 'rgba(151,187,205,1)',
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                pointHighlightStroke: 'rgba(151,187,205,1)',
                data,
            },
        ],
    };

    return (
        <>
            <div className='text'>
                <h1>
                    {intl.formatMessage(
                        messages.monthByMonthVolumeChartActivities,
                    )}
                </h1>
            </div>
            <div className='chart chart-line'>
                <LineChart
                    data={config}
                    options={{ responsive: true, maintainAspectRatio: true }}
                />
            </div>
        </>
    );
};

export default injectIntl(MonthByMonthVolumeChart);
