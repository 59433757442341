import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line import/no-extraneous-dependencies
import { cnpj, cpf } from 'cpf-cnpj-validator';
// eslint-disable-next-line import/no-extraneous-dependencies
import { unMask } from 'remask';
import messages from './SearchBar.intl';

class SearchBar extends React.Component {
    handleSearchKeyUp = (e) => {
        const target = e.target;
        const key = e.key;

        if (key === 'Enter') {
            let value = target.value;
            if (cnpj.isValid(value) || cpf.isValid(value))
                value = unMask(value);

            this.props.handleSearchSubmit &&
                this.props.handleSearchSubmit(value);
        }
    };

    handleSearchClickButton = () => {
        let value = this.searchInput.value;

        if (cnpj.isValid(value) || cpf.isValid(value)) value = unMask(value);

        this.props.handleSearchSubmit && this.props.handleSearchSubmit(value);
    };

    handleSearchChange = (e) => {
        this.props.handleSearchChange &&
            this.props.handleSearchChange(e.target.value);
    };

    render() {
        const { intl, loading, searchTerm } = this.props;

        return (
            <div id='search-area' className='search-area'>
                <input
                    ref={(node) => (this.searchInput = node)}
                    value={searchTerm}
                    id='search-input'
                    className='search-input search-input-show'
                    type='text'
                    disabled={loading}
                    placeholder={intl.formatMessage(messages.placeholder, {
                        entity: intl.formatMessage(messages.placeholder),
                    })}
                    onChange={this.handleSearchChange}
                    onKeyUp={this.handleSearchKeyUp}
                />
                <div className='right'>
                    <button
                        id='search-btn'
                        className='search-btn icomoon icon-search'
                        onClick={this.handleSearchClickButton}
                    >
                        <FontAwesomeIcon icon='search' />
                    </button>
                </div>
            </div>
        );
    }
}

SearchBar.propTypes = {
    searchTerm: PropTypes.string,
    handleSearchSubmit: PropTypes.func.isRequired,
    handleSearchChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    intl: PropTypes.any,
};

export default injectIntl(SearchBar);
