import React from 'react';

const HomeIcon = ({
    width = '26',
    height = '30',
    viewBox = '0 0 36 40',
    fill = 'none',
    style = '',
}) => (
    <svg
        width={width}
        height={height}
        fill={fill}
        viewBox={viewBox}
        style={style}
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M2 14.4444L18 2L34 14.4444V34C34 34.943 33.6254 35.8474 32.9586 36.5142C32.2918 37.1809 31.3874 37.5555 30.4444 37.5555H5.55556C4.61256 37.5555 3.70819 37.1809 3.0414 36.5142C2.3746 35.8474 2 34.943 2 34V14.4444Z'
            stroke='black'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default HomeIcon;
