import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    feeMessageId: {
        id: 'feeMessages.feeMessageId',
        defaultMessage: '#ID',
    },

    feeMessageContractor: {
        id: 'tarification.tarificationContractor',
        defaultMessage: 'Contractor',
    },

    feeMessageDueDate: {
        id: 'feeMessages.feeMessageDueDate',
        defaultMessage: 'Validade da Tarifa',
    },

    FeePayableType: {
        id: 'feeMessages.type',
        defaultMessage: 'Tipo',
    },

    feeMessageValue: {
        id: 'feeMessages.value',
        defaultMessage: 'Valor Tarifa',
    },
});

export default messages;
