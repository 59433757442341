import { Typography } from '@material-ui/core';
import React from 'react';

function Loading(props) {
    const LoadingSvg = () => (
        <div className='loadingV2'>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 360 360'
                width='220'
                height='220'
            >
                <path
                    d='M292 0L283.631 113.059C278.484 182.789 217.312 236.855 143.492 236.855H110.143C110.145 207.025 98.8886 178.293 78.6253 156.402C58.362 134.511 30.5839 121.074 0.842773 118.775C8.46949 51.4922 68.5042 0 140.508 0H292Z'
                    fill='#37D5BB'
                />
                <path
                    className='lowerV2'
                    d='M118.159 118.855H0L8.36886 5.79598C8.49519 4.10641 8.65309 2.43264 8.85836 0.774658C38.5994 3.07354 66.3775 16.5113 86.6409 38.4023C106.904 60.2932 118.16 89.0248 118.159 118.855Z'
                    fill='#37D5BB'
                />
            </svg>
        </div>
    );

    return props.loading ? (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                minHeight: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <LoadingSvg />
            <Typography
                className='loadingtextV2'
                variant='h4'
                style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'italic',
                    fontWeight: '600',
                }}
            >
                {/* Carregando... */}
            </Typography>
        </div>
    ) : null;
}

export default Loading;
