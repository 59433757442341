import { toast } from 'react-toastify';
import axios from 'axios';
import * as actionTypes from './actionTypes';

export const initStates = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.STATES_GET_LIST_REQUEST,
        });

        return axios
            .get('/States')
            .then((response) => {
                dispatch({
                    type: actionTypes.STATES_GET_LIST_SUCCESS,
                    states: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.STATES_GET_LIST_FAILURE,
                    error,
                });
                toast.error(`Não foi possível listar os estados. ${error}`);
            });
    };
};

export const initCities = (uf) => {
    return (dispatch, getState) => {
        if (uf) {
            const states = getState().stateReducer.states;
            const state = states.find((s) => s.uf === uf);

            dispatch({
                type: actionTypes.CITIES_GET_LIST_SUCCESS,
                cities: state && state.cities,
            });
        } else {
            dispatch({
                type: actionTypes.CITIES_GET_LIST_SUCCESS,
                cities: [],
            });
        }
    };
};
