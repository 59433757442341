import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend,
} from 'recharts';
import './TransactionConversionPercent.css';
import { injectIntl } from 'react-intl';
import messages from './TransactionConversionPercent.intl';

const TransactionConversionPercent = (props) => {
    const { intl } = props;
    const colors = props.colors;
    const data = props.data.data.data.map((data) => {
        const tratada = { date: data.date };
        for (let i = 0; i < data.values.length; i++) {
            tratada[data.values[i].name] = data.values[i].value;
        }
        return tratada;
    });
    const values = props.data.data.dataValueNames;
    const tickFormatter = (number) => {
        return `${number}%`;
    };
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className='custom-tooltip'>
                    <p>{`${payload[0].payload.date}`}</p>
                    {payload.map((payload) => {
                        const color = payload.color;
                        return (
                            <p key={payload.name} style={{ color }}>{`${
                                payload.name
                            } : ${payload.value}%`}</p>
                        );
                    })}
                </div>
            );
        }

        return null;
    };
    return (
        <>
            <div className='text'>
                <h1>
                    {intl.formatMessage(messages.transactionConversionPercent)}
                </h1>
            </div>

            <div className='chart chart-line'>
                <ResponsiveContainer
                    width={window.screen.width / 3}
                    height={500}
                >
                    <BarChart
                        width={window.screen.width / 3}
                        height={500}
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis tick={{ fontSize: 13 }} dataKey='date' />
                        <YAxis tickFormatter={tickFormatter} />
                        <Tooltip content={CustomTooltip} />
                        <Legend />
                        {values.map((value, index) => {
                            return (
                                <Bar
                                    key={value}
                                    name={value}
                                    type='monotone'
                                    dataKey={value}
                                    fill={colors[index]}
                                />
                            );
                        })}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </>
    );
};

export default injectIntl(withRouter(TransactionConversionPercent));
