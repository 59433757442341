/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';

import * as actions from '../../store/actions/index';

import Tabs from '../../components/Tabs/Tabs';
import Header from '../../components/Header/Header';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';

import messages from './BillingOfficeDetailsPage.intl';

import { AuthContext } from '../../context/AuthContext';
import BillingOfficeDetailsForm from '../../components/BillingOfficeDetailsForm/BillingOfficeDetailsForm';
import BillingOfficeFileForm from '../../components/BillingOfficeFileForm/BillingOfficeFileForm';

class BillingOfficeDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        activeTab: 0,
        showModal: false,
        cities: [],
        selectedState: null,
        selectedCity: null,
    };

    componentDidMount() {
        this.props.onInitBillingOfficerDetail(this.props.match.params.id);
        this.props.onInitContractors();
    }

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }
    };

    handleSubmit = (event, values) => {
        if (this.props.loadingDetails) return;

        const billingOffice = this.props.billingOffice || {};
        const contractors = this.props.contractorsList || {};

        const payload = {
            ...values,
            contractors: values.contractorsId?.map((id) => {
                const contractor = contractors.find(
                    (x) => x.contractorId === id,
                );
                return {
                    ...contractor,
                    id: contractor?.contractorId,
                };
            }),
        };

        if (this.props.match.params.id === 'new') {
            this.props.onCreateBillingOffice(payload);
        } else {
            this.props.onUpdateBillingOffice(billingOffice.id, payload);
        }
    };

    handleNewLogoFileSaveButtonClick = (file) => {
        const { id } = this.props.billingOffice;
        this.props.onUploadBillingOfficeNormalLogo(id, file);
    };

    handleNewSmallLogoSaveButtonClick = (file) => {
        const { id } = this.props.billingOffice;
        this.props.onUploadBillingOfficeSmallLogo(id, file);
    };

    handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handleToggleDelete = async () => {
        this.setState({
            showModal: !this.state.showModal,
        });

        await this.props.onDeleteBillingOffice(this.props.billingOffice.id);
    };

    render() {
        const { intl, loadingDetails, loadingNormalLogo } = this.props;
        const billingOffice = this.props.billingOffice || {};
        const isNew = this.props.match.params.id === 'new';

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/contractors',
                            name: intl.formatMessage(messages.contractors),
                            active: false,
                        },
                        {
                            path: '/billingOffice/:id',
                            name: intl.formatMessage(
                                messages.titleBillingOffice,
                                {
                                    entity:
                                        this.props.billingOffice &&
                                        this.props.billingOffice.displayName,
                                },
                            ),
                            active: true,
                        },
                    ]}
                />
                <header>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(
                                messages.titleBillingOffice,
                                {
                                    entity: '',
                                },
                            )}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content'>
                    <Loading loading={loadingDetails} />
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={[
                            {
                                name: intl.formatMessage(
                                    messages.registrationInfo,
                                ),
                                component: (
                                    <BillingOfficeDetailsForm
                                        key='billingOfficeTab'
                                        billingOffice={billingOffice}
                                        handleSubmit={this.handleSubmit}
                                        readOnly={!isNew}
                                        loading={loadingDetails}
                                        handleToggleModal={
                                            this.handleToggleModal
                                        }
                                        contractorList={
                                            this.props.contractorsList
                                        }
                                        isSystem={
                                            this.context.contractor.isSystem
                                        }
                                    />
                                ),
                            },
                            {
                                name: 'Importar Arquivo',
                                disabled: isNew,
                                component: (
                                    <BillingOfficeFileForm
                                        key='filesTab'
                                        billingOffice={billingOffice}
                                        handleNewLogoFileSaveButtonClick={
                                            this
                                                .handleNewLogoFileSaveButtonClick
                                        }
                                        handleNewSmallLogoSaveButtonClick={
                                            this
                                                .handleNewSmallLogoSaveButtonClick
                                        }
                                        handleNewAttachmentSaveButtonClick={
                                            this
                                                .handleNewAttachmentSaveButtonClick
                                        }
                                        loading={loadingDetails}
                                        loadingNormalLogo={loadingNormalLogo}
                                    />
                                ),
                            },
                        ]}
                    />
                </section>
                <Modal
                    title='Excluir Escritório de cobranças'
                    isOpen={this.state.showModal}
                    handleToggle={this.handleToggleModal}
                    type={modalTypes.ERROR}
                    hasFooter
                    firstButtonText='Sim'
                    firstButtonClass='btn-delete'
                    secondButtonText='Não'
                    toggleFirstButton={this.handleToggleDelete}
                    toggleSecondButton={this.handleToggleModal}
                >
                    Deseja excluir {billingOffice.displayName}?
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        billingOffice: state.billingOfficeReducer.billingOffice,
        contractorsList: state.contractorReducer.contractors ?? [],
        loadingDetails: state.billingOfficeReducer.loadingDetails,
        normalLogoFileId: state.billingOfficeReducer.normalLogoFileId,
        smallLogoFileId: state.billingOfficeReducer.smallLogoFileId,
        loadingNormalLogo: state.billingOfficeReducer.loadingNormalLogo,
        loadingSmallLogo: state.billingOfficeReducer.loadingSmallLogo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitContractors: () =>
            dispatch(
                actions.initContractors('?sort=-createdAt&skip=0&take=100'),
            ),
        onInitBillingOfficerDetail: (id) =>
            dispatch(actions.initBillingOfficerDetail(id)),
        onCreateBillingOffice: (values) =>
            dispatch(actions.createBillingOffice(values)),
        onUpdateBillingOffice: (id, values) =>
            dispatch(actions.updateBillingOffice(id, values)),
        onDeleteBillingOffice: (values) =>
            dispatch(actions.deleteBillingOffice(values)),
        onUploadBillingOfficeNormalLogo: (id, file) =>
            dispatch(actions.uploadBillingOfficeNormalLogo(id, file)),
        onUploadBillingOfficeSmallLogo: (id, file) =>
            dispatch(actions.uploadBillingOfficeSmallLogo(id, file)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(BillingOfficeDetailsPage),
);
