import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';

import * as actions from '../../store/actions/index';

import Tabs from '../../components/Tabs/Tabs';
import Header from '../../components/Header/Header';

import TransactionDetailsForm from '../../components/TransactionDetailsForm/TransactionDetailsForm';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './TransactionDetailsPage.intl';
import { AuthContext } from '../../context/AuthContext';
import TransactionFileForm from '../../components/TransactionFileForm/TransactionFileForm';

class TransactionDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        activeTab: 0,
        showModal: false,
    };

    componentDidMount() {
        if (this.props.match.params.id === 'new') {
            this.props.onInitTransactionDetails('new');
        } else if (this.props.match.params.id !== 'import') {
            this.props.onInitTransactionDetails(this.props.match.params.id);
        }
    }

    handleNewAttachmentSaveButtonClick = (file) => {
        this.props.onImportExcel(file);
        this.props.history.push(`/transactions`);
    };

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }
    };

    handleSubmit = (event, values) => {
        if (this.props.loadingDetails) return;

        if (!values.contractorId) {
            const { contractorId } = this.context;
            values.contractorId = contractorId;
        }

        if (this.props.match.params.id === 'new') {
            this.props.onCreateTransaction(values);
        } else {
            this.props.onUpdateTransaction(this.props.match.params.id, values);
        }
    };

    handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handleContractorsChange = (selectedContractor) => {
        this.setState({
            selectedContractor: {
                selectedLabelOption: selectedContractor.label,
                selectedValueOption: selectedContractor.value,
            },
        });
    };

    render() {
        const { intl, loadingDetails, loadingAttachment } = this.props;

        const isNew = this.props.match.params.id === 'new';
        const isImport = this.props.match.params.id === 'import';

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/transactions',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/transactions/:id',
                            name: intl.formatMessage(
                                messages.titleTransaction,
                                {
                                    entity:
                                        this.props.transaction &&
                                        this.props.transaction
                                            .transactionDescription,
                                },
                            ),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(
                                messages.titleTransaction,
                                {
                                    entity: '',
                                },
                            )}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content transactionDetails'>
                    {!isImport && <Loading loading={loadingDetails} />}
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={
                            isImport
                                ? [
                                      {
                                          name: 'Importar Arquivo',
                                          component: (
                                              <TransactionFileForm
                                                  key='filesTab'
                                                  handleNewAttachmentSaveButtonClick={
                                                      this
                                                          .handleNewAttachmentSaveButtonClick
                                                  }
                                                  loadingAttachment={
                                                      loadingAttachment
                                                  }
                                              />
                                          ),
                                      },
                                  ]
                                : [
                                      {
                                          name: intl.formatMessage(
                                              messages.registrationInfo,
                                          ),
                                          component: (
                                              <TransactionDetailsForm
                                                  transaction={
                                                      this.props.transaction
                                                  }
                                                  handleSubmit={
                                                      this.handleSubmit
                                                  }
                                                  handleContractorsChange={
                                                      this
                                                          .handleContractorsChange
                                                  }
                                                  handleDelete={
                                                      this.handleToggleModal
                                                  }
                                                  isNew={isNew}
                                                  loading={
                                                      this.props.loadingDetails
                                                  }
                                                  selectedContractor={
                                                      this.state
                                                          .selectedContractor
                                                  }
                                              />
                                          ),
                                      },
                                  ]
                        }
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        transaction: state.transactionReducer.transaction,
        loadingDetails: state.transactionReducer.loadingDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTransactionDetails: (transactionId) =>
            dispatch(actions.initTransactionDetails(transactionId)),
        onUpdateTransaction: (transactionId, values) =>
            dispatch(actions.updateTransaction(transactionId, values)),
        onCreateTransaction: (values) =>
            dispatch(actions.createTransaction(values)),
        onImportExcel: (file) =>
            dispatch(actions.uploadTransactionAttachment(file)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TransactionDetailsPage),
);
