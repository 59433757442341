import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    value: {
        id: 'global.value',
        defaultMessage: 'Valor',
    },
});

export default messages;
