import React from 'react';
import { injectIntl } from 'react-intl';
import { FormGroup, Col } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import messages from './Combobox.intl';

const combobox = (props) => {
    const { intl, filter, enums } = props;

    return (
        <Col lg={4} md={4} sm={12}>
            <FormGroup>
                <AvField
                    type='select'
                    name={`filters[${filter.id}].value`}
                    id={`filters[${filter.id}].value`}
                    label={intl.formatMessage(messages.value)}
                    value={filter.value}
                    onChange={(e) =>
                        props.handleFieldChange(e, filter.id, 'value')
                    }
                >
                    <option value=''>-</option>
                    {enums &&
                        enums.map((en) => {
                            return (
                                <option key={en.key} value={en.key}>
                                    {en.value}
                                </option>
                            );
                        })}
                </AvField>
            </FormGroup>
        </Col>
    );
};

export default injectIntl(combobox);
