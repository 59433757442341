import React, { useState, useEffect } from 'react';

import { injectIntl } from 'react-intl';

import { Row, Col, Media, Button } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import messages from './BillingOfficeFileForm.intl';

import Loading from '../Loading/Loading';

function BillingOfficeFileForm(props) {
    const [logo, setLogo] = useState(null);
    const [logoDataUrl, setLogoDataUrl] = useState(null);
    const [showSaveLogoButton, setShowSaveLogoButton] = useState(false);

    const { intl, loadingNormalLogo, readOnly, billingOffice } = props;

    const { logoFileId } = billingOffice || {};

    useEffect(() => {
        setLogoDataUrl(
            logoFileId
                ? `${window.API_URI}/BillingOffice/${billingOffice.id}/Logo`
                : 'https://place-hold.it/300x150',
        );

        setShowSaveLogoButton(false);
    }, [billingOffice.id, logoFileId]);

    function handleLogoFileChange(e) {
        const fileReader = new FileReader();

        fileReader.onload = () => {
            setLogoDataUrl(fileReader.result);
            setShowSaveLogoButton(true);
        };

        setLogo(e.target.files[0]);
        fileReader.readAsDataURL(e.target.files[0]);
    }

    function handleNewLogoSaveButtonClick(e) {
        e.preventDefault();

        props.handleNewLogoFileSaveButtonClick &&
            props.handleNewLogoFileSaveButtonClick(logo);

        setShowSaveLogoButton(false);
    }

    return (
        <AvForm onValidSubmit={props.handleSubmit}>
            <Row>
                <Col xs={12} md={6}>
                    {loadingNormalLogo && <Loading loading />}
                    <Media object src={logoDataUrl} height='150px' />
                    <br />
                    <br />
                    <Row>
                        <Col xs={8}>
                            <AvField
                                label={intl.formatMessage(
                                    messages.logoNormalText,
                                )}
                                type='file'
                                onChange={handleLogoFileChange}
                                name='normalLogoFileId'
                                id='normalLogoFileId'
                                disabled={readOnly}
                                accept='image/x-png,image/gif,image/jpeg'
                            />
                        </Col>
                        {showSaveLogoButton && (
                            <Col xs={4} className='mt-3'>
                                <Button
                                    className='btn-submit'
                                    onClick={handleNewLogoSaveButtonClick}
                                >
                                    Confirmar
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </AvForm>
    );
}

export default injectIntl(BillingOfficeFileForm);
