import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'clients.title',
        defaultMessage: 'Clientes {entity}',
    },

    subtitle: {
        id: 'clients.subtitle',
        defaultMessage: ' {length} cliente(s) encontrado(s)',
    },

    titleOnlyClient: {
        id: 'clients.titleOnlyClient',
        defaultMessage: 'Cliente',
    },

    clientId: {
        id: 'clients.clientId',
        default: 'Id cliente',
    },

    name: {
        id: 'clients.name',
        defaultMessage: 'Nome',
    },

    cpfCnpj: {
        id: 'clients.cpfCnpj',
        defaultMessage: 'CPF/CNPJ',
    },

    contractorId: {
        id: 'clients.contractorId',
        defaultMessage: 'ID contratante',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
});

export default messages;
