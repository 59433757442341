import * as actionTypes from '../actions/actionTypes';

const initialState = {
    widgets: [],
    dataArray: [],
    colors: [],
    cards: [],
};

const setWidgets = (state, widgets) => {
    return {
        ...state,
        widgets,
    };
};

const setWidgetData = (state, payload) => {
    const dataArray = state.dataArray.slice();
    let found = dataArray.find((d) => d.widgetId === payload.widgetId);

    if (found) {
        found = payload;
    } else {
        dataArray.push(payload);
    }

    return {
        ...state,
        dataArray,
    };
};

const setWidgetRequest = (state) => {
    return {
        ...state,
        widgets: [],
        dataArray: [],
    };
};

const setFinancialCards = (state, cards) => {
    return { ...state, cards };
};

const setGeneratedColors = (state, colors) => {
    return {
        ...state,
        colors,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.WIDGETS_GET_SUCCESS:
            return setWidgets(state, action.widgets);

        case actionTypes.WIDGET_GET_SUCCESS:
            return setWidgetData(state, action.payload);

        case actionTypes.WIDGETS_GET_REQUEST:
            return setWidgetRequest(state, action.payload);

        case actionTypes.COLORS_GET_SUCCESS:
            return setGeneratedColors(state, action.colors);

        case actionTypes.FINANCIAL_DOCUMENTS_CARDS_GET_SUCCESS:
            return setFinancialCards(state, action.cards);

        default:
            return state;
    }
};

export default reducer;
