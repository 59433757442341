import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const message = defineMessages({
    ...globalMessages,

    title: {
        id: 'reconciliation.title',
        defaultMessage: 'Reconciliações',
    },

    paymentTitle: {
        id: 'payment.paymentTitle',
        defaultMessage: 'Pagamentos recebidos',
    },

    subtitle: {
        id: 'reconciliation.subtitle',
        defaultMessage: 'Reconciliação(ções)',
    },

    paymentSubtitle: {
        id: 'payment.paymentSubtitle',
        defaultMessage: 'Pagamento(s)',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    operatorTransactionId: {
        id: 'reconciliation.operatorTransactionId',
        defaultMessage: 'Id Transação Operador',
    },

    paymentDate: {
        id: 'reconciliation.paymentDate',
        defaultMessage: 'Data de criação',
    },

    reconciliationTypeDescription: {
        id: 'reconciliation.reconciliationTypeDescription',
        defaultMessage: 'Tipo',
    },

    installmentBeginEnd: {
        id: 'reconciliation.installmentBeginEnd',
        defaultMessage: 'Parcela início/Fim',
    },

    paymentValue: {
        id: 'reconciliation.paymentValue',
        defaultMessage: 'Valor(R$)',
    },

    taxValue: {
        id: 'reconciliation.taxValue',
        defaultMessage: 'Taxa(R$)',
    },

    bankDetails: {
        id: 'reconciliation.bankDetails',
        defaultMessage: 'Dados Bancários',
    },

    bankAccount: {
        id: 'reconciliation.bankAccount',
        defaultMessage: 'Número da conta',
    },

    bankAgency: {
        id: 'reconciliation.bankAgency',
        defaultMessage: 'Agência bancária',
    },

    bankCode: {
        id: 'reconciliation.bankCode',
        defaultMessage: 'Código bancário',
    },

    transactionRefId: {
        id: 'reconciliation.transactionRefId',
        defaultMessage: 'ID da transação',
    },

    reconciliationTabTitle: {
        id: 'reconciliation.reconciliationTabTitle',
        defaultMessage: 'Reconciliação',
    },

    paymentTabTitle: {
        id: 'payment.paymentTabTitle',
        defaultMessage: 'Pagamentos',
    },

    reconciliationAuditTabTitle: {
        id: 'reconciliationAudit.reconciliationAuditTabTitle',
        defaultMessage: 'Auditoria',
    },

    enterprise: {
        id: 'payment.enterprise',
        defaultMessage: 'Enterprise',
    },

    paymentReceivedId: {
        id: 'payment.paymentReceivedId',
        defaultMessage: 'ID Pagamento Recebido',
    },

    accountingDocumentNumber: {
        id: 'payment.accountingDocumentNumber',
        defaultMessage: 'Número documento',
    },

    operatorPaymentReceivedTransactionId: {
        id: 'payment.operatorPaymentReceivedTransactionId',
        defaultMessage: 'Id transação operador',
    },

    payDate: {
        id: 'payment.payDate',
        defaultMessage: 'Data de pagamento',
    },

    payTime: {
        id: 'payment.payTime',
        defaultMessage: 'Hora do pagamento',
    },

    collectionId: {
        id: 'payment.collectionId',
        defaultMessage: 'Id da collection',
    },

    paymentPaymentValue: {
        id: 'payment.paymentValue',
        defaultMessage: 'Valor do pagamento',
    },

    paymentMethodType: {
        id: 'payment.paymentMethodType',
        defaultMessage: 'Tipo do método de pagamento',
    },
});

export default message;
