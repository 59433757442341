import React, { Component } from 'react';

import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from './ClientListPage.intl';
import * as actions from '../../store/actions/index';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import EvtTable, {
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import { filterPropertyTypes } from '../../utils/enums';
import { AuthContext } from '../../context/AuthContext';
import { handleCpfCnpj } from '../../utils/handleCpfCnpj';

class ClientListPage extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
        query: '',
    };

    reloadClientsList = () => {
        const { query } = this.state;
        this.props.onInitClients(query);
        this.props.onInitClientsCount(query);
    };

    handleRowClick = (e, id) => {
        this.props.history.push(`/clients/${id}`);
    };

    handleNewClick = () => {
        this.props.history.push(`/clients/new`);
    };

    handleGetTrProps = (state, rowInfo) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: () =>
                this.props.history.push(`/clients/${rowInfo.original.id}`),
        };
    };

    handleRefreshButtonClick = () => {
        this.reloadClientsList();
    };

    handleFiltersChange = (filters) => {
        this.setState({
            filters,
        });
    };

    handleEvTableStateChange = (query) => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadClientsList();
            },
        );
    };

    render() {
        const { intl, loadingList, clients, clientsCount } = this.props;
        const { filters } = this.state;
        const newClientButtonEnabled = this.context.hasClaim('client-create');
        const properties = [
            {
                key: 'name',
                value: intl.formatMessage(messages.name),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'clientCpfCnpj',
                value: intl.formatMessage(messages.cpfCnpj),
                type: filterPropertyTypes.TEXT,
                map: (input) => input.replace(/[^0-9]/g, ''),
            },
            {
                key: 'clientERPId',
                value: intl.formatMessage(messages.clientId),
                type: filterPropertyTypes.TEXT,
            },
        ];

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.clientId)}</span>
                    </div>
                ),
                accessor: 'clientERPId',
                minWidth: calculateColumnWidth(
                    clients,
                    'clientERPId',
                    intl.formatMessage(messages.clientId),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.name)}</span>
                    </div>
                ),
                accessor: 'name',
                Cell: (row) => {
                    const id = row.index + 1;
                    return (
                        <div className='customer-container'>
                            <span className={`color-number color-number-${id}`}>
                                {row.value.substr(0, 1).toUpperCase()}
                            </span>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                minWidth: calculateColumnWidth(
                    clients,
                    'name',
                    intl.formatMessage(messages.name),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.cpfCnpj)}</span>
                    </div>
                ),
                accessor: 'clientCpfCnpj',
                Cell: (row) => handleCpfCnpj(row.value),
                minWidth: calculateColumnWidth(
                    clients,
                    'clientCpfCnpj',
                    intl.formatMessage(messages.cpfCnpj),
                ),
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/clients',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={
                                clientsCount +
                                intl.formatMessage(messages.subtitle)
                            }
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>

                        {newClientButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.newButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleOnlyClient,
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={clients}
                        length={clientsCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                        defaultSorted={[
                            {
                                id: 'createdAt',
                                desc: true,
                            },
                        ]}
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        clients: state.clientReducer.clients || [],
        loadingList: state.clientReducer.loadingList,
        clientsCount: state.clientReducer.clientsCount,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitClients: (filter) => dispatch(actions.initClients(filter)),
        onInitClientsCount: (filter) =>
            dispatch(actions.initClientsCount(filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ClientListPage),
);
