import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    billingVolumeChart: {
        id: 'dashboard.billingVolumeChart',
        defaultMessage: 'Billing Volume Chart',
    },
});
