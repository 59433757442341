import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    transactionConversionPercent: {
        id: 'dashboard.transactionConversionPercent',
        defaultMessage: 'Transactions Conversion Percent',
    },
});
