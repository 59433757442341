import { toast } from 'react-toastify';
import axios from 'axios';
import { push } from 'connected-react-router';
import * as actionTypes from './actionTypes';

export const initParameters = (filter) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.PARAMETERS_GET_LIST_REQUEST,
        });

        axios
            .get(`/Parameters${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.PARAMETERS_GET_LIST_SUCCESS,
                    parameters: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.PARAMETERS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initParametersCount = (filter) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.PARAMETERS_GET_COUNT_REQUEST,
        });
        await axios
            .get(`/Parameters/Count${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.PARAMETERS_GET_COUNT_SUCCESS,
                    parametersCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.PARAMETERS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initParameterDetails = (parameterId) => {
    return (dispatch) => {
        if (parameterId === 'new') {
            dispatch({
                type: actionTypes.PARAMETER_GET_DETAILS_NEW,
            });
        } else {
            dispatch({
                type: actionTypes.PARAMETER_GET_DETAILS_REQUEST,
            });

            axios
                .get(`/Parameters/${parameterId}`)
                .then(function(response) {
                    dispatch({
                        type: actionTypes.PARAMETER_GET_DETAILS_SUCCESS,
                        parameter: response.data,
                    });
                })
                .catch(function(error) {
                    dispatch({
                        type: actionTypes.PARAMETER_GET_DETAILS_FAILURE,
                        error,
                    });
                });
        }
    };
};

export const createParameter = (values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.PARAMETER_POST_REQUEST,
        });

        axios
            .post(`/Parameters`, { ...values })
            .then(function(response) {
                dispatch({
                    type: actionTypes.PARAMETER_POST_SUCCESS,
                    parameterId: response.data,
                });

                toast.success('Pedido criado com sucesso');
                dispatch(push('/parameters'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.PARAMETER_POST_FAILURE,
                    error,
                });
            });
    };
};

export const updateParameter = (parameterId, values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.PARAMETER_PUT_REQUEST,
        });

        axios
            .put(`/Parameters/${parameterId}`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.PARAMETER_PUT_SUCCESS,
                });
                toast.success('Parâmetro alterado com sucesso');
                dispatch(push('/parameters'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.PARAMETER_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const uploadParameterAttachment = (file) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.PARAMETER_UPLOAD_ATTACHMENT_REQUEST,
        });

        const data = new FormData();
        data.append('file', file);

        axios
            .post(`/Parameters/PostExcel`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(() => {
                dispatch({
                    type: actionTypes.PARAMETER_UPLOAD_ATTACHMENT_SUCCESS,
                });

                toast.success('os parâmetros foram atualizadas com sucesso.');
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.PARAMETER_UPLOAD_ATTACHMENT_FAILURE,
                    error,
                });
                console.log(error);
            });
    };
};
