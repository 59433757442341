import { FormattedMessage } from 'react-intl';
import React from 'react';

import { apiErrorCodes } from './apiErrorMessages.intl';

export const formatErrorMessage = (errorCode, response) => {
    const result = apiErrorCodes.find((aec) => aec.errorCode === errorCode);

    if (result) {
        return (
            <FormattedMessage
                id={result.id}
                defaultMessage={result.defaultMessage}
            />
        );
    }
    if (response && typeof response === 'string') {
        return (
            <FormattedMessage id='apiMessageError' defaultMessage={response} />
        );
    }
    return (
        <FormattedMessage
            id='global.defaultError'
            defaultMessage='Oh, não! Alguma coisa deu errado...'
        />
    );
};
