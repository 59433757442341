import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import { connectRouter, routerMiddleware } from 'connected-react-router';

import thunk from 'redux-thunk';
import globalReducer from './reducers/globalReducer';
import authenticationReducer from './reducers/authenticationReducer';
import contactReducer from './reducers/contactReducer';
import contractorReducer from './reducers/contractorReducer';
import billingOfficeReducer from './reducers/billingOfficeReducer';
import collectionReducer from './reducers/collectionReducer';
import parameterReducer from './reducers/parameterReducer';
import transactionReducer from './reducers/transactionReducer';
import stateReducer from './reducers/stateReducer';
import userReducer from './reducers/userReducer';
import settingsReducer from './reducers/settingsReducer';
import fileReducer from './reducers/fileReducer';
import roleReducer from './reducers/roleReducer';
import claimReducer from './reducers/claimReducer';
import widgetReducer from './reducers/widgetReducer';
import ocrOperationResultReducer from './reducers/ocrOperationResultReducer';
import logReducer from './reducers/logReducer';
import uiContextReducer from './reducers/uiContextReducer';
import feeMessageReducer from './reducers/feeMessageReducer';
import clientReducer from './reducers/clientReducer';
import financialDocumentsReducer from './reducers/financialDocumentsReducer';
import reconciliationReducer from './reducers/reconciliationReducer';

export default function configureStore(history, initialState) {
    const reducers = {
        globalReducer,
        authenticationReducer,
        contactReducer,
        contractorReducer,
        billingOfficeReducer,
        collectionReducer,
        parameterReducer,
        transactionReducer,
        feeMessageReducer,
        userReducer,
        stateReducer,
        settingsReducer,
        fileReducer,
        roleReducer,
        claimReducer,
        widgetReducer,
        ocrOperationResultReducer,
        logReducer,
        uiContextReducer,
        clientReducer,
        financialDocumentsReducer,
        reconciliationReducer,
    };

    const middleware = [thunk, routerMiddleware(history)];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';

    if (
        isDevelopment &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION__
    ) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
    });

    // Reset redux state only if logoff was successfully
    const combineReducersApp = (state, action) => {
        if (action.type === 'LOG_OUT_SUCCESS') {
            state = {};
        }

        return rootReducer(state, action);
    };

    return createStore(
        combineReducersApp,
        initialState,
        compose(
            applyMiddleware(...middleware, routerMiddleware(history)),
            ...enhancers,
        ),
    );
}
