import * as actionTypes from '../actions/actionTypes';

const initialState = {
    clients: [],
    client: null,
    clientsCount: 0,
    loadingDetails: true,
    loadingList: true,
};

const setClients = (state, action) => {
    return {
        ...state,
        clients: action.clients,
        loadingList: false,
    };
};

const setClientsRequest = (state, action) => {
    return {
        ...state,
        clients: action.clients,
        loadingList: true,
    };
};

const setClientsCount = (state, action) => {
    return {
        ...state,
        clientsCount: action.clientsCount,
    };
};

const setClientDetails = (state, action) => {
    return {
        ...state,
        client: action.client,
        loadingDetails: false,
    };
};

const setClientDetailsPost = (state, action) => {
    return {
        ...state,
        client: action.client,
        loadingDetails: false,
        clients: [],
    };
};

const setClientDetailsNew = (state) => {
    return {
        ...state,
        client: undefined,
        loadingDetails: false,
    };
};

const setClientDetailsRequest = (state) => {
    return {
        ...state,
        client: undefined,
        loadingDetails: true,
        emailSent: false,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLIENTS_GET_LIST_REQUEST:
            return setClientsRequest(state, action);

        case actionTypes.CLIENTS_GET_LIST_FAILURE:
        case actionTypes.CLIENTS_GET_LIST_SUCCESS:
            return setClients(state, action);

        case actionTypes.CLIENT_GET_DETAILS_SUCCESS:
            return setClientDetails(state, action);

        case actionTypes.CLIENT_POST_SUCCESS:
        case actionTypes.CLIENT_POST_FAILURE:
            return setClientDetailsPost(state, action);

        case actionTypes.CLIENT_GET_DETAILS_REQUEST:
        case actionTypes.CLIENT_POST_REQUEST:
            return setClientDetailsRequest(state, action);

        case actionTypes.CLIENT_GET_DETAILS_NEW:
            return setClientDetailsNew(state, action);

        case actionTypes.CLIENTS_GET_COUNT_SUCCESS:
            return setClientsCount(state, action);

        default:
            return state;
    }
};

export default reducer;
