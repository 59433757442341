//  Login
export const SESSION_REQUEST = 'SESSION_REQUEST';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';
export const SESSION_FAILURE = 'SESSION_FAILURE';

export const SET_SESSION_ACCESS_SELECTION = 'SET_SESSION_ACCESS_SELECTION';
export const SESSION_ACCESS_SELECTION_REQUEST =
    'SESSION_ACCESS_SELECTION_REQUEST';
export const SESSION_ACCESS_SELECTION_SUCCESS =
    'SESSION_ACCESS_SELECTION_SUCCESS';
export const SESSION_ACCESS_SELECTION_FAILURE =
    'SESSION_ACCESS_SELECTION_FAILURE';

export const GET_CURRENT_SESSION_REQUEST = 'GET_CURRENT_SESSION_REQUEST';
export const GET_CURRENT_SESSION_SUCCESS = 'GET_CURRENT_SESSION_SUCCESS';
export const GET_CURRENT_SESSION_FAILURE = 'GET_CURRENT_SESSION_FAILURE';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const FORGOT_PASSWORD_CONFIRMATION_REQUEST =
    'FORGOT_PASSWORD_CONFIRMATION_REQUEST';
export const FORGOT_PASSWORD_CONFIRMATION_SUCCESS =
    'FORGOT_PASSWORD_CONFIRMATION_SUCCESS';
export const FORGOT_PASSWORD_CONFIRMATION_FAILURE =
    'FORGOT_PASSWORD_CONFIRMATION_FAILURE';

export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_FAILURE = 'USER_CHANGE_PASSWORD_FAILURE';

//  Billing Office Page
export const BILLINGOFFICE_GET_LIST_REQUEST = 'BILLINGOFFICE_GET_LIST_REQUEST';
export const BILLINGOFFICE_GET_LIST_SUCCESS = 'BILLINGOFFICE_GET_LIST_SUCCESS';
export const BILLINGOFFICE_GET_LIST_FAILURE = 'BILLINGOFFICE_GET_LIST_FAILURE';

export const BILLINGOFFICE_GET_COUNT_REQUEST =
    'BILLINGOFFICE_GET_COUNT_REQUEST';
export const BILLINGOFFICE_GET_COUNT_SUCCESS =
    'BILLINGOFFICE_GET_COUNT_SUCCESS';
export const BILLINGOFFICE_GET_COUNT_FAILURE =
    'BILLINGOFFICE_GET_COUNT_FAILURE';

export const BILLINGOFFICE_GET_DETAILS_REQUEST =
    'BILLINGOFFICE_GET_DETAILS_REQUEST';
export const BILLINGOFFICE_GET_DETAILS_SUCCESS =
    'BILLINGOFFICE_GET_DETAILS_SUCCESS';
export const BILLINGOFFICE_GET_DETAILS_FAILURE =
    'BILLINGOFFICE_GET_DETAILS_FAILURE';

export const BILLINGOFFICE_POST_REQUEST = 'BILLINGOFFICE_POST_REQUEST';
export const BILLINGOFFICE_POST_SUCCESS = 'BILLINGOFFICE_POST_SUCCESS';
export const BILLINGOFFICE_POST_FAILURE = 'BILLINGOFFICE_POST_FAILURE';

export const BILLINGOFFICE_PUT_REQUEST = 'BILLINGOFFICE_PUT_REQUEST';
export const BILLINGOFFICE_PUT_SUCCESS = 'BILLINGOFFICE_PUT_SUCCESS';
export const BILLINGOFFICE_PUT_FAILURE = 'BILLINGOFFICE_PUT_FAILURE';

export const BILLINGOFFICE_DELETE_REQUEST = 'BILLINGOFFICE_DELETE_REQUEST';
export const BILLINGOFFICE_DELETE_SUCCESS = 'BILLINGOFFICE_DELETE_SUCCESS';
export const BILLINGOFFICE_DELETE_FAILURE = 'BILLINGOFFICE_DELETE_FAILURE';

export const BILLINGOFFICE_UPLOAD_NORMAL_LOGO_REQUEST =
    'BILLINGOFFICE_UPLOAD_NORMAL_LOGO_REQUEST';
export const BILLINGOFFICE_UPLOAD_NORMAL_LOGO_SUCCESS =
    'BILLINGOFFICE_UPLOAD_NORMAL_LOGO_SUCCESS';
export const BILLINGOFFICE_UPLOAD_NORMAL_LOGO_FAILURE =
    'BILLINGOFFICE_UPLOAD_NORMAL_LOGO_FAILURE';

export const BILLINGOFFICE_UPLOAD_SMALL_LOGO_REQUEST =
    'BILLINGOFFICE_UPLOAD_SMALL_LOGO_REQUEST';
export const BILLINGOFFICE_UPLOAD_SMALL_LOGO_SUCCESS =
    'BILLINGOFFICE_UPLOAD_SMALL_LOGO_SUCCESS';
export const BILLINGOFFICE_UPLOAD_SMALL_LOGO_FAILURE =
    'BILLINGOFFICE_UPLOAD_SMALL_LOGO_FAILURE';

export const BILLINGOFFICE_GET_DETAILS_NEW = 'BILLINGOFFICE_GET_DETAILS_NEW';
//  Contractor's  Page
export const CONTRACTORS_GET_LIST_REQUEST = 'CONTRACTORS_GET_LIST_REQUEST';
export const CONTRACTORS_GET_LIST_SUCCESS = 'CONTRACTORS_GET_LIST_SUCCESS';
export const CONTRACTORS_GET_LIST_FAILURE = 'CONTRACTORS_GET_LIST_FAILURE';

export const CONTRACTORS_GET_COUNT_REQUEST = 'CONTRACTORS_GET_COUNT_REQUEST';
export const CONTRACTORS_GET_COUNT_SUCCESS = 'CONTRACTORS_GET_COUNT_SUCCESS';
export const CONTRACTORS_GET_COUNT_FAILURE = 'CONTRACTORS_GET_COUNT_FAILURE';

export const CONTRACTOR_GET_DETAILS_REQUEST = 'CONTRACTOR_GET_DETAILS_REQUEST';
export const CONTRACTOR_GET_DETAILS_SUCCESS = 'CONTRACTOR_GET_DETAILS_SUCCESS';
export const CONTRACTOR_GET_DETAILS_FAILURE = 'CONTRACTOR_GET_DETAILS_FAILURE';

export const CONTRACTOR_GET_DETAILS_NEW = 'CONTRACTOR_GET_DETAILS_NEW';

export const CONTRACTOR_POST_REQUEST = 'CONTRACTOR_POST_REQUEST';
export const CONTRACTOR_POST_SUCCESS = 'CONTRACTOR_POST_SUCCESS';
export const CONTRACTOR_POST_FAILURE = 'CONTRACTOR_POST_FAILURE';

export const CONTRACTOR_PUT_REQUEST = 'CONTRACTOR_PUT_REQUEST';
export const CONTRACTOR_PUT_SUCCESS = 'CONTRACTOR_PUT_SUCCESS';
export const CONTRACTOR_PUT_FAILURE = 'CONTRACTOR_PUT_FAILURE';

export const CONTRACTOR_DELETE_REQUEST = 'CONTRACTOR_DELETE_REQUEST';
export const CONTRACTOR_DELETE_SUCCESS = 'CONTRACTOR_DELETE_SUCCESS';
export const CONTRACTOR_DELETE_FAILURE = 'CONTRACTOR_DELETE_FAILURE';

export const CONTRACTOR_UPLOAD_NORMAL_LOGO_REQUEST =
    'CONTRACTOR_UPLOAD_NORMAL_LOGO_REQUEST';
export const CONTRACTOR_UPLOAD_NORMAL_LOGO_SUCCESS =
    'CONTRACTOR_UPLOAD_NORMAL_LOGO_SUCCESS';
export const CONTRACTOR_UPLOAD_NORMAL_LOGO_FAILURE =
    'CONTRACTOR_UPLOAD_NORMAL_LOGO_FAILURE';

export const CONTRACTOR_UPLOAD_SMALL_LOGO_REQUEST =
    'CONTRACTOR_UPLOAD_SMALL_LOGO_REQUEST';
export const CONTRACTOR_UPLOAD_SMALL_LOGO_SUCCESS =
    'CONTRACTOR_UPLOAD_SMALL_LOGO_SUCCESS';
export const CONTRACTOR_UPLOAD_SMALL_LOGO_FAILURE =
    'CONTRACTOR_UPLOAD_SMALL_LOGO_FAILURE';

export const CONTRACTOR_UPLOAD_CUSTOMERAPP_LOGO_REQUEST =
    'CONTRACTOR_UPLOAD_CUSTOMERAPP_LOGO_REQUEST';
export const CONTRACTOR_UPLOAD_CUSTOMERAPP_LOGO_SUCCESS =
    'CONTRACTOR_UPLOAD_CUSTOMERAPP_LOGO_SUCCESS';
export const CONTRACTOR_UPLOAD_CUSTOMERAPP_LOGO_FAILURE =
    'CONTRACTOR_UPLOAD_CUSTOMERAPP_LOGO_FAILURE';

export const CONTRACTOR_UPLOAD_ATTACHMENT_REQUEST =
    'CONTRACTOR_UPLOAD_ATTACHMENT_REQUEST';
export const CONTRACTOR_UPLOAD_ATTACHMENT_SUCCESS =
    'CONTRACTOR_UPLOAD_ATTACHMENT_SUCCESS';
export const CONTRACTOR_UPLOAD_ATTACHMENT_FAILURE =
    'CONTRACTOR_UPLOAD_ATTACHMENT_FAILURE';

//  Collections  Page
export const COLLECTIONS_GET_LIST_REQUEST = 'COLLECTIONS_GET_LIST_REQUEST';
export const COLLECTIONS_GET_LIST_SUCCESS = 'COLLECTIONS_GET_LIST_SUCCESS';
export const COLLECTIONS_GET_LIST_FAILURE = 'COLLECTIONS_GET_LIST_FAILURE';

export const INSTALLMENTS_CREATE_REQUEST = 'INSTALLMENTS_CREATE_REQUEST';
export const INSTALLMENTS_CREATE_SUCCESS = 'INSTALLMENTS_CREATE_SUCCESS';
export const INSTALLMENTS_CREATE_FAILURE = 'INSTALLMENTS_CREATE_FAILURE';
export const INSTALLMENTS_CREATE_CLEAN = 'INSTALLMENTS_CREATE_CLEAN';

export const COLLECTIONS_GET_COUNT_REQUEST = 'COLLECTIONS_GET_COUNT_REQUEST';
export const COLLECTIONS_GET_COUNT_SUCCESS = 'COLLECTIONS_GET_COUNT_SUCCESS';
export const COLLECTIONS_GET_COUNT_FAILURE = 'COLLECTIONS_GET_COUNT_FAILURE';

export const COLLECTION_GET_DETAILS_REQUEST = 'COLLECTION_GET_DETAILS_REQUEST';
export const COLLECTION_GET_DETAILS_SUCCESS = 'COLLECTION_GET_DETAILS_SUCCESS';
export const COLLECTION_GET_DETAILS_FAILURE = 'COLLECTION_GET_DETAILS_FAILURE';

export const COLLECTION_POST_REQUEST = 'COLLECTION_POST_REQUEST';
export const COLLECTION_POST_SUCCESS = 'COLLECTION_POST_SUCCESS';
export const COLLECTION_POST_FAILURE = 'COLLECTION_POST_FAILURE';

export const COLLECTION_GET_DETAILS_NEW = 'COLLECTION_GET_DETAILS_NEW';

export const COLLECTION_PUT_REQUEST = 'COLLECTION_PUT_REQUEST';
export const COLLECTION_PUT_SUCCESS = 'COLLECTION_PUT_SUCCESS';
export const COLLECTION_PUT_FAILURE = 'COLLECTION_PUT_FAILURE';

export const COLLECTION_UPLOAD_ATTACHMENT_REQUEST =
    'COLLECTION_UPLOAD_ATTACHMENT_REQUEST';
export const COLLECTION_UPLOAD_ATTACHMENT_SUCCESS =
    'COLLECTION_UPLOAD_ATTACHMENT_SUCCESS';
export const COLLECTION_UPLOAD_ATTACHMENT_FAILURE =
    'COLLECTION_UPLOAD_ATTACHMENT_FAILURE';

export const COLLECTION_COPY_LINK_REQUEST = 'COLLECTION_COPY_LINK_REQUEST';
export const COLLECTION_COPY_LINK_SUCCESS = 'COLLECTION_COPY_LINK_SUCCESS';
export const COLLECTION_COPY_LINK_FAILURE = 'COLLECTION_COPY_LINK_FAILURE';

export const COLLECTION_POST_EMAIL_REQUEST = 'COLLECTION_POST_EMAIL_REQUEST';
export const COLLECTION_POST_EMAIL_SUCCESS = 'COLLECTION_POST_EMAIL_SUCCESS';
export const COLLECTION_POST_EMAIL_FAILURE = 'COLLECTION_POST_EMAIL_FAILURE';

//  Contact's  Page
export const CONTACTS_GET_LIST_REQUEST = 'CONTACTS_GET_LIST_REQUEST';
export const CONTACTS_GET_LIST_SUCCESS = 'CONTACTS_GET_LIST_SUCCESS';
export const CONTACTS_GET_LIST_FAILURE = 'CONTACTS_GET_LIST_FAILURE';

export const CONTACTS_GET_COUNT_REQUEST = 'CONTACTS_GET_COUNT_REQUEST';
export const CONTACTS_GET_COUNT_SUCCESS = 'CONTACTS_GET_COUNT_SUCCESS';
export const CONTACTS_GET_COUNT_FAILURE = 'CONTACTS_GET_COUNT_FAILURE';

export const CONTACT_GET_DETAILS_REQUEST = 'CONTACT_GET_DETAILS_REQUEST';
export const CONTACT_GET_DETAILS_SUCCESS = 'CONTACT_GET_DETAILS_SUCCESS';
export const CONTACT_GET_DETAILS_FAILURE = 'CONTACT_GET_DETAILS_FAILURE';

export const CONTACT_GET_DETAILS_NEW = 'CONTACT_GET_DETAILS_NEW';

export const CONTACT_POST_REQUEST = 'CONTACT_POST_REQUEST';
export const CONTACT_POST_SUCCESS = 'CONTACT_POST_SUCCESS';
export const CONTACT_POST_FAILURE = 'CONTACT_POST_FAILURE';

export const CONTACT_PUT_REQUEST = 'CONTACT_PUT_REQUEST';
export const CONTACT_PUT_SUCCESS = 'CONTACT_PUT_SUCCESS';
export const CONTACT_PUT_FAILURE = 'CONTACT_PUT_FAILURE';

export const CONTACT_ADD_ACCESS = 'CONTACT_ADD_ACCESS';

export const CONTACT_DELETE_REQUEST = 'CONTACT_DELETE_REQUEST';
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS';
export const CONTACT_DELETE_FAILURE = 'CONTACT_DELETE_FAILURE';

export const CONTACT_SEND_PASSWORD_REQUEST = 'CONTACT_SEND_PASSWORD_REQUEST';
export const CONTACT_SEND_PASSWORD_SUCCESS = 'CONTACT_SEND_PASSWORD_SUCCESS';
export const CONTACT_SEND_PASSWORD_FAILURE = 'CONTACT_SEND_PASSWORD_FAILURE';

export const CONTACT_UPLOAD_ATTACHMENT_REQUEST =
    'CONTACT_UPLOAD_ATTACHMENT_REQUEST';
export const CONTACT_UPLOAD_ATTACHMENT_SUCCESS =
    'CONTACT_UPLOAD_ATTACHMENT_SUCCESS';
export const CONTACT_UPLOAD_ATTACHMENT_FAILURE =
    'CONTACT_UPLOAD_ATTACHMENT_FAILURE';

// Transactions Page
export const TRANSACTIONS_GET_LIST_REQUEST = 'TRANSACTIONS_GET_LIST_REQUEST';
export const TRANSACTIONS_GET_LIST_SUCCESS = 'TRANSACTIONS_GET_LIST_SUCCESS';
export const TRANSACTIONS_GET_LIST_FAILURE = 'TRANSACTIONS_GET_LIST_FAILURE';

export const TRANSACTIONS_GET_COUNT_REQUEST = 'TRANSACTIONS_GET_COUNT_REQUEST';
export const TRANSACTIONS_GET_COUNT_SUCCESS = 'TRANSACTIONS_GET_COUNT_SUCCESS';
export const TRANSACTIONS_GET_COUNT_FAILURE = 'TRANSACTIONS_GET_COUNT_FAILURE';

export const TRANSACTION_GET_DETAILS_REQUEST =
    'TRANSACTION_GET_DETAILS_REQUEST';
export const TRANSACTION_GET_DETAILS_SUCCESS =
    'TRANSACTION_GET_DETAILS_SUCCESS';
export const TRANSACTION_GET_DETAILS_FAILURE =
    'TRANSACTION_GET_DETAILS_FAILURE';

export const TRANSACTION_POST_REQUEST = 'TRANSACTION_POST_REQUEST';
export const TRANSACTION_POST_SUCCESS = 'TRANSACTION_POST_SUCCESS';
export const TRANSACTION_POST_FAILURE = 'TRANSACTION_POST_FAILURE';

export const TRANSACTION_GET_DETAILS_NEW = 'TRANSACTION_GET_DETAILS_NEW';

export const TRANSACTION_PUT_REQUEST = 'TRANSACTION_PUT_REQUEST';
export const TRANSACTION_PUT_SUCCESS = 'TRANSACTION_PUT_SUCCESS';
export const TRANSACTION_PUT_FAILURE = 'TRANSACTION_PUT_FAILURE';

export const TRANSACTION_GET_EXPORT_REQUEST = 'TRANSACTION_GET_EXPORT_REQUEST';
export const TRANSACTION_GET_EXPORT_SUCCESS = 'TRANSACTION_GET_EXPORT_SUCCESS';
export const TRANSACTION_GET_EXPORT_FAILURE = 'TRANSACTION_GET_EXPORT_FAILURE';

export const TRANSACTION_COPY_LINK_REQUEST = 'TRANSACTION_COPY_LINK_REQUEST';
export const TRANSACTION_COPY_LINK_SUCCESS = 'TRANSACTION_COPY_LINK_SUCCESS';
export const TRANSACTION_COPY_LINK_FAILURE = 'TRANSACTION_COPY_LINK_FAILURE';

export const TRANSACTION_POST_EMAIL_REQUEST = 'TRANSACTION_POST_EMAIL_REQUEST';
export const TRANSACTION_POST_EMAIL_SUCCESS = 'TRANSACTION_POST_EMAIL_SUCCESS';
export const TRANSACTION_POST_EMAIL_FAILURE = 'TRANSACTION_POST_EMAIL_FAILURE';

export const TRANSACTION_UPLOAD_ATTACHMENT_REQUEST =
    'TRANSACTION_UPLOAD_ATTACHMENT_REQUEST';
export const TRANSACTION_UPLOAD_ATTACHMENT_SUCCESS =
    'TRANSACTION_UPLOAD_ATTACHMENT_SUCCESS';
export const TRANSACTION_UPLOAD_ATTACHMENT_FAILURE =
    'TRANSACTION_UPLOAD_ATTACHMENT_FAILURE';

// Tarifications Page
export const TARIFICATIONS_GET_LIST_REQUEST = 'TARIFICATIONS_GET_LIST_REQUEST';
export const TARIFICATIONS_GET_LIST_SUCCESS = 'TARIFICATIONS_GET_LIST_SUCCESS';
export const TARIFICATIONS_GET_LIST_FAILURE = 'TARIFICATIONS_GET_LIST_FAILURE';

export const TARIFICATIONS_GET_FEETYPE_REQUEST =
    'TARIFICATIONS_GET_FEETYPE_REQUEST';
export const TARIFICATIONS_GET_FEETYPE_SUCCESS =
    'TARIFICATIONS_GET_FEETYPE_SUCCESS';

export const TARIFICATIONS_GET_PAYMENT_METHOD_TYPE_REQUEST =
    'TARIFICATIONS_GET_PAYMENT_METHOD_TYPE_REQUEST';
export const TARIFICATIONS_GET_PAYMENT_METHOD_TYPE_SUCCESS =
    'TARIFICATIONS_GET_PAYMENT_METHOD_TYPE_SUCCESS';

export const TARIFICATIONS_GET_COUNT_REQUEST =
    'TARIFICATIONS_GET_COUNT_REQUEST';
export const TARIFICATIONS_GET_COUNT_SUCCESS =
    'TARIFICATIONS_GET_COUNT_SUCCESS';
export const TARIFICATIONS_GET_COUNT_FAILURE =
    'TARIFICATIONS_GET_COUNT_FAILURE';

export const TARIFICATION_GET_DETAILS_REQUEST =
    'TARIFICATION_GET_DETAILS_REQUEST';
export const TARIFICATION_GET_DETAILS_SUCCESS =
    'TARIFICATION_GET_DETAILS_SUCCESS';
export const TARIFICATION_GET_DETAILS_FAILURE =
    'TARIFICATION_GET_DETAILS_FAILURE';

export const TARIFICATION_POST_REQUEST = 'TARIFICATION_POST_REQUEST';
export const TARIFICATION_POST_SUCCESS = 'TARIFICATION_POST_SUCCESS';
export const TARIFICATION_POST_FAILURE = 'TARIFICATION_POST_FAILURE';

export const TARIFICATION_GET_DETAILS_NEW = 'TARIFICATION_GET_DETAILS_NEW';

export const TARIFICATION_PUT_REQUEST = 'TARIFICATION_PUT_REQUEST';
export const TARIFICATION_PUT_SUCCESS = 'TARIFICATION_PUT_SUCCESS';
export const TARIFICATION_PUT_FAILURE = 'TARIFICATION_PUT_FAILURE';

export const TARIFICATION_DELETE_REQUEST = 'TARIFICATION_DELETE_REQUEST';
export const TARIFICATION_DELETE_SUCCESS = 'TARIFICATION_DELETE_SUCCESS';
export const TARIFICATION_DELETE_FAILURE = 'TARIFICATION_DELETE_FAILURE';

export const TARIFICATION_GET_EXPORT_REQUEST =
    'TARIFICATION_GET_EXPORT_REQUEST';
export const TARIFICATION_GET_EXPORT_SUCCESS =
    'TARIFICATION_GET_EXPORT_SUCCESS';
export const TARIFICATION_GET_EXPORT_FAILURE =
    'TARIFICATION_GET_EXPORT_FAILURE';

export const TARIFICATION_CONTRACTORS_GET_LIST_REQUEST =
    'TARIFICATION_CONTRACTORS_GET_LIST_REQUEST';
export const TARIFICATION_CONTRACTORS_GET_LIST_SUCCESS =
    'TARIFICATION_CONTRACTORS_GET_LIST_SUCCESS';
export const TARIFICATION_CONTRACTORS_GET_LIST_FAILURE =
    'TARIFICATION_CONTRACTORS_GET_LIST_FAILURE';

export const TARIFICATIONS_GET_PAYMENT_OPERATOR_TYPE_REQUEST =
    'TARIFICATIONS_GET_PAYMENT_OPERATOR_TYPE_REQUEST';
export const TARIFICATIONS_GET_PAYMENT_OPERATOR_TYPE_SUCCESS =
    'TARIFICATIONS_GET_PAYMENT_OPERATOR_TYPE_SUCCESS';

// Billing List
export const BILLING_GET_LIST_REQUEST = 'BILLING_GET_LIST_REQUEST';
export const BILLING_GET_LIST_SUCCESS = 'BILLING_GET_LIST_SUCCESS';
export const BILLING_GET_LIST_FAILURE = 'BILLING_GET_LIST_FAILURE';

export const BILLING_GET_COUNT_REQUEST = 'BILLING_GET_COUNT_REQUEST';
export const BILLING_GET_COUNT_SUCCESS = 'BILLING_GET_COUNT_SUCCESS';
export const BILLING_GET_COUNT_FAILURE = 'BILLING_GET_COUNT_FAILURE';
// FeeMessage Page
export const FEE_MESSAGE_GET_LIST_REQUEST = 'FEE_MESSAGE_GET_LIST_REQUEST';
export const FEE_MESSAGE_GET_LIST_SUCCESS = 'FEE_MESSAGE_GET_LIST_SUCCESS';
export const FEE_MESSAGE_GET_LIST_FAILURE = 'FEE_MESSAGE_GET_LIST_FAILURE';

export const FEE_MESSAGE_GET_COUNT_REQUEST = 'FEE_MESSAGE_GET_COUNT_REQUEST';
export const FEE_MESSAGE_GET_COUNT_SUCCESS = 'FEE_MESSAGE_GET_COUNT_SUCCESS';
export const FEE_MESSAGE_GET_COUNT_FAILURE = 'FEE_MESSAGE_GET_COUNT_FAILURE';

export const FEE_MESSAGE_GET_DETAILS_REQUEST =
    'FEE_MESSAGE_GET_DETAILS_REQUEST';
export const FEE_MESSAGE_GET_DETAILS_SUCCESS =
    'FEE_MESSAGE_GET_DETAILS_SUCCESS';
export const FEE_MESSAGE_GET_DETAILS_FAILURE =
    'FEE_MESSAGE_GET_DETAILS_FAILURE';

export const FEE_MESSAGE_POST_REQUEST = 'FEE_MESSAGE_POST_REQUEST';
export const FEE_MESSAGE_POST_SUCCESS = 'FEE_MESSAGE_POST_SUCCESS';
export const FEE_MESSAGE_POST_FAILURE = 'FEE_MESSAGE_POST_FAILURE';

export const FEE_MESSAGE_GET_DETAILS_NEW = 'FEE_MESSAGE_GET_DETAILS_NEW';

export const FEE_MESSAGE_PUT_REQUEST = 'FEE_MESSAGE_PUT_REQUEST';
export const FEE_MESSAGE_PUT_SUCCESS = 'FEE_MESSAGE_PUT_SUCCESS';
export const FEE_MESSAGE_PUT_FAILURE = 'PARAMETER_PUT_FAILURE';

export const FEE_MESSAGE_DELETE_REQUEST = 'FEE_MESSAGE_DELETE_REQUEST';
export const FEE_MESSAGE_DELETE_SUCCESS = 'FEE_MESSAGE_DELETE_SUCCESS';
export const FEE_MESSAGE_DELETE_FAILURE = 'FEE_MESSAGE_DELETE_FAILURE';

export const FEE_MESSAGE_CONTRACTORS_GET_LIST_REQUEST =
    'FEE_MESSAGE_CONTRACTORS_GET_LIST_REQUEST';
export const FEE_MESSAGE_CONTRACTORS_GET_LIST_SUCCESS =
    'FEE_MESSAGE_CONTRACTORS_GET_LIST_SUCCESS';
export const FEE_MESSAGE_CONTRACTORS_GET_LIST_FAILURE =
    'FEE_MESSAGE_CONTRACTORS_GET_LIST_FAILURE';

export const FEE_MESSAGES_GET_TYPES_REQUEST = 'FEE_MESSAGE_GET_TYPES_REQUEST';
export const FEE_MESSAGES_GET_TYPES_SUCCESS = 'FEE_MESSAGE_GET_TYPES_SUCCESS';

//  Parameters  Page
export const PARAMETERS_GET_LIST_REQUEST = 'PARAMETERS_GET_LIST_REQUEST';
export const PARAMETERS_GET_LIST_SUCCESS = 'PARAMETERS_GET_LIST_SUCCESS';
export const PARAMETERS_GET_LIST_FAILURE = 'PARAMETERS_GET_LIST_FAILURE';

export const PARAMETERS_GET_COUNT_REQUEST = 'PARAMETERS_GET_COUNT_REQUEST';
export const PARAMETERS_GET_COUNT_SUCCESS = 'PARAMETERS_GET_COUNT_SUCCESS';
export const PARAMETERS_GET_COUNT_FAILURE = 'PARAMETERS_GET_COUNT_FAILURE';

export const PARAMETER_GET_DETAILS_REQUEST = 'PARAMETER_GET_DETAILS_REQUEST';
export const PARAMETER_GET_DETAILS_SUCCESS = 'PARAMETER_GET_DETAILS_SUCCESS';
export const PARAMETER_GET_DETAILS_FAILURE = 'PARAMETER_GET_DETAILS_FAILURE';

export const PARAMETER_POST_REQUEST = 'PARAMETER_POST_REQUEST';
export const PARAMETER_POST_SUCCESS = 'PARAMETER_POST_SUCCESS';
export const PARAMETER_POST_FAILURE = 'PARAMETER_POST_FAILURE';

export const PARAMETER_GET_DETAILS_NEW = 'PARAMETER_GET_DETAILS_NEW';

export const PARAMETER_PUT_REQUEST = 'PARAMETER_PUT_REQUEST';
export const PARAMETER_PUT_SUCCESS = 'PARAMETER_PUT_SUCCESS';
export const PARAMETER_PUT_FAILURE = 'PARAMETER_PUT_FAILURE';

export const PARAMETER_UPLOAD_ATTACHMENT_REQUEST =
    'PARAMETER_UPLOAD_ATTACHMENT_REQUEST';
export const PARAMETER_UPLOAD_ATTACHMENT_SUCCESS =
    'PARAMETER_UPLOAD_ATTACHMENT_SUCCESS';
export const PARAMETER_UPLOAD_ATTACHMENT_FAILURE =
    'PARAMETER_UPLOAD_ATTACHMENT_FAILURE';

//  Invoice List Page

export const INVOICES_CTE_GET_LIST_REQUEST = 'INVOICES_CTE_GET_LIST_REQUEST';
export const INVOICES_CTE_GET_LIST_SUCCESS = 'INVOICES_CTE_GET_LIST_SUCCESS';

export const INVOICES_GET_LIST_REQUEST = 'INVOICES_GET_LIST_REQUEST';
export const INVOICES_GET_LIST_SUCCESS = 'INVOICES_GET_LIST_SUCCESS';
export const INVOICES_GET_LIST_FAILURE = 'INVOICES_GET_LIST_FAILURE';

export const INVOICES_REPORT_GET_LIST_REQUEST =
    'INVOICES_REPORT_GET_LIST_REQUEST';
export const INVOICES_REPORT_GET_LIST_SUCCESS =
    'INVOICES_REPORT_GET_LIST_SUCCESS';
export const INVOICES_REPORT_GET_LIST_FAILURE =
    'INVOICES_REPORT_GET_LIST_FAILURE';

export const INVOICE_CTE_GET_COUNT_REQUEST = 'INVOICE_CTE_GET_COUNT_REQUEST';
export const INVOICE_CTE_GET_COUNT_SUCCESS = 'INVOICE_CTE_GET_COUNT_SUCCESS';
export const INVOICE_CTE_GET_COUNT_FAILURE = 'INVOICE_CTE_GET_COUNT_FAILURE';

export const INVOICE_GET_COUNT_REQUEST = 'INVOICE_GET_COUNT_REQUEST';
export const INVOICE_GET_COUNT_SUCCESS = 'INVOICE_GET_COUNT_SUCCESS';
export const INVOICE_GET_COUNT_FAILURE = 'INVOICE_GET_COUNT_FAILURE';

export const INVOICE_UPLOAD_FILE_REQUEST = 'INVOICE_UPLOAD_FILE_REQUEST';
export const INVOICE_UPLOAD_FILE_SUCCESS = 'INVOICE_UPLOAD_FILE_SUCCESS';
export const INVOICE_UPLOAD_FILE_FAILURE = 'INVOICE_UPLOAD_FILE_FAILURE';

export const INVOICE_GET_DETAILS_REQUEST = 'INVOICE_GET_DETAILS_REQUEST';
export const INVOICE_GET_DETAILS_SUCCESS = 'INVOICE_GET_DETAILS_SUCCESS';
export const INVOICE_GET_DETAILS_FAILURE = 'INVOICE_GET_DETAILS_FAILURE';

export const INVOICE_UPLOAD_DOCUMENT_REQUEST =
    'INVOICE_UPLOAD_DOCUMENT_REQUEST';
export const INVOICE_UPLOAD_DOCUMENT_SUCCESS =
    'INVOICE_UPLOAD_DOCUMENT_SUCCESS';
export const INVOICE_UPLOAD_DOCUMENT_FAILURE =
    'INVOICE_UPLOAD_DOCUMENT_FAILURE';

export const INVOICE_PUT_REQUEST = 'INVOICE_PUT_REQUEST';
export const INVOICE_PUT_SUCCESS = 'INVOICE_PUT_SUCCESS';
export const INVOICE_PUT_FAILURE = 'INVOICE_PUT_FAILURE';

export const INVOICE_CANCEL_DOCUMENT_REQUEST =
    'INVOICE_CANCEL_DOCUMENT_REQUEST';
export const INVOICE_CANCEL_DOCUMENT_SUCCESS =
    'INVOICE_CANCEL_DOCUMENT_SUCCESS';
export const INVOICE_CANCEL_DOCUMENT_FAILURE =
    'INVOICE_CANCEL_DOCUMENT_FAILURE';

// Fields List Page
export const FIELDS_GET_LIST_REQUEST = 'FIELDS_GET_LIST_REQUEST';
export const FIELDS_GET_LIST_SUCCESS = 'FIELDS_GET_LIST_SUCCESS';
export const FIELDS_GET_LIST_FAILURE = 'FIELDS_GET_LIST_FAILURE';

export const FIELDS_GET_COUNT_REQUEST = 'FIELDS_GET_COUNT_REQUEST';
export const FIELDS_GET_COUNT_SUCCESS = 'FIELDS_GET_COUNT_SUCCESS';
export const FIELDS_GET_COUNT_FAILURE = 'FIELDS_GET_COUNT_FAILURE';

export const FIELD_GET_DETAILS_REQUEST = 'FIELD_GET_DETAILS_REQUEST';
export const FIELD_GET_DETAILS_SUCCESS = 'FIELD_GET_DETAILS_SUCCESS';
export const FIELD_GET_DETAILS_FAILURE = 'FIELD_GET_DETAILS_FAILURE';

export const FIELD_GET_DETAILS_NEW = 'FIELD_GET_DETAILS_NEW';

export const FIELD_POST_REQUEST = 'FIELD_POST_REQUEST';
export const FIELD_POST_SUCCESS = 'FIELD_POST_SUCCESS';
export const FIELD_POST_FAILURE = 'FIELD_POST_FAILURE';

export const FIELD_PUT_REQUEST = 'FIELD_PUT_REQUEST';
export const FIELD_PUT_SUCCESS = 'FIELD_PUT_SUCCESS';
export const FIELD_PUT_FAILURE = 'FIELD_PUT_FAILURE';

export const FIELD_DELETE_REQUEST = 'FIELD_DELETE_REQUEST';
export const FIELD_DELETE_SUCCESS = 'FIELD_DELETE_SUCCESS';
export const FIELD_DELETE_FAILURE = 'FIELD_DELETE_FAILURE';

//  User's  Page
export const USERS_GET_LIST_REQUEST = 'USERS_GET_LIST_REQUEST';
export const USERS_GET_LIST_SUCCESS = 'USERS_GET_LIST_SUCCESS';
export const USERS_GET_LIST_FAILURE = 'USERS_GET_LIST_FAILURE';

export const USERS_GET_COUNT_REQUEST = 'USERS_GET_COUNT_REQUEST';
export const USERS_GET_COUNT_SUCCESS = 'USERS_GET_COUNT_SUCCESS';
export const USERS_GET_COUNT_FAILURE = 'USERS_GET_COUNT_FAILURE';

export const USER_GET_DETAILS_REQUEST = 'USER_GET_DETAILS_REQUEST';
export const USER_GET_DETAILS_SUCCESS = 'USER_GET_DETAILS_SUCCESS';
export const USER_GET_DETAILS_FAILURE = 'USER_GET_DETAILS_FAILURE';

export const USER_GET_DETAILS_NEW = 'USER_GET_DETAILS_NEW';

export const USER_POST_REQUEST = 'USER_POST_REQUEST';
export const USER_POST_SUCCESS = 'USER_POST_SUCCESS';
export const USER_POST_FAILURE = 'USER_POST_FAILURE';

export const USER_PUT_REQUEST = 'USER_PUT_REQUEST';
export const USER_PUT_SUCCESS = 'USER_PUT_SUCCESS';
export const USER_PUT_FAILURE = 'USER_PUT_FAILURE';

export const USER_ADD_ACCESS = 'USER_ADD_ACCESS';
export const USER_ADD_ACCESS_INITIAL = 'USER_ADD_ACCESS_INITIAL';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE';

export const USER_SEND_PASSWORD_REQUEST = 'USER_SEND_PASSWORD_REQUEST';
export const USER_SEND_PASSWORD_SUCCESS = 'USER_SEND_PASSWORD_SUCCESS';
export const USER_SEND_PASSWORD_FAILURE = 'USER_SEND_PASSWORD_FAILURE';

export const USER_GENERATE_NEW_PASSWORD_REQUEST =
    'USER_GENERATE_NEW_PASSWORD_REQUEST';
export const USER_GENERATE_NEW_PASSWORD_SUCCESS =
    'USER_GENERATE_NEW_PASSWORD_SUCCESS';
export const USER_GENERATE_NEW_PASSWORD_FAILURE =
    'USER_GENERATE_NEW_PASSWORD_FAILURE';

// Roles
export const ROLE_GET_DETAILS_NEW = 'ROLE_GET_DETAILS_NEW';
export const ROLES_GET_LIST_REQUEST = 'ROLES_GET_LIST_REQUEST';
export const ROLES_GET_LIST_SUCCESS = 'ROLES_GET_LIST_SUCCESS';
export const ROLES_GET_LIST_FAILURE = 'ROLES_GET_LIST_FAILURE';

export const ROLES_CONTRACTOR_GET_LIST_REQUEST =
    'ROLES_CONTRACTOR_GET_LIST_REQUEST';
export const ROLES_CONTRACTOR_GET_LIST_SUCCESS =
    'ROLES_CONTRACTOR_GET_LIST_SUCCESS';
export const ROLES_CONTRACTOR_GET_LIST_FAILURE =
    'ROLES_CONTRACTOR_GET_LIST_FAILURE';

export const ROLES_GET_DETAILS_REQUEST = 'ROLES_GET_DETAILS_REQUEST';
export const ROLES_GET_DETAILS_SUCCESS = 'ROLES_GET_DETAILS_SUCCESS';
export const ROLES_GET_DETAILS_FAILURE = 'ROLES_GET_DETAILS_FAILURE';
export const ROLES_SET_CLAIMS_SUCCESS = 'ROLES_SET_CLAIMS_SUCCESS';
export const ROLES_SET_ROLES_SUCCESS = 'ROLES_SET_ROLES_SUCCESS';
export const ROLE_POST_REQUEST = 'ROLE_POST_REQUEST';
export const ROLE_POST_SUCCESS = 'ROLE_POST_SUCCESS';
export const ROLE_POST_FAILURE = 'ROLE_POST_FAILURE';
export const ROLE_PUT_REQUEST = 'ROLE_PUT_REQUEST';
export const ROLE_PUT_SUCCESS = 'ROLE_PUT_SUCCESS';
export const ROLE_PUT_FAILURE = 'ROLE_PUT_FAILURE';
export const ROLE_CLEANUP = 'ROLE_CLEANUP';
export const ROLES_SET_ROLE_LEVELS_SUCCESS = 'ROLES_SET_ROLE_LEVELS_SUCCESS';
export const ROLES_SET_ACCESS_SUCCESS = 'ROLES_SET_ACCESS_SUCCESS';
export const ROLES_CLEANUP_ROLE_LEVELS_SUCCESS =
    'ROLES_CLEANUP_ROLE_LEVELS_SUCCESS';
export const ROLE_SET_REQUEST = 'ROLE_SET_REQUEST';
export const ROLE_SET_SUCCESS = 'ROLE_SET_SUCCESS';

export const ROLES_GET_COUNT_REQUEST = 'ROLES_GET_COUNT_REQUEST';
export const ROLES_GET_COUNT_SUCCESS = 'ROLES_GET_COUNT_SUCCESS';
export const ROLES_GET_COUNT_FAILURE = 'ROLES_GET_COUNT_FAILURE';

// States
export const STATES_GET_LIST_REQUEST = 'STATES_GET_LIST_REQUEST';
export const STATES_GET_LIST_SUCCESS = 'STATES_GET_LIST_SUCCESS';
export const STATES_GET_LIST_FAILURE = 'STATES_GET_LIST_FAILURE';

// Cities
export const CITIES_GET_LIST_REQUEST = 'CITIES_GET_LIST_REQUEST';
export const CITIES_GET_LIST_SUCCESS = 'CITIES_GET_LIST_SUCCESS';
export const CITIES_GET_LIST_FAILURE = 'CITIES_GET_LIST_FAILURE';

// Files
export const FILES_CREATE_REQUEST = 'FILES_CREATE_REQUEST';
export const FILES_CREATE_SUCCESS = 'FILES_CREATE_SUCCESS';
export const FILES_CREATE_FAILURE = 'FILES_CREATE_FAILURE';
export const FILES_GET_REQUEST = 'FILES_GET_REQUEST';
export const FILES_GET_SUCCESS = 'FILES_GET_SUCCESS';
export const FILES_GET_FAILURE = 'FILES_GET_FAILURE';

// Settings
export const SETTINGS_CHANGE_PASSWORD_REQUEST =
    'SETTINGS_CHANGE_PASSWORD_REQUEST';
export const SETTINGS_CHANGE_PASSWORD_SUCCESS =
    'SETTINGS_CHANGE_PASSWORD_SUCCESS';
export const SETTINGS_CHANGE_PASSWORD_FAILURE =
    'SETTINGS_CHANGE_PASSWORD_FAILURE';
export const SETTINGS_GENERAL_GET_REQUEST = 'SETTINGS_GENERAL_GET_REQUEST';
export const SETTINGS_GENERAL_GET_SUCCESS = 'SETTINGS_GENERAL_GET_SUCCESS';
export const SETTINGS_GENERAL_GET_FAILURE = 'SETTINGS_GENERAL_GET_FAILURE';
export const SETTINGS_GENERAL_PUT_REQUEST = 'SETTINGS_GENERAL_PUT_REQUEST';
export const SETTINGS_GENERAL_PUT_SUCCESS = 'SETTINGS_GENERAL_PUT_SUCCESS';
export const SETTINGS_GENERAL_PUT_FAILURE = 'SETTINGS_GENERAL_PUT_SUCCESS';

// Reconciliation
export const RECONCILIATION_GET_REQUEST = 'RECONCILIATION_GET_REQUEST';
export const RECONCILIATION_GET_SUCCESS = 'RECONCILIATION_GET_SUCCESS';
export const RECONCILIATION_GET_FAILURE = 'RECONCILIATION_GET_FAILURE';

export const PAYMENTRECEIVED_GET_REQUEST = 'PAYMENTRECEIVED_GET_REQUEST';
export const PAYMENTRECEIVED_GET_SUCCESS = 'PAYMENTRECEIVED_GET_SUCCESS';
export const PAYMENTRECEIVED_GET_FAILURE = 'PAYMENTRECEIVED_GET_FAILURE';

export const RECONCILIATION_AUDIT_GET_REQUEST =
    'RECONCILIATION_AUDIT_GET_REQUEST';
export const RECONCILIATION_AUDIT_GET_SUCCESS =
    'RECONCILIATION_AUDIT_GET_SUCCESS';
export const RECONCILIATION_AUDIT_GET_FAILURE =
    'RECONCILIATION_AUDIT_GET_FAILURE';

export const PAYMENTRECEIVED_COUNT_GET_REQUEST =
    'PAYMENTRECEIVED_COUNT_GET_REQUEST';
export const PAYMENTRECEIVED_COUNT_GET_FAILURE =
    'PAYMENTRECEIVED_COUNT_GET_FAILURE';
export const PAYMENTRECEIVED_COUNT_GET_SUCCESS =
    'PAYMENTRECEIVED_COUNT_GET_SUCCESS';

export const RECONCILIATION_COUNT_GET_REQUEST =
    'RECONCILIATION_COUNT_GET_REQUEST';
export const RECONCILIATION_COUNT_GET_SUCCESS =
    'RECONCILIATION_COUNT_GET_SUCCESS';
export const RECONCILIATION_COUNT_GET_FAILURE =
    'RECONCILIATION_COUNT_GET_FAILURE';

export const RECONCILIATION_AUDIT_COUNT_GET_REQUEST =
    'RECONCILIATION_AUDIT_COUNT_GET_REQUEST';
export const RECONCILIATION_AUDIT_COUNT_GET_SUCCESS =
    'RECONCILIATION_AUDIT_COUNT_GET_SUCCESS';
export const RECONCILIATION_AUDIT_COUNT_GET_FAILURE =
    'RECONCILIATION_AUDIT_COUNT_GET_FAILURE';

// Roles
export const CLAIMS_GET_LIST_REQUEST = 'CLAIMS_GET_LIST_REQUEST';
export const CLAIMS_GET_LIST_SUCCESS = 'CLAIMS_GET_LIST_SUCCESS';
export const CLAIMS_GET_LIST_FAILURE = 'CLAIMS_GET_LIST_FAILURE';

// FILTER EVERYTHING
export const FILTER_EVERYTHING = 'FILTER_EVERYTHING';

// Widgets
export const WIDGETS_GET_REQUEST = 'WIDGETS_GET_REQUEST';
export const WIDGETS_GET_SUCCESS = 'WIDGETS_GET_SUCCESS';
export const WIDGETS_GET_FAILURE = 'WIDGETS_GET_FAILURE';

export const WIDGET_GET_REQUEST = 'WIDGET_GET_REQUEST';
export const WIDGET_GET_SUCCESS = 'WIDGET_GET_SUCCESS';
export const WIDGET_GET_FAILURE = 'WIDGET_GET_FAILURE';

export const COLORS_GET_REQUEST = 'COLORS_GET_REQUEST';
export const COLORS_GET_SUCCESS = 'COLORS_GET_SUCCESS';
export const COLORS_GET_FAILURE = 'COLORS_GET_FAILURE';

export const GET_USER_CONTRACTOR_LIST_REQUEST =
    'GET_USER_CONTRACTOR_LIST_REQUEST';
export const GET_USER_CONTRACTOR_LIST_SUCCESS =
    'GET_USER_CONTRACTOR_LIST_SUCCESS';
export const GET_USER_CONTRACTOR_LIST_FAILURE =
    'GET_USER_CONTRACTOR_LIST_FAILURE';

export const FINANCIAL_DOCUMENTS_CARDS_GET_REQUEST =
    'FINANCIAL_DOCUMENTS_CARDS_GET_REQUEST';
export const FINANCIAL_DOCUMENTS_CARDS_GET_SUCCESS =
    'FINANCIAL_DOCUMENTS_CARDS_GET_SUCCESS';
export const FINANCIAL_DOCUMENTS_CARDS_GET_FAILURE =
    'FINANCIAL_DOCUMENTS_CARDS_GET_FAILURE';
export const FINANCIAL_DOCUMENT_CARD = 'FINANCIAL_DOCUMENT_CARD';
export const FINANCIAL_DOCUMENT_CARD_QUERY = 'FINANCIAL_DOCUMENT_CARD_QUERY';

// OCR OPERATION RESULTS
export const OCR_OPERATION_RESULT_REQUEST = 'OCR_OPERATION_RESULT_REQUEST';
export const OCR_OPERATION_RESULT_SUCCESS = 'OCR_OPERATION_RESULT_SUCCESS';
export const OCR_OPERATION_RESULT_FAILURE = 'OCR_OPERATION_RESULT_FAILURE';

// lOGS
export const AUDIT_LOGS_GET_LIST_REQUEST = 'AUDIT_LOGS_GET_LIST_REQUEST';
export const AUDIT_LOGS_GET_LIST_SUCCESS = 'AUDIT_LOGS_GET_LIST_SUCCESS';
export const AUDIT_LOGS_GET_LIST_FAILURE = 'AUDIT_LOGS_GET_LIST_FAILURE';

export const TECHNICAL_LOGS_GET_LIST_REQUEST =
    'TECHNICAL_LOGS_GET_LIST_REQUEST';
export const TECHNICAL_LOGS_GET_LIST_SUCCESS =
    'TECHNICAL_LOGS_GET_LIST_SUCCESS';
export const TECHNICAL_LOGS_GET_LIST_FAILURE =
    'TECHNICAL_LOGS_GET_LIST_FAILURE';

export const AUDIT_LOGS_GET_COUNT_REQUEST = 'AUDIT_LOGS_GET_COUNT_REQUEST';
export const AUDIT_LOGS_GET_COUNT_SUCCESS = 'AUDIT_LOGS_GET_COUNT_SUCCESS';
export const AUDIT_LOGS_GET_COUNT_FAILURE = 'AUDIT_LOGS_GET_COUNT_FAILURE';

export const TECHNICAL_LOGS_GET_COUNT_REQUEST =
    'TECHNICAL_LOGS_GET_COUNT_REQUEST';
export const TECHNICAL_LOGS_GET_COUNT_SUCCESS =
    'TECHNICAL_LOGS_GET_COUNT_SUCCESS';
export const TECHNICAL_LOGS_GET_COUNT_FAILURE =
    'TECHNICAL_LOGS_GET_COUNT_FAILURE';

export const TRANSACTION_LOGS_ERROR_GET_REQUEST =
    'TRANSACTION_LOGS_ERROR_GET_REQUEST';
export const TRANSACTION_LOGS_ERROR_GET_SUCCESS =
    'TRANSACTION_LOGS_ERROR_GET_SUCCESS';
export const TRANSACTION_LOGS_ERROR_GET_FAILURE =
    'TRANSACTION_LOGS_ERROR_GET_FAILURE';

export const TRANSACTION_LOGS_ERROR_GET_COUNT_REQUEST =
    'TRANSACTION_LOGS_ERROR_GET_COUNT_REQUEST';
export const TRANSACTION_LOGS_ERROR_GET_COUNT_SUCCESS =
    'TRANSACTION_LOGS_ERROR_GET_COUNT_SUCCESS';
export const TRANSACTION_LOGS_ERROR_GET_COUNT_FAILURE =
    'TRANSACTION_LOGS_ERROR_GET_COUNT_FAILURE';

export const CONTRACTOR_SET_REQUEST = 'CONTRACTOR_SET_REQUEST';
export const CONTRACTOR_SET_SUCCESS = 'CONTRACTOR_SET_SUCCESS';
export const CONTRACTOR_SET_FAILURE = 'CONTRACTOR_SET_FAILURE';

export const CONTRACTORS_GET_LAYOUT_REQUEST = 'CONTRACTORS_GET_LAYOUT_REQUEST';
export const CONTRACTORS_GET_LAYOUT_SUCCESS = 'CONTRACTORS_GET_LAYOUT_SUCCESS';
export const CONTRACTORS_GET_LAYOUT_FAILURE = 'CONTRACTORS_GET_LAYOUT_FAILURE';

export const INVOICE_GET_XML_ZIP_FAILURE = 'INVOICE_GET_XML_ZIP_FAILURE';
export const INVOICE_GET_XML_ZIP_SUCCESS = 'INVOICE_GET_XML_ZIP_SUCCESS';
export const INVOICE_GET_XML_ZIP_REQUEST = 'INVOICE_GET_XML_ZIP_REQUEST';

export const UI_CONTEXT_PAGE_UPDATE = 'UI_CONTEXT_PAGE_UPDATE';
export const UI_CONTEXT_FILTER_UPDATE = 'UI_CONTEXT_FILTER_UPDATE';
export const UI_CONTEXT_FILTER_RESET = 'UI_CONTEXT_FILTER_RESET';
export const UI_CONTEXT_ORDENATION_UPDATE = 'UI_CONTEXT_ORDENATION_UPDATE';
export const CHANGE_TAB_TARIFICATIONS = 'CHANGE_TAB_TARIFICATIONS';

//  Client's  Page
export const CLIENTS_GET_LIST_REQUEST = 'CLIENTS_GET_LIST_REQUEST';
export const CLIENTS_GET_LIST_SUCCESS = 'CLIENTS_GET_LIST_SUCCESS';
export const CLIENTS_GET_LIST_FAILURE = 'CLIENTS_GET_LIST_FAILURE';

export const CLIENTS_GET_COUNT_REQUEST = 'CLIENTS_GET_COUNT_REQUEST';
export const CLIENTS_GET_COUNT_SUCCESS = 'CLIENTS_GET_COUNT_SUCCESS';
export const CLIENTS_GET_COUNT_FAILURE = 'CLIENTS_GET_COUNT_FAILURE';

export const CLIENT_GET_DETAILS_REQUEST = 'CLIENT_GET_DETAILS_REQUEST';
export const CLIENT_GET_DETAILS_SUCCESS = 'CLIENT_GET_DETAILS_SUCCESS';
export const CLIENT_GET_DETAILS_FAILURE = 'CLIENT_GET_DETAILS_FAILURE';

export const CLIENT_GET_DETAILS_NEW = 'CLIENT_GET_DETAILS_NEW';

export const CLIENT_POST_REQUEST = 'CLIENT_POST_REQUEST';
export const CLIENT_POST_SUCCESS = 'CLIENT_POST_SUCCESS';
export const CLIENT_POST_FAILURE = 'CLIENT_POST_FAILURE';

export const CLIENT_PUT_REQUEST = 'CLIENT_PUT_REQUEST';
export const CLIENT_PUT_SUCCESS = 'CLIENT_PUT_SUCCESS';
export const CLIENT_PUT_FAILURE = 'CLIENT_PUT_FAILURE';

export const CLIENT_DELETE_REQUEST = 'CLIENT_DELETE_REQUEST';
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS';
export const CLIENT_DELETE_FAILURE = 'CLIENT_DELETE_FAILURE';

//  FinancialDocument's  Page
export const FINANCIAL_DOCUMENTS_GET_LIST_REQUEST =
    'FINANCIAL_DOCUMENTS_GET_LIST_REQUEST';
export const FINANCIAL_DOCUMENTS_GET_LIST_SUCCESS =
    'FINANCIAL_DOCUMENTS_GET_LIST_SUCCESS';
export const FINANCIAL_DOCUMENTS_GET_LIST_FAILURE =
    'FINANCIAL_DOCUMENTS_GET_LIST_FAILURE';

export const FINANCIAL_DOCUMENTS_GET_COUNT_REQUEST =
    'FINANCIAL_DOCUMENTS_GET_COUNT_REQUEST';
export const FINANCIAL_DOCUMENTS_GET_COUNT_SUCCESS =
    'FINANCIAL_DOCUMENTS_GET_COUNT_SUCCESS';
export const FINANCIAL_DOCUMENTS_GET_COUNT_FAILURE =
    'FINANCIAL_DOCUMENTS_GET_COUNT_FAILURE';

export const FINANCIAL_DOCUMENT_GET_DETAILS_REQUEST =
    'FINANCIAL_DOCUMENT_GET_DETAILS_REQUEST';
export const FINANCIAL_DOCUMENT_GET_DETAILS_SUCCESS =
    'FINANCIAL_DOCUMENT_GET_DETAILS_SUCCESS';
export const FINANCIAL_DOCUMENT_GET_DETAILS_FAILURE =
    'FINANCIAL_DOCUMENT_GET_DETAILS_FAILURE';

export const FINANCIAL_DOCUMENT_GET_DETAILS_NEW =
    'FINANCIAL_DOCUMENT_GET_DETAILS_NEW';

export const FINANCIAL_DOCUMENT_POST_REQUEST =
    'FINANCIAL_DOCUMENT_POST_REQUEST';
export const FINANCIAL_DOCUMENT_POST_SUCCESS =
    'FINANCIAL_DOCUMENT_POST_SUCCESS';
export const FINANCIAL_DOCUMENT_POST_FAILURE =
    'FINANCIAL_DOCUMENT_POST_FAILURE';

export const FINANCIAL_DOCUMENT_PUT_REQUEST = 'FINANCIAL_DOCUMENT_PUT_REQUEST';
export const FINANCIAL_DOCUMENT_PUT_SUCCESS = 'FINANCIAL_DOCUMENT_PUT_SUCCESS';
export const FINANCIAL_DOCUMENT_PUT_FAILURE = 'FINANCIAL_DOCUMENT_PUT_FAILURE';

export const FINANCIAL_DOCUMENT_DELETE_REQUEST =
    'FINANCIAL_DOCUMENT_DELETE_REQUEST';
export const FINANCIAL_DOCUMENT_DELETE_SUCCESS =
    'FINANCIAL_DOCUMENT_DELETE_SUCCESS';
export const FINANCIAL_DOCUMENT_DELETE_FAILURE =
    'FINANCIAL_DOCUMENT_DELETE_FAILURE';
