import React from 'react';
import { injectIntl } from 'react-intl';

import { Container, Row, Col } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { CircularProgress } from '@material-ui/core';
import Modal from '../Modal/Modal';
import messages from './DynamicFilterModal.intl';
import DynamicFilterField from '../DynamicFilterField/DynamicFilterField';

function DynamicFilterModal(props) {
    const {
        intl,
        filters,
        handleFormSubmit,
        handleAddRemoveFilter,
        handleFieldChange,
        handleResetFilter,
    } = props;
    function handleSubmitFilters(e, values) {
        Object.keys(values.filters).forEach((key) => {
            const type = filters.find((f) => f.id === key).type;
            values.filters[key] = { ...values.filters[key], type, id: key };
        });
        handleFormSubmit(e, values);
    }
    return (
        <Modal
            title={props.filterEntityTitle}
            isOpen={props.isOpen}
            handleToggle={props.handleToggle}
            hasFooter={false}
            size='lg'
        >
            <AvForm onValidSubmit={handleSubmitFilters}>
                <Container fluid>
                    {filters.map((filter) => {
                        return (
                            <DynamicFilterField
                                key={filter.id}
                                filter={filter}
                                properties={props.properties}
                                handleAddRemoveFilter={handleAddRemoveFilter}
                                handleFieldChange={handleFieldChange}
                            />
                        );
                    })}
                    <Row className='mt-3'>
                        <Col xs={12}>
                            <button type='submit' disabled={props.loading}>
                                {props.loadingExport ? (
                                    <CircularProgress
                                        style={{ color: 'white' }}
                                    />
                                ) : (
                                    props.submitButtonText ??
                                    intl.formatMessage(messages.filterButton)
                                )}
                            </button>

                            {handleResetFilter && (
                                <button
                                    type='button'
                                    onClick={handleResetFilter}
                                >
                                    {intl.formatMessage(messages.restoreButton)}
                                </button>
                            )}

                            {handleAddRemoveFilter && (
                                <button
                                    type='button'
                                    // className={`new-btn small filter`}
                                    title={intl.formatMessage(
                                        messages.addFilter,
                                    )}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.handleAddRemoveFilter({
                                            id: null,
                                            showAddButton: true,
                                        });
                                    }}
                                >
                                    {intl.formatMessage(messages.addFilter)}
                                </button>
                            )}
                        </Col>
                    </Row>
                </Container>
            </AvForm>
        </Modal>
    );
}

export default injectIntl(DynamicFilterModal);
