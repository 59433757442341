import React, { useState } from 'react';

import { injectIntl } from 'react-intl';

import { Row, Col, Button } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import Loading from '../Loading/Loading';

function ContactFileForm(props) {
    const [attachment, setAttachment] = useState(null);
    const [attachmentDataUrl, setAttachmentDataUrl] = useState(null);
    const [showSaveAttachmentButton, setShowSaveAttachmentButton] = useState(
        false,
    );

    const { loadingAttachment, readOnly } = props;

    function handleAttachmentFileChange(e) {
        const fileReader = new FileReader();

        fileReader.onload = () => {
            setAttachmentDataUrl(fileReader.result);
            setShowSaveAttachmentButton(true);
        };

        setAttachment(e.target.files[0]);
        fileReader.readAsDataURL(e.target.files[0]);
    }

    function handleNewAttachmentSaveButtonClick(e) {
        e.preventDefault();

        props.handleNewAttachmentSaveButtonClick &&
            props.handleNewAttachmentSaveButtonClick(attachment);

        setShowSaveAttachmentButton(false);
    }

    return (
        <AvForm onValidSubmit={props.handleSubmit}>
            <Row>
                {loadingAttachment && <Loading loading />}

                <Col xs={8} md={attachmentDataUrl ? 12 : 12}>
                    <Row>
                        <Col xs={8}>
                            <AvField
                                label='.csv ou .xlsx'
                                type='file'
                                onChange={handleAttachmentFileChange}
                                name='attachmentFileId'
                                id='attachmentFileId'
                                disabled={readOnly}
                            />
                        </Col>
                        {showSaveAttachmentButton && (
                            <Col xs={4} className='mt-3'>
                                <Button
                                    className='btn-submit'
                                    onClick={handleNewAttachmentSaveButtonClick}
                                >
                                    Confirmar
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </AvForm>
    );
}

export default injectIntl(ContactFileForm);
