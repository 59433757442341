import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loading = (props) => {
    return (
        <>
            {props.loading && (
                <div className='loading-inside'>
                    <FontAwesomeIcon icon='spinner' size='4x' pulse />
                </div>
            )}
        </>
    );
};

export default Loading;
