import * as actionTypes from '../actions/actionTypes';

const initialState = {
    billingOffices: [],
    billingOffice: null,
    loadingOfficeDetails: true,
    loadingOfficeList: false,
    loadingBillingOfficesLayout: false,
    billingOfficesCount: 0,
    loadingNormalLogo: false,
    loadingSmallLogo: false,
    loadingDetails: false,
};

const setBillingOffices = (state, action) => {
    return {
        ...state,
        billingOffices: action?.billingOffices ?? [],
        loadingOfficeList: false,
    };
};

const setBillingOfficeDetails = (state, action) => {
    return {
        ...state,
        billingOffice: action.billingOffice,
        loadingDetails: false,
    };
};

const setBillingOfficeDetailsPost = (state, action) => {
    return {
        ...state,
        billingOffice: action.billingOffice,
        loadingDetails: false,
        contractors: [],
    };
};

const setBillingOfficeDetailsNew = (state) => {
    return {
        ...state,
        billingOffice: undefined,
        loadingDetails: false,
    };
};

const setBillingOfficeDetailsRequest = (state) => {
    return {
        ...state,
        billingOffice: undefined,
        loadingDetails: true,
    };
};

const setUploadNormalLogoRequest = (state) => {
    return {
        ...state,
        loadingNormalLogo: true,
    };
};

const setUploadSmallLogoRequest = (state) => {
    return {
        ...state,
        loadingSmallLogo: true,
    };
};

const setUploadNormalLogo = (state) => {
    return {
        ...state,
        loadingNormalLogo: false,
        normalLogoFileId: `${state.normalLogoFileId}`,
    };
};

const setUploadSmallLogo = (state) => {
    return {
        ...state,
        loadingSmallLogo: false,
        smallLogoFileId: `${state.smallLogoFileId}`,
    };
};

const setUploadNormalLogoFailure = (state) => {
    return {
        ...state,
        loadingNormalLogo: false,
    };
};

const setUploadSmallLogoFailure = (state) => {
    return {
        ...state,
        loadingSmallLogo: false,
    };
};

const setBillingOfficesRequest = (state) => {
    return {
        ...state,
        billingOffices: undefined,
        loadingOfficeList: true,
    };
};

const setBillingOfficeCount = (state, action) => {
    return {
        ...state,
        billingOfficesCount: action?.billingOfficesCount ?? 0,
    };
};

const setBillingOfficeUpdatedRequest = (state) => {
    return {
        ...state,
        loadingDetails: true,
    };
};

const setBillingOfficeUpdatedSuccess = (state) => {
    return {
        ...state,
        loadingDetails: true,
        billingOffice: undefined,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BILLINGOFFICE_GET_COUNT_FAILURE:
        case actionTypes.BILLINGOFFICE_GET_COUNT_REQUEST:
        case actionTypes.BILLINGOFFICE_GET_COUNT_SUCCESS:
            return setBillingOfficeCount(state, action);

        case actionTypes.BILLINGOFFICE_PUT_REQUEST:
            return setBillingOfficeUpdatedRequest(state, action);

        case actionTypes.BILLINGOFFICE_PUT_FAILURE:
        case actionTypes.BILLINGOFFICE_PUT_SUCCESS:
            return setBillingOfficeUpdatedSuccess(state, action);

        case actionTypes.BILLINGOFFICE_DELETE_REQUEST:
            return setBillingOfficeUpdatedRequest(state, action);

        case actionTypes.BILLINGOFFICE_DELETE_FAILURE:
        case actionTypes.BILLINGOFFICE_DELETE_SUCCESS:
            return setBillingOfficeUpdatedSuccess(state, action);

        case actionTypes.BILLINGOFFICE_GET_LIST_REQUEST:
            return setBillingOfficesRequest(state, action);

        case actionTypes.BILLINGOFFICE_GET_LIST_SUCCESS:
        case actionTypes.BILLINGOFFICE_GET_LIST_FAILURE:
            return setBillingOffices(state, action);

        case actionTypes.BILLINGOFFICE_GET_DETAILS_FAILURE:
        case actionTypes.BILLINGOFFICE_GET_DETAILS_SUCCESS:
            return setBillingOfficeDetails(state, action);

        case actionTypes.BILLINGOFFICE_POST_SUCCESS:
        case actionTypes.BILLINGOFFICE_POST_FAILURE:
            return setBillingOfficeDetailsPost(state, action);

        case actionTypes.BILLINGOFFICE_POST_REQUEST:
        case actionTypes.BILLINGOFFICE_GET_DETAILS_REQUEST:
            return setBillingOfficeDetailsRequest(state, action);

        case actionTypes.BILLINGOFFICE_GET_DETAILS_NEW:
            return setBillingOfficeDetailsNew(state, action);

        case actionTypes.BILLINGOFFICE_UPLOAD_NORMAL_LOGO_REQUEST:
            return setUploadNormalLogoRequest(state, action);

        case actionTypes.BILLINGOFFICE_UPLOAD_SMALL_LOGO_REQUEST:
            return setUploadSmallLogoRequest(state, action);

        case actionTypes.BILLINGOFFICE_UPLOAD_NORMAL_LOGO_SUCCESS:
            return setUploadNormalLogo(state, action);

        case actionTypes.BILLINGOFFICE_UPLOAD_SMALL_LOGO_SUCCESS:
            return setUploadSmallLogo(state, action);

        case actionTypes.BILLINGOFFICE_UPLOAD_NORMAL_LOGO_FAILURE:
            return setUploadNormalLogoFailure(state, action);

        case actionTypes.BILLINGOFFICE_UPLOAD_SMALL_LOGO_FAILURE:
            return setUploadSmallLogoFailure(state, action);

        default:
            return state;
    }
};

export default reducer;
