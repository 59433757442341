import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    name: {
        id: 'parameters.parameterName',
        defaultMessage: 'Nome da Variável',
    },

    description: {
        id: 'parameters.parameterDescription',
        defaultMessage: 'Descrição da Variável',
    },

    low: {
        id: 'parameters.parameterLow',
        defaultMessage: 'Valor da Variável',
    },

    value: {
        id: 'global.value',
        defaultMessage: 'Total do Parâmetro',
    },

    currency: {
        id: 'global.currency',
        defaultMessage: 'Currency',
    },
    unitValue: {
        id: 'global.unitValue',
        defaultMessage: 'Valor Unitário',
    },

    isAvailable: {
        id: 'global.isAvailable',
        defaultMessage: 'Disponível',
    },
    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },
    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },
    company: {
        id: 'roles.company',
        defaultMessage: 'Contratante',
    },
});

export default messages;
