import React from 'react';

import { injectIntl } from 'react-intl';

import { Row, Col, FormGroup } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import messages from './ContractorDetailsForm.intl';

import EvtSelect from '../EvtSelect/EvtSelect';
import { handleCpfCnpj } from '../../utils/handleCpfCnpj';

function ContractorDetailsForm(props) {
    const { intl, states, cities, readOnly } = props;
    const contractor = props.contractor || {};
    const address = contractor.address || {};

    const billingEmails = contractor.billing?.emails.join('; ');

    const state =
        address && states && states.find((s) => s.uf === address.state);
    const city =
        address && state && state.cities.find((s) => s.name === address.city);

    const statesOptions =
        states &&
        states.map((state) => {
            return {
                label: state.name,
                value: state.uf,
            };
        });

    const citiesOption =
        cities &&
        cities.map((city) => {
            return {
                label: city.name,
                value: city.name,
            };
        });

    return (
        <AvForm onValidSubmit={props.handleSubmit}>
            <Row>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <p
                        style={{
                            color: '#A9A9A9',
                            fontSize: '25px',
                            paddingBottom: '15px',
                            paddingLeft: '10px',
                            borderBottom: '3px solid #A9A9A9',
                            marginBottom: '30px',
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '5px',
                        }}
                    >
                        Informações do Contratante
                    </p>
                </div>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.companyName)}
                            type='text'
                            name='companyName'
                            id='companyName'
                            value={contractor.companyName || ''}
                            errorMessage=' '
                            disabled={readOnly}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.cnpj)}
                            type='text'
                            name='documentNumber1'
                            id='documentNumber1'
                            value={handleCpfCnpj(contractor.documentNumber1)}
                            errorMessage=' '
                            required
                            disabled={readOnly}
                            validate={{
                                pattern: {
                                    value:
                                        '^([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}|[0-9]{11}|[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}|[0-9]{11}|[0-9]{14})$',
                                    errorMessage: intl.formatMessage(
                                        messages.invalidCNPJ,
                                    ),
                                },
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.displayName)}
                            type='text'
                            name='displayName'
                            id='displayName'
                            value={contractor.displayName || ''}
                            errorMessage=' '
                            disabled={readOnly}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.email)}
                            type='email'
                            name='emailAddress'
                            id='email'
                            value={contractor.emailAddress || ''}
                            errorMessage=' '
                            disabled={readOnly}
                            validate={{
                                pattern: {
                                    value:
                                        '^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
                                    errorMessage: intl.formatMessage(
                                        messages.invalidEmail,
                                    ),
                                },
                            }}
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.street)}
                            type='text'
                            name='address.street'
                            id='street'
                            value={address.street || ''}
                            errorMessage=' '
                            disabled={readOnly}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.number)}
                            type='number'
                            name='address.number'
                            id='number'
                            value={address.number || ''}
                            errorMessage=' '
                            disabled={readOnly}
                            required
                            validate={{
                                pattern: {
                                    value: '^[0-9]+$',
                                    errorMessage: ' ',
                                },
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.complement)}
                            type='text'
                            name='address.complement'
                            id='complement'
                            disabled={readOnly}
                            value={address.complement || ''}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={2} lg={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.neighborhood)}
                            type='text'
                            name='address.neighborhood'
                            id='neighborhood'
                            disabled={readOnly}
                            value={address.neighborhood || ''}
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={4} className='text-left'>
                    {!readOnly ? (
                        <EvtSelect
                            label={intl.formatMessage(messages.state)}
                            name='address.state'
                            options={statesOptions}
                            handleSelectedOptionChange={props.handleStateChange}
                            option={
                                contractor && {
                                    selectedValueOption: props.selectedState
                                        ? props.selectedState
                                              .selectedValueOption
                                        : state && state.uf,
                                    selectedLabelOption: props.selectedState
                                        ? props.selectedState
                                              .selectedLabelOption
                                        : state && state.name,
                                }
                            }
                        />
                    ) : (
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.state)}
                                type='text'
                                name='address.state'
                                id='state'
                                disabled={readOnly}
                                value={state && state.name}
                                errorMessage=' '
                                required
                            />
                        </FormGroup>
                    )}
                </Col>
                <Col xs={12} md={4} lg={4} className='text-left'>
                    {!readOnly ? (
                        <EvtSelect
                            label={intl.formatMessage(messages.city)}
                            name='address.city'
                            options={citiesOption}
                            handleSelectedOptionChange={props.handleCityChange}
                            key={state && state.name}
                            option={
                                contractor && {
                                    selectedValueOption: props.selectedCity
                                        ? props.selectedCity.selectedValueOption
                                        : city && city.name,
                                    selectedLabelOption: props.selectedCity
                                        ? props.selectedCity.selectedLabelOption
                                        : city && city.name,
                                }
                            }
                        />
                    ) : (
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.city)}
                                type='text'
                                name='address.city'
                                id='state'
                                disabled={readOnly}
                                value={city && city.name}
                                errorMessage=' '
                                required
                            />
                        </FormGroup>
                    )}
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.postalCode)}
                            type='text'
                            name='address.postalCode'
                            id='postalCode'
                            disabled={readOnly}
                            value={address.postalCode || ''}
                            validate={{
                                required: { value: true, errorMessage: ' ' },
                                pattern: {
                                    value: '^\\d{8}$',
                                    errorMessage: intl.formatMessage(
                                        messages.invalidPostalCode,
                                    ),
                                },
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.domain)}
                            type='text'
                            name='domain'
                            id='domain'
                            disabled={readOnly}
                            value={contractor.domain || ''}
                            errorMessage=' '
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                    <AvField
                        label={intl.formatMessage(messages.primaryColorText)}
                        type='color'
                        name='primaryColor'
                        id='primaryColor'
                        disabled={readOnly}
                        value={contractor.primaryColor}
                    />
                </Col>
            </Row>
            <Row>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    <p
                        style={{
                            color: '#A9A9A9',
                            fontSize: '25px',
                            paddingBottom: '15px',
                            paddingLeft: '10px',
                            borderBottom: '3px solid #A9A9A9',
                            marginBottom: '30px',
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '5px',
                        }}
                    >
                        Billing
                    </p>
                </div>
            </Row>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.billing)}
                            type='text'
                            name='Billing.Emails'
                            id='Billing.Emails'
                            value={billingEmails || ''}
                            errorMessage=' '
                            disabled={readOnly}
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={12}>
                    <br />
                    <div className='mt-3'>
                        {!readOnly && (
                            <button
                                type='submit'
                                className='btn-submit'
                                disabled={props.loading}
                            >
                                {intl.formatMessage(messages.saveButton)}
                            </button>
                        )}
                    </div>
                </Col>
            </Row>
        </AvForm>
    );
}

export default injectIntl(ContractorDetailsForm);
