import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    veryWeak: {
        id: 'PasswordStrengthMeter.veryWeak',
        defaultMessage: 'Muito Fraca',
    },
    weak: {
        id: 'PasswordStrengthMeter.weak',
        defaultMessage: 'Fraca',
    },
    regular: {
        id: 'PasswordStrengthMeter.regular',
        defaultMessage: 'Média',
    },
    strong: {
        id: 'PasswordStrengthMeter.strong',
        defaultMessage: 'Forte',
    },
    veryStrong: {
        id: 'PasswordStrengthMeter.veryStrong',
        defaultMessage: 'Muito Forte',
    },
});

export default messages;
