import { defineMessages } from 'react-intl';

const globalMessages = defineMessages({
    previousButton: {
        id: 'global.previousButton',
        defaultMessage: '<',
    },
    nextButtonText: {
        id: 'global.nextButton',
        defaultMessage: '>',
    },
    searchButton: {
        id: 'global.searchButton',
        defaultMessage: 'Buscar {entity}',
    },
    noDataText: {
        id: 'global.noDataText',
        defaultMessage: 'Sem dados',
    },
    loadingText: {
        id: 'global.loadingText',
        defaultMessage: 'Carregando...',
    },
    pageText: {
        id: 'global.pageText',
        defaultMessage: 'Página',
    },
    ofText: {
        id: 'global.ofText',
        defaultMessage: 'de',
    },
    rowsText: {
        id: 'global.rowsText',
        defaultMessage: 'linhas',
    },
    pageJumpText: {
        id: 'global.pageJumpText',
        defaultMessage: 'pular para página',
    },
    rowsSelectorText: {
        id: 'global.rowsSelectorText',
        defaultMessage: 'linhas por página',
    },
    newButtonText: {
        id: 'global.newButtonText',
        defaultMessage: 'Novo {entity}',
    },
    importButtonText: {
        id: 'global.importButtonText',
        defaultMessage: 'Importar',
    },
    sendButtonText: {
        id: 'global.sendButtonText',
        defaultMessage: 'Enviar',
    },
    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },
    primaryColorText: {
        id: 'global.primaryColorText',
        defaultMessage: 'Cor Para Customização',
    },
    logoNormalText: {
        id: 'global.logoNormalText',
        defaultMessage: 'Logomarca Tamanho Normal',
    },
    logoMiniText: {
        id: 'global.logoMiniText',
        defaultMessage: 'Logomarca Tamanho Pequeno',
    },
    logoCustomerApp: {
        id: 'global.logoCustomerApp',
        defaultMessage: 'Logomarca Aplicativo do Cliente',
    },
    yesText: {
        id: 'global.yesText',
        defaultMessage: 'Sim',
    },
    noText: {
        id: 'global.noText',
        defaultMessage: 'Não',
    },
    buttonChangeText: {
        id: 'global.buttonChangeText',
        defaultMessage: 'Trocar',
    },
    minLengthPassword: {
        id: 'global.minLengthPassword',
        defaultMessage: 'Sua senha deve ter no mínimo 8 caracteres',
    },
    confirmDeleteText: {
        id: 'global.confirmDeleteText',
        defaultMessage: 'Tem certeza que deseja excluir?',
    },
    confirmCancelText: {
        id: 'global.confirmCancelText',
        defaultMessage: 'Tem certeza que deseja cancelar?',
    },
    confirmDeactiveText: {
        id: 'global.confirmDeactiveText',
        defaultMessage: 'Tem certeza que deseja desativar?',
    },
    confirmDeactiveCropText: {
        id: 'global.confirmDeactiveCropText',
        defaultMessage: 'Tem certeza que deseja inativar o crop?',
    },
    confirmActiveCropText: {
        id: 'global.confirmActiveCropText',
        defaultMessage: 'Tem certeza que deseja ativar o crop?',
    },
    confirmButton: {
        id: 'global.confirmButton',
        defaultMessage: 'Confirmar',
    },
    cancelButton: {
        id: 'global.cancelButton',
        defaultMessage: 'Cancelar',
    },
    fileText: {
        id: 'global.fileText',
        defaultMessage: 'Arquivo',
    },
    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },
    updateButton: {
        id: 'global.updateButton',
        defaultMessage: 'Atualizar',
    },
    available: {
        id: 'global.available',
        defaultMessage: 'Disponíveis',
    },
    active: {
        id: 'global.active',
        defaultMessage: 'Ativas',
    },
    order: {
        id: 'global.order',
        defaultMessage: 'Ordem',
    },
    type: {
        id: 'global.type',
        defaultMessage: 'Tipo',
    },
    parameters: {
        id: 'global.parameters',
        defaultMessage: 'Parâmetros',
    },
    saveButton: {
        id: 'global.saveButton',
        defaultMessage: 'Salvar',
    },
    text: {
        id: 'global.text',
        defaultMessage: 'Texto',
    },
    from: {
        id: 'global.from',
        defaultMessage: 'A partir de',
    },
    to: {
        id: 'global.to',
        defaultMessage: 'até',
    },

    characters: {
        id: 'global.characters',
        defaultMessage: 'Caracteres',
    },
    size: {
        id: 'global.size',
        defaultMessage: 'Tamanho',
    },
    newText: {
        id: 'global.newText',
        defaultMessage: 'Texto novo',
    },
    oldText: {
        id: 'global.oldText',
        defaultMessage: 'Texto antigo',
    },
    actions: {
        id: 'global.actions',
        defaultMessage: 'Ações {entity}',
    },
    errorText: {
        id: 'global.errorText',
        defaultMessage:
            'Erro {entity}: Por favor, entre em contato com o suporte',
    },
    createdAt: {
        id: 'global.createdAt',
        defaultMessage: 'Criado em',
    },
    createdBy: {
        id: 'global.createdBy',
        defaultMessage: 'Criado por',
    },
    helloText: {
        id: 'global.helloText',
        defaultMessage: 'Olá, {entity}',
    },
    january: {
        id: 'global.months.january',
        defaultMessage: 'Janeiro',
    },
    february: {
        id: 'global.months.february',
        defaultMessage: 'Fevereiro',
    },
    march: {
        id: 'global.months.march',
        defaultMessage: 'Março',
    },
    april: {
        id: 'global.months.april',
        defaultMessage: 'Abril',
    },
    may: {
        id: 'global.months.may',
        defaultMessage: 'Maio',
    },
    june: {
        id: 'global.months.june',
        defaultMessage: 'Junho',
    },
    july: {
        id: 'global.months.july',
        defaultMessage: 'Julho',
    },
    august: {
        id: 'global.months.august',
        defaultMessage: 'Agosto',
    },
    september: {
        id: 'global.months.september',
        defaultMessage: 'Setembro',
    },
    october: {
        id: 'global.months.october',
        defaultMessage: 'Outubro',
    },
    november: {
        id: 'global.months.november',
        defaultMessage: 'Novembro',
    },
    december: {
        id: 'global.months.december',
        defaultMessage: 'Dezembro',
    },
    create: {
        id: 'global.create',
        defaultMessage: 'Criar',
    },
    generate: {
        id: 'global.generate',
        defaultMessage: 'Gerar',
    },
    welcome: {
        id: 'global.welcome',
        defaultMessage: 'Bem-vindo, ',
    },
    defaultError: {
        id: 'global.defaultError',
        defaultMessage: 'Oh, não! Alguma coisa deu errado...',
    },
    date: {
        id: 'global.date',
        defaultMessage: 'Data',
    },
    developedBy: {
        id: 'global.developedBy',
        defaultMessage: 'Desenvolvido por',
    },
    cities: {
        id: 'global.cities',
        defaultMessage: 'Cidades',
    },
    states: {
        id: 'global.states',
        defaultMessage: 'Estados',
    },
    complete: {
        id: 'global.complete',
        defaultMessage: 'Completo',
    },
    search: {
        id: 'global.search',
        defaultMessage: 'Pesquisar',
    },
    copy: {
        id: 'global.copy',
        defaultMessage: 'Copiar',
    },
    export: {
        id: 'global.export',
        defaultMessage: 'Exportar',
    },
    import: {
        id: 'global.import',
        defaultMessage: 'Importar',
    },
    anticipate: {
        id: 'global.anticipate',
        defaultMessage: 'Adiantar',
    },
    accept: {
        id: 'global.accept',
        defaultMessage: 'Aceitar',
    },
    warning: {
        id: 'global.warning',
        defaultMessage: 'Atenção',
    },
    confirmAnticipate: {
        id: 'global.confirmAnticipate',
        defaultMessage: 'Tem certeza que deseja adiantar?',
    },
    fields: {
        id: 'global.fields',
        defaultMessage: 'Campos',
    },
    description: {
        id: 'global.description',
        defaultMessage: 'Descrição',
    },
    required: {
        id: 'global.required',
        defaultMessage: 'Obrigatório',
    },
    amount: {
        id: 'global.amount',
        defaultMessage: 'Quantidade',
    },

    total: {
        id: 'global.total',
        defaultMessage: 'Total',
    },

    registrationInfo: {
        id: 'global.registrationInfo',
        defaultMessage: 'Dados Cadastrais',
    },

    status: {
        id: 'global.status',
        defaultMessage: 'Status',
    },
    selectPlaceholder: {
        id: 'global.selectPlaceholder',
        defaultMessage: 'Digite para pesquisa...',
    },
    selectAll: {
        id: 'global.selectAll',
        defaultMessage: 'Selecionar todos',
    },
});

export default globalMessages;
