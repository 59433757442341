export function paymentMethodTypeParse(type) {
    switch (type) {
        case 1:
            return 'Cartão de crédito';
        case 4:
            return 'Carteira digital';
        case 5:
            return 'Pix';
        case 6:
            return 'Dinheiro';
        case 255:
            return 'Outros';
        default:
            return type;
    }
}
