import React, { Component } from 'react';

import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from 'reactstrap';
import messages from './ContractorListPage.intl';
import * as actions from '../../store/actions/index';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import { AuthContext } from '../../context/AuthContext';

import EvtTable, {
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import { filterPropertyTypes } from '../../utils/enums';
import { handleCpfCnpj } from '../../utils/handleCpfCnpj';
import Tabs from '../../components/Tabs/Tabs';

class ContractorListPage extends Component {
    static contextType = AuthContext;

    state = {
        filtersContractors: [],
        filtersBillingOffice: [],
        queryContractors: '',
        queryBillingOffice: '',
        activeTab: 0,
    };

    componentDidMount() {
        const index =
            new URLSearchParams(window.location.search).get('index') || 0;

        if (
            index === 0 &&
            window.location.href.split('/')[3].indexOf('?') === -1
        )
            this.props.history.push(`contractors?index=${index}`);

        this.setState({
            activeTab: Number(index),
        });
    }

    reloadList = () => {
        const { queryContractors } = this.state;
        this.props.onInitContractors(queryContractors);
        this.props.onInitContractorsCount(queryContractors);
    };

    resetFilters = () => {
        this.setState({
            filtersContractors: [],
            filtersBillingOffice: [],
        });
    };

    reloadBillingList = () => {
        const { queryBillingOffice } = this.state;
        this.props.onInitBillingOffice(queryBillingOffice);
        this.props.onInitBillingOfficeCount(queryBillingOffice);
    };

    handleGetTrProps = (state, rowInfo) => {
        const { activeTab } = this.state;
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: () => {
                if (activeTab === 0) {
                    this.props.history.push(
                        `/contractors/${rowInfo.original.contractorId}`,
                    );
                }

                if (activeTab === 1) {
                    this.props.history.push(
                        `/billingOffice/${rowInfo.original.id}`,
                    );
                }
            },
        };
    };

    handleTabClick = (e, index) => {
        const path = window.location.pathname;
        this.props.onChangeTab(path, index);

        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }

        switch (index) {
            case 0:
                this.reloadList(index);
                break;
            case 1:
                this.reloadBillingList(index);
                break;
            default:
                break;
        }
        this.props.history.push(`contractors?index=${index}`);
    };

    handleNewClick = () => {
        const { activeTab } = this.state;

        if (activeTab === 0) this.props.history.push(`/contractors/new`);
        if (activeTab === 1) this.props.history.push(`/billingOffice/new`);
    };

    handleRefreshButtonClick = () => {
        this.reloadList();
    };

    billingRefreshButtonClick = () => {
        this.reloadBillingList();
    };

    handleEvTableStateChange = (queryContractors) => {
        this.setState(
            {
                queryContractors,
            },
            () => {
                this.reloadList();
            },
        );
    };

    handleEvTableBillingOfficeStateChange = (queryBillingOffice) => {
        this.setState(
            {
                queryBillingOffice,
            },
            () => {
                this.reloadBillingList();
            },
        );
    };

    handleBillingChange = (queryBillingOffice) => {
        this.setState(
            {
                queryBillingOffice,
            },
            () => {
                this.reloadBillingList();
            },
        );
    };

    handleFiltersChange = (filtersContractors) => {
        this.setState({
            filtersContractors,
        });
    };

    handleFiltersBillingOfficeChange = (filtersBillingOffice) => {
        this.setState({
            filtersBillingOffice,
        });
    };

    render() {
        const {
            contractors,
            contractorsCount,
            loadingList,
        } = this.props.contractor;
        const {
            billingOffices,
            billingOfficesCount,
            loadingOfficeList,
        } = this.props.billingOffice;

        const { intl } = this.props;
        const { filtersContractors, filtersBillingOffice } = this.state;

        const newContractButtonEnabled = this.context.hasClaim(
            'contractor-create',
        );
        const properties = [
            {
                key: 'displayName',
                value: intl.formatMessage(messages.displayName),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'documentNumber1',
                value: intl.formatMessage(messages.cnpj),
                type: filterPropertyTypes.TEXT,
                map: (input) => input.replace(/[^0-9]/g, ''),
            },
            {
                key: 'emailAddress',
                value: intl.formatMessage(messages.email),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'address.city',
                value: intl.formatMessage(messages.city),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'address.state',
                value: intl.formatMessage(messages.state),
                type: filterPropertyTypes.TEXT,
            },
        ];

        const propertiesOffice = [
            {
                key: 'displayName',
                value: intl.formatMessage(messages.displayName),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'name',
                value: intl.formatMessage(messages.name),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'emailAddress',
                value: intl.formatMessage(messages.email),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'cnpj',
                value: intl.formatMessage(messages.cnpj),
                type: filterPropertyTypes.TEXT,
                map: (input) => input.replace(/[^0-9]/g, ''),
            },
        ];

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.displayName)}</span>
                    </div>
                ),
                accessor: 'displayName',
                Cell: (row) => {
                    const id = row.index + 1;
                    return (
                        <div className='customer-container'>
                            <span className={`color-number color-number-${id}`}>
                                {row.value.substr(0, 1).toUpperCase()}
                            </span>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                minWidth: calculateColumnWidth(
                    contractors,
                    'displayName',
                    intl.formatMessage(messages.displayName),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.cnpj)}</span>
                    </div>
                ),
                accessor: 'documentNumber1',
                minWidth: calculateColumnWidth(
                    contractors,
                    'documentNumber1',
                    intl.formatMessage(messages.cnpj),
                ),
                Cell: (row) => {
                    return (
                        <div className='customer-container'>
                            <div className=''>{handleCpfCnpj(row.value)}</div>
                        </div>
                    );
                },
                style: {
                    textAlign: 'center',
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.email)}</span>
                    </div>
                ),
                accessor: 'emailAddress',
                minWidth: calculateColumnWidth(
                    contractors,
                    'emailAddress',
                    intl.formatMessage(messages.email),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.city)}</span>
                    </div>
                ),
                accessor: 'address.city',
                minWidth: calculateColumnWidth(
                    contractors,
                    'address.city',
                    intl.formatMessage(messages.city),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.state)}</span>
                    </div>
                ),
                accessor: 'address.state',
                minWidth: calculateColumnWidth(
                    contractors,
                    'address.state',
                    intl.formatMessage(messages.state),
                ),
            },
        ];

        const columnsOffice = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.displayName)}</span>
                    </div>
                ),
                accessor: 'displayName',
                Cell: (row) => {
                    const id = row.index + 1;
                    return (
                        <div className='customer-container'>
                            <span className={`color-number color-number-${id}`}>
                                {row.value.substr(0, 1).toUpperCase()}
                            </span>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                minWidth: calculateColumnWidth(
                    contractors,
                    'displayName',
                    intl.formatMessage(messages.displayName),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.name)}</span>
                    </div>
                ),
                accessor: 'name',
                Cell: (row) => {
                    const id = row.index + 1;
                    return (
                        <div className='customer-container'>
                            <span className={`color-number color-number-${id}`}>
                                {row.value.substr(0, 1).toUpperCase()}
                            </span>
                            <div className=''>{row.value}</div>
                        </div>
                    );
                },
                minWidth: calculateColumnWidth(
                    contractors,
                    'displayName',
                    intl.formatMessage(messages.displayName),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.cnpj)}</span>
                    </div>
                ),
                accessor: 'cnpj',
                minWidth: calculateColumnWidth(
                    contractors,
                    'cnpj',
                    intl.formatMessage(messages.cnpj),
                ),
                Cell: (row) => {
                    return (
                        <div className='customer-container'>
                            <div className=''>{handleCpfCnpj(row.value)}</div>
                        </div>
                    );
                },
                style: {
                    textAlign: 'center',
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.email)}</span>
                    </div>
                ),
                accessor: 'emailAddress',
                minWidth: calculateColumnWidth(
                    contractors,
                    'emailAddress',
                    intl.formatMessage(messages.email),
                ),
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/contractors',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <Tabs
                    handleTabClick={this.handleTabClick}
                    activeTab={this.state.activeTab}
                    tabs={[
                        {
                            name: intl.formatMessage(
                                messages.navNameContractor,
                            ),
                            component: (
                                <>
                                    <header className='table-screen'>
                                        <section className='title'>
                                            <Header
                                                title={intl.formatMessage(
                                                    messages.title,
                                                )}
                                                subtitle={
                                                    contractorsCount +
                                                    intl.formatMessage(
                                                        messages.subtitle,
                                                    )
                                                }
                                            />
                                        </section>
                                        <section className='btns-topo'>
                                            <EvtFilter
                                                properties={properties}
                                                handleFiltersChange={
                                                    this.handleFiltersChange
                                                }
                                                loading={loadingList}
                                            />
                                            <button
                                                className={`new-btn small ${
                                                    loadingList ? 'loading' : ''
                                                }`}
                                                onClick={
                                                    this
                                                        .handleRefreshButtonClick
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon='sync'
                                                    size='1x'
                                                />
                                            </button>

                                            {newContractButtonEnabled && (
                                                <button
                                                    className='new-btn'
                                                    onClick={
                                                        this.handleNewClick
                                                    }
                                                >
                                                    {intl.formatMessage(
                                                        messages.newButtonText,
                                                        {
                                                            entity: intl.formatMessage(
                                                                messages.titleContractor,
                                                                {
                                                                    entity: '',
                                                                },
                                                            ),
                                                        },
                                                    )}
                                                </button>
                                            )}
                                        </section>
                                    </header>
                                    <section className='content-middle'>
                                        <EvtTable
                                            filters={filtersContractors}
                                            columns={columns}
                                            data={contractors}
                                            length={contractorsCount}
                                            pageSize={10}
                                            handleStateChange={
                                                this.handleEvTableStateChange
                                            }
                                            handleGetTrProps={
                                                this.handleGetTrProps
                                            }
                                            loading={loadingList}
                                            defaultSorted={[
                                                {
                                                    id: 'createdAt',
                                                    desc: true,
                                                },
                                            ]}
                                        />
                                    </section>
                                </>
                            ),
                        },
                        this.context.contractor.isSystem && {
                            name: intl.formatMessage(
                                messages.navNameBillingOffice,
                            ),
                            component: (
                                <>
                                    <header className='table-screen'>
                                        <section className='title'>
                                            <Header
                                                title={intl.formatMessage(
                                                    messages.titleBillingOffice,
                                                )}
                                                subtitle={
                                                    billingOfficesCount +
                                                    intl.formatMessage(
                                                        messages.subtitle,
                                                    )
                                                }
                                            />
                                        </section>
                                        <section className='btns-topo'>
                                            <EvtFilter
                                                properties={propertiesOffice}
                                                handleFiltersChange={
                                                    this
                                                        .handleFiltersBillingOfficeChange
                                                }
                                                loading={loadingOfficeList}
                                            />
                                            <button
                                                className={`new-btn small ${
                                                    loadingOfficeList
                                                        ? 'loading'
                                                        : ''
                                                }`}
                                                onClick={
                                                    this
                                                        .billingRefreshButtonClick
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon='sync'
                                                    size='1x'
                                                />
                                            </button>

                                            {newContractButtonEnabled && (
                                                <button
                                                    className='new-btn'
                                                    onClick={
                                                        this.handleNewClick
                                                    }
                                                >
                                                    {intl.formatMessage(
                                                        messages.newButtonText,
                                                        {
                                                            entity: intl.formatMessage(
                                                                messages.titleContractor,
                                                                {
                                                                    entity: '',
                                                                },
                                                            ),
                                                        },
                                                    )}
                                                </button>
                                            )}
                                        </section>
                                    </header>
                                    <section className='content-middle'>
                                        <EvtTable
                                            filters={filtersBillingOffice}
                                            columns={columnsOffice}
                                            data={billingOffices}
                                            length={billingOfficesCount}
                                            pageSize={10}
                                            handleStateChange={
                                                this
                                                    .handleEvTableBillingOfficeStateChange
                                            }
                                            handleGetTrProps={
                                                this.handleGetTrProps
                                            }
                                            loading={loadingOfficeList}
                                            defaultSorted={[
                                                {
                                                    id: 'createdAt',
                                                    desc: true,
                                                },
                                            ]}
                                        />
                                    </section>
                                </>
                            ),
                        },
                    ]}
                />
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contractor: {
            contractors: state.contractorReducer.contractors ?? [],
            contractorsCount: state.contractorReducer.contractorsCount ?? 0,
            loadingList: state.contractorReducer.loadingList,
        },
        billingOffice: {
            billingOffices: state.billingOfficeReducer.billingOffices ?? [],
            billingOfficesCount:
                state.billingOfficeReducer.billingOfficesCount ?? 0,
            loadingOfficeList: state.billingOfficeReducer.loadingOfficeList,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitContractors: (query) => dispatch(actions.initContractors(query)),
        onInitContractorsCount: (query) =>
            dispatch(actions.initContractorsCount(query)),
        onInitBillingOffice: (queryBillingOffice) =>
            dispatch(actions.initBillingOfficers(queryBillingOffice)),
        onInitBillingOfficeCount: (queryBillingOffice) =>
            dispatch(actions.initBillingOfficersCount(queryBillingOffice)),
        onChangeTab: (path, index) =>
            dispatch(actions.changeTabTarifications(path, index)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ContractorListPage),
);
