import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'users.title',
        defaultMessage: 'Usuários',
    },

    titleUser: {
        id: 'users.titleUser',
        defaultMessage: 'Usuário {entity}',
    },

    registrationInfo: {
        id: 'users.registrationInfo',
        defaultMessage: 'Dados Cadastrais',
    },

    access: {
        id: 'users.access',
        defaultMessage: 'Acessos',
    },

    deleteUser: {
        id: 'users.deleteUser',
        defaultMessage: 'Excluir Usuário',
    },

    name: {
        id: 'users.name',
        defaultMessage: 'Nome',
    },

    email: {
        id: 'users.email',
        defaultMessage: 'Email',
    },

    password: {
        id: 'users.password',
        defaultMessage: 'Senha',
    },

    role: {
        id: 'users.role',
        defaultMessage: 'Função',
    },
    roleExists: {
        id: 'users.roleExists',
        defaultMessage: 'Perfil já cadastrado',
    },
    company: {
        id: 'users.company',
        defaultMessage: 'Contratante',
    },
    billingOffice: {
        id: 'users.billingOffice',
        defaultMessage: 'Escritório de cobranças',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    modalTitle: {
        id: 'users.selectAcccess',
        defaultMessage: 'Selecione o acesso',
    },

    deleteAccess: {
        id: 'modal.deleteAccess',
        defaultMessage: 'Apagar acesso',
    },

    userAccessAlreadyAdded: {
        id: 'modal.userAccessAlreadyAdded',
        defaultMessage: 'Usuário já possui esse acesso',
    },

    newPasswordConfirmationModalTitle: {
        id: 'users.newPasswordConfirmationModalTitle',
        defaultMessage: 'Envio de renovação de senha',
    },

    newPasswordConfirmationModalText: {
        id: 'users.newPasswordConfirmationModalText',
        defaultMessage:
            'Tem certeza que deseja enviar um email de renovação de senha para esse usuário?',
    },
    generateNewPasswordModalTitle: {
        id: 'users.generateNewPasswordModalTitle',
        defaultMessage: 'Gerar nova senha',
    },

    newPassword: {
        id: 'user.newPassword',
        defaultMessage: 'Nova senha',
    },

    confirmNewPassword: {
        id: 'user.confirmNewPassword',
        defaultMessage: 'Confirmar nova senha',
    },

    generateNewPasswordConfirmationModalTitle: {
        id: 'users.generateNewPasswordConfirmationModalTitle',
        defaultMessage: 'Confirme sua nova senha',
    },

    generateNewPasswordSuccess: {
        id: 'users.generateNewPasswordSuccess',
        defaultMessage: 'Senha gerada com sucesso.',
    },

    passwordWrong: {
        id: 'users.passwordWrong',
        defaultMessage: 'As senhas não coincidem',
    },

    cpfCnpj: {
        id: 'contacts.customerCpfCnpj',
        defaultMessage: '"CPF/CNPJ',
    },
    invalidCNPJ: {
        id: 'global.invalidCNPJ',
        defaultMessage: 'CPF ou CNPJ inválido',
    },
});

export default messages;
