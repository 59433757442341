import React from 'react';

import Checkbox from '../CheckBox/CheckBox';

const checkBoxList = (props) => {
    let count = 0;
    if (props.groupby) {
        return props.groupby.map((entity) => {
            count++;
            const billingOfficeStyle =
                entity === 'Gestor de cobranças'
                    ? props.color ?? 'rgb(23, 222, 99)'
                    : '';
            return (
                <fieldset
                    className='form-group'
                    // eslint-disable-next-line react/no-unknown-property
                    inline='true'
                    name={props.name}
                    key={props.name + count}
                    style={{ borderColor: billingOfficeStyle }}
                >
                    <legend style={{ color: billingOfficeStyle }}>
                        {entity}
                    </legend>
                    <div className='form-control border-0 p-0 h-auto is-untouched is-pristine av-valid'>
                        {props.items &&
                            props.items
                                .filter((x) => x.entity === entity)
                                .map((item) => {
                                    return (
                                        <Checkbox
                                            label={item.description}
                                            value={item.value}
                                            id={item.value}
                                            key={item.value}
                                            checked={
                                                !!props.checked[item.value]
                                            }
                                            onChange={props.handleChange}
                                            isReadOnly={props.isReadOnly}
                                        />
                                    );
                                })}
                    </div>
                    <div className='valid-feedback' />
                </fieldset>
            );
        });
    }
    return (
        <fieldset
            className='form-group'
            // eslint-disable-next-line react/no-unknown-property
            inline='true'
            name={props.name}
            key={props.name + count}
        >
            <div className='form-control border-0 p-0 h-auto is-untouched is-pristine av-valid'>
                {props.items &&
                    props.items.map((item) => {
                        return (
                            <Checkbox
                                label={item.description}
                                value={item.value}
                                id={item.value}
                                key={item.value}
                                checked={!!props.checked[item.value]}
                                onChange={props.handleChange}
                            />
                        );
                    })}
            </div>
            <div className='valid-feedback' />
        </fieldset>
    );
};

export default checkBoxList;
