/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as actions from '../../store/actions/index';

import messages from './TechnicalLogsTable.intl';

import JsonTreeModal from '../../components/JsonTreeModal/JsonTreeModal';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import EvtTable from '../../components/EvtTable/EvtTable';

import { filterPropertyTypes } from '../../utils/enums';

class TechnicalLogsTable extends Component {
    state = {
        activeTab: 0,
        showTechnicalLogModal: false,
        technicalLog: {},
        filters: [],
        query: '',
    };

    reloadTechnicalLogsList = () => {
        const { query } = this.state;
        this.props.onInitTechnicalLogsCount(query);
        this.props.onInitTechnicalLogs(query);
    };

    handleTechnicalLogToggleModal = (technicalLog) => {
        this.setState({
            showTechnicalLogModal: !this.state.showTechnicalLogModal,
            technicalLog,
        });
    };

    handleTechnicalLogsRowClick = (e, technicalLog) => {
        this.handleTechnicalLogToggleModal(technicalLog);
    };

    handleFiltersChange = (filters) => {
        this.setState({
            filters,
        });
    };

    handleEvTableStateChange = (query) => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadTechnicalLogsList();
            },
        );
    };

    handleRefreshButtonClick = () => {
        this.reloadTechnicalLogsList();
    };

    handleGetTrProps = (state, rowInfo) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: () => this.handleTechnicalLogToggleModal(rowInfo.original),
        };
    };

    render() {
        const {
            intl,
            loadingTechnicalLogsList,
            technicalLogs,
            technicalLogsCount,
        } = this.props;
        const { filters } = this.state;

        const properties = [
            {
                key: 'createdBy',
                value: intl.formatMessage(messages.createdBy),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'createdAt',
                value: intl.formatMessage(messages.createdAt),
                type: filterPropertyTypes.DATE,
            },
            {
                key: 'type',
                value: intl.formatMessage(messages.type),
                type: filterPropertyTypes.ENUM,
                enums: [
                    { key: 1, value: 'Debug' },
                    { key: 2, value: 'Info' },
                    { key: 3, value: 'Warning' },
                    { key: 4, value: 'Error' },
                    { key: 5, value: 'Fatal' },
                ],
            },
        ];

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.createdAt)}</span>
                    </div>
                ),
                accessor: 'createdAt',
                Cell: (row) => {
                    return new Date(row.value).toLocaleString(
                        navigator.language,
                    );
                },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.createdBy)}</span>
                    </div>
                ),
                accessor: 'createdBy',
            },

            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.type)}</span>
                    </div>
                ),
                accessor: 'type',
                Cell: (row) => row.value,
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.message)}</span>
                    </div>
                ),
                accessor: 'message',
            },
        ];

        return (
            <>
                <header className='table-screen'>
                    <section className='title' />
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingTechnicalLogsList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingTechnicalLogsList
                                    ? 'loading'
                                    : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                    </section>
                </header>
                <EvtTable
                    filters={filters}
                    columns={columns}
                    data={technicalLogs}
                    length={technicalLogsCount}
                    pageSize={10}
                    handleStateChange={this.handleEvTableStateChange}
                    handleGetTrProps={this.handleGetTrProps}
                    loading={loadingTechnicalLogsList}
                    defaultSorted={[
                        {
                            id: 'createdAt',
                            desc: true,
                        },
                    ]}
                />
                <JsonTreeModal
                    isOpen={this.state.showTechnicalLogModal}
                    handleToggle={this.handleTechnicalLogToggleModal}
                    data={this.state.technicalLog}
                    darkTheme={false}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        technicalLogs: state.logReducer.technicalLogs || [],
        technicalLogsCount: state.logReducer.technicalLogsCount,
        loadingTechnicalLogsList: state.logReducer.loadingTechnicalLogsList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTechnicalLogs: (filters) =>
            dispatch(actions.initTechnicalLogs(filters)),
        onInitTechnicalLogsCount: (filters) =>
            dispatch(actions.initTechnicalLogsCount(filters)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TechnicalLogsTable),
);
