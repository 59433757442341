import * as actionTypes from '../actions/actionTypes';

const initialState = {
    contractors: [],
    contractor: null,
    loadingDetails: true,
    loadingList: false,
    loadingContractorLayout: false,
    contractorsCount: 0,
    loadingNormalLogo: false,
    loadingSmallLogo: false,
    loadingCustomerAppLogo: false,
    loadingAttachment: false,
};

const setContractors = (state, action) => {
    return {
        ...state,
        contractors: action.contractors,
        loadingList: false,
    };
};

const setContractorsRequest = (state, action) => {
    return {
        ...state,
        contractors: action.contractors,
        loadingList: true,
    };
};

const setContractorDetails = (state, action) => {
    return {
        ...state,
        contractor: action.contractor,
        loadingDetails: false,
    };
};

const setContractorDetailsPost = (state, action) => {
    return {
        ...state,
        contractor: action.contractor,
        loadingDetails: false,
        contractors: [],
    };
};

const setContractorDetailsNew = (state) => {
    return {
        ...state,
        contractor: undefined,
        loadingDetails: false,
    };
};

const setContractorDetailsRequest = (state) => {
    return {
        ...state,
        contractor: undefined,
        loadingDetails: true,
    };
};

const setContractorsCount = (state, action) => {
    return {
        ...state,
        contractorsCount: action.contractorsCount,
    };
};

const setUploadNormalLogoRequest = (state) => {
    return {
        ...state,
        loadingNormalLogo: true,
    };
};

const setUploadSmallLogoRequest = (state) => {
    return {
        ...state,
        loadingSmallLogo: true,
    };
};

const setUploadCustomerAppLogoRequest = (state) => {
    return {
        ...state,
        loadingCustomerAppLogo: true,
    };
};

const setUploadAttachmentRequest = (state) => {
    return {
        ...state,
        loadingAttachment: true,
    };
};

const setUploadNormalLogo = (state) => {
    return {
        ...state,
        loadingNormalLogo: false,
        normalLogoFileId: `${state.normalLogoFileId}`,
    };
};

const setUploadSmallLogo = (state) => {
    return {
        ...state,
        loadingSmallLogo: false,
        smallLogoFileId: `${state.smallLogoFileId}`,
    };
};

const setUploadCustomerAppLogo = (state) => {
    return {
        ...state,
        loadingCustomerAppLogo: false,
        customerAppLogoFileId: `${state.customerAppLogoFileId}`,
    };
};

const setUploadAttachment = (state) => {
    return {
        ...state,
        loadingAttachment: false,
        attachmentFileId: `${state.attachmentFileId}`,
    };
};

const setUploadNormalLogoFailure = (state) => {
    return {
        ...state,
        loadingNormalLogo: false,
    };
};

const setUploadSmallLogoFailure = (state) => {
    return {
        ...state,
        loadingSmallLogo: false,
    };
};

const setUploadCustomerAppLogoFailure = (state) => {
    return {
        ...state,
        loadingCustomerAppLogo: false,
    };
};

const setUploadAttachmentFailure = (state) => {
    return {
        ...state,
        loadingAttachment: false,
    };
};

const setContractorLayout = (state, action) => {
    return {
        ...state,
        contractorlayout: action.contractorLayout,
        loading: false,
        loadingContractorLayout: false,
    };
};

const setContractorLayoutRequest = (state) => {
    return {
        ...state,
        contractorlayout: undefined,
        loading: false,
        loadingContractorLayout: true,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CONTRACTORS_GET_COUNT_FAILURE:
        case actionTypes.CONTRACTORS_GET_COUNT_REQUEST:
        case actionTypes.CONTRACTORS_GET_COUNT_SUCCESS:
            return setContractorsCount(state, action);

        case actionTypes.CONTRACTORS_GET_LIST_REQUEST:
            return setContractorsRequest(state, action);

        case actionTypes.CONTRACTORS_GET_LIST_FAILURE:
        case actionTypes.CONTRACTORS_GET_LIST_SUCCESS:
            return setContractors(state, action);

        case actionTypes.CONTRACTOR_PUT_REQUEST:
            return setContractorDetailsRequest(state, action);

        case actionTypes.CONTRACTOR_PUT_SUCCESS:
        case actionTypes.CONTRACTOR_PUT_FAILURE:
            return setContractorDetails(state, action);

        case actionTypes.CONTRACTOR_GET_DETAILS_FAILURE:
        case actionTypes.CONTRACTOR_GET_DETAILS_SUCCESS:
            return setContractorDetails(state, action);

        case actionTypes.CONTRACTOR_POST_SUCCESS:
        case actionTypes.CONTRACTOR_POST_FAILURE:
            return setContractorDetailsPost(state, action);

        case actionTypes.CONTRACTOR_POST_REQUEST:
        case actionTypes.CONTRACTOR_GET_DETAILS_REQUEST:
            return setContractorDetailsRequest(state, action);

        case actionTypes.CONTRACTOR_GET_DETAILS_NEW:
            return setContractorDetailsNew(state, action);

        case actionTypes.CONTRACTOR_UPLOAD_NORMAL_LOGO_REQUEST:
            return setUploadNormalLogoRequest(state, action);

        case actionTypes.CONTRACTOR_UPLOAD_SMALL_LOGO_REQUEST:
            return setUploadSmallLogoRequest(state, action);

        case actionTypes.CONTRACTOR_UPLOAD_ATTACHMENT_REQUEST:
            return setUploadAttachmentRequest(state, action);

        case actionTypes.CONTRACTOR_UPLOAD_NORMAL_LOGO_SUCCESS:
            return setUploadNormalLogo(state, action);

        case actionTypes.CONTRACTOR_UPLOAD_SMALL_LOGO_SUCCESS:
            return setUploadSmallLogo(state, action);

        case actionTypes.CONTRACTOR_UPLOAD_ATTACHMENT_SUCCESS:
            return setUploadAttachment(state, action);

        case actionTypes.CONTRACTOR_UPLOAD_NORMAL_LOGO_FAILURE:
            return setUploadNormalLogoFailure(state, action);

        case actionTypes.CONTRACTOR_UPLOAD_SMALL_LOGO_FAILURE:
            return setUploadSmallLogoFailure(state, action);

        case actionTypes.CONTRACTOR_UPLOAD_CUSTOMERAPP_LOGO_FAILURE:
            return setUploadCustomerAppLogoFailure(state, action);

        case actionTypes.CONTRACTOR_UPLOAD_CUSTOMERAPP_LOGO_SUCCESS:
            return setUploadCustomerAppLogo(state, action);

        case actionTypes.CONTRACTOR_UPLOAD_CUSTOMERAPP_LOGO_REQUEST:
            return setUploadCustomerAppLogoRequest(state, action);

        case actionTypes.CONTRACTOR_UPLOAD_ATTACHMENT_FAILURE:
            return setUploadAttachmentFailure(state, action);

        case actionTypes.CONTRACTORS_GET_LAYOUT_SUCCESS:
            return setContractorLayout(state, action);

        case actionTypes.CONTRACTORS_GET_LAYOUT_REQUEST:
            return setContractorLayoutRequest(state, action);

        case actionTypes.CONTRACTOR_SET_SUCCESS:
            return setContractorDetails(state, action);

        default:
            return state;
    }
};

export default reducer;
