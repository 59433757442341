/* eslint-disable no-empty-pattern */
/* eslint-disable no-empty */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Container, Row, Col, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import * as actions from '../../store/actions/index';
import Header from '../../components/Header/Header';
import UserDetailsForm from '../../components/UserDetailsForm/UserDetailsForm';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './UserDetailsPage.intl';
import { AuthContext } from '../../context/AuthContext';
import EvtSelect from '../../components/EvtSelect/EvtSelect';
import { CLIENT_ACCESS_TYPE } from '../../utils/constants';
import PasswordStrengthMeter from '../../components/PasswordStrengthMeter/PasswordStrengthMeter';

class UserDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        showNewAccessModal: false,
        showContractorComboBox: false,
        showRoleComboBox: false,
        showNewPasswordConfirmationModal: false,
        showGenerateNewPasswordModal: false,
        showCpfCnpj: false,
        isClient: false,
        newPassword: '',
    };

    componentDidMount() {
        this.props.onInitUserDetails(this.props.match.params.id);
        this.props.onInitBillingOffice();

        const { contractor } = this.context;

        if (this.context.claims.length === 0) return;

        if (this.context.hasClaim('role-level-full')) {
            this.setState({
                showContractorComboBox: true,
                showRoleComboBox: false,
            });
        } else if (this.context.hasClaim('role-level-contractor')) {
            this.setState({
                showContractorComboBox: false,
                showRoleComboBox: true,
            });

            this.props.onSetSelectedContractor({
                contractorId: contractor.contractorId,
                displayName: contractor.displayName,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { user, session, emailSent } = this.props;

        if (prevProps.session !== session) {
            this.props.onInitCurrentSession();
        }

        if (prevProps.emailSent !== emailSent) {
            if (user?.userId === session?.userId && emailSent) {
                this.props.onLogout();
            }
        }
    }

    handleSubmit = (event, values) => {
        if (this.props.loading) return;

        values.accesses = this.props.accesses;
        if (values.username === '') values.username = values.emailAddress;

        if (this.props.match.params.id === 'new') {
            this.props.onCreateUser(values);
        } else {
            this.props.onUpdateUser(this.props.user.userId, values);
        }
    };

    handleSubmitAccess = () => {
        this.props.onUpdateUser(this.props.user.userId, this.props.user);
    };

    handleNewAccessModalToogle = () => {
        const { contractor } = this.props;
        if (contractor) {
            contractor.contractorId &&
                this.props.onInitRolesByContractor(contractor.contractorId);
        }
        let state = {
            showContractorComboBox: true,
            showRoleComboBox: false,
        };

        if (this.context.hasClaim('role-level-full')) {
        } else if (this.context.hasClaim('role-level-contractor')) {
            state = {
                ...state,
                showContractorComboBox: false,
                showRoleComboBox: true,
            };
        } else {
            state = {
                ...state,
                showContractorComboBox: false,
                showRoleComboBox: true,
            };
        }

        this.setState({
            ...state,
            showNewAccessModal: !this.state.showNewAccessModal,
            showCpfCnpj: false,
        });
    };

    handleNewPasswordModalToogle = () => {
        this.setState({
            showNewPasswordConfirmationModal: !this.state
                .showNewPasswordConfirmationModal,
        });
    };

    handleSendNewPasswordButtonClick = () => {
        const { user } = this.props;
        this.props.onSendNewPassword(user);

        this.handleNewPasswordModalToogle();
    };

    handleGenerateNewPasswordButtonToggle = () => {
        this.setState({
            showGenerateNewPasswordModal: !this.state
                .showGenerateNewPasswordModal,
        });
    };

    handleGenerateNewPasswordButtonClick = () => {
        this.handleGenerateNewPasswordButtonToggle();
    };

    handleDelete = () => {
        const { userId } = this.props.user;
        if (userId) {
            this.props.onDeleteUser(userId);
        } else {
            toast.error('Não foi possível identificar o usuário para exclusão');
        }
    };

    handleContractorChange = (selectedContractor) => {
        if (selectedContractor) {
            this.props.onSetSelectedContractor({
                contractorId: selectedContractor.value,
                displayName: selectedContractor.label,
            });

            this.setState({
                showRoleComboBox: true,
            });
        }
        selectedContractor.value &&
            this.props.onInitRolesByContractor(selectedContractor.value);
    };

    handleRoleChange = (selectedRole) => {
        if (selectedRole) {
            this.props.onSetSelectedRole({
                roleId: selectedRole.value,
                name: selectedRole.label,
            });
            const isClient = selectedRole.data.type === CLIENT_ACCESS_TYPE;
            this.setState({
                showCpfCnpj: true,
                isClient,
            });
        }
        /* let accessType;
        for (let i = 0; i < this.props.rolesContractor.length; i++) {
            if (this.props.rolesContractor[i].roleId === values.roleId) {
                accessType = this.props.rolesContractor[i].accessType;
            }
        } */
    };

    handleRemoveAccessButtonClick = (accessToDelete) => {
        this.props.onRemoveUserAccess(accessToDelete);
    };

    handleAddAccessModalSubmit = (event, values) => {
        const { accesses, contractor, role } = this.props;

        if (this.context.hasClaim('role-level-contractor')) {
            values = {
                ...values,
            };
        }

        const found =
            accesses &&
            accesses.find(
                (m) =>
                    m.roleId === values.roleId &&
                    m.contractorId === values.contractorId,
            );

        if (found) {
            toast.error(this.props.intl.formatMessage(messages.roleExists));
            return;
        }

        const access = {
            contractorId: contractor?.contractorId,
            contractorName: contractor?.displayName,
            roleId: role?.roleId,
            roleName: role?.name,
        };

        // Validate type of Access (Company or Client)
        if (values.clients) {
            access.companies = [];
            access.clients = [values.clients];
        } else if (values.companies) {
            access.companies = [values.companies];
            access.clients = [];
        }

        this.props.onAddUserAccess(access);

        this.setState({
            showRoleComboBox: false,
            showCpfCnpj: false,
            showNewAccessModal: !this.state.showNewAccessModal,
        });
    };

    handleVerifyNewPassword = (event, values) => {
        const { newPassword, confirmNewPassword } = values;

        if (newPassword !== confirmNewPassword) {
            toast.error(this.props.intl.formatMessage(messages.passwordWrong));
            // eslint-disable-next-line no-useless-return
            return;
        }

        const { user } = this.props;

        this.props.onGenerateNewPassword(user.userId, newPassword);
        this.handleGenerateNewPasswordButtonToggle();
    };

    handleChangeNewPassword = (event) => {
        const { id, value } = event.target;
        this.setState({
            [id]: value,
        });
    };

    render() {
        const {
            intl,
            contractor,
            billingOffices,
            contractorsId,
            user,
        } = this.props;
        const { isClient } = this.state;
        const { contractorId } = contractor || {};
        const { billingOfficeId, billingOfficeName } = user || {};

        const id = this.props.match.params.id;

        const canCreateUser =
            this.context.hasClaim('user-create') && id === 'new';
        const canDeleteUser =
            this.context.hasClaim('user-delete') && id !== 'new';
        const canUpdateUser =
            this.context.hasClaim('user-update') && id !== 'new';
        const canResetPassword =
            this.context.hasClaim('user-reset-password-request-create') &&
            id !== 'new';
        const canGenerateNewPassword =
            this.context.hasClaim('user-change-password') && id !== 'new';
        const canSetNewAccess = this.context.hasClaim('role-view');
        const canEditFields = id === 'new';

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/users',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/users/:id',
                            name: intl.formatMessage(messages.titleUser, {
                                entity: this.props.user && this.props.user.name,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header className='row'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.titleUser, {
                                entity: '',
                            })}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content roles-form'>
                    <Loading loading={false} />
                    <UserDetailsForm
                        key={this.props.user}
                        user={this.props.user}
                        handleSubmit={this.handleSubmit}
                        handleToggle={this.handleToggleModal}
                        handleDelete={this.handleDelete}
                        accesses={this.props ? this.props.accesses : []}
                        canCreateUser={canCreateUser}
                        canDeleteUser={canDeleteUser}
                        canUpdateUser={canUpdateUser}
                        canResetPassword={canResetPassword}
                        canSetNewAccess={canSetNewAccess}
                        canGenerateNewPassword={canGenerateNewPassword}
                        canEditFields={canEditFields}
                        handleNewAccessButtonClick={
                            this.handleNewAccessModalToogle
                        }
                        handleGenerateNewPasswordButtonClick={
                            this.handleGenerateNewPasswordButtonToggle
                        }
                        handleNewPasswordButtonClick={
                            this.handleNewPasswordModalToogle
                        }
                        handleRemoveAccessButtonClick={
                            this.handleRemoveAccessButtonClick
                        }
                        loading={this.props.loadingDetails}
                        billingOffices={billingOffices}
                        selectedBillingOffice={{
                            billingOfficeId,
                            billingOfficeName,
                        }}
                        contractorsId={contractorsId}
                        isSystem={this.context.contractor.isSystem}
                    />
                </section>
                <Modal
                    key='newPasswordConfirmationModal'
                    title={intl.formatMessage(
                        messages.newPasswordConfirmationModalTitle,
                    )}
                    isOpen={this.state.showNewPasswordConfirmationModal}
                    handleToggle={this.handleNewPasswordModalToogle}
                    type={modalTypes.WARNING}
                    hasFooter
                    firstButtonText={intl.formatMessage(messages.yesText)}
                    secondButtonText={intl.formatMessage(messages.noText)}
                    secondButtonClass='delete'
                    toggleFirstButton={this.handleSendNewPasswordButtonClick}
                    toggleSecondButton={this.handleNewPasswordModalToogle}
                >
                    {intl.formatMessage(
                        messages.newPasswordConfirmationModalText,
                    )}
                </Modal>
                <Modal
                    title={intl.formatMessage(messages.modalTitle)}
                    isOpen={this.state.showNewAccessModal}
                    handleToggle={this.handleNewAccessModalToogle}
                >
                    <AvForm onValidSubmit={this.handleAddAccessModalSubmit}>
                        <Container fluid>
                            <Row>
                                {this.state.showContractorComboBox && (
                                    <Col xs={12} className='text-left mt-3'>
                                        <EvtSelect
                                            label={intl.formatMessage(
                                                messages.company,
                                            )}
                                            name='contractorId'
                                            url='/Contractors'
                                            labelKey='displayName'
                                            valueKey='contractorId'
                                            async
                                            required
                                            handleSelectedOptionChange={
                                                this.handleContractorChange
                                            }
                                        />
                                    </Col>
                                )}
                                {this.state.showRoleComboBox && (
                                    <Col xs={12} className='text-left mt-3'>
                                        <EvtSelect
                                            label={intl.formatMessage(
                                                messages.role,
                                            )}
                                            name='roleId'
                                            url={
                                                contractorId &&
                                                `/Contractors/${contractorId}/Roles`
                                            }
                                            labelKey='name'
                                            valueKey='roleId'
                                            async
                                            required
                                            key={`${contractorId}roleId`}
                                            handleSelectedOptionChange={
                                                this.handleRoleChange
                                            }
                                        />
                                    </Col>
                                )}
                                {this.state.showCpfCnpj && (
                                    <Col>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.cpfCnpj,
                                                )}
                                                type='text'
                                                name={
                                                    isClient
                                                        ? 'clients'
                                                        : 'companies'
                                                }
                                                id={
                                                    isClient
                                                        ? 'clients'
                                                        : 'companies'
                                                }
                                                value=''
                                                errorMessage=''
                                                validate={{
                                                    required: {
                                                        value: !!isClient,
                                                    },
                                                    pattern: {
                                                        value:
                                                            '^([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}|[0-9]{11}|[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}|[0-9]{11}|[0-9]{14})$',
                                                        errorMessage: intl.formatMessage(
                                                            messages.invalidCNPJ,
                                                        ),
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                )}
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12}>
                                    <button className='btn-submit'>
                                        {intl.formatMessage(messages.create)}
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </AvForm>
                </Modal>
                <Modal
                    title={intl.formatMessage(
                        messages.generateNewPasswordModalTitle,
                    )}
                    isOpen={this.state.showGenerateNewPasswordModal}
                    handleToggle={this.handleGenerateNewPasswordButtonToggle}
                >
                    <AvForm onValidSubmit={this.handleVerifyNewPassword}>
                        <Container fluid>
                            <Row>
                                <Col xs={12} className='text-left mt-3'>
                                    <AvField
                                        label={intl.formatMessage(
                                            messages.newPassword,
                                        )}
                                        type='password'
                                        id='newPassword'
                                        name='newPassword'
                                        onChange={this.handleChangeNewPassword}
                                        value=''
                                        errorMessage=''
                                    />
                                </Col>
                                <Col xs={12} className='text-left mt-3'>
                                    <AvField
                                        label={intl.formatMessage(
                                            messages.confirmNewPassword,
                                        )}
                                        type='password'
                                        id='confirmNewPassword'
                                        name='confirmNewPassword'
                                        value=''
                                        errorMessage=' '
                                        required
                                        validate={{
                                            match: {
                                                value: 'newPassword',
                                                errorMessage:
                                                    'As senhas não são iguais',
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                            <PasswordStrengthMeter
                                password={this.state.newPassword}
                            />
                            <Row className='mt-2'>
                                <Col xs={12}>
                                    <button className='btn-submit'>
                                        {intl.formatMessage(messages.generate)}
                                    </button>
                                </Col>
                            </Row>
                        </Container>
                    </AvForm>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        loadingDetails: state.userReducer.loadingDetails,
        billingOffices: state.billingOfficeReducer.billingOffices ?? [],
        contractors: state.contractorReducer.contractors || [],
        roles: state.roleReducer.roles || [],
        rolesContractor: state.roleReducer.rolesContractor || [],
        userContractors: state.contractorReducer.contractors || [],
        accesses: state.userReducer.accesses,
        contractorsId: state.userReducer.contractorsId || [],
        session: state.authenticationReducer.session,
        emailSent: state.userReducer.emailSent,
        contractor: state.contractorReducer.contractor,
        role: state.roleReducer.role,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitUserDetails: (userId) =>
            dispatch(actions.initUserDetails(userId)),
        onInitBillingOffice: () =>
            dispatch(actions.initBillingOfficersByContractId()),
        onCreateUser: (values) => dispatch(actions.createUser(values)),
        onUpdateUser: (userId, values) =>
            dispatch(actions.updateUser(userId, values)),
        onDeleteUser: (userId) => dispatch(actions.deleteUser(userId)),
        onGetUserContractors: () => dispatch(actions.getUserContractors()),
        onAddUserAccess: (newAccess) =>
            dispatch(actions.addUserAccess(newAccess)),
        onRemoveUserAccess: (removeAccess) =>
            dispatch(actions.removeUserAccess(removeAccess)),
        onSendNewPassword: (user) => dispatch(actions.sendNewPassword(user)),
        onGenerateNewPassword: (userId, newPassword) =>
            dispatch(actions.generateNewPassword(userId, newPassword)),
        onLogout: () => dispatch(actions.logout()),
        onInitCurrentSession: () => dispatch(actions.getCurrentSession()),
        onSetSelectedContractor: (selectedContractor) =>
            dispatch(actions.setSelectedContractor(selectedContractor)),
        onSetSelectedRole: (selectedRole) =>
            dispatch(actions.setSelectedRole(selectedRole)),
        onInitRolesByContractor: (selectedContractor) =>
            dispatch(actions.initRolesByContractor(selectedContractor)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(UserDetailsPage),
);
