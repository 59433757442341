import { toast } from 'react-toastify';
import axios from 'axios';
import { push } from 'connected-react-router';
import * as actionTypes from './actionTypes';

export const initContacts = (filter = '') => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CONTACTS_GET_LIST_REQUEST,
        });

        axios
            .get(`/Contacts${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.CONTACTS_GET_LIST_SUCCESS,
                    contacts: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.CONTACTS_GET_LIST_FAILURE,
                    error,
                });
            });
    };
};

export const initContactsCount = (filter) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.CONTACTS_GET_COUNT_REQUEST,
        });

        await axios
            .get(`/Contacts/Count${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.CONTACTS_GET_COUNT_SUCCESS,
                    contactsCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.CONTACTS_GET_COUNT_FAILURE,
                    error,
                });
            });
    };
};

export const initContactDetails = (contactId) => {
    if (contactId === 'new') {
        return (dispatch) => {
            dispatch({
                type: actionTypes.CONTACT_GET_DETAILS_NEW,
            });
        };
    }
    return (dispatch) => {
        dispatch({
            type: actionTypes.CONTACT_GET_DETAILS_REQUEST,
        });

        axios
            .get(`/Contacts/${contactId}`)
            .then(function(response) {
                dispatch({
                    type: actionTypes.CONTACT_GET_DETAILS_SUCCESS,
                    contact: response.data,
                });
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.CONTACT_GET_DETAILS_FAILURE,
                    error,
                });
            });
    };
};

export const createContact = (values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CONTACT_POST_REQUEST,
        });

        axios
            .post(`/Contacts`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.CONTACT_POST_SUCCESS,
                });
                toast.success('Usuário criado com sucesso');
                dispatch(push('/contacts'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.CONTACT_POST_FAILURE,
                    error,
                });
            });
    };
};

export const updateContact = (contactId, values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CONTACT_PUT_REQUEST,
        });

        axios
            .put(`/Contacts/${contactId}`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.CONTACT_PUT_SUCCESS,
                });
                toast.success('Usuário alterado com sucesso');
                dispatch(push('/contacts'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.CONTACT_PUT_FAILURE,
                    error,
                });
            });
    };
};

export const deleteContact = (contactId) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CONTACT_DELETE_REQUEST,
        });

        axios
            .delete(`/Contacts/${contactId}`)
            .then(function() {
                dispatch({
                    type: actionTypes.CONTACT_DELETE_SUCCESS,
                });
                toast.success('Usuário desativado com sucesso');
                dispatch(push('/contacts'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.CONTACT_DELETE_FAILURE,
                    error,
                });
            });
    };
};

export const addContactAccess = (newAccess) => {
    return (dispatch, getState) => {
        let accesses = [];

        if (getState().contactReducer.accesses) {
            accesses = getState().contactReducer.accesses;
        }

        accesses.push(newAccess);

        dispatch({
            type: actionTypes.CONTACT_ADD_ACCESS,
            accesses,
        });
    };
};

export const removeContactAccess = (removeAccess) => {
    return (dispatch, getState) => {
        let accesses = [];

        if (getState().contactReducer.accesses) {
            accesses = getState().contactReducer.accesses;
        }

        const acessUpdate = [];

        for (let i = 0, len = accesses.length; i < len; i++) {
            if (
                accesses[i].roleId !== removeAccess.roleId ||
                accesses[i].contractorId !== removeAccess.contractorId
            ) {
                acessUpdate.push(accesses[i]);
            }
        }

        dispatch({
            type: actionTypes.CONTACT_ADD_ACCESS,
            accesses: acessUpdate,
        });
    };
};

export const sendNewPassword = (contact) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CONTACT_SEND_PASSWORD_REQUEST,
        });

        const { emailAddress } = contact;

        return axios
            .post(`/Contacts/${contact.contactId}/ResetPasswordRequests`, {
                emailAddress,
                clientType: 'Web',
                clientVersion: process.env.REACT_APP_VERSION,
            })
            .then(() => {
                dispatch({
                    type: actionTypes.CONTACT_SEND_PASSWORD_SUCCESS,
                });

                toast.success(
                    'E-mail de alteração de senha enviado com sucesso.',
                );
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.CONTACT_SEND_PASSWORD_FAILURE,
                    error,
                });
            });
    };
};

export const uploadContactAttachment = (file) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CONTACT_UPLOAD_ATTACHMENT_REQUEST,
        });

        const data = new FormData();
        data.append('file', file);

        axios
            .post(`/Contacts/PostExcel`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(() => {
                dispatch({
                    type: actionTypes.CONTACT_UPLOAD_ATTACHMENT_SUCCESS,
                });

                toast.success('Os contatos foram atualizadas com sucesso.');
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.CONTACT_UPLOAD_ATTACHMENT_FAILURE,
                    error,
                });
                console.log(error);
            });
    };
};
