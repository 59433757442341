import React from 'react';

const checkBox = (props) => {
    return (
        <div className='form-check form-check-inline'>
            <input
                type='checkbox'
                className='is-untouched is-pristine av-valid form-check-input'
                value={props.value}
                id={props.id}
                key={props.id}
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.isReadOnly}
            />
            <label htmlFor={props.id} className='form-check-label'>
                {props.label}
            </label>
        </div>
    );
};

export default checkBox;
