import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'financialdocuments.title',
        defaultMessage: 'Clientes {entity}',
    },

    subtitle: {
        id: 'financialdocuments.subtitle',
        defaultMessage: ' {length} documento(s) encontrado(s)',
    },

    titleOnlyFinancialDocuments: {
        id: 'financialdocuments.titleOnlyFinancialDocuments',
        defaultMessage: 'Documentos financeiros',
    },

    referenceDocument: {
        id: 'financialdocuments.referenceDocument',
        default: 'Documento',
    },

    documentDate: {
        id: 'financialdocuments.documentDate',
        default: 'Data emissão',
    },

    dueDate: {
        id: 'financialdocuments.dueDate',
        default: 'Data vencimento',
    },

    documentValue: {
        id: 'financialdocuments.documentValue',
        default: 'Valor documento',
    },

    statusDescription: {
        id: 'financialdocuments.statusDescription',
        default: 'Status',
    },

    files: {
        id: 'financialdocuments.files',
        defaultMessage: 'Arquivos',
    },

    actions: {
        id: 'financialdocuments.actions',
        defaultMessage: 'Ações',
    },

    boleto: {
        id: 'financialdocuments.boleto',
        defaultMessage: 'Boleto',
    },

    clientCpfCnpj: {
        id: 'financialdocuments.clientCpfCnpj',
        defaultMessage: 'CPF/CNPJ',
    },
    pay: {
        id: 'financialdocuments.pay',
        defaultMessage: 'Pagar',
    },

    notafiscal: {
        id: 'financialdocuments.notafiscal',
        defaultMessage: 'Nota Fiscal',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
});

export default messages;
