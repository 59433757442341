import React, { Component } from 'react';

import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from './ParameterListPage.intl';
import * as actions from '../../store/actions/index';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import EvtTable, {
    calculateColumnWidth,
} from '../../components/EvtTable/EvtTable';
import EvtFilter from '../../components/EvtFilter/EvtFilter';
import { filterPropertyTypes } from '../../utils/enums';
import { AuthContext } from '../../context/AuthContext';

class ParameterListPage extends Component {
    static contextType = AuthContext;

    state = {
        filters: [],
    };

    reloadParametersList = () => {
        const { query } = this.state;

        this.props.onInitParameters(query);
        this.props.onInitParametersCount(query);
    };

    handleGetTrProps = (state, rowInfo) => {
        return {
            style: {
                cursor: 'pointer',
            },
            onClick: () =>
                this.props.history.push(`/parameters/${rowInfo.original.id}`),

            /*  className: !rowInfo.original.isAvailable ? 'disabled-row' : '',
            title: !rowInfo.original.isAvailable
                ? intl.formatMessage(messages.unavailableparameter)
                : '', */
        };
    };

    handleNewClick = () => {
        this.props.history.push(`/parameters/new`);
    };

    handleRefreshButtonClick = () => {
        this.reloadParametersList();
    };

    handleFiltersChange = (filters) => {
        this.setState({
            filters,
        });
    };

    handleEvTableStateChange = (query) => {
        this.setState(
            {
                query,
            },
            () => {
                this.reloadParametersList();
            },
        );
    };

    render() {
        const { intl, loadingList, parameters, parametersCount } = this.props;
        const { filters } = this.state;

        const newParameterButtonEnabled = this.context.hasClaim(
            'parameter-create',
        );
        const properties = [
            {
                key: 'name',
                value: intl.formatMessage(messages.parameterName),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'description',
                value: intl.formatMessage(messages.parameterDescription),
                type: filterPropertyTypes.TEXT,
            },
            {
                key: 'low',
                value: intl.formatMessage(messages.parameterLow),
                type: filterPropertyTypes.TEXT,
            },
        ];

        const columns = [
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.parameterName)}
                        </span>
                    </div>
                ),
                accessor: 'name',
                minWidth: calculateColumnWidth(
                    parameters,
                    'name',
                    intl.formatMessage(messages.parameterName),
                ),
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>
                            {intl.formatMessage(messages.parameterDescription)}
                        </span>
                    </div>
                ),
                accessor: 'description',
                maxWidth: 400,
                minWidth: 400,
                style: { overflow: 'visible' },
            },
            {
                Header: () => (
                    <div className='header-table-title'>
                        <span>{intl.formatMessage(messages.parameterLow)}</span>
                    </div>
                ),
                accessor: 'low',
                maxWidth: 300,
                style: { overflow: 'visible' },
            },
        ];

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/parameters',
                            name: intl.formatMessage(messages.title),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.title)}
                            subtitle={
                                this.props.parametersCount +
                                intl.formatMessage(messages.subtitle)
                            }
                        />
                    </section>
                    <section className='btns-topo'>
                        <EvtFilter
                            properties={properties}
                            handleFiltersChange={this.handleFiltersChange}
                            loading={loadingList}
                        />
                        <button
                            className={`new-btn small ${
                                this.props.loadingList ? 'loading' : ''
                            }`}
                            onClick={this.handleRefreshButtonClick}
                        >
                            <FontAwesomeIcon icon='sync' size='1x' />
                        </button>
                        {newParameterButtonEnabled && (
                            <button
                                className='new-btn'
                                onClick={this.handleNewClick}
                            >
                                {intl.formatMessage(messages.importButtonText, {
                                    entity: intl.formatMessage(
                                        messages.titleParameter,
                                        {
                                            entity: '',
                                        },
                                    ),
                                })}
                            </button>
                        )}
                    </section>
                </header>
                <section className='content-middle'>
                    <EvtTable
                        filters={filters}
                        columns={columns}
                        data={parameters}
                        length={parametersCount}
                        pageSize={10}
                        handleStateChange={this.handleEvTableStateChange}
                        handleGetTrProps={this.handleGetTrProps}
                        loading={loadingList}
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        parameters: state.parameterReducer.parameters || [],
        parametersCount: state.parameterReducer.parametersCount || 0,
        loadingList: state.parameterReducer.loadingList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitParameters: (filter) => dispatch(actions.initParameters(filter)),
        onInitParametersCount: (filter) =>
            dispatch(actions.initParametersCount(filter)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ParameterListPage),
);
