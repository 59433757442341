import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    contractorName: {
        id: 'widgetTable.contractorName',
        defaultMessage: 'Contratante',
    },

    supplierName: {
        id: 'widgetTable.supplierName',
        defaultMessage: 'Fornecedor',
    },

    dueDate: {
        id: 'widgetTable.dueDate',
        defaultMessage: 'Dt. Venc',
    },

    value: {
        id: 'widgetTable.value',
        defaultMessage: 'Valor',
    },

    paymentsDueList: {
        id: 'widgetTable.paymentsDueList',
        defaultMessage: 'Pagamentos pendentes',
    },
});
