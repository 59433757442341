import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    valuesByPaymentOperator: {
        id: 'dashboard.valuesByPaymentOperator',
        defaultMessage: 'Vallues By Payment Operator',
    },
});
