import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import './BillingVolumeChart.css';
import { injectIntl } from 'react-intl';

import messages from './BillingVolumeChart.intl';

const BillingVolumeChart = (props) => {
    const { intl } = props;
    const colors = props.colors;
    const data = props.data.data.data.map((data) => {
        const tratada = { date: data.date };
        for (let i = 0; i < data.values.length; i++) {
            tratada[data.values[i].name] = data.values[i].value;
        }
        return tratada;
    });
    const values = props.data.data.dataValueNames;
    const tickFormatter = (number) => {
        if (number > 1000000000) {
            return `$${(number / 1000000000).toString()}B`;
        }
        if (number > 1000000) {
            return `$${(number / 1000000).toString()}M`;
        }
        if (number > 1000) {
            return `$${(number / 1000).toString()}K`;
        }
        return `$${number.toString()}`;
    };
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className='custom-tooltip'>
                    <p>{`${payload[0].payload.date}`}</p>
                    {payload.map((payload) => {
                        const color = payload.color;
                        const valor = payload.payload.Valor.toLocaleString(
                            'pt-br',
                            { style: 'currency', currency: 'BRL' },
                        );
                        return payload.name === 'Valor' ? (
                            <p key={payload.name} style={{ color }}>{`${
                                payload.name
                            } : ${valor}`}</p>
                        ) : (
                            <p key={payload.name} style={{ color }}>{`${
                                payload.name
                            } : ${payload.value}`}</p>
                        );
                    })}
                </div>
            );
        }

        return null;
    };
    return (
        <>
            <div className='text'>
                <h1>{intl.formatMessage(messages.billingVolumeChart)}</h1>
            </div>

            <div className='chart chart-line'>
                <ResponsiveContainer
                    width={window.screen.width - window.screen.width * 0.3}
                    height={500}
                >
                    <ComposedChart
                        width={1000}
                        height={500}
                        data={data}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid stroke='#f5f5f5' />
                        <XAxis dataKey='date' scale='band' />
                        <YAxis dataKey={values[0]} />
                        <YAxis
                            tickFormatter={tickFormatter}
                            yAxisId='right'
                            dataKey={values[1]}
                            orientation='right'
                        />
                        <Tooltip content={CustomTooltip} />
                        <Legend wrapperStyle={{ bottom: 10 }} />
                        <Bar
                            name={values[0]}
                            dataKey={values[0]}
                            barSize={20}
                            fill={colors[0]}
                        />
                        <Line
                            name={values[1]}
                            yAxisId='right'
                            type='monotone'
                            dataKey={values[1]}
                            stroke={colors[1]}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </>
    );
};

export default injectIntl(withRouter(BillingVolumeChart));
