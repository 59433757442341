import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import * as actionType from './actionTypes';

export const initConfigurationGeneralSettings = (hasClaim) => {
    return (dispatch) => {
        dispatch({ type: actionType.SETTINGS_GENERAL_GET_REQUEST });
        if (hasClaim)
            axios
                .get('/settings')
                .then((response) => {
                    dispatch({
                        type: actionType.SETTINGS_GENERAL_GET_SUCCESS,
                        settings: response.data,
                    });
                })
                .catch(function(error) {
                    dispatch({
                        type: actionType.SETTINGS_GENERAL_GET_FAILURE,
                        error,
                    });
                });
    };
};

export const updateSettingsFromSubmit = (data) => {
    return (dispatch) => {
        dispatch({ type: actionType.SETTINGS_GENERAL_PUT_REQUEST });

        axios
            .put('/settings', data)
            .then(() => {
                toast.success('Configurações alteradas com sucesso');
                dispatch({
                    type: actionType.SETTINGS_GENERAL_PUT_SUCCESS,
                });
                dispatch(push('/home'));
            })
            .catch((error) => {
                const errorStatus = `${error.message.replace(/\D/g, '')}`;
                if (errorStatus === '400') toast.error(error.response.data);
                else toast.error('Ops! Não foi possível salvar as alterações');
                dispatch({
                    type: actionType.SETTINGS_GENERAL_PUT_FAILURE,
                    error,
                });
            });
    };
};
