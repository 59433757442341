/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { connect } from 'react-redux';
import DynamicFilterModal from '../DynamicFilterModal/DynamicFilterModal';
import SearchBar from '../SearchBar/SearchBar';

import { Operators } from '../../models/FilterBuilder';
import { filterPropertyTypes } from '../../utils/enums';

import * as actions from '../../store/actions/index';

function EvtFilter(props) {
    const defaultFilter = {
        showAddButton: true,
        property: '',
        operator: '',
        value: '',
        type: '',
        enums: [],
        id: 'firstFilter',
    };

    const [hideAddButton] = useState(props && !props.hideAddButton);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDynamicFilterModal, setShowDynamicFilterModal] = useState(false);
    const [filters, setFilters] = useState([defaultFilter]);

    useEffect(() => {
        loadPrevFilters();
    }, []);

    useEffect(() => {
        loadInicialFilters();
    }, [props?.filters]);

    const loadPrevFilters = () => {
        const path = window.location.pathname;
        if (
            typeof props.uiContext === 'undefined' ||
            typeof props.uiContext[path] === 'undefined' ||
            typeof props.uiContext[path].filters === 'undefined'
        )
            return;

        if (props.uiContext[path].filter.length > 0) {
            if (
                !props.uiContext[path].filter.some(
                    (filter) => filter.from === 'searchBar',
                )
            )
                setFilters(props.uiContext[path].filter);

            notifyFiltersChange(props.uiContext[path].filter, false);
        }
    };

    const loadInicialFilters = () => {
        if (props?.filters?.length > 0 && filters[0].property === '') {
            setFilters(props?.filters);
        }
    };

    function handleDynamicFilterModalToogle() {
        setShowDynamicFilterModal(!showDynamicFilterModal);
    }

    function handleSearchBarChange(newSearchTerm) {
        setSearchTerm(newSearchTerm);
    }

    function handleSearchBarSubmit(newSearchTerm) {
        setSearchTerm(newSearchTerm);

        const properties = props.properties || [];
        const filters = properties.map((p) => {
            return {
                property: p.key,
                operator: Operators.like,
                value: newSearchTerm
                    .replace(/#/g, '')
                    .replace(/&/g, '')
                    .replace(/[^a-zA-Z\0-9\u00C0-\u00FF@._-\s]/gi, ''),
                from: 'searchBar',
            };
        });

        filters.push({
            operator: Operators.or,
            property: Operators.or,
        });
        const path = window.location.pathname;

        if (path.includes('tarifications') || path.includes('transactions'))
            filters.push({ searchTerm });

        setFilters([defaultFilter]);
        props.updateCurrentFilter(window.location.pathname, filters);
        props.handleFiltersChange && props.handleFiltersChange(filters);
    }

    function notifyFiltersChange(filters, toggle = true) {
        setSearchTerm('');
        if (toggle) setShowDynamicFilterModal(!showDynamicFilterModal);

        props.handleFiltersChange && props.handleFiltersChange(filters);
    }

    const handleFormSubmit = (event, form) => {
        if (typeof form.filters === 'undefined') return;

        let filters = Object.keys(form.filters).map((filter) => {
            return form.filters[filter];
        });

        filters = filters.filter(
            (item) =>
                (item.value || item.valueFrom) &&
                (item.operator || item.valueFrom) &&
                item.property,
        );

        filters = filters.map((item) => {
            const rawProperty = props.properties.find(
                (collumn) => collumn.key === item.property,
            );

            if (item.value)
                item.value = item.value.replace(/#/g, '').replace(/&/g, '');

            if (rawProperty && typeof rawProperty.map === 'function') {
                return { ...item, value: rawProperty.map(item.value) };
            }

            return item;
        });

        const array = [];
        let newFilters = filters.map((filter) => {
            if (filter.valueFrom) {
                const valueFrom = new Date(
                    `${filter.valueFrom}T00:00:00`,
                ).toISOString();
                filter.operator = 'ge';
                filter.value = valueFrom;
                array.push({ ...filter });
            }
            if (filter.valueTo) {
                const valueTo = new Date(
                    `${filter.valueTo}T23:59:59`,
                ).toISOString();
                filter.operator = 'le';
                filter.value = valueTo;
            }
            if (filter.type === filterPropertyTypes.NUMBER) {
                filter.value = filter.value.replace(',', '.');
            }
            return filter;
        });
        newFilters = newFilters.concat(array);

        // Redux context
        props.updateCurrentFilter(
            window.location.pathname,
            newFilters,
            props.index,
        );

        notifyFiltersChange(newFilters);
    };

    const handleResetFilter = () => {
        setFilters([
            {
                showAddButton: true,
                property: '',
                operator: '',
                value: '',
                type: '',
                enums: [],
                id: Math.random()
                    .toString(36)
                    .substr(2, 9),
            },
        ]);

        // Redux context
        props.resetCurrentFilter(window.location.pathname);

        notifyFiltersChange([]);
    };

    const handleAddRemoveFilter = ({ id = '', showAddButton }) => {
        if (showAddButton) {
            const newFilters = [
                ...filters.map((item) => ({ ...item, showAddButton: false })),
                {
                    showAddButton: false,
                    property: '',
                    operator: '',
                    value: '',
                    type: '',
                    enums: [],
                    id: Math.random()
                        .toString(36)
                        .substr(2, 9),
                },
            ];
            // Redux context
            // this.props.updateCurrentFilter(window.location.pathname, newFilters)

            // UI and other business logic
            setFilters(newFilters);
        } else {
            const newFilters =
                filters.length === 1
                    ? [
                          {
                              showAddButton: true,
                              property: '',
                              operator: '',
                              value: '',
                              type: '',
                              enums: [],
                              id: Math.random()
                                  .toString(36)
                                  .substr(2, 9),
                          },
                      ]
                    : filters.filter((f) => {
                          return f.id !== id;
                      });
            if (newFilters.length === 1) newFilters[0].showAddButton = true;

            // Redux context
            // this.props.updateCurrentFilter(window.location.pathname, newFilters)

            setFilters(newFilters);
        }
    };

    const handleFieldChange = (e, id, field) => {
        const value = e.target.value;

        const property = props.properties.find((prop) => prop.key === value);

        const canUpdateType = field === 'property';
        const canUpdateEnums =
            property && property.type === filterPropertyTypes.ENUM;

        const newFilters = filters.filter((f) => {
            if (f.id === id) {
                f[field] = value;
                canUpdateType && property.type && (f.type = property.type);
                canUpdateEnums && (f.enums = property.enums);
                canUpdateEnums && (f.operator = 'eq');
            }

            return f;
        });

        setFilters(newFilters);
    };

    return (
        <>
            {!props.hideSearchBar && (
                <SearchBar
                    searchTerm={searchTerm}
                    handleSearchChange={handleSearchBarChange}
                    handleSearchSubmit={handleSearchBarSubmit}
                    loading={props.loading}
                />
            )}

            {hideAddButton && (
                <button
                    className='new-btn small'
                    onClick={handleDynamicFilterModalToogle}
                >
                    <FontAwesomeIcon icon='filter' />
                </button>
            )}
            <DynamicFilterModal
                filterEntityTitle='Filtros'
                isOpen={showDynamicFilterModal}
                filters={filters}
                handleToggle={handleDynamicFilterModalToogle}
                properties={props.properties}
                handleFormSubmit={handleFormSubmit}
                handleAddRemoveFilter={handleAddRemoveFilter}
                handleFieldChange={handleFieldChange}
                handleResetFilter={handleResetFilter}
            />
        </>
    );
}

EvtFilter.propTypes = {
    properties: PropTypes.array.isRequired,
    handleFiltersChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        uiContext: state.uiContextReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCurrentFilter: (...args) =>
            dispatch(actions.updateCurrentFilter(...args)),
        resetCurrentFilter: (...args) =>
            dispatch(actions.resetCurrentFilter(...args)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EvtFilter);
