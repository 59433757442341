import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,
    name: {
        id: 'detailsTab.name',
        defaultMessage: 'Razão social',
    },
    cnpj: {
        id: 'detailsTab.cnpj',
        defaultMessage: 'CPF/CNPJ',
    },
    email: {
        id: 'detailsTab.email',
        defaultMessage: 'E-mail',
    },
    invalidCNPJ: {
        id: 'global.invalidCNPJ',
        defaultMessage: 'CPF ou CNPJ inválido',
    },
    invalidEmail: {
        id: 'global.invalidEmail',
        defaultMessage: 'Email inválido',
    },
    displayName: {
        id: 'detailsTab.displayName',
        defaultMessage: 'Nome Fantasia',
    },
    primaryColorText: {
        id: 'detailsTab.primaryColorText',
        defaultMessage: 'Cor primária',
    },
    secondaryColorText: {
        id: 'detailsTab.secondaryColorText',
        defaultMessage: 'Cor secundária',
    },
});

export default messages;
