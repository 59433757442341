import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    headerTitle: {
        id: 'home.title',
        defaultMessage: 'Dashboard',
    },
    selectContractorTitle: {
        id: 'home.selectContractorTitle',
        defaultMessage: 'Selecione um contratante',
    },
    headerSubtitle: {
        id: 'home.subtitle',
        defaultMessage: 'Bem vindo ao allpays.',
    },
    titleEdit: {
        id: 'home.titleEdit',
        defaultMessage: 'Editar',
    },
    subtitleEdit: {
        id: 'home.subtitleEdit',
        defaultMessage: 'Adicionar e editar items no seu painel',
    },
    headerButtonEdit: {
        id: 'home.headerButtonEdit',
        defaultMessage: 'Editar',
    },
    headerButtonSave: {
        id: 'home.headerButtonSave',
        defaultMessage: 'Salvar',
    },
    changePassword: {
        id: 'settings.changePassword',
        defaultMessage: 'Alterar senha',
    },
    editModeAlertText: {
        id: 'dashboard.editModeAlertText',
        defaultMessage: 'Atenção: o modo de edição do layout está ativo.',
    },
    radioButtonTitle: {
        id: 'dashboard.radioButtonTitle',
        defaultMessage: 'Escolha a quantidade de dias dos gráficos:',
    },
});
