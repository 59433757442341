import React from 'react';

const UserIcon = ({
    width = '26',
    height = '30',
    viewBox = '0 0 26 30',
    fill = 'none',
    style = '',
}) => (
    <svg
        width={width}
        height={height}
        fill={fill}
        viewBox={viewBox}
        style={style}
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.09717 23.25C8.54488 23.25 8.09717 22.8023 8.09717 22.25V16.75C8.09717 16.1977 8.54488 15.75 9.09717 15.75H19.0972C19.6495 15.75 20.0972 16.1977 20.0972 16.75V22.25C20.0972 22.8023 19.6495 23.25 19.0972 23.25H9.09717Z'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M10.877 15.7497L11.544 12.9997C11.6373 12.6129 11.8806 12.2789 12.2203 12.0715C12.5599 11.8641 12.9681 11.8001 13.355 11.8937L22.1 14.0057C22.4867 14.0993 22.8203 14.3427 23.0276 14.6824C23.2348 15.022 23.2986 15.4301 23.205 15.8167L22.149 20.1897C22.0451 20.619 21.7575 20.9806 21.3626 21.1783C20.9677 21.3761 20.5058 21.3897 20.1 21.2157'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M14.25 19.125C14.4571 19.125 14.625 19.2929 14.625 19.5C14.625 19.7071 14.4571 19.875 14.25 19.875C14.0429 19.875 13.875 19.7071 13.875 19.5C13.875 19.2929 14.0429 19.125 14.25 19.125'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M0.75 17.25C0.751558 15.0883 1.78765 13.0578 3.53716 11.788C5.28666 10.5182 7.53822 10.1625 9.594 10.831'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.5 9C9.77817 9 11.625 7.15317 11.625 4.875C11.625 2.59683 9.77817 0.75 7.5 0.75C5.22183 0.75 3.375 2.59683 3.375 4.875C3.375 7.15317 5.22183 9 7.5 9Z'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default UserIcon;
