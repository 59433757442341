/* eslint-disable no-empty-pattern */
/* eslint-disable no-empty */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Container } from 'reactstrap';
import * as actions from '../../store/actions/index';
import Header from '../../components/Header/Header';
import ClientDetailsForm from '../../components/ClientDetailsForm/ClientDetailsForm';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './ClientDetailsPage.intl';
import { AuthContext } from '../../context/AuthContext';
import Modal, { modalTypes } from '../../components/Modal/Modal';

class ClientDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        showModal: false,
    };

    componentDidMount() {
        this.props.onInitClientDetails(this.props.match.params.id);
    }

    handleSubmit = (event, values) => {
        if (this.props.loading) return;

        if (this.props.match.params.id === 'new') {
            this.props.onCreateClient(values);
        } else {
            this.props.onUpdateClient(this.props.client.id, values);
        }
    };

    handleContractorChange = (selectedContractor) => {
        if (selectedContractor) {
            this.props.onSetSelectedContractor({
                contractorId: selectedContractor.value,
                displayName: selectedContractor.label,
            });
        }
    };

    handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handleToggleDelete = async () => {
        this.setState({
            showModal: !this.state.showModal,
        });

        await this.props.onDeleteClient(this.props.client.id);
    };

    render() {
        const { intl, loadingDetails } = this.props;

        const id = this.props.match.params.id;

        const contractorsOption = new Map();
        contractorsOption.set(
            this.props.contractor && this.props.contractor.contractorId,
            this.props.contractor && this.props.contractor.displayName,
        );

        const canCreateClient =
            this.context.hasClaim('client-create') && id === 'new';
        const canDeleteClient =
            this.context.hasClaim('client-delete') && id !== 'new';
        const canUpdateClient =
            this.context.hasClaim('client-update') && id !== 'new';

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/clients',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/clients/:id',
                            name: intl.formatMessage(messages.titleClient, {
                                entity:
                                    this.props.client && this.props.client.name,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header className='row'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.titleClient, {
                                entity: '',
                            })}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content roles-form'>
                    <Loading loading={loadingDetails} />
                    <ClientDetailsForm
                        key={this.props.client}
                        client={this.props.client}
                        handleSubmit={this.handleSubmit}
                        handleDelete={this.handleToggleModal}
                        canCreateClient={canCreateClient}
                        canDeleteClient={canDeleteClient}
                        canUpdateClient={canUpdateClient}
                        isNew={this.props.match.params.id === 'new'}
                        contractorsOption={contractorsOption}
                        handleContractorChange={this.handleContractorChange}
                    />
                </section>
                <Modal
                    title={intl.formatMessage(messages.deleteClient)}
                    isOpen={this.state.showModal}
                    handleToggle={this.handleToggleModal}
                    type={modalTypes.ERROR}
                    hasFooter
                    firstButtonText={intl.formatMessage(messages.yesText)}
                    secondButtonText={intl.formatMessage(messages.noText)}
                    toggleFirstButton={this.handleToggleDelete}
                    toggleSecondButton={this.handleToggleModal}
                >
                    {intl.formatMessage(messages.confirmDeleteText)}
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.clientReducer.client,
        loadingDetails: state.clientReducer.loadingDetails,
        contractor: state.contractorReducer.contractor,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitClientDetails: (clientId) =>
            dispatch(actions.initClientDetails(clientId)),
        onCreateClient: (values) => dispatch(actions.createClient(values)),
        onUpdateClient: (clientId, values) =>
            dispatch(actions.updateClient(clientId, values)),
        onDeleteClient: (clientId) => dispatch(actions.deleteClient(clientId)),
        onSetSelectedContractor: (selectedContractor) =>
            dispatch(actions.setSelectedContractor(selectedContractor)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ClientDetailsPage),
);
