import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import zxcvbn from 'zxcvbn';
import messages from './PasswordStrengthMeter.intl';

const PasswordStrengthMeter = ({ password, intl }) => {
    const testResult = zxcvbn(password);
    const num = (testResult.score * 100) / 4;

    const createPassLabel = () => {
        switch (testResult.score) {
            case 0:
                return intl.formatMessage(messages.veryWeak);
            case 1:
                return intl.formatMessage(messages.weak);
            case 2:
                return intl.formatMessage(messages.regular);
            case 3:
                return intl.formatMessage(messages.strong);
            case 4:
                return intl.formatMessage(messages.veryStrong);
            default:
                return intl.formatMessage(messages.veryWeak);
        }
    };

    const funcProgressColor = () => {
        switch (testResult.score) {
            case 0:
                return '#828282';
            case 1:
                return '#EA1111';
            case 2:
                return '#FFAD00';
            case 3:
                return '#9bc158';
            case 4:
                return '#00b500';
            default:
                return 'none';
        }
    };

    const changePasswordColor = () => ({
        width: `${num}%`,
        background: funcProgressColor(),
        height: '7px',
    });

    return (
        <>
            <div className='progress' style={{ height: '7px' }}>
                <div className='progress-bar' style={changePasswordColor()} />
            </div>
            <p style={{ color: funcProgressColor() }}>{createPassLabel()}</p>
        </>
    );
};

export default injectIntl(memo(PasswordStrengthMeter));
