import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'billingOffice.title',
        defaultMessage: 'Escritório de cobranças',
    },

    contractors: {
        id: 'billingOffice.contractors',
        defaultMessage: 'Contratantes',
    },

    titleBillingOffice: {
        id: 'billingOffice.titleBillingOffice',
        defaultMessage: 'Escritório de cobranças {entity}',
    },

    registrationInfo: {
        id: 'billingOffice.registrationInfo',
        defaultMessage: 'Dados Cadastrais',
    },

    deleteBillingOffice: {
        id: 'billingOffice.deleteBillingOffice',
        defaultMessage: 'Excluir Escritório de cobranças',
    },

    name: {
        id: 'global.name',
        defaultMessage: 'Nome',
    },

    cnpj: {
        id: 'global.cnpj',
        defaultMessage: 'CPF/CNPJ',
    },

    email: {
        id: 'global.email',
        defaultMessage: 'E-mail',
    },

    city: {
        id: 'global.city',
        defaultMessage: 'Cidade',
    },

    state: {
        id: 'global.state',
        defaultMessage: 'Estado',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
});

export default messages;
