import * as actionTypes from '../actions/actionTypes';

const initialState = {
    states: [],
    cities: [],
};

const setStates = (state, action) => {
    return {
        ...state,
        states: action.states,
    };
};

const setCities = (state, action) => {
    return {
        ...state,
        cities: action.cities,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STATES_GET_LIST_SUCCESS:
            return setStates(state, action);
        case actionTypes.CITIES_GET_LIST_SUCCESS:
            return setCities(state, action);
        default:
            return state;
    }
};

export default reducer;
