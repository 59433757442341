/* eslint-disable no-empty-pattern */
/* eslint-disable no-empty */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';
import * as actions from '../../store/actions/index';

import Header from '../../components/Header/Header';
import ContactDetailsForm from '../../components/ContactsDetailsForm/ContactsDetailsForm';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './ContactsDetailsPage.intl';
import Tabs from '../../components/Tabs/Tabs';
import { AuthContext } from '../../context/AuthContext';
import ContactFileForm from '../../components/ContactFileForm/ContactFileForm';

class ContactDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        activeTab: 0,
        showNewAccessModal: false,
        showContractorComboBox: false,
        showRoleComboBox: false,
        showNewPasswordConfirmationModal: false,
    };

    componentDidMount() {
        this.props.onInitContactDetails(this.props.match.params.id);

        const { contractor } = this.context;

        if (this.context.claims.length === 0) return;

        if (this.context.hasClaim('role-level-full')) {
            this.setState({
                showContractorComboBox: true,
                showRoleComboBox: false,
            });
        } else if (this.context.hasClaim('role-level-contractor')) {
            this.setState({
                showContractorComboBox: false,
                showRoleComboBox: true,
            });

            this.props.onSetSelectedContractor({
                contractorId: contractor.contractorId,
                displayName: contractor.displayName,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { contact, session } = this.props;

        if (prevProps.session !== session) {
            this.props.onInitCurrentSession();
        }

        if (prevProps.emailSent !== this.props.emailSent) {
            if (
                contact &&
                session &&
                contact.contactId === session.contactId &&
                this.props.emailSent
            ) {
                this.props.onLogout();
            }
        }
    }

    handleNewAttachmentSaveButtonClick = (file) => {
        this.props.onUploadContactAttachment(file);
        this.props.history.push(`/collections`);
    };

    handleSubmit = (event, values) => {
        if (this.props.loading) return;

        values.accesses = this.props && this.props.accesses;

        if (this.props.match.params.id === 'new') {
            this.props.onCreateContact(values);
        } else {
            this.props.onUpdateContact(this.props.contact.contactId, values);
        }
    };

    handleSubmitAccess = () => {
        this.props.onUpdateContact(
            this.props.contact.contactId,
            this.props.contact,
        );
    };

    handleNewAccessModalToogle = () => {
        let state = {
            showContractorComboBox: true,
            showRoleComboBox: false,
        };

        if (this.context.hasClaim('role-level-full')) {
        } else if (this.context.hasClaim('role-level-contractor')) {
            state = {
                ...state,
                showContractorComboBox: false,
                showRoleComboBox: true,
            };
        } else {
            state = {
                ...state,
                showContractorComboBox: false,
                showRoleComboBox: true,
            };
        }

        this.setState({
            ...state,
            showNewAccessModal: !this.state.showNewAccessModal,
        });
    };

    handleNewPasswordModalToogle = () => {
        this.setState({
            showNewPasswordConfirmationModal: !this.state
                .showNewPasswordConfirmationModal,
        });
    };

    handleSendNewPasswordButtonClick = () => {
        const { contact } = this.props;
        this.props.onSendNewPassword(contact);

        this.handleNewPasswordModalToogle();
    };

    handleContractorChange = (selectedContractor) => {
        if (selectedContractor) {
            this.props.onSetSelectedContractor({
                contractorId: selectedContractor.value,
                displayName: selectedContractor.label,
            });

            this.setState({
                showRoleComboBox: true,
            });
        }
    };

    handleRoleChange = (selectedRole) => {
        if (selectedRole) {
            this.props.onSetSelectedRole({
                roleId: selectedRole.value,
                name: selectedRole.label,
            });
        }
    };

    handleRemoveAccessButtonClick = (roleToDelete) => {
        this.props.onRemoveContactAccess(roleToDelete);
    };

    render() {
        const {
            intl,
            loadingDetails,
            contractor,
            loadingAttachment,
        } = this.props;
        const {} = contractor || {};

        const isNew = this.props.match.params.id === 'new';

        const id = this.props.match.params.id;

        const canCreateContact =
            this.context.hasClaim('contact-create') && id === 'new';
        const canDeleteContact =
            this.context.hasClaim('contact-delete') && id !== 'new';
        const canUpdateContact =
            this.context.hasClaim('contact-update') && id !== 'new';
        const canResetPassword =
            this.context.hasClaim('contact-reset-password-request-create') &&
            id !== 'new';

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/contacts',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/contacts/:id',
                            name: intl.formatMessage(messages.titleContact, {
                                entity:
                                    this.props.contact &&
                                    this.props.contact.name,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header className='row'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(messages.titleContact, {
                                entity: '',
                            })}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content roles-form'>
                    <Loading loading={loadingDetails} />
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={
                            isNew
                                ? [
                                      {
                                          name: 'Importar Arquivo',
                                          component: (
                                              <ContactFileForm
                                                  key='filesTab'
                                                  handleNewAttachmentSaveButtonClick={
                                                      this
                                                          .handleNewAttachmentSaveButtonClick
                                                  }
                                                  loadingAttachment={
                                                      loadingAttachment
                                                  }
                                              />
                                          ),
                                      },
                                  ]
                                : [
                                      {
                                          name: intl.formatMessage(
                                              messages.registrationInfo,
                                          ),
                                          component: (
                                              <ContactDetailsForm
                                                  key={this.props.contact}
                                                  contact={this.props.contact}
                                                  handleSubmit={
                                                      this.handleSubmit
                                                  } /* 
                                            handleToggle={this.handleToggleModal}
                                            handleDelete={this.handleDelete} */
                                                  accesses={
                                                      this.props
                                                          ? this.props.accesses
                                                          : []
                                                  }
                                                  canCreateContact={
                                                      canCreateContact
                                                  }
                                                  canDeleteContact={
                                                      canDeleteContact
                                                  }
                                                  canUpdateContact={
                                                      canUpdateContact
                                                  }
                                                  canResetPassword={
                                                      canResetPassword
                                                  }
                                                  handleNewAccessButtonClick={
                                                      this
                                                          .handleNewAccessModalToogle
                                                  }
                                                  handleNewPasswordButtonClick={
                                                      this
                                                          .handleNewPasswordModalToogle
                                                  }
                                                  handleRemoveAccessButtonClick={
                                                      this
                                                          .handleRemoveAccessButtonClick
                                                  }
                                              />
                                          ),
                                      },
                                  ]
                        }
                    />
                </section>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contact: state.contactReducer.contact,
        loadingDetails: state.contactReducer.loadingDetails,
        contractors: state.contractorReducer.contractors || [],
        roles: state.roleReducer.roles || [],
        rolesContractor: state.roleReducer.rolesContractor || [],
        contactContractors: state.contractorReducer.contractors || [],
        accesses: state.contactReducer.accesses,
        session: state.authenticationReducer.session,
        emailSent: state.contactReducer.emailSent,
        contractor: state.contractorReducer.contractor,
        role: state.roleReducer.role,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitContactDetails: (contactId) =>
            dispatch(actions.initContactDetails(contactId)),
        onCreateContact: (values) => dispatch(actions.createContact(values)),
        onUpdateContact: (contactId, values) =>
            dispatch(actions.updateContact(contactId, values)),
        onDeleteContact: (contactId) =>
            dispatch(actions.deleteContact(contactId)),

        onRemoveContactAccess: (removeAccess) =>
            dispatch(actions.removeContactAccess(removeAccess)),
        onSendNewPassword: (contact) =>
            dispatch(actions.sendNewPassword(contact)),
        onLogout: () => dispatch(actions.logout()),
        onInitCurrentSession: () => dispatch(actions.getCurrentSession()),
        onSetSelectedContractor: (selectedContractor) =>
            dispatch(actions.setSelectedContractor(selectedContractor)),
        onSetSelectedRole: (selectedRole) =>
            dispatch(actions.setSelectedRole(selectedRole)),
        onUploadContactAttachment: (file) =>
            dispatch(actions.uploadContactAttachment(file)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ContactDetailsPage),
);
