import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    title: {
        id: 'settings.title',
        defaultMessage: 'Configurações',
    },
    changePassword: {
        id: 'settings.changePassword',
        defaultMessage: 'Alterar senha',
    },

    generalSettingsTitle: {
        id: 'settings.generalSettingsTitle',
        defaultMessage: 'Configurações gerais',
    },

    passwordSettingsTitle: {
        id: 'settings.passwordSettingsTitle',
        defaultMessage: 'Configuração de senha',
    },
});
