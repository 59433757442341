import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    homeLink: {
        id: 'sidebar.homeLink',
        defaultMessage: 'Início',
    },
    contractorsLink: {
        id: 'sidebar.contractorsLink',
        defaultMessage: 'Contratantes',
    },
    collectionsLink: {
        id: 'sidebar.collectionsLink',
        defaultMessage: 'Recebíveis',
    },
    parametersLink: {
        id: 'sidebar.parametersLink',
        defaultMessage: 'Parâmetros',
    },
    tarificationsLink: {
        id: 'sidebar.tarificationsLink',
        defaultMessage: 'Tarifações',
    },

    feemessagesLink: {
        id: 'sidebar.feemessagesLink',
        defaultMessage: 'Mensagens',
    },
    transactionsLink: {
        id: 'sidebar.transactionsLink',
        defaultMessage: 'Transações',
    },
    fieldsLink: {
        id: 'sidebar.fieldsLink',
        defaultMessage: 'Campos',
    },
    usersLink: {
        id: 'sidebar.usersLink',
        defaultMessage: 'Usuários',
    },
    contactsLink: {
        id: 'sidebar.contactsLink',
        defaultMessage: 'Contatos',
    },
    clientsLink: {
        id: 'sidebar.clientsLink',
        defaultMessage: 'Clientes',
    },
    financialDocumentsLink: {
        id: 'sidebar.financialDocumentsLink',
        defaultMessage: 'Documentos financeiros',
    },
    settingsLink: {
        id: 'sidebar.settingsLink',
        defaultMessage: 'Configurações',
    },
    logoutLink: {
        id: 'sidebar.logoutLink',
        defaultMessage: 'Sair',
    },
    rolesLink: {
        id: 'sidebar.rolesLink',
        defaultMessage: 'Perfis',
    },
    reconciliationLink: {
        id: 'sidebar.reconciliationLink',
        defaultMessage: 'Reconciliação',
    },
});

export default messages;
