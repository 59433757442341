export const Operators = {
    equalTo: 'eq',
    notEqualTo: 'ne',
    greaterThan: 'gt',
    greaterThanOrEqualTo: 'ge',
    lessThan: 'lt',
    lessThanOrEqualTo: 'le',
    like: 'like',
    sort: 'sort',
    skip: 'skip',
    take: 'take',
    or: 'or',
};

export const SortDirection = {
    asc: 'asc',
    desc: 'desc',
};

export default class FilterBuilder {
    filters = [];

    filter = (property, operator, value) => {
        this.filters.push({
            property,
            operator,
            value,
        });

        return this;
    };

    build = () => {
        let query = '?';

        this.filters.map((filter) => {
            const { property, operator, value } = filter;
            if (property === 'statusCode') {
                const today = new Date().toISOString().split('.')[0];
                switch (value) {
                    case '1':
                        return (query = `?filter[isPayed]=eq:false&filter[dueDate]=ge:${today}`);
                    case '2':
                        return (query = `?filter[isPayed]=eq:true`);
                    case '4':
                        return (query = `?filter[isPayed]=eq:false&filter[dueDate]=le:${today}`);
                    default:
                        break;
                }
            }

            if (query.length > 1) {
                query += '&';
            }

            if (operator === Operators.sort) {
                const ascOrDesc = value === SortDirection.asc ? '' : '-';
                query += `${Operators.sort}=${ascOrDesc}${property}`;
            } else if (
                operator === Operators.skip ||
                operator === Operators.take
            ) {
                query += `${operator}=${value}`;
            } else if (operator === Operators.or) {
                query += `${operator}=true`;
            } else {
                query += `filter[${property}]=${operator}:${value}`;
            }

            return query;
        });
        if (this.filters[0].property === 'beginDate') {
            const queryBilling = query;
            return queryBilling;
        }
        return query;
    };

    convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: item,
            };
        }, initialValue);
    };

    buildAsJSON = () => {
        let beginDate;
        let endDate;
        let sort;
        let query = this.convertArrayToObject(this.filters, 'property');
        query.skip = query?.skip?.value;
        query.take = query?.take?.value;

        const filterProperty = this.filters[0].property;
        const filterDates = ['date', 'paymentDate', 'payDate', 'createdAt'];

        if (query.or) {
            query = {
                skip: query.skip,
                take: query.take,
                Or: true,
                term: query[filterProperty].value,
            };
        }

        if (filterDates.includes(filterProperty)) {
            const dataInit = this.filters.filter((param) => {
                return (
                    param.property === filterProperty && param.operator === 'ge'
                );
            });
            const dataEnd = this.filters.filter((param) => {
                return (
                    param.property === filterProperty && param.operator === 'le'
                );
            });
            const fieldStart = `${filterProperty}Init`;
            const fieldEnd = `${filterProperty}End`;
            beginDate = {
                property: filterProperty,
                value: dataInit[0]?.value,
            };
            endDate = {
                property: filterProperty,
                value: dataEnd[0]?.value,
            };
            query[fieldStart] = beginDate;
            query[fieldEnd] = endDate;

            delete query[this.filters[0].property];
        }

        if (this.filters?.[0].operator === 'sort') {
            sort = {
                property: this.filters[0].property,
                value: this.filters[0].value,
            };
            query.sort = sort;
            delete query.createdAt;
            delete query.aging;
            delete query.expireAt;
            delete query.operatorType;
        }

        query = `filter=${JSON.stringify(query)}`;

        return query;
    };
}
