import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,
    placeholder: {
        id: 'global.search',
        defaultMessage: 'Pesquisar',
    },
});
