import React from 'react';
import { injectIntl } from 'react-intl';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import messages from './FinancialDocumentsDetailsForm.intl';
import DetailSection from '../DetailSection/DetailSection';
import { handleCpfCnpj } from '../../utils/handleCpfCnpj';

function FinancialDocumentDetailsForm(props) {
    const { intl } = props;

    const financialDocument = props.financialDocument || {};
    return (
        <AvForm>
            <DetailSection title='Identificação do documento'>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.referenceDocument,
                                )}
                                type='text'
                                name='referenceDocument'
                                id='referenceDocument'
                                value={financialDocument.referenceDocument}
                                errorMessage=' '
                                disabled
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.collectionIdRef,
                                )}
                                type='text'
                                name='collectionIdRef'
                                id='collectionIdRef'
                                value={financialDocument.collectionIdRef}
                                errorMessage=' '
                                disabled
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.company)}
                                type='text'
                                name='company'
                                id='company'
                                value={financialDocument.company}
                                errorMessage=' '
                                disabled
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.fiscalYear)}
                                type='text'
                                name='fiscalYear'
                                id='fiscalYear'
                                value={financialDocument.fiscalYear}
                                errorMessage=' '
                                disabled
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.documentNumber,
                                )}
                                type='text'
                                name='documentNumber'
                                id='documentNumber'
                                value={financialDocument.documentNumber}
                                errorMessage=' '
                                disabled
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.documentType,
                                )}
                                type='text'
                                name='documentType'
                                id='documentType'
                                value={financialDocument.documentType}
                                errorMessage=' '
                                disabled
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>

            <DetailSection title='Dados do cliente'>
                <Row>
                    <Col>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.clientCpfCnpj,
                                )}
                                type='text'
                                name='clientCpfCnpj'
                                id='clientCpfCnpj'
                                value={handleCpfCnpj(
                                    financialDocument.clientCpfCnpj,
                                )}
                                errorMessage=' '
                                disabled
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.clientErpId)}
                                type='text'
                                name='clientErpId'
                                id='clientErpId'
                                value={financialDocument.clientErpId}
                                errorMessage=' '
                                disabled
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>
            <DetailSection title='Dados da cobrança'>
                <Row>
                    <Col>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.documentDate,
                                )}
                                type='text'
                                name='documentDate'
                                id='documentDate'
                                value={
                                    financialDocument.documentDate
                                        ? new Date(
                                              financialDocument.documentDate,
                                          ).toLocaleString(navigator.language)
                                        : ''
                                }
                                errorMessage=' '
                                disabled
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.dueDate)}
                                type='text'
                                name='dueDate'
                                id='dueDate'
                                value={
                                    financialDocument.dueDate
                                        ? new Date(
                                              financialDocument.dueDate,
                                          ).toLocaleString(navigator.language)
                                        : ''
                                }
                                errorMessage=' '
                                disabled
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.documentValue,
                                )}
                                type='text'
                                name='documentValue'
                                id='documentValue'
                                value={
                                    new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(
                                        financialDocument.documentValue,
                                    ) || ''
                                }
                                errorMessage=' '
                                disabled
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.statusDescription,
                                )}
                                type='text'
                                name='statusDescription'
                                id='statusDescription'
                                value={
                                    financialDocument.statusDescription || ''
                                }
                                errorMessage=' '
                                disabled
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>
        </AvForm>
    );
}

export default injectIntl(FinancialDocumentDetailsForm);
