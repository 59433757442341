import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import { AvField } from 'availity-reactstrap-validation';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import axios from 'axios';
import messages from './EvtSelect.intl';

function EvtSelect(props) {
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedLabel, setSelectedLabel] = useState(null);

    const {
        intl,
        key,
        name,
        label,
        required,
        options,
        async,
        defaultOptions,
        disabled,
        url,
        labelKey,
        labelKey2,
        valueKey,
        handleSelectedOptionChange,
        option,
        isSearchable,
        canRemoveItem,
    } = props;

    const { selectedValueOption, selectedLabelOption } = option || {};
    useEffect(() => {
        setSelectedValue(selectedValueOption);
        setSelectedLabel(selectedLabelOption);
    }, [selectedValueOption, selectedLabelOption]);

    function handleChange(option) {
        setSelectedValue(option.value);
        setSelectedLabel(option.label);
        handleSelectedOptionChange && handleSelectedOptionChange(option);
    }

    function handleLoadOptions(inputValue, callback) {
        if (!url) {
            callback([]);
            return;
        }
        // PrefixUrl permite passar params pela URL
        const prefixUrl = url.indexOf('?') === -1 ? '?' : '&';
        const filter = `${prefixUrl}filter[${labelKey}]=like:${inputValue.toUpperCase()}`;

        const filter2 = labelKey2
            ? `&filter[${labelKey2}]=like:${inputValue.toUpperCase()}&or=true`
            : '';

        const skipTake = '&skip=0&take=100';

        axios
            .get(url + filter + filter2 + skipTake)
            .then((response) => {
                const data =
                    canRemoveItem && response.data.length > 0
                        ? [
                              {
                                  label: 'Nenhum',
                                  value: '',
                                  data: '',
                              },
                          ]
                        : response.data;

                if (response.data.length > 0 && canRemoveItem)
                    data.push(...(response.data || {}));

                const options = data.map((d) => {
                    return {
                        label:
                            d[labelKey] ||
                            d.label + (labelKey2 ? ` - ${d[labelKey2]}` : ''),
                        value: d[valueKey] || d.value,
                        data: d,
                    };
                });

                if (response.data.length === 0 && canRemoveItem) options.pop();

                callback(options);
            })
            .catch(() => {
                callback([]);
            });
    }

    return (
        <>
            {label && <label>{label}</label>}
            {async ? (
                <AsyncSelect
                    key={key}
                    placeholder={intl.formatMessage(messages.selectPlaceholder)}
                    className='evt-select'
                    onChange={handleChange}
                    loadOptions={handleLoadOptions}
                    defaultOptions={
                        defaultOptions === undefined ? true : defaultOptions
                    }
                    value={
                        selectedValue
                            ? { label: selectedLabel, value: selectedValue }
                            : null
                    }
                    isDisabled={disabled}
                    isSearchable={isSearchable}
                />
            ) : (
                <Select
                    key={key}
                    placeholder={intl.formatMessage(messages.selectPlaceholder)}
                    className='evt-select'
                    options={options}
                    onChange={handleChange}
                    value={
                        selectedValue
                            ? { label: selectedLabel, value: selectedValue }
                            : null
                    }
                    isDisabled={disabled}
                />
            )}
            <AvField
                key={`${key}_hidden`}
                type='hidden'
                id={name}
                name={name}
                value={selectedValue}
                validate={{
                    required: { value: required },
                }}
            />
        </>
    );
}

EvtSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.array,
    required: PropTypes.bool,
    async: PropTypes.bool,
    defaultOptions: PropTypes.bool,
    url: PropTypes.string,
    labelKey: PropTypes.string,
    valueKey: PropTypes.string,
    disabled: PropTypes.bool,
    handleSelectedOptionChange: PropTypes.func,
};

export default injectIntl(EvtSelect);
