import React from 'react';
import { injectIntl } from 'react-intl';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import messages from './ChooseContractorForm.intl';

const ChooseContractorForm = (props) => {
    const { intl, loading, contractorColor } = props;
    const accesses = props.accesses || [];

    let defaultAccess = null;
    if (accesses.length > 0) {
        defaultAccess = accesses[0];
    }

    return (
        <AvForm onValidSubmit={props.handleAccessFormSubmit}>
            <AvField
                type='select'
                name='contractorId'
                id='contractorId'
                label={intl.formatMessage(messages.access)}
                errorMessage=' '
                value={defaultAccess && defaultAccess.contractorId}
                required
            >
                {accesses.map((access, index) => {
                    return (
                        <option
                            key={`${access.contractorId}_${index}`}
                            value={access.contractorId}
                        >
                            {access.contractorName}
                        </option>
                    );
                })}
            </AvField>
            <br />
            <div className='btns-login'>
                <button
                    style={{ backgroundColor: contractorColor }}
                    disabled={loading}
                    type='submit'
                >
                    {intl.formatMessage(messages.loginButton)}
                </button>
                <button
                    style={{ color: contractorColor }}
                    className='btn-link btnForgotPassword'
                    type='button'
                    onClick={props.handleBackClickToogle}
                >
                    {intl.formatMessage(messages.returnButton)}
                </button>
            </div>
        </AvForm>
    );
};

export default injectIntl(ChooseContractorForm);
