import React from 'react';
import PropTypes from 'prop-types';

const header = (props) => {
    return (
        <>
            <h2 className='title-page'>{props.title}</h2>
            <p className='text-muted subtitle-page'>{props.subtitle}</p>
        </>
    );
};

header.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
};

export default header;
