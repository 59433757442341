import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'collections.title',
        defaultMessage: 'Recebível',
    },

    titleCollection: {
        id: 'collections.titleCollection',
        defaultMessage: 'Recebível {entity}',
    },

    subtitle: {
        id: 'collections.subtitle',
        defaultMessage: ' Recebível(eis) encontrado(s)',
    },

    collectionCode: {
        id: 'collections.collectionCode',
        defaultMessage: 'Recebível',
    },

    referenceDocument: {
        id: 'collections.referenceDocument',
        defaultMessage: 'Doc. Referência',
    },

    collectionItemCode: {
        id: 'collections.collectionItemCode',
        defaultMessage: 'Nº Documento ERP',
    },

    dueDate: {
        id: 'collections.dueDate',
        defaultMessage: 'Data de Vencimento',
    },

    daysInArrears: {
        id: 'collections.daysInArrears',
        defaultMessage: 'Dias em Atraso',
    },

    source: {
        id: 'collections.source',
        defaultMessage: 'Origem',
    },

    clientNumber: {
        id: 'collections.clientNumber',
        defaultMessage: 'Id Cliente',
    },

    clientName: {
        id: 'collections.clientName',
        defaultMessage: 'Cliente',
    },

    clientCpfCnpj: {
        id: 'collections.clientCpfCnpj',
        defaultMessage: 'CPF/CNPJ',
    },

    clientPhoneNumbers: {
        id: 'collections.clientPhoneNumbers',
        defaultMessage: 'Nr Telefone',
    },

    collectionId: {
        id: 'collections.collectionId',
        defaultMessage: 'ID do recebível',
    },

    id: {
        id: 'collections.id',
        defaultMessage: 'id',
    },

    billDischargeValue: {
        id: 'collections.billDischargeValue',
        defaultMessage: 'Valor Proposta',
    },

    billAdjustedValue: {
        id: 'collections.billAdjustedValue',
        defaultMessage: 'Valor Documento',
    },

    collectionServiceSheetCode: {
        id: 'collections.collectionServiceSheetCode',
        defaultMessage: 'Ano',
    },

    collectionDescription: {
        id: 'fields.description',
        defaultMessage: 'Descrição',
    },

    collectionValue: {
        id: 'collections.valueTransaction',
        defaultMessage: 'Valor Documento(Corrigido)',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    qtdInvoices: {
        id: 'home.qtdInvoices',
        defaultMessage: 'NF',
    },

    unavailableCollection: {
        id: 'collections.unavailableCollection',
        defaultMessage: 'Collection indisponível',
    },

    documentDate: {
        id: 'collections.collectionDocumentDate',
        defaultMessage: 'Data Emissão',
    },

    importButtonText: {
        id: 'collections.importButtonText',
        defaultMessage: 'Número Referência',
    },
    modalTitle: {
        id: 'collections.modalTitle',
        defaultMessage: 'Enviar email de cobrança',
    },
    modalInstallmentsTitle: {
        id: 'collections.modalInstallmentsTitle',
        defaultMessage: 'Novo parcelamento',
    },
    yesText: {
        id: 'collections.yesText',
        defaultMessage: 'Enviar',
    },
    noText: {
        id: 'collections.noText',
        defaultMessage: 'Cancelar',
    },
    alternativeEmail: {
        id: 'collections.alternativeEmail',
        defaultMessage: 'E-mail alternativo',
    },
    expireAt: {
        id: 'collections.expireAt',
        defaultMessage: 'Nova data de expiração',
    },
    value: {
        id: 'collections.value',
        defaultMessage: 'Novo valor',
    },
    confirmButton: {
        id: 'collections.installmentsConfirm',
        defaultMessage: 'Confirmar',
    },
});

export default messages;
