import React from 'react';
import { Bar as BarChart } from 'react-chartjs';
import { injectIntl } from 'react-intl';

import messages from './MonthlyVolumeByClientChart.intl';

const MonthlyVolumeByClientChart = (props) => {
    const { data, intl } = props;

    const config = {
        labels: data.labels.length > 0 ? data.labels : [''],
        datasets: [
            {
                label: 'My First dataset',
                fillColor: 'rgba(151,187,205,0.2)',
                strokeColor: 'rgba(151,187,205,1)',
                pointColor: 'rgba(151,187,205,1)',
                pointStrokeColor: '#fff',
                pointHighlightFill: '#fff',
                pointHighlightStroke: 'rgba(151,187,205,1)',
                data: data.values.length > 0 ? data.values : [0],
            },
        ],
    };

    return (
        <>
            <div className='text'>
                <h1>
                    {intl.formatMessage(
                        messages.monthlyVolumeByClientChartVolume,
                    )}
                </h1>
            </div>
            <div className='chart chart-line'>
                <BarChart
                    data={config}
                    options={{ responsive: true, maintainAspectRatio: false }}
                />
            </div>
        </>
    );
};

export default injectIntl(MonthlyVolumeByClientChart);
