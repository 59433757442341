import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    transactionCode: {
        id: 'transactions.transactionCode',
        defaultMessage: 'Código Recebível',
    },

    transactionItemCode: {
        id: 'transactions.transactionItemCode',
        defaultMessage: 'Código Item Recebível',
    },

    transactionId: {
        id: 'transactions.transactionId',
        defaultMessage: 'Id Recebimento',
    },

    transactionServiceSheetCode: {
        id: 'transactions.transactionServiceSheetCode',
        defaultMessage: 'Código Folha',
    },

    transactionServiceSheetItemCode: {
        id: 'transactions.transactionServiceSheetItemCode',
        defaultMessage: 'Código Item Folha',
    },

    operatorId: {
        id: 'transactions.operatorId',
        defaultMessage: 'Adquirente',
    },

    paymentMethod: {
        id: 'transactions.transactionpaymentMethod',
        defaultMessage: 'Método de Pagamento',
    },

    paymentMethodDetail: {
        id: 'transactions.paymentMethodDetail',
        defaultMessage: 'Detalhe Método de Pagamento',
    },

    transactionAmount: {
        id: 'transactions.amount',
        defaultMessage: 'Quantidade',
    },

    operatorFeeValue: {
        id: 'transactions.operatorFeeValue',
        defaultMessage: 'Tarifa Adquirente',
    },

    operatorFeeRate: {
        id: 'transactions.operatorFeeRate',
        defaultMessage: 'Taxa Adquirente',
    },

    comissionFeeValue: {
        id: 'transactions.comissionFeeValue',
        defaultMessage: 'Tarifa Allpays',
    },

    comissionFeeRate: {
        id: 'transactions.comissionFeeRate',
        defaultMessage: 'Taxa Allpays',
    },

    numberOfInstallments: {
        id: 'transactions.numberOfInstallments',
        defaultMessage: 'Quantidade de Parcelas',
    },

    operatorAccountId: {
        id: 'transactions.operatorAccountId',
        defaultMessage: 'Id Contractor/Adquirente',
    },

    collectionId: {
        id: 'transactions.collectionId',
        defaultMessage: 'Id. Recebível',
    },

    transactionPaymentFull: {
        id: 'transactions.transactionPaymentFull',
        defaultMessage: 'Quitação Total',
    },

    payDate: {
        id: 'transactions.payDate',
        defaultMessage: 'Data Aprovação',
    },

    payTime: {
        id: 'transactions.payTime',
        defaultMessage: 'Hora Aprovação',
    },

    recordDate: {
        id: 'transactions.recordDate',
        defaultMessage: 'Data',
    },

    recordTime: {
        id: 'transactions.recordTime',
        defaultMessage: 'Hora',
    },

    measureUnit: {
        id: 'transactions.measureUnit',
        defaultMessage: 'Measure Unit',
    },

    devicePayerId: {
        id: 'transactions.devicePayerId',
        defaultMessage: 'Id Device Transação',
    },

    billValue: {
        id: 'transactions.billValue',
        defaultMessage: 'Valor do Documento',
    },

    billDischargeValue: {
        id: 'transactions.billDischargeValue',
        defaultMessage: 'Valor proposta',
    },

    totalBillDischarge: {
        id: 'transactions.totalBillDischarge',
        defaultMessage: 'Valor proposta com quitação total',
    },

    referenceDocument: {
        id: 'transactions.referenceDocument',
        defaultMessage: 'Doc. Referência',
    },

    isPayed: {
        id: 'transactions.isPayed',
        defaultMessage: 'Pago',
    },

    companyCnpj: {
        id: 'collections.companyCnpj',
        defaultMessage: 'Empresa',
    },

    clientNumber: {
        id: 'collections.clientNumber',
        defaultMessage: 'Id Cliente',
    },

    clientName: {
        id: 'collections.clientName',
        defaultMessage: 'Nome do Cliente',
    },

    phone: {
        id: 'collections.collectionClientPhoneNumbers',
        defaultMessage: 'Nr do Cliente',
    },

    email: {
        id: 'collections.email',
        defaultMessage: 'E-mail do Cliente',
    },

    customerCpfCnpj: {
        id: 'collections.customerCpfCnpj',
        defaultMessage: 'CPF/CNPJ',
    },

    value: {
        id: 'global.value',
        defaultMessage: 'Total do Recebível',
    },

    currency: {
        id: 'global.currency',
        defaultMessage: 'Currency',
    },
    unitValue: {
        id: 'global.unitValue',
        defaultMessage: 'Valor Unitário',
    },

    isAvailable: {
        id: 'global.isAvailable',
        defaultMessage: 'Disponível',
    },
    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },
    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },
    company: {
        id: 'roles.company',
        defaultMessage: 'Contratante',
    },
});

export default messages;
