/* eslint-disable  */
import React, { useMemo, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { Row, Col, FormGroup } from 'reactstrap';
import DetailSection from '../DetailSection/DetailSection';

import { handleDates } from '../../utils/handleDates';
import messages from './FeeMessagesDetailsForm.intl';
import { AuthContext } from '../../context/AuthContext';

function FeeMessageDetailsForm(props) {
    const context = useContext(AuthContext);

    const { intl, isNew, canDeleteFeeMessage } = props;
    const feeMessage = props.feeMessage || {};
    const feeMessagesTypes = props.feeMessagesTypes || {};
    const contractors = props.contractors || {};

    const emptyOption = (label) => {
        return (
            <option key={0} value={null}>
                {label || 'Selecione'}
            </option>
        );
    };
    
    const contractorsOptions = useMemo(() =>
    Object.entries(contractors).map((contractor) => {
        return (
            <option
                    key={contractor[1].contractorId}
                    value={contractor[1].contractorId}
                >
                    {contractor[1].displayName}
                </option>
            );
        }),
        );
        contractorsOptions.splice(0, 0, emptyOption('Todos'));
        
        const feeMessagesTypesOptions = useMemo(() =>
        Object.entries(feeMessagesTypes).map((feeMessagetype) => {
            return (
                feeMessagetype.length > 0 && (
                    <option key={feeMessagetype[1]} value={feeMessagetype[1]}>
                        {feeMessagetype[0]}
                    </option>
                )
            );
        }),
    );

    feeMessagesTypesOptions.splice(0, 0, emptyOption());

    return (
        <AvForm onValidSubmit={props.handleSubmit}>
            {!isNew && (
                <Row>
                    <Col lg={12} md={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.feeMessageId,
                                )}
                                type='text'
                                name='id'
                                id='id'
                                value={feeMessage.id || ''}
                                errorMessage=' '
                                disabled='true'
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            )}
            <DetailSection title='Informações Gerais'>
                <Row>
                    <Col lg={12} md={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.feeMessageContractor,
                                )}
                                type='select'
                                name='contractorId'
                                id='contractorId'
                                value={isNew ? 0 : feeMessage.contractorId || 0}
                                errorMessage=' '
                            >
                                {contractorsOptions}
                            </AvField>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={4}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.FeePayableType,
                                )}
                                type='select'
                                name='feePayableType'
                                id='feePayableType'
                                value={isNew ? 0 : feeMessage.feePayableType || 0}
                                errorMessage=' '
                                required
                            >
                                {feeMessagesTypesOptions}
                            </AvField>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={4}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.feeMessageValue,
                                )}
                                type='text'
                                name='value'
                                id='value'
                                value={isNew ? '' : `${feeMessage.value || ''}`}
                                errorMessage=' '
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={4}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.feeMessageDueDate,
                                )}
                                type='date'
                                name='expireAt'
                                id='expireAt'
                                value={
                                    isNew
                                        ? 0
                                        : feeMessage?.expireAt
                                        ? handleDates(
                                              new Date(feeMessage?.expireAt),
                                              '-',
                                          )
                                        : ''
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>
            <Row className='mt-3'>
                <Col xs={12}>
                    <br />
                    <div className='mt-3'>
                        <button
                            type='submit'
                            className='btn-submit'
                            disabled={props.loading}
                        >
                            {intl.formatMessage(messages.saveButton)}
                        </button>
                        {canDeleteFeeMessage && (
                            <button
                                type='button'
                                className='btn-delete'
                                onClick={props.handleDelete}
                            >
                                {intl.formatMessage(messages.deleteButton)}
                            </button>
                        )}
                    </div>
                </Col>
            </Row>
        </AvForm>
    );
}

export default injectIntl(FeeMessageDetailsForm);
