import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'contractors.title',
        defaultMessage: 'Contratantes',
    },

    company: {
        id: 'contractor.Name',
        defaultMessage: 'Acessos',
    },

    submitButton: {
        id: 'contractor.submitButton',
        defaultMessage: 'Gravar',
    },

    access: {
        id: 'chooseContractor.access',
        defaultMessage: 'Acessos',
    },

    returnButton: {
        id: 'global.returnButton',
        defaultMessage: 'Retornar',
    },

    loginButton: {
        id: 'global.loginButton',
        defaultMessage: 'Entrar',
    },
});

export default messages;
