import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    valuesByMethodPayment: {
        id: 'dashboard.valuesByMethodPayment',
        defaultMessage: 'Values By Method Payment',
    },
});
