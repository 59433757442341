import * as actionTypes from '../actions/actionTypes';

const initialState = {
    ocrOperationResult: null,
};

const setOcrOperationResult = (state, action) => {
    return {
        ...state,
        ocrOperationResult: action.ocrOperationResult,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OCR_OPERATION_RESULT_SUCCESS:
            return setOcrOperationResult(state, action);
        default:
            return state;
    }
};

export default reducer;
