import React from 'react';
import { Row, Col, FormGroup } from 'reactstrap';

import { AvField, AvForm } from 'availity-reactstrap-validation';
// import { Checkbox } from '@chakra-ui/react';
import messages from './SettingsOptions.intl';
import { TopicTitle } from '../TopicTitle';

export const SettingsPassword = ({ intl, handleChangePasswordFormSubmit }) => {
    return (
        <AvForm onValidSubmit={handleChangePasswordFormSubmit}>
            <Row>
                <Col xs={12} md={6} lg={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(
                                messages.newPasswordLabelText,
                            )}
                            type='password'
                            name='newPassword'
                            id='newPassword'
                            errorMessage=' '
                            required
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(
                                messages.confirmPasswordLabelText,
                            )}
                            type='password'
                            name='confirmNewPassword'
                            id='confirmNewPassword'
                            errorMessage=' '
                            required
                            validate={{
                                match: {
                                    value: 'newPassword',
                                    errorMessage: intl.formatMessage(
                                        messages.passwordDoesNotMatchValidationText,
                                    ),
                                },
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <button className='btn-submit'>
                        {intl.formatMessage(messages.buttonChangeText)}
                    </button>
                </Col>
            </Row>
        </AvForm>
    );
};
export const GeneralSettings = ({
    handleUpdateSettingsFromSubmit,
    settings,
}) => {
    const checkboxStyle = {
        cursor: 'pointer',
        height: 15,
    };
    const validation = (fieldValue = undefined) => {
        if (fieldValue !== undefined) {
            // Será mantido o padrão: @aaa;@bbb.com;@ccc...
            const regex = /^(@[a-z0-9]+.[a-z]+;?)*$/;
            const value = fieldValue.split(';');
            let result = true;
            value.forEach((string) => {
                if (!regex.test(string) || (string === '' && value !== ''))
                    result = 'Domínio incorreto. Ex: @gmail.com;@...';
            });
            return result;
        }
        return null;
    };
    return (
        <AvForm onValidSubmit={handleUpdateSettingsFromSubmit}>
            <div className='mb-5 pb-5'>
                <Row>
                    <TopicTitle title={messages.paymentTitle.defaultMessage} />
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <AvField
                            label={messages.lockPixPayment.defaultMessage}
                            type='checkbox'
                            name={messages.lockPixPayment.id}
                            id={messages.lockPixPayment.id}
                            value={settings?.lockPixPayment}
                            checked={settings?.lockPixPayment}
                            style={checkboxStyle}
                        />
                    </Col>

                    <Col xs={12} md={6}>
                        <AvField
                            label={
                                messages.groupCollectionsForTransactions
                                    .defaultMessage
                            }
                            type='checkbox'
                            name={messages.groupCollectionsForTransactions.id}
                            id={messages.groupCollectionsForTransactions.id}
                            value={settings?.groupCollectionsForTransactions}
                            checked={settings?.groupCollectionsForTransactions}
                            style={checkboxStyle}
                        />
                    </Col>
                </Row>
            </div>

            <div className='mb-5'>
                <Row>
                    <TopicTitle title={messages.emailTitle.defaultMessage} />
                </Row>

                <Row>
                    <Col xs={12}>
                        <AvField
                            label={
                                messages.allowSendEmailOnlyForThisDomains
                                    .defaultMessage
                            }
                            type='text'
                            value={
                                settings?.email.allowSendEmailOnlyForThisDomains
                            }
                            name={messages.allowSendEmailOnlyForThisDomains.id}
                            id={messages.allowSendEmailOnlyForThisDomains.id}
                            validate={{
                                myValidation: validation,
                            }}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={12} md={4}>
                        <AvField
                            label={
                                messages.maxMontlySendedEmailNumber
                                    .defaultMessage
                            }
                            type='number'
                            value={settings?.email.maxMontlySendedEmailNumber}
                            name={messages.maxMontlySendedEmailNumber.id}
                            id={messages.maxMontlySendedEmailNumber.id}
                        />
                    </Col>

                    <Col xs={12} md={3}>
                        <AvField
                            label={messages.isActiveEmail.defaultMessage}
                            type='checkbox'
                            name={messages.isActiveEmail.id}
                            id={messages.isActiveEmail.id}
                            value={settings?.email.isActive}
                            checked={settings?.email.isActive}
                            style={checkboxStyle}
                        />
                    </Col>

                    <Col xs={12} md={5}>
                        <AvField
                            label={
                                messages.allowSendEmailOnlyOverdueCollections
                                    .defaultMessage
                            }
                            type='checkbox'
                            name={
                                messages.allowSendEmailOnlyOverdueCollections.id
                            }
                            id={
                                messages.allowSendEmailOnlyOverdueCollections.id
                            }
                            value={
                                settings?.email
                                    .allowSendEmailOnlyOverdueCollections
                            }
                            style={checkboxStyle}
                            checked={
                                settings?.email
                                    .allowSendEmailOnlyOverdueCollections
                            }
                        />
                    </Col>
                </Row>
            </div>

            <div className='mb-5'>
                <Row>
                    <TopicTitle title={messages.smsTitle.defaultMessage} />
                </Row>

                <Row>
                    <Col xs={12} md={4}>
                        <AvField
                            label={
                                messages.allowSendSMSOnlyForThisNumber
                                    .defaultMessage
                            }
                            type='text'
                            value={settings?.sms.allowSendSMSOnlyForThisNumber}
                            name={messages.allowSendSMSOnlyForThisNumber.id}
                            id={messages.allowSendSMSOnlyForThisNumber.id}
                        />
                    </Col>

                    <Col xs={12} md={4}>
                        <AvField
                            label={
                                messages.maxMontlySendedSmsNumber.defaultMessage
                            }
                            type='number'
                            value={settings?.sms.maxMontlySendedSmsNumber}
                            name={messages.maxMontlySendedSmsNumber.id}
                            id={messages.maxMontlySendedSmsNumber.id}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <AvField
                            label={messages.isActiveSms.defaultMessage}
                            type='checkbox'
                            name={messages.isActiveSms.id}
                            id={messages.isActiveSms.id}
                            value={settings?.sms.isActive}
                            style={checkboxStyle}
                            checked={settings?.sms.isActive}
                        />
                    </Col>
                </Row>
            </div>
            <Row className='mt-3'>
                <Col xs={12}>
                    <br />
                    <div className='mt-3'>
                        <button type='submit' className='btn-submit'>
                            Salvar
                        </button>
                    </div>
                </Col>
            </Row>
        </AvForm>
    );
};
