import React from 'react';

const DetailSection = ({ title, children }) => {
    return (
        <div style={{ width: '100%', marginBottom: 10 }}>
            <p
                className='text-muted subtitle-page'
                style={{ marginBottom: 5, marginLeft: 5, color: '#323232' }}
            >
                {title}
            </p>
            <div
                style={{
                    borderWidth: 2,
                    borderRadius: 10,
                    borderColor: '#323232',
                    borderStyle: 'solid',
                    width: '100%',
                    padding: 10,
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default DetailSection;
