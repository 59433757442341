import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    transactionConversion: {
        id: 'dashboard.transactionConversion',
        defaultMessage: 'Transactions Conversion',
    },
});
