import * as actionTypes from './actionTypes';

export const updateCurrentPage = (pageNumber, path, index) => (dispatch) => {
    dispatch({
        type: actionTypes.UI_CONTEXT_PAGE_UPDATE,
        payload: { pageNumber, path, index },
    });
};

export const updateOrdenation = (path, ordenation) => (dispatch) => {
    dispatch({
        type: actionTypes.UI_CONTEXT_ORDENATION_UPDATE,
        payload: { ordenation, path },
    });
};

export const updateCurrentFilter = (
    path,
    filter,
    index,
    pageNumber = 0,
    ordenation,
) => (dispatch) => {
    dispatch({
        type: actionTypes.UI_CONTEXT_FILTER_UPDATE,
        payload: { filter, path, index, pageNumber, ordenation },
    });
};

export const resetCurrentFilter = (path) => (dispatch) => {
    dispatch({
        type: actionTypes.UI_CONTEXT_FILTER_RESET,
        payload: { path },
    });
};

export const changeTabTarifications = (path, index) => (dispatch) => {
    dispatch({
        type: actionTypes.CHANGE_TAB_TARIFICATIONS,
        payload: { path, index },
    });
};
