import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';

import * as actions from '../../store/actions/index';

import Tabs from '../../components/Tabs/Tabs';
import Header from '../../components/Header/Header';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import CollectionDetailsForm from '../../components/CollectionDetailsForm/CollectionDetailsForm';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './CollectionDetailsPage.intl';
import { AuthContext } from '../../context/AuthContext';
import CollectionFileForm from '../../components/CollectionFileForm/CollectionFileForm';

class CollectionDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        activeTab: 0,
        showModal: false,
    };

    componentDidMount() {
        this.props.onInitCollectionDetails(this.props.match.params.id);
    }

    handleNewAttachmentSaveButtonClick = (file) => {
        this.props.onUploadCollectionAttachment(file);
        this.props.history.push(`/collections`);
    };

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }
    };

    handleSubmit = (event, values) => {
        if (this.props.loadingDetails) return;

        /* if (!values.contractorId) {
            const { contractorId } = this.context;
            values.contractorId = contractorId;
        } */

        if (this.props.match.params.id === 'new') {
            this.props.onCreateCollection(values);
        } else {
            this.props.onUpdateCollection(this.props.match.params.id, values);
        }
    };

    handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handleToggleDelete = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
        this.props.onDeleteCollection(this.props.collection.collectionId);
    };

    handleContractorsChange = (selectedContractor) => {
        this.setState({
            selectedContractor: {
                selectedLabelOption: selectedContractor.label,
                selectedValueOption: selectedContractor.value,
            },
        });
    };

    render() {
        const { intl, loadingDetails, loadingAttachment } = this.props;

        const isNew = this.props.match.params.id === 'new';

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/collections',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/collections/:id',
                            name: intl.formatMessage(messages.titleCollection, {
                                entity:
                                    this.props.collection &&
                                    this.props.collection.collectionDescription,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(
                                messages.titleCollection,
                                {
                                    entity: '',
                                },
                            )}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content collectionDetails'>
                    <Loading loading={loadingDetails} />
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={
                            isNew
                                ? [
                                      {
                                          name: 'Importar Arquivo',
                                          component: (
                                              <CollectionFileForm
                                                  key='filesTab'
                                                  handleNewAttachmentSaveButtonClick={
                                                      this
                                                          .handleNewAttachmentSaveButtonClick
                                                  }
                                                  loadingAttachment={
                                                      loadingAttachment
                                                  }
                                              />
                                          ),
                                      },
                                  ]
                                : [
                                      {
                                          name: intl.formatMessage(
                                              messages.registrationInfo,
                                          ),
                                          component: (
                                              <CollectionDetailsForm
                                                  collection={
                                                      this.props.collection
                                                  }
                                                  handleSubmit={
                                                      this.handleSubmit
                                                  }
                                                  handleContractorsChange={
                                                      this
                                                          .handleContractorsChange
                                                  }
                                                  handleDelete={
                                                      this.handleToggleModal
                                                  }
                                                  isNew={isNew}
                                                  loading={
                                                      this.props.loadingDetails
                                                  }
                                                  selectedContractor={
                                                      this.state
                                                          .selectedContractor
                                                  }
                                              />
                                          ),
                                      },
                                  ]
                        }
                    />
                </section>
                <Modal
                    title={intl.formatMessage(messages.deleteCollection)}
                    isOpen={this.state.showModal}
                    handleToggle={this.handleToggleModal}
                    type={modalTypes.ERROR}
                    hasFooter
                    firstButtonText='Sim'
                    secondButtonText='Não'
                    toggleFirstButton={this.handleToggleDelete}
                    toggleSecondButton={this.handleToggleModal}
                >
                    {intl.formatMessage(messages.confirmDeleteText)}
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        collection: state.collectionReducer.collection,
        loadingDetails: state.collectionReducer.loadingDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitCollectionDetails: (collectionId) =>
            dispatch(actions.initCollectionDetails(collectionId)),
        onUpdateCollection: (collectionId, values) =>
            dispatch(actions.updateCollection(collectionId, values)),
        onCreateCollection: (values) =>
            dispatch(actions.createCollection(values)),
        onUploadCollectionAttachment: (file) =>
            dispatch(actions.uploadCollectionAttachment(file)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(CollectionDetailsPage),
);
