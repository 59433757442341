import Dashboard from '../containers/Dashboard/Dashboard';
import ContactListPage from '../containers/ContactsListPage/ContactsListPage';
import ContactDetailsPage from '../containers/ContactsDetailsPage/ContactsDetailsPage';
import ContractorListPage from '../containers/ContractorListPage/ContractorListPage';
import ContractorDetailsPage from '../containers/ContractorDetailsPage/ContractorDetailsPage';
import CollectionListPage from '../containers/CollectionListPage/CollectionListPage';
import CollectionDetailsPage from '../containers/CollectionDetailsPage/CollectionDetailsPage';
import ParameterListPage from '../containers/ParameterListPage/ParameterListPage';
import ParameterDetailsPage from '../containers/ParameterDetailsPage/ParameterDetailsPage';
import UserListPage from '../containers/UserListPage/UserListPage';
import UserDetailsPage from '../containers/UserDetailsPage/UserDetailsPage';
import SettingsPage from '../containers/SettingsPage/SettingsPage';
import TransactionListPage from '../containers/TransactionListPage/TransactionListPage';
import TransactionDetailsPage from '../containers/TransactionDetalisPage/TransactionDetailsPage';
import FeeMessagesDetailsPage from '../containers/FeeMessagesDetalisPage/FeeMessagesDetailsPage';
import RoleListPage from '../containers/RoleListPage/RoleListPage';
import RoleDetailsPage from '../containers/RoleDetailsPage/RoleDetailsPage';
import LogListPage from '../containers/LogListPage/LogListPage';
import ClientListPage from '../containers/ClientListPage/ClientListPage';
import ClientDetailsPage from '../containers/ClientDetailsPage/ClientDetailsPage';
import FinancialDocumentDetailsPage from '../containers/FinancialDocumentDetailsPage/FinancialDocumentDetailsPage';
import FinancialDocumentsListPage from '../containers/FinancialDocumentsListPage/FinancialDocumentstListPage';
import ReconciliationListPage from '../containers/ReconciliationListPage/ReconciliationListPage';
import BillingOfficeDetailsPage from '../containers/BillingOfficeDetailsPage/BillingOfficeDetailsPage';

const indexRoutes = [
    {
        path: '/home',
        name: 'Dashboard',
        component: Dashboard,
        claim: '',
    },
    {
        path: '/contacts',
        name: 'ContactListPage',
        component: ContactListPage,
        claim: 'external-user-view',
    },
    {
        path: '/contacts/:id',
        name: 'ContactDetailsPage',
        component: ContactDetailsPage,
        claim: 'external-user-view',
    },
    {
        path: '/contractors',
        name: 'ContractorListPage',
        component: ContractorListPage,
        claim: 'contractor-view',
    },
    {
        path: '/contractors/:id',
        name: 'ContractorDetailsPage',
        component: ContractorDetailsPage,
        claim: 'contractor-view',
    },
    {
        path: '/billingOffice/:id',
        name: 'BillingOfficeDetailsPage',
        component: BillingOfficeDetailsPage,
        claim: 'contractor-view',
    },
    {
        path: '/collections',
        name: 'CollectionListPage',
        component: CollectionListPage,
        claim: 'collection-view',
    },
    {
        path: '/collections/:id',
        name: 'CollectionDetailsPage',
        component: CollectionDetailsPage,
        claim: 'collection-view',
    },
    {
        path: '/parameters',
        name: 'ParameterListPage',
        component: ParameterListPage,
        claim: 'parameter-view',
    },
    {
        path: '/parameters/:id',
        name: 'ParameterDetailsPage',
        component: ParameterDetailsPage,
        claim: 'parameter-view',
    },
    {
        path: '/roles',
        name: 'RoleListPage',
        component: RoleListPage,
        claim: 'role-view',
    },
    {
        path: '/roles/:id',
        name: 'RoleDetailsPage',
        component: RoleDetailsPage,
        claim: 'role-view',
    },
    {
        path: '/settings',
        name: 'SettingsPage',
        component: SettingsPage,
        claim: '',
    },
    {
        path: '/transactions',
        name: 'TransactionListPage',
        component: TransactionListPage,
        claim: 'transaction-view',
    },
    {
        path: '/transactions/:id',
        name: 'TransactionDetailsPage',
        component: TransactionDetailsPage,
        claim: 'transaction-view',
    },
    {
        path: '/users',
        name: 'UserListPage',
        component: UserListPage,
        claim: 'user-view',
    },
    {
        path: '/users/:id',
        name: 'UserDetailsPage',
        component: UserDetailsPage,
        claim: 'user-view',
    },
    {
        path: '/logs',
        name: 'LogListPage',
        component: LogListPage,
        claim: '',
    },
    {
        path: '/feeMessages/:id',
        name: 'FeeMessagesDetailsPage',
        component: FeeMessagesDetailsPage,
        claim: 'fee-transaction-rule-view',
    },
    {
        path: '/clients',
        name: 'ClientListPage',
        component: ClientListPage,
        claim: 'client-view',
    },
    {
        path: '/clients/:id',
        name: 'ClientDetailsPage',
        component: ClientDetailsPage,
        claim: 'client-view',
    },
    {
        path: '/financialDocuments',
        name: 'FinancialDocumentsListPage',
        component: FinancialDocumentsListPage,
        claim: 'financial-document-view',
    },
    {
        path: '/financialDocuments/:id',
        name: 'FinancialDocumentDetailsPage',
        component: FinancialDocumentDetailsPage,
        claim: 'financial-document-view',
    },
    {
        path: '/reconciliation',
        name: 'ReconciliationListPage',
        component: ReconciliationListPage,
        claim: 'reconciliation-view',
    },
];

export default indexRoutes;
