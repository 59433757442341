/* eslint-disable no-restricted-syntax */
import { toast } from 'react-toastify';
import axios from 'axios';
import { push } from 'connected-react-router';
import * as actionTypes from './actionTypes';

export const initRoles = (filter) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ROLES_GET_LIST_REQUEST,
        });

        axios
            .get(`/roles${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.ROLES_GET_LIST_SUCCESS,
                    roles: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.ROLES_GET_LIST_FAILURE,
                    error,
                });
                toast.error(
                    `Ocorreu um erro ao carregar a lista de roles. ${
                        error.response.data
                    }`,
                );
            });
    };
};

export const initRolesCount = (filter) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.ROLES_GET_COUNT_REQUEST,
        });

        await axios
            .get(`/Roles/Count${filter}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.ROLES_GET_COUNT_SUCCESS,
                    rolesCount: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.ROLES_GET_COUNT_FAILURE,
                    error,
                });
                toast.error(
                    `Ocorreu um erro ao calcular a quantidade de roles. ${
                        error.response.data
                    }`,
                );
            });
    };
};

export const initRolesDetails = (roleId) => {
    if (roleId === 'new') {
        return (dispatch) => {
            dispatch({
                type: actionTypes.ROLE_GET_DETAILS_NEW,
            });
        };
    }
    return (dispatch) => {
        dispatch({
            type: actionTypes.ROLES_GET_DETAILS_REQUEST,
        });

        axios
            .get(`/roles/${roleId}`)
            .then((response) => {
                dispatch({
                    type: actionTypes.ROLES_GET_DETAILS_SUCCESS,
                    role: response.data,
                });

                return axios
                    .get(`/Contractors/${response.data.contractorId}/Roles`)
                    .then((response) => {
                        dispatch({
                            type: actionTypes.ROLES_CONTRACTOR_GET_LIST_SUCCESS,
                            rolesContractor: response.data,
                        });
                    })
                    .catch((error) => {
                        dispatch({
                            type: actionTypes.ROLES_CONTRACTOR_GET_LIST_FAILURE,
                            error,
                        });
                        toast.error(
                            `Ocorreu um erro ao carregar a lista de roles. ${
                                error.response.data
                            }`,
                        );
                    });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.ROLES_GET_DETAILS_FAILURE,
                    error,
                });
                let errorMessage = '';
                if (error.response) {
                    errorMessage = error.response.data;
                } else if (error.request) {
                    errorMessage = error.request;
                } else {
                    errorMessage = error.message;
                }
                toast.error(
                    `Ocorreu um erro ao carregar a role. ${errorMessage}`,
                );
            });
    };
};

export const createRole = (values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ROLE_POST_REQUEST,
        });

        axios
            .post(`/Roles`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.ROLE_POST_SUCCESS,
                });
                toast.success('Perfil criado com sucesso');
                dispatch(push('/roles'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.ROLE_POST_FAILURE,
                    error,
                });

                let errorMessage = '';
                if (error.response) {
                    errorMessage = error.response.data;
                } else if (error.request) {
                    errorMessage = error.request;
                } else {
                    errorMessage = error.message;
                }
                toast.error(
                    `Ocorreu um erro ao criar o perfil. ${errorMessage}`,
                );
            });
    };
};

export const updateRole = (values) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ROLE_PUT_REQUEST,
        });
        axios
            .put(`/roles`, { ...values })
            .then(function() {
                dispatch({
                    type: actionTypes.ROLE_PUT_SUCCESS,
                });
                toast.success('Perfil alterado com sucesso');
                dispatch(push('/roles'));
            })
            .catch(function(error) {
                dispatch({
                    type: actionTypes.ROLE_PUT_FAILURE,
                    error,
                });
                let errorMessage = '';
                if (error.response) {
                    errorMessage = error.response.data;
                } else if (error.request) {
                    errorMessage = error.request;
                } else if (error.message) {
                    errorMessage = error.message;
                }
                errorMessage &&
                    toast.error(
                        `Ocorreu um erro ao atualizar o perfil. ${errorMessage}`,
                    );
            });
    };
};

export const changeSelectedUserRole = (role, checked) => {
    return (dispatch, getState) => {
        const roles = getState().roleReducer.checkedRoles;
        const final = [];

        for (const key in roles) {
            if (key !== role) {
                final[key] = true;
            }
        }

        if (checked) {
            final[role] = true;
        }

        dispatch({
            type: actionTypes.ROLES_SET_ROLES_SUCCESS,
            checkedRoles: final,
        });
    };
};

export const checkAllClaims = (checked) => {
    return (dispatch, getState) => {
        const claims = getState().claimReducer.claims;
        const checkedClaims = [];

        claims.forEach((c) => (checkedClaims[c.value] = checked));
        dispatch({
            type: actionTypes.ROLES_SET_CLAIMS_SUCCESS,
            checkedClaims,
        });
    };
};

export const changeSelectedClaim = (claim, checked) => {
    return (dispatch, getState) => {
        const claims = getState().roleReducer.checkedClaims;
        const final = [];

        for (const key in claims) {
            if (key !== claim) {
                final[key] = true;
            }
        }

        if (checked) {
            final[claim] = true;
        }

        dispatch({
            type: actionTypes.ROLES_SET_CLAIMS_SUCCESS,
            checkedClaims: final,
        });
    };
};

export const changeSelectedRoleLevel = (level, checked) => {
    return (dispatch, getState) => {
        const levels = getState().roleReducer.checkedRoleLevel;
        const final = [];

        for (const key in levels) {
            if (key !== level) {
                final[key] = true;
            }
        }

        if (checked) {
            final[level] = true;
        }

        dispatch({
            type: actionTypes.ROLES_SET_ROLE_LEVELS_SUCCESS,
            checkedRoleLevel: final,
        });
    };
};

export const initCleanRole = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ROLE_CLEANUP,
        });
    };
};

export const cleanRoleLevels = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ROLES_CLEANUP_ROLE_LEVELS_SUCCESS,
        });
    };
};

export const setSelectedRole = (role) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ROLE_SET_REQUEST,
        });
        dispatch({
            type: actionTypes.ROLE_SET_SUCCESS,
            role,
        });
    };
};

export const uncheckAllClaims = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ROLES_SET_CLAIMS_SUCCESS,
            checkedClaims: [],
        });
    };
};
