import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    clientId: {
        id: 'clients.clientId',
        default: 'Id cliente',
    },

    name: {
        id: 'clients.name',
        defaultMessage: 'Nome',
    },

    cpfCnpj: {
        id: 'clients.cpfCnpj',
        defaultMessage: 'CPF/CNPJ',
    },

    contractorId: {
        id: 'clients.contractorId',
        defaultMessage: 'ID contratante',
    },

    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },

    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },
});

export default messages;
