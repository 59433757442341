import React, { useState, useEffect } from 'react';

import { injectIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Media, Button } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import messages from './ContractorFileForm.intl';

import Loading from '../Loading/Loading';

function ContractorFileForm(props) {
    const [logo, setLogo] = useState(null);
    const [logoDataUrl, setLogoDataUrl] = useState(null);
    const [showSaveLogoButton, setShowSaveLogoButton] = useState(false);

    const [smallLogo, setSmallLogo] = useState(null);
    const [smallLogoDataUrl, setSmallLogoDataUrl] = useState(null);
    const [showSaveSmallLogoButton, setShowSaveSmallLogoButton] = useState(
        false,
    );

    const [customerAppLogo, setCustomerAppLogo] = useState({
        logo: '',
        data: '',
    });
    const [
        showSaveCustomerAppLogoButton,
        setShowSaveCustomerAppLogoButton,
    ] = useState(false);

    const [attachment, setAttachment] = useState(null);
    const [attachmentDataUrl, setAttachmentDataUrl] = useState(null);
    const [showSaveAttachmentButton, setShowSaveAttachmentButton] = useState(
        false,
    );

    const {
        intl,
        loadingNormalLogo,
        loadingSmallLogo,
        loadingCustomerAppLogo,
        loadingAttachment,
        readOnly,
        contractor,
        isSystem,
    } = props;

    const {
        normalLogoFileId,
        smallLogoFileId,
        customerAppLogoFileId,
        attachmentFileId,
    } = contractor || {};

    useEffect(() => {
        setSmallLogoDataUrl(
            smallLogoFileId
                ? `${window.API_URI}/Contractors/${
                      contractor.contractorId
                  }/SmallLogo`
                : 'https://place-hold.it/150',
        );
        setCustomerAppLogo({
            ...customerAppLogo,
            data: customerAppLogoFileId
                ? `${window.API_URI}/Contractors/${
                      contractor.contractorId
                  }/CustomerAppLogo`
                : 'https://place-hold.it/150',
        });
        setLogoDataUrl(
            normalLogoFileId
                ? `${window.API_URI}/Contractors/${
                      contractor.contractorId
                  }/Logo`
                : 'https://place-hold.it/300x150',
        );

        setShowSaveLogoButton(false);
        setShowSaveSmallLogoButton(false);
        setShowSaveCustomerAppLogoButton(false);
    }, [
        contractor.contractorId,
        smallLogoFileId,
        customerAppLogoFileId,
        normalLogoFileId,
    ]);

    useEffect(() => {
        setAttachmentDataUrl(
            attachmentFileId
                ? `${window.API_URI}/Contractors/${
                      contractor.contractorId
                  }/Attachment`
                : null,
        );

        setShowSaveAttachmentButton(false);
    }, [contractor.contractorId, attachmentFileId]);

    function handleLogoFileChange(e) {
        const fileReader = new FileReader();

        fileReader.onload = () => {
            setLogoDataUrl(fileReader.result);
            setShowSaveLogoButton(true);
        };

        setLogo(e.target.files[0]);
        fileReader.readAsDataURL(e.target.files[0]);
    }

    function handleSmallLogoFileChange(e) {
        const fileReader = new FileReader();

        fileReader.onload = () => {
            setSmallLogoDataUrl(fileReader.result);
            setShowSaveSmallLogoButton(true);
        };

        setSmallLogo(e.target.files[0]);
        fileReader.readAsDataURL(e.target.files[0]);
    }

    function handleCustomerAppLogoFileChange(e) {
        const fileReader = new FileReader();
        const logo = e.target.files[0];

        fileReader.onload = () => {
            setCustomerAppLogo({
                logo,
                data: fileReader.result,
            });
            setShowSaveCustomerAppLogoButton(true);
        };

        setCustomerAppLogo({
            ...customerAppLogo,
            logo,
        });
        fileReader.readAsDataURL(e.target.files[0]);
    }

    function handleAttachmentFileChange(e) {
        const fileReader = new FileReader();

        fileReader.onload = () => {
            setAttachmentDataUrl(fileReader.result);
            setShowSaveAttachmentButton(true);
        };

        setAttachment(e.target.files[0]);
        fileReader.readAsDataURL(e.target.files[0]);
    }

    function handleNewLogoSaveButtonClick(e) {
        e.preventDefault();

        props.handleNewLogoFileSaveButtonClick &&
            props.handleNewLogoFileSaveButtonClick(logo);

        setShowSaveLogoButton(false);
    }

    function handleNewSmallLogoSaveButtonClick(e) {
        e.preventDefault();

        props.handleNewSmallLogoSaveButtonClick &&
            props.handleNewSmallLogoSaveButtonClick(smallLogo);

        setShowSaveSmallLogoButton(false);
    }

    function handleNewCustomerAppLogoSaveButtonClick(e) {
        e.preventDefault();

        props.handleNewCustomerAppLogoSaveButtonClick &&
            props.handleNewCustomerAppLogoSaveButtonClick(customerAppLogo.logo);

        setShowSaveCustomerAppLogoButton(false);
    }

    function handleNewAttachmentSaveButtonClick(e) {
        e.preventDefault();

        props.handleNewAttachmentSaveButtonClick &&
            props.handleNewAttachmentSaveButtonClick(attachment);

        setShowSaveAttachmentButton(false);
    }

    return (
        <AvForm onValidSubmit={props.handleSubmit}>
            <Row>
                <Col xs={12} md={6}>
                    {loadingNormalLogo && <Loading loading />}
                    <Media object src={logoDataUrl} height='150px' />
                    <br />
                    <br />
                    <Row>
                        <Col xs={8}>
                            <AvField
                                label={intl.formatMessage(
                                    messages.logoNormalText,
                                )}
                                type='file'
                                onChange={handleLogoFileChange}
                                name='normalLogoFileId'
                                id='normalLogoFileId'
                                disabled={readOnly}
                                accept='image/x-png,image/gif,image/jpeg'
                            />
                        </Col>
                        {showSaveLogoButton && (
                            <Col xs={4} className='mt-3'>
                                <Button
                                    className='btn-submit'
                                    onClick={handleNewLogoSaveButtonClick}
                                >
                                    Confirmar
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col xs={12} md={6}>
                    {loadingSmallLogo && <Loading loading />}
                    <Media object src={smallLogoDataUrl} height='150px' />
                    <br />
                    <br />
                    <Row>
                        <Col xs={8}>
                            <AvField
                                label={intl.formatMessage(
                                    messages.logoMiniText,
                                )}
                                type='file'
                                onChange={handleSmallLogoFileChange}
                                name='smallLogoFileId'
                                id='smallLogoFileId'
                                disabled={readOnly}
                            />
                        </Col>
                        {showSaveSmallLogoButton && (
                            <Col xs={4} className='mt-3'>
                                <Button
                                    className='btn-submit'
                                    onClick={handleNewSmallLogoSaveButtonClick}
                                >
                                    Confirmar
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            <Row>
                {isSystem && (
                    <Col xs={12} md={6}>
                        {loadingCustomerAppLogo && <Loading loading />}
                        <Media
                            object
                            src={customerAppLogo.data}
                            height='150px'
                        />
                        <br />
                        <br />
                        <Row>
                            <Col xs={8}>
                                <AvField
                                    label={intl.formatMessage(
                                        messages.logoCustomerApp,
                                    )}
                                    type='file'
                                    onChange={handleCustomerAppLogoFileChange}
                                    name='customerAppLogoFileId'
                                    id='customerAppLogoFileId'
                                    disabled={readOnly}
                                />
                            </Col>
                            {showSaveCustomerAppLogoButton && (
                                <Col xs={4} className='mt-3'>
                                    <Button
                                        className='btn-submit'
                                        onClick={
                                            handleNewCustomerAppLogoSaveButtonClick
                                        }
                                    >
                                        Confirmar
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Col>
                )}
                {loadingAttachment && <Loading loading />}
                {attachmentDataUrl && (
                    <Col xs={2} md={1} className='mt-4 text-center'>
                        <a href={attachmentDataUrl} download='attachment'>
                            <FontAwesomeIcon icon='download' size='3x' />
                        </a>
                    </Col>
                )}
                <Col xs={8} md={attachmentDataUrl ? 10 : 12}>
                    <Row>
                        <Col xs={8}>
                            <AvField
                                label='Anexo'
                                type='file'
                                onChange={handleAttachmentFileChange}
                                name='attachmentFileId'
                                id='attachmentFileId'
                                disabled={readOnly}
                            />
                        </Col>
                        {showSaveAttachmentButton && (
                            <Col xs={4} className='mt-3'>
                                <Button
                                    className='btn-submit'
                                    onClick={handleNewAttachmentSaveButtonClick}
                                >
                                    Confirmar
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </AvForm>
    );
}

export default injectIntl(ContractorFileForm);
