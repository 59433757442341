import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Container } from 'reactstrap';

import * as actions from '../../store/actions/index';

import Tabs from '../../components/Tabs/Tabs';
import Header from '../../components/Header/Header';
import Modal, { modalTypes } from '../../components/Modal/Modal';
import FeeMessagesDetailsForm from '../../components/FeeMessagesDetailsForm/FeeMessagesDetailsForm';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Loading from '../../components/Loading/Loading';
import messages from './FeeMessagesDetailsPage.intl';
import { AuthContext } from '../../context/AuthContext';

class FeeMessagesDetailsPage extends Component {
    static contextType = AuthContext;

    state = {
        activeTab: 0,
        showModal: false,
    };

    componentDidMount() {
        this.props.onInitFeeMessageDetails(this.props.match.params.id);
        this.props.onInitFeeMessagesType();
        this.props.onInitContractorsFeeMessage();
    }

    handleTabClick = (e, index) => {
        if (this.state.activeTab !== index) {
            this.setState({
                activeTab: index,
            });
        }
    };

    handleSubmit = (event, values) => {
        if (this.props.loadingDetails) return;
        values.value = parseFloat(
            values.value
                .replace('R$', '')
                .replace('%', '')
                .replace(',', '.'),
        );
        if (values.contractorId === 'Todos') {
            values.contractorId = null;
        }

        values.feePayableType = parseInt(values.feePayableType, 10);
        values.expireAt = new Date(values.expireAt);

        if (values.contractorId === '') {
            values.contractorId = null;
        }

        if (this.props.match.params.id === 'new') {
            this.props.onCreateFeeMessage(values);
        } else {
            this.props.onUpdateFeeMessage(values);
        }
    };

    handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handleToggleDelete = async () => {
        this.setState({
            showModal: !this.state.showModal,
        });

        await this.props.onDeleteFeeMessage(this.props.feeMessage.id);
    };

    handleFeeMessageChange = (selectedFeeMessage) => {
        this.setState({
            selectedFeeMessage: {
                selectedLabelOption: selectedFeeMessage.label,
                selectedValueOption: selectedFeeMessage.value,
            },
        });
    };

    render() {
        const { intl, loadingDetails } = this.props;

        const isNew = this.props.match.params.id === 'new';

        const canDeleteFeeMessage =
            this.context.hasClaim('fee-transaction-rule-delete') && !isNew;

        return (
            <Container fluid>
                <Breadcrumb
                    routes={[
                        {
                            path: '/home',
                            name: intl.formatMessage(messages.home),
                            active: false,
                        },
                        {
                            path: '/tarifications',
                            name: intl.formatMessage(messages.title),
                            active: false,
                        },
                        {
                            path: '/feemessages/:id',
                            name: intl.formatMessage(messages.titleFeeMessage, {
                                entity:
                                    this.props.feeMessage &&
                                    this.props.feeMessage.feeMessageDescription,
                            }),
                            active: true,
                        },
                    ]}
                />
                <header className='table-screen'>
                    <section className='title'>
                        <Header
                            title={intl.formatMessage(
                                messages.titleFeeMessage,
                                {
                                    entity: '',
                                },
                            )}
                        />
                    </section>
                </header>
                <section className='content-middle bg-content feeMessageDetails'>
                    <Loading loading={loadingDetails} />
                    <Tabs
                        handleTabClick={this.handleTabClick}
                        activeTab={this.state.activeTab}
                        tabs={[
                            {
                                name: intl.formatMessage(
                                    messages.registrationInfo,
                                ),
                                component: (
                                    <FeeMessagesDetailsForm
                                        canDeleteFeeMessage={
                                            canDeleteFeeMessage
                                        }
                                        feeMessage={this.props.feeMessage}
                                        handleSubmit={this.handleSubmit}
                                        handleFeeMessageChange={
                                            this.handleFeeMessageChange
                                        }
                                        handleDelete={this.handleToggleModal}
                                        isNew={isNew}
                                        loading={this.props.loadingDetails}
                                        selectedFeeMessage={
                                            this.state.selectedFeeMessage
                                        }
                                        feeMessagesTypes={
                                            this.props.feeMessagesTypes
                                        }
                                        contractors={this.props.contractors}
                                    />
                                ),
                            },
                        ]}
                    />
                </section>
                <Modal
                    title={intl.formatMessage(messages.deleteFeeMessage)}
                    isOpen={this.state.showModal}
                    handleToggle={this.handleToggleModal}
                    type={modalTypes.ERROR}
                    hasFooter
                    firstButtonText={intl.formatMessage(messages.yesText)}
                    secondButtonText={intl.formatMessage(messages.noText)}
                    toggleFirstButton={this.handleToggleDelete}
                    toggleSecondButton={this.handleToggleModal}
                >
                    {intl.formatMessage(messages.confirmDeleteText)}
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        feeMessage: state.feeMessageReducer.feeMessage,
        feeMessagesTypes: state.feeMessageReducer.feeMessagesTypes,
        contractors: state.feeMessageReducer.contractors,
        loadingDetails: state.feeMessageReducer.loadingDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitFeeMessageDetails: (feeMessageId) =>
            dispatch(actions.initFeeMessageDetails(feeMessageId)),
        onUpdateFeeMessage: (feeMessageId, values) =>
            dispatch(actions.updateFeeMessage(feeMessageId, values)),
        onCreateFeeMessage: (values) =>
            dispatch(actions.createFeeMessage(values)),
        onInitFeeMessagesType: () => dispatch(actions.initFeeMessagesTypes()),
        onInitContractorsFeeMessage: () =>
            dispatch(actions.initContractorsFeeMessage()),
        onDeleteFeeMessage: (values) =>
            dispatch(actions.deleteFeeMessage(values)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(FeeMessagesDetailsPage),
);
