import * as actionTypes from '../actions/actionTypes';

const initialState = {};

const setReconciliation = (state, action) => {
    return {
        ...state,
        reconciliationData: action.reconciliationData,
        loadingList: false,
    };
};
const setReconciliationRequest = (state) => {
    return {
        ...state,
        reconciliationData: undefined,
        loadingList: true,
    };
};

const setPaymentReceived = (state, action) => {
    return {
        ...state,
        paymentReceivedData: action.paymentReceivedData,
        loadingPaymentReceivedList: false,
    };
};
const setPaymentReceivedRequest = (state) => {
    return {
        ...state,
        paymentReceivedData: undefined,
        loadingPaymentReceivedList: true,
    };
};

const setReconciliationCount = (state, action) => {
    return {
        ...state,
        reconciliationCount: action.reconciliationCount,
    };
};

const setPaymentReceivedCount = (state, action) => {
    return {
        ...state,
        paymentReceivedCount: action.paymentReceivedCount,
    };
};

const setReconciliationAudit = (state, action) => {
    return {
        ...state,
        reconciliationAuditData: action.reconciliationAuditData,
        loadingReconciliationAuditList: false,
    };
};
const setReconciliationAuditRequest = (state) => {
    return {
        ...state,
        reconciliationAuditData: undefined,
        loadingReconciliationAuditList: true,
    };
};

const setReconciliationAuditCount = (state, action) => {
    return {
        ...state,
        reconciliationAuditCount: action.reconciliationAuditCount,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RECONCILIATION_GET_SUCCESS:
        case actionTypes.RECONCILIATION_GET_FAILURE:
            return setReconciliation(state, action);

        case actionTypes.RECONCILIATION_GET_REQUEST:
            return setReconciliationRequest(state, action);

        case actionTypes.RECONCILIATION_COUNT_GET_SUCCESS:
            return setReconciliationCount(state, action);

        case actionTypes.PAYMENTRECEIVED_GET_SUCCESS:
        case actionTypes.PAYMENTRECEIVED_GET_FAILURE:
            return setPaymentReceived(state, action);

        case actionTypes.PAYMENTRECEIVED_GET_REQUEST:
            return setPaymentReceivedRequest(state, action);

        case actionTypes.PAYMENTRECEIVED_COUNT_GET_SUCCESS:
            return setPaymentReceivedCount(state, action);

        case actionTypes.RECONCILIATION_AUDIT_GET_SUCCESS:
        case actionTypes.RECONCILIATION_AUDIT_GET_FAILURE:
            return setReconciliationAudit(state, action);

        case actionTypes.RECONCILIATION_AUDIT_GET_REQUEST:
            return setReconciliationAuditRequest(state, action);

        case actionTypes.RECONCILIATION_AUDIT_COUNT_GET_SUCCESS:
            return setReconciliationAuditCount(state, action);
        default:
            return state;
    }
};

export default reducer;
