import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'contacts.title',
        defaultMessage: 'Usuários',
    },

    titleContact: {
        id: 'contacts.titleContact',
        defaultMessage: 'Usuário {entity}',
    },

    registrationInfo: {
        id: 'contacts.registrationInfo',
        defaultMessage: 'Dados Cadastrais',
    },

    access: {
        id: 'contacts.access',
        defaultMessage: 'Acessos',
    },

    deleteContact: {
        id: 'contacts.deleteContact',
        defaultMessage: 'Excluir Usuário',
    },

    name: {
        id: 'contacts.name',
        defaultMessage: 'Nome',
    },

    email: {
        id: 'contacts.email',
        defaultMessage: 'Email',
    },

    password: {
        id: 'contacts.password',
        defaultMessage: 'Senha',
    },

    role: {
        id: 'contacts.role',
        defaultMessage: 'Função',
    },

    company: {
        id: 'contacts.company',
        defaultMessage: 'Contratante',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },

    modalTitle: {
        id: 'contacts.selectAcccess',
        defaultMessage: 'Selecione o acesso',
    },

    deleteAccess: {
        id: 'modal.deleteAccess',
        defaultMessage: 'Apagar acesso',
    },

    contactAccessAlreadyAdded: {
        id: 'modal.contactAccessAlreadyAdded',
        defaultMessage: 'Usuário já possui esse acesso',
    },

    newPasswordConfirmationModalTitle: {
        id: 'contacts.newPasswordConfirmationModalTitle',
        defaultMessage: 'Envio de renovação de senha',
    },

    newPasswordConfirmationModalText: {
        id: 'contacts.newPasswordConfirmationModalText',
        defaultMessage:
            'Tem certeza que deseja enviar um email de renovação de senha para esse usuário?',
    },
});

export default messages;
