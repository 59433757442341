import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink as ReactNavLink } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';
import { Nav, NavItem, NavLink } from 'reactstrap';
import Icon from '../../assets/icons/Index';
import messages from './Sidebar.intl';
import * as actions from '../../store/actions/index';
import Modal from '../Modal/Modal';
import ChooseContractorForm from '../ChooseContractorForm/ChooseContractorForm';

class SideBar extends Component {
    state = {
        showModal: false,
    };

    handleAccessFormSubmit = (e, values) => {
        const session = this.props.session || {};
        session.accesses = this.props.accesses;

        this.props.onAccessChange({
            ...values,
            session,
        });

        setTimeout(() => {
            this.props.handleAccessChange();
        }, 1000);
    };

    handleBackClickToogle = () => {
        this.setState({
            showModal: false,
        });
    };

    render() {
        const { intl } = this.props;
        const accesses = this.props?.accesses || [];
        let username = '';
        if (this.props.username) {
            username =
                this.props.username.length <= 20 &&
                this.props?.username.split(' ').length > 1
                    ? `${this.props?.username.split(' ')[0]} ${
                          this.props?.username.split(' ')[1]
                      }`
                    : `${this.props?.username.split(' ')[0]}`;
        }
        const linkStyle =
            accesses.length < 2
                ? {
                      pointerEvents: 'none',
                      cursor: 'pointer',
                      backgroundColor: '#ffffff',
                      boxShadow: 'none',
                  }
                : {
                      textDecoration: 'none',
                      cursor: 'pointer',
                      backgroundColor: '#ffffff',
                      boxShadow: 'none',
                  };

        return (
            <>
                <Modal
                    title='Trocar Acesso'
                    isOpen={this.state.showModal}
                    hasFooter={false}
                    size='md'
                >
                    <ChooseContractorForm
                        // loading={loading}
                        accesses={accesses}
                        contractorColor={
                            this.props.contractorlayout?.primaryColor
                        }
                        handleBackClickToogle={this.handleBackClickToogle}
                        handleAccessFormSubmit={this.handleAccessFormSubmit}
                    />
                </Modal>
                <aside
                    className={`menu ${
                        this.props.isMenuCollapsed ? 'small' : ''
                    }`}
                >
                    <div className='area-menu'>
                        <button
                            className='minimize-menu'
                            onClick={this.props.collapseMenu}
                        >
                            <FontAwesomeIcon
                                icon={`${
                                    this.props.isMenuCollapsed
                                        ? 'chevron-right'
                                        : 'chevron-left'
                                }`}
                            />
                        </button>

                        <section id='menus'>
                            <div className='menu-system'>
                                <button
                                    onClick={() =>
                                        this.setState({
                                            showModal: true,
                                        })
                                    }
                                    style={linkStyle}
                                >
                                    <p id='welcome-user'>
                                        <span
                                            style={{
                                                color: '#444 !important',
                                                paddingTop: '16px',
                                            }}
                                        >
                                            {this.props &&
                                                this.props.contractor}
                                        </span>
                                    </p>
                                </button>
                                <div id='logo-container'>
                                    <img
                                        src={this.props.logoSrc}
                                        alt='...'
                                        id='logo'
                                        className='logo'
                                    />
                                </div>
                                <img
                                    src={this.props.logoMiniSrc}
                                    alt='...'
                                    id='logo-mini'
                                />

                                <p id='welcome-user'>
                                    <span style={{ color: '#444 !important' }}>
                                        {intl.formatMessage(messages.welcome)}
                                    </span>
                                    <span>{username}</span>
                                </p>

                                <ScrollArea
                                    speed={0.8}
                                    className='content-menu'
                                    contentClassName='content'
                                    horizontal={false}
                                >
                                    <div>
                                        <Nav vertical className='nav-principal'>
                                            <NavItem>
                                                <NavLink
                                                    className='sidebar-link'
                                                    activeClassName='selected'
                                                    to='/home'
                                                    tag={ReactNavLink}
                                                    title={intl.formatMessage(
                                                        messages.homeLink,
                                                    )}
                                                >
                                                    <Icon
                                                        style={{
                                                            marginRight: '20px',
                                                        }}
                                                        name='home'
                                                    />
                                                    <p>
                                                        {intl.formatMessage(
                                                            messages.homeLink,
                                                        )}
                                                    </p>
                                                </NavLink>
                                            </NavItem>

                                            {this.props.hasClaim(
                                                'contractor-menu-view',
                                            ) && (
                                                <NavItem className=''>
                                                    <NavLink
                                                        className='sidebar-link'
                                                        activeClassName='selected'
                                                        to='/contractors'
                                                        onClick={() =>
                                                            this.props.resetCurrentFilter(
                                                                '/contractors',
                                                            )
                                                        }
                                                        tag={ReactNavLink}
                                                        title={intl.formatMessage(
                                                            messages.contractorsLink,
                                                        )}
                                                    >
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    '20px',
                                                            }}
                                                            name='contractors'
                                                        />
                                                        <p>
                                                            {intl.formatMessage(
                                                                messages.contractorsLink,
                                                            )}
                                                        </p>
                                                    </NavLink>
                                                </NavItem>
                                            )}

                                            {this.props.hasClaim(
                                                'collection-menu-view',
                                            ) && (
                                                <NavItem className=''>
                                                    <NavLink
                                                        className='sidebar-link'
                                                        activeClassName='selected'
                                                        to='/collections'
                                                        onClick={() =>
                                                            this.props.resetCurrentFilter(
                                                                '/collections',
                                                            )
                                                        }
                                                        tag={ReactNavLink}
                                                        title={intl.formatMessage(
                                                            messages.collectionsLink,
                                                        )}
                                                    >
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    '20px',
                                                            }}
                                                            name='collections'
                                                        />
                                                        <p>
                                                            {intl.formatMessage(
                                                                messages.collectionsLink,
                                                            )}
                                                        </p>
                                                    </NavLink>
                                                </NavItem>
                                            )}
                                            {this.props.hasClaim(
                                                'transaction-menu-view',
                                            ) && (
                                                <NavItem className=''>
                                                    <NavLink
                                                        className='sidebar-link'
                                                        activeClassName='selected'
                                                        to='/transactions'
                                                        onClick={() =>
                                                            this.props.resetCurrentFilter(
                                                                '/transactions',
                                                            )
                                                        }
                                                        tag={ReactNavLink}
                                                        title={intl.formatMessage(
                                                            messages.transactionsLink,
                                                        )}
                                                    >
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    '20px',
                                                            }}
                                                            name='transactions'
                                                        />
                                                        <p>
                                                            {intl.formatMessage(
                                                                messages.transactionsLink,
                                                            )}
                                                        </p>
                                                    </NavLink>
                                                </NavItem>
                                            )}
                                            {this.props.hasClaim(
                                                'parameter-menu-view',
                                            ) && (
                                                <NavItem className=''>
                                                    <NavLink
                                                        className='sidebar-link'
                                                        activeClassName='selected'
                                                        to='/parameters'
                                                        onClick={() =>
                                                            this.props.resetCurrentFilter(
                                                                '/parameters',
                                                            )
                                                        }
                                                        tag={ReactNavLink}
                                                        title={intl.formatMessage(
                                                            messages.parametersLink,
                                                        )}
                                                    >
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    '20px',
                                                            }}
                                                            name='parameters'
                                                        />
                                                        <p>
                                                            {intl.formatMessage(
                                                                messages.parametersLink,
                                                            )}
                                                        </p>
                                                    </NavLink>
                                                </NavItem>
                                            )}

                                            {this.props.hasClaim(
                                                'user-menu-view',
                                            ) && (
                                                <NavItem className=''>
                                                    <NavLink
                                                        className='sidebar-link'
                                                        activeClassName='selected'
                                                        to='/users'
                                                        onClick={() =>
                                                            this.props.resetCurrentFilter(
                                                                '/users',
                                                            )
                                                        }
                                                        tag={ReactNavLink}
                                                        title={intl.formatMessage(
                                                            messages.usersLink,
                                                        )}
                                                    >
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    '20px',
                                                            }}
                                                            name='users'
                                                        />
                                                        <p>
                                                            {intl.formatMessage(
                                                                messages.usersLink,
                                                            )}
                                                        </p>
                                                    </NavLink>
                                                </NavItem>
                                            )}

                                            {this.props.hasClaim(
                                                'external-user-menu-view',
                                            ) && (
                                                <NavItem className=''>
                                                    <NavLink
                                                        className='sidebar-link'
                                                        activeClassName='selected'
                                                        to='/contacts'
                                                        onClick={() =>
                                                            this.props.resetCurrentFilter(
                                                                '/contacts',
                                                            )
                                                        }
                                                        tag={ReactNavLink}
                                                        title={intl.formatMessage(
                                                            messages.contactsLink,
                                                        )}
                                                    >
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    '20px',
                                                            }}
                                                            name='contacts'
                                                        />
                                                        <p>
                                                            {intl.formatMessage(
                                                                messages.contactsLink,
                                                            )}
                                                        </p>
                                                    </NavLink>
                                                </NavItem>
                                            )}

                                            {this.props.hasClaim(
                                                'client-menu-view',
                                            ) && (
                                                <NavItem className=''>
                                                    <NavLink
                                                        className='sidebar-link'
                                                        activeClassName='selected'
                                                        to='/clients'
                                                        onClick={() =>
                                                            this.props.resetCurrentFilter(
                                                                '/clients',
                                                            )
                                                        }
                                                        tag={ReactNavLink}
                                                        title={intl.formatMessage(
                                                            messages.clientsLink,
                                                        )}
                                                    >
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    '20px',
                                                            }}
                                                            name='users'
                                                        />
                                                        <p>
                                                            {intl.formatMessage(
                                                                messages.clientsLink,
                                                            )}
                                                        </p>
                                                    </NavLink>
                                                </NavItem>
                                            )}

                                            {this.props.hasClaim(
                                                'financial-document-menu-view',
                                            ) && (
                                                <NavItem className=''>
                                                    <NavLink
                                                        className='sidebar-link'
                                                        activeClassName='selected'
                                                        to='/financialDocuments'
                                                        onClick={() =>
                                                            this.props.resetCurrentFilter(
                                                                '/financialDocuments',
                                                            )
                                                        }
                                                        tag={ReactNavLink}
                                                        title={intl.formatMessage(
                                                            messages.financialDocumentsLink,
                                                        )}
                                                    >
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    '20px',
                                                            }}
                                                            name='parameters'
                                                        />
                                                        <p>
                                                            {intl.formatMessage(
                                                                messages.financialDocumentsLink,
                                                            )}
                                                        </p>
                                                    </NavLink>
                                                </NavItem>
                                            )}

                                            {this.props.hasClaim(
                                                'role-menu-view',
                                            ) && (
                                                <NavItem className=''>
                                                    <NavLink
                                                        className='sidebar-link'
                                                        activeClassName='selected'
                                                        to='/roles'
                                                        onClick={() =>
                                                            this.props.resetCurrentFilter(
                                                                '/roles',
                                                            )
                                                        }
                                                        tag={ReactNavLink}
                                                        title={intl.formatMessage(
                                                            messages.rolesLink,
                                                        )}
                                                    >
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    '20px',
                                                            }}
                                                            name='profiles'
                                                        />
                                                        <p>
                                                            {intl.formatMessage(
                                                                messages.rolesLink,
                                                            )}
                                                        </p>
                                                    </NavLink>
                                                </NavItem>
                                            )}

                                            {this.props.hasClaim(
                                                'reconciliation-view',
                                            ) && (
                                                <NavItem className=''>
                                                    <NavLink
                                                        className='sidebar-link'
                                                        activeClassName='selected'
                                                        to='/reconciliation'
                                                        onClick={() =>
                                                            this.props.resetCurrentFilter(
                                                                '/reconciliation',
                                                            )
                                                        }
                                                        tag={ReactNavLink}
                                                        title={intl.formatMessage(
                                                            messages.reconciliationLink,
                                                        )}
                                                    >
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    '20px',
                                                            }}
                                                            name='parameters'
                                                        />
                                                        <p>
                                                            {intl.formatMessage(
                                                                messages.reconciliationLink,
                                                            )}
                                                        </p>
                                                    </NavLink>
                                                </NavItem>
                                            )}

                                            {this.props.hasClaim(
                                                'log-menu-view',
                                            ) && (
                                                <NavItem className=''>
                                                    <NavLink
                                                        className='sidebar-link'
                                                        activeClassName='selected'
                                                        to='/logs'
                                                        onClick={() =>
                                                            this.props.resetCurrentFilter(
                                                                '/logs',
                                                            )
                                                        }
                                                        tag={ReactNavLink}
                                                        title='Logs'
                                                    >
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    '20px',
                                                            }}
                                                            name='logs'
                                                        />
                                                        <p>Logs</p>
                                                    </NavLink>
                                                </NavItem>
                                            )}
                                            {this.props.hasClaim(
                                                'settings-menu-view',
                                            ) && (
                                                <NavItem className=''>
                                                    <NavLink
                                                        className='sidebar-link'
                                                        activeClassName='selected'
                                                        to='/settings'
                                                        onClick={() =>
                                                            this.props.resetCurrentFilter(
                                                                '/settings',
                                                            )
                                                        }
                                                        tag={ReactNavLink}
                                                        title={intl.formatMessage(
                                                            messages.settingsLink,
                                                        )}
                                                    >
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    '20px',
                                                            }}
                                                            name='settings'
                                                        />
                                                        <p>
                                                            {intl.formatMessage(
                                                                messages.settingsLink,
                                                            )}
                                                        </p>
                                                    </NavLink>
                                                </NavItem>
                                            )}

                                            <NavItem className=''>
                                                <NavLink
                                                    className='sidebar-link'
                                                    href='#'
                                                    onClick={
                                                        this.props
                                                            .handleLogoutClick
                                                    }
                                                    title={intl.formatMessage(
                                                        messages.logoutLink,
                                                    )}
                                                >
                                                    <Icon
                                                        style={{
                                                            marginRight: '20px',
                                                        }}
                                                        name='logout'
                                                    />
                                                    <p>
                                                        {intl.formatMessage(
                                                            messages.logoutLink,
                                                        )}
                                                    </p>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </ScrollArea>
                            </div>
                        </section>

                        <footer className='text-center'>
                            <p>
                                v{process.env.REACT_APP_VERSION}
                                <br />
                                {intl.formatMessage(messages.developedBy)}{' '}
                                <a
                                    href='https://www.evtit.com/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    evt/apps
                                </a>
                            </p>
                        </footer>
                    </div>
                </aside>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accesses: state.authenticationReducer.accesses,
        session: state.authenticationReducer.session,
        contractorlayout: state.contractorReducer.contractorlayout,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetCurrentFilter: (...args) =>
            dispatch(actions.resetCurrentFilter(...args)),
        onAccessChange: (payload) => dispatch(actions.setAccess(payload)),
        onInitCurrentSession: () => dispatch(actions.getCurrentSession()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(SideBar));
