import { defineMessages } from 'react-intl';

import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    title: {
        id: 'clients.title',
        defaultMessage: 'Usuários',
    },

    titleClient: {
        id: 'clients.titleClient',
        defaultMessage: 'Cliente {entity}',
    },

    registrationInfo: {
        id: 'clients.registrationInfo',
        defaultMessage: 'Dados Cadastrais',
    },

    deleteClient: {
        id: 'clients.deleteClient',
        defaultMessage: 'Excluir Cliente',
    },

    name: {
        id: 'clients.name',
        defaultMessage: 'Nome',
    },

    home: {
        id: 'home.title',
        defaultMessage: 'Início',
    },
});

export default messages;
