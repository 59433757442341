import React from 'react';

const CollectionIcon = ({
    width = '26',
    height = '30',
    viewBox = '0 0 26 30',
    fill = 'none',
    style = '',
}) => (
    <svg
        width={width}
        height={height}
        fill={fill}
        viewBox={viewBox}
        style={style}
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20.75 21.75C20.75 22.5784 20.0784 23.25 19.25 23.25H2.75C1.92157 23.25 1.25 22.5784 1.25 21.75V2.25C1.25 1.42157 1.92157 0.75 2.75 0.75H13.379C13.7765 0.750085 14.1578 0.907982 14.439 1.189L20.311 7.061C20.592 7.3422 20.7499 7.72345 20.75 8.121V21.75Z'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M20.75 8.25H14.75C13.9216 8.25 13.25 7.57843 13.25 6.75V0.75'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M4.64199 9.344C5.08118 9.92169 5.7749 10.2484 6.49999 10.219C7.63899 10.219 8.56299 9.526 8.56299 8.672C8.56299 7.818 7.63899 7.126 6.49999 7.126C5.36099 7.126 4.43799 6.433 4.43799 5.578C4.43799 4.723 5.36099 4.031 6.49999 4.031C7.22508 4.00161 7.91879 4.32831 8.35799 4.906'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M6.5 10.219V11.25'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M6.5 3V4.031'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.25 14.75C4.25 14.4739 4.47386 14.25 4.75 14.25H17.25C17.5261 14.25 17.75 14.4739 17.75 14.75V19.75C17.75 20.0261 17.5261 20.25 17.25 20.25H4.75C4.47386 20.25 4.25 20.0261 4.25 19.75V14.75Z'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M4.25 17.25H17.75'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M8.75 14.25V20.25'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M13.25 14.25V20.25'
            stroke='#15253B'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default CollectionIcon;
