import React, { useState, useEffect, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { Row, Col, FormGroup } from 'reactstrap';

import messages from './CollectionDetailsForm.intl';
import { AuthContext } from '../../context/AuthContext';
import DetailSection from '../DetailSection/DetailSection';

import { handleCpfCnpj } from '../../utils/handleCpfCnpj';

function CollectionDetailsForm(props) {
    const context = useContext(AuthContext);

    const { intl /* isNew */ } = props;

    const collection = props.collection || {};

    const isReadOnly = true;
    const [, setContractorId] = useState(null);
    const [, setShowContractorsSelect] = useState(false);

    useEffect(() => {
        const { contractorId } = context;

        if (context.claims.length === 0) return;

        if (context.hasClaim('role-level-full')) {
            setShowContractorsSelect(true);
        } else if (context.hasClaim('role-level-contractor')) {
            setShowContractorsSelect(false);
            setContractorId(contractorId);
        }
    }, [context]);

    useEffect(() => {
        if (collection.collectionId) {
            setContractorId(collection.contractorId);
        }
    }, [collection]);

    const contractorsOption = new Map();
    contractorsOption.set(
        collection && collection.contractorId,
        collection && collection.contractorName,
    );

    return (
        <AvForm onValidSubmit={props.handleSubmit}>
            <DetailSection title='Identificação do recebível'>
                <Row>
                    <Col lg={6} md={6}>
                        <AvField
                            label={intl.formatMessage(messages.collectionId)}
                            type='text'
                            name='collectionId'
                            id='collectionId'
                            value={collection.collectionId || ''}
                            errorMessage=' '
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col lg={6} md={6}>
                        <AvField
                            label={intl.formatMessage(
                                messages.accountingDocumentNumber,
                            )}
                            type='text'
                            name='accountingDocumentNumber'
                            id='accountingDocumentNumber'
                            value={collection.accountingDocumentNumber || ''}
                            disabled={isReadOnly}
                            errorMessage=' '
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} md={3}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.enterprise)}
                                type='text'
                                name='enterprise'
                                id='enterprise'
                                value={collection.enterprise || ''}
                                disabled={isReadOnly}
                                errorMessage=' '
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.fiscalYear)}
                                type='text'
                                name='fiscalYear'
                                id='fiscalYear'
                                value={collection.fiscalYear || ''}
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.issuedAccountingLine,
                                )}
                                type='text'
                                name='issuedAccountingLine'
                                id='issuedAccountingLine'
                                value={collection.issuedAccountingLine || ''}
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.documentType,
                                )}
                                type='text'
                                name='documentType'
                                id='documentType'
                                value={collection.documentType || ''}
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>
            <DetailSection title='Dados do Cliente'>
                <Row>
                    <Col lg={3} md={3} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.clientNumber,
                                )}
                                type='text'
                                name='clientNumber'
                                id='clientNumber'
                                value={collection.clientNumber || ''}
                                disabled={isReadOnly}
                                errorMessage=' '
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.clientName)}
                                type='text'
                                name='clientName'
                                id='clientName'
                                value={collection.clientName || ''}
                                disabled={isReadOnly}
                                errorMessage=' '
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.cpfCnpj)}
                                type='text'
                                name='cpfCnpj'
                                id='cpfCnpj'
                                value={handleCpfCnpj(collection?.clientCpfCnpj)}
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.clientEmails,
                                )}
                                type='text'
                                name='clientEmails'
                                id='clientEmails'
                                value={collection.clientEmails || ''}
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.clientPhoneNumbers,
                                )}
                                type='text'
                                name='clientPhoneNumbers'
                                id='clientPhoneNumbers'
                                value={collection.clientPhoneNumbers || ''}
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.companyCnpj)}
                                type='text'
                                name='companyCnpj'
                                id='companyCnpj'
                                value={handleCpfCnpj(collection?.companyCnpj)}
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>
            <DetailSection title='Dados da Cobrança'>
                <Row>
                    <Col lg={3} md={3} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.referenceDocument,
                                )}
                                type='text'
                                name='referenceDocument'
                                id='referenceDocument'
                                value={collection.referenceDocument || ''}
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={2} md={2} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.documentDate,
                                )}
                                type='text'
                                name='documentDate'
                                id='documentDate'
                                value={
                                    new Date(
                                        collection.documentDate,
                                    ).toLocaleDateString(navigator.language) ||
                                    ''
                                }
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={2} md={2} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.dueDate)}
                                type='text'
                                name='dueDate'
                                id='dueDate'
                                value={
                                    new Date(
                                        collection.dueDate,
                                    ).toLocaleDateString(navigator.language) ||
                                    ''
                                }
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={2} md={2} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.daysInArrears,
                                )}
                                type='text'
                                name='daysInArrears'
                                id='daysInArrears'
                                value={collection.daysInArrears || '0'}
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.billValue)}
                                type='text'
                                name='billValue'
                                id='billValue'
                                value={
                                    new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(collection.billValue) || ''
                                }
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} md={3} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.billAdjustedValue,
                                )}
                                type='text'
                                name='billAdjustedValue'
                                id='billAdjustedValue'
                                value={
                                    new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(collection.billAdjustedValue) ||
                                    ''
                                }
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.billDischargeValue,
                                )}
                                type='text'
                                name='billDischargeValue'
                                id='billDischargeValue'
                                value={
                                    new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(collection.billDischargeValue) ||
                                    ''
                                }
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={5} md={5} sm={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.totalBillDischarge,
                                )}
                                type='text'
                                name='totalBillDischarge'
                                id='totalBillDischarge'
                                value={
                                    new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(collection.totalBillDischarge) ||
                                    ''
                                }
                                disabled={isReadOnly}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>
        </AvForm>
    );
}

export default injectIntl(CollectionDetailsForm);
