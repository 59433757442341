import React from 'react';
import { injectIntl } from 'react-intl';
import { FormGroup, Col } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import messages from './DatePicker.intl';

const datePicker = (props) => {
    const { intl, filter } = props;

    return (
        <>
            <Col lg={4} md={4} sm={12}>
                <FormGroup>
                    <AvField
                        label={intl.formatMessage(messages.from)}
                        type='date'
                        name={`filters[${filter.id}].valueFrom`}
                        id={`filters[${filter.id}].valueFrom`}
                        value={filter.valueFrom}
                        validate={{ datetime: true }}
                        onChange={(e) =>
                            props.handleFieldChange &&
                            props.handleFieldChange(e, filter.id, 'valueFrom')
                        }
                    />
                </FormGroup>
            </Col>
            <Col lg={4} md={4} sm={12}>
                <FormGroup>
                    <AvField
                        label={intl.formatMessage(messages.to)}
                        type='date'
                        name={`filters[${filter.id}].valueTo`}
                        id={`filters[${filter.id}].valueTo`}
                        value={filter.valueTo}
                        validate={{ datetime: true }}
                        onChange={(e) =>
                            props.handleFieldChange &&
                            props.handleFieldChange(e, filter.id, 'valueTo')
                        }
                    />
                </FormGroup>
            </Col>
        </>
    );
};

export default injectIntl(datePicker);
