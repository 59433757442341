import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    transactionsByPaymentOperator: {
        id: 'dashboard.transactionsByPaymentOperator',
        defaultMessage: 'Transactions By Payment Operator',
    },
});
