import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import ReactTable from 'react-table';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import messages from './EvtTable.intl';

import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';

import Header from '../Header/Header';

import FilterBuilder, {
    Operators,
    SortDirection,
} from '../../models/FilterBuilder';
import * as actions from '../../store/actions/index';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGES = 1;

export const calculateColumnWidth = (rows, accessor, headerText) => {
    const maxWidth = 400;
    const magicSpacing = 5;
    const cellLength = Math.max(
        ...rows.map((row) => (`${row[accessor]}` || '').length),
        headerText.length,
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
};

class EvtTable extends Component {
    state = {
        page: DEFAULT_PAGE,
        pages: DEFAULT_PAGES,
        sorted:
            (this.props.uiContext &&
                this.props.uiContext[window.location.pathname] &&
                this.props.uiContext[window.location.pathname].ordenation) ||
            null,
    };

    componentDidMount() {
        this.updatePages();
    }

    componentDidUpdate(prevProps) {
        const { length, filters } = this.props;

        if (prevProps.length !== length) {
            this.updatePages();
        }

        if (prevProps.filters !== filters) {
            this.setState(
                {
                    page: 0,
                },
                () => {
                    const filter = this.buildFilter();
                    this.props.handleStateChange &&
                        this.props.handleStateChange(filter);
                },
            );
        }
    }

    updatePages = () => {
        const { pageSize } = this.props;
        const { length } = this.props;

        const pages =
            length > pageSize ? Math.ceil(length / pageSize) : DEFAULT_PAGES;

        this.setState({
            pages,
        });
    };

    buildFilter = () => {
        const { filters, pageSize } = this.props;
        const page = this.state.page || 0;
        const sorted = this.state.sorted;

        const skip = page * pageSize;
        const take = pageSize;

        let fb = new FilterBuilder();

        if (filters) {
            filters.forEach((f) => {
                fb = fb.filter(f.property, f.operator, f.value);
            });
        }

        if (sorted) {
            fb = fb.filter(
                sorted.id,
                Operators.sort,
                sorted.desc ? SortDirection.desc : SortDirection.asc,
            );
        }

        if (pageSize !== 0) {
            fb = fb
                .filter('skip', Operators.skip, skip)
                .filter('take', Operators.take, take);
        }

        const { pathname } = window.location;

        switch (pathname) {
            case '/tarifications':
                return fb.buildAsJSON();

            case '/transactions':
                return fb.buildAsJSON();

            default:
                return fb.build();
        }
    };

    handleTabChange = () => {};

    handleFetchData = (state) => {
        const { uiContext } = this.props;

        let sorted = this.state.sorted || null;
        if (state.sorted.length > 0) {
            sorted = state.sorted[0];
        }

        const pathName = window.location.pathname;

        const hasPageNumber =
            typeof uiContext[pathName] !== 'undefined' &&
            typeof uiContext[pathName].pageNumber !== 'undefined';

        this.props.onOrdernationUpdate(pathName, sorted);

        if (pathName === '/tarifications' || pathName === '/contractors') {
            this.setState(
                {
                    page:
                        state.page === 0
                            ? hasPageNumber
                                ? uiContext[pathName].pageNumber[
                                      uiContext[pathName].index
                                  ]
                                : 0
                            : hasPageNumber
                            ? uiContext[pathName].pageNumber[
                                  uiContext[pathName].index
                              ]
                            : state.page,
                    sorted,
                },
                () => {
                    const query = this.buildFilter();
                    this.props.handleStateChange &&
                        this.props.handleStateChange(query);
                },
            );
        } else {
            this.setState(
                {
                    page:
                        state.page === 0
                            ? hasPageNumber
                                ? uiContext[pathName].pageNumber
                                : 0
                            : state.page,
                    sorted,
                },
                () => {
                    const query = this.buildFilter();
                    this.props.handleStateChange &&
                        this.props.handleStateChange(query);
                },
            );
        }
    };

    render() {
        const {
            intl,
            pageSize,
            loading,
            columns,
            data,
            defaultSorted,
            handleGetTrProps,
            countHeaderText,
        } = this.props;
        const { page, pages } = this.state;
        const showPagination = this.props.showPagination ?? true;

        return (
            <Container fluid>
                {countHeaderText && <Header subtitle={countHeaderText} />}
                <ReactTable
                    manual
                    showPagination={showPagination}
                    loading={loading}
                    columns={columns}
                    data={data}
                    PaginationComponent={Pagination}
                    page={page}
                    pages={pages}
                    noDataText={intl.formatMessage(messages.noDataText)}
                    minRows={0}
                    defaultPageSize={pageSize}
                    defaultSorted={defaultSorted}
                    className='-striped -highlight text-center'
                    previousText={intl.formatMessage(messages.previousButton)}
                    nextText={intl.formatMessage(messages.nextButtonText)}
                    loadingText={<Loading loading />}
                    pageText={intl.formatMessage(messages.pageText)}
                    ofText={intl.formatMessage(messages.ofText)}
                    rowsText={intl.formatMessage(messages.rowsText)}
                    pageJumpText={intl.formatMessage(messages.pageJumpText)}
                    rowsSelectorText={intl.formatMessage(
                        messages.rowsSelectorText,
                    )}
                    getTrProps={handleGetTrProps}
                    onFetchData={this.handleFetchData}
                    title='Campo Indisponivel'
                />
            </Container>
        );
    }
}

EvtTable.propTypes = {
    loading: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    length: PropTypes.number,
    filters: PropTypes.array,
    pageSize: PropTypes.number.isRequired,
    handleStateChange: PropTypes.func.isRequired,
    handleGetTrProps: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
    return {
        onOrdernationUpdate: (...args) =>
            dispatch(actions.updateOrdenation(...args)),
    };
};

const mapStateToProps = (state) => {
    return {
        uiContext: state.uiContextReducer,
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(EvtTable),
);
