import React, { memo, useMemo } from 'react';
import { Button } from '@chakra-ui/button';
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuDivider,
} from '@chakra-ui/react';
import { FiMoreVertical, FiUserPlus } from 'react-icons/fi';

function BeatMenuItem({ item, index }) {
    return (
        <MenuItem
            key={`${index}`}
            icon={item.icon}
            borderRadius='sm'
            iconSpacing='5'
            isDisabled={item.disable}
            bg='transparent'
            p='3'
            onClick={(e) => item.onClick(e)}
            style={{ boxShadow: 'none' }}
        >
            {item.label}
        </MenuItem>
    );
}

const BeatMeatBallMenu = ({ items }) => {
    const menuItems = useMemo(() => {
        return {
            topRow: items?.filter((item) => !item.isBottomRow),
            bottomRow: items?.filter((item) => item.isBottomRow),
            hasBottomRow: items?.some((item) => item.isBottomRow),
        };
    }, [items]);

    return (
        <Menu>
            <MenuButton
                as={Button}
                bg='transparent'
                color='gray.500'
                _active={{
                    bg: 'gray.100',
                }}
                aria-label='Options'
                onClick={(e) => {
                    e.stopPropagation();
                }}
                style={{ boxShadow: 'none' }}
            >
                {items?.length ? <FiMoreVertical /> : <FiUserPlus />}
            </MenuButton>
            <MenuList>
                {menuItems?.topRow?.map((item, index) => (
                    <BeatMenuItem key={index} item={item} index={index} />
                ))}
                {menuItems?.hasBottomRow && <MenuDivider />}
                {menuItems?.bottomRow?.map((item, index) => (
                    <BeatMenuItem item={item} index={index} />
                ))}
            </MenuList>
        </Menu>
    );
};

export default memo(BeatMeatBallMenu);
