import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

export default defineMessages({
    ...globalMessages,

    valueConversionPercent: {
        id: 'dashboard.valueConversionPercent',
        defaultMessage: 'Transactions By Method Payment',
    },
});
