import { defineMessages } from 'react-intl';
import globalMessages from '../../../utils/globalMessages';

const message = defineMessages({
    ...globalMessages,
    title: {
        id: 'reconciliationAudit.title',
        defaultMessage: 'Auditoria',
    },
    subtitle: {
        id: 'reconciliationAudit.subtitle',
        defaultMessage: 'Item(ns)',
    },
});

export default message;
