import { defineMessages } from 'react-intl';
import globalMessages from '../../utils/globalMessages';

const messages = defineMessages({
    ...globalMessages,

    name: {
        id: 'users.name',
        defaultMessage: 'Nome',
    },

    userName: {
        id: 'users.userName',
        defaultMessage: 'Usuário',
    },

    email: {
        id: 'users.email',
        defaultMessage: 'Email',
    },

    role: {
        id: 'users.role',
        defaultMessage: 'Função',
    },

    company: {
        id: 'users.company',
        defaultMessage: 'Contratante',
    },

    password: {
        id: 'users.password',
        defaultMessage: 'Senha',
    },

    passwordDoesNotMatch: {
        id: 'users.passwordDoesNotMatch',
        defaultMessage: 'Senha diferente',
    },

    confirmPassword: {
        id: 'users.confirmPassword',
        defaultMessage: 'Confirmar Senha',
    },

    submitButton: {
        id: 'global.submitButton',
        defaultMessage: 'Enviar',
    },

    deleteButton: {
        id: 'global.deleteButton',
        defaultMessage: 'Excluir',
    },

    titleMap: {
        id: 'access.title',
        defaultMessage: 'Selecione o acesso desejado',
    },

    contractorName: {
        id: 'contractors.titleOnlyContractor',
        defaultMessage: 'Contratante',
    },

    billingOffice: {
        id: 'contractors.billingOffice',
        defaultMessage: 'Escritório de cobranças',
    },

    billingOfficeName: {
        id: 'contractors.billingOfficeName',
        defaultMessage: 'Escritório de cobrança',
    },

    roleName: {
        id: 'roles.name',
        defaultMessage: 'Perfil',
    },

    newAccess: {
        id: 'users.newAccess',
        defaultMessage: 'Novo acesso',
    },

    newPasswordButtonText: {
        id: 'users.newPasswordButtonText',
        defaultMessage: 'Resetar senha',
    },

    generateNewPasswordButtonText: {
        id: 'users.generateNewPasswordButtonText',
        defaultMessage: 'Nova senha',
    },

    blocked: {
        id: 'users.blocked',
        defaultMessage: 'Usuário bloqueado',
    },
    systemUser: {
        id: 'users.systemUser',
        defaultMessage: 'Usuário de sistema',
    },
    sessionId: {
        id: 'users.sessionId',
        defaultMessage: 'SessionId para uso de integração',
    },
    cpfCnpj: {
        id: 'roles.cpfCpnj',
        defaultMessage: 'CPF/CNPJ',
    },
});

export default messages;
