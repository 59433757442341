import React, { useState, useEffect, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { Row, Col, FormGroup } from 'reactstrap';
import DetailSection from '../DetailSection/DetailSection';

import messages from './TransactionDetailsForm.intl';
import { AuthContext } from '../../context/AuthContext';

import { handleCpfCnpj } from '../../utils/handleCpfCnpj';
import { paymentMethodTypeParse } from '../../utils/paymentMethodParser';
import { paymentOperatorParse } from '../../utils/paymentOperatorParser';

function TransactionDetailsForm(props) {
    const context = useContext(AuthContext);

    const { intl /* isNew */ } = props;

    const transaction = props.transaction || {};

    /* const canCreateTransaction =
        context.hasClaim('transaction-create') && isNew;
    const canUpdateTransaction =
        context.hasClaim('transaction-update') && !isNew; */
    // const document =
    //     (Object.keys(transaction).length !== 0 &&
    //         transaction?.collections?.[0].clientCpfCnpj) ||
    //     '';

    const isReadOnly = true;
    const [, setContractorId] = useState(null);
    const [, setShowContractorsSelect] = useState(false);

    useEffect(() => {
        const { contractorId } = context;

        if (context.claims.length === 0) return;

        if (context.hasClaim('role-level-full')) {
            setShowContractorsSelect(true);
        } else if (context.hasClaim('role-level-contractor')) {
            setShowContractorsSelect(false);
            setContractorId(contractorId);
        }
    }, [context]);

    useEffect(() => {
        if (transaction.transactionId) {
            setContractorId(transaction.contractorId);
        }
    }, [transaction]);

    const contractorsOption = new Map();
    contractorsOption.set(
        transaction && transaction.contractorId,
        transaction && transaction.contractorName,
    );

    return (
        <AvForm onValidSubmit={props.handleSubmit}>
            <DetailSection title='Identificação do Recebimento'>
                <Row>
                    <Col lg={12} md={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.transactionId,
                                )}
                                type='text'
                                name='transactionId'
                                id='transactionId'
                                value={transaction.transactionId || ''}
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>
            <DetailSection title='Dados do Cliente'>
                <Row>
                    <Col lg={3} md={3}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.clientNumber,
                                )}
                                type='text'
                                name='clientNumber'
                                id='clientNumber'
                                value={
                                    Object.keys(transaction).length !== 0
                                        ? transaction.collections[0]
                                              .clientNumber
                                        : ''
                                }
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6} md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.clientName)}
                                type='text'
                                name='clientName'
                                id='clientName'
                                value={
                                    Object.keys(transaction).length !== 0
                                        ? transaction.collections[0].clientName
                                        : ''
                                }
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={3}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.customerCpfCnpj,
                                )}
                                type='text'
                                name='documentNumber'
                                id='documentNumber'
                                value={handleCpfCnpj(
                                    transaction?.collections?.[0].clientCpfCnpj,
                                )}
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.email)}
                                type='text'
                                name='email'
                                id='email'
                                value={
                                    Object.keys(transaction).length !== 0
                                        ? transaction.collections[0]
                                              .clientEmails
                                        : ''
                                }
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={3}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.phone)}
                                type='text'
                                name='phone'
                                id='phone'
                                value={
                                    Object.keys(transaction).length !== 0
                                        ? transaction.collections[0]
                                              .clientPhoneNumbers
                                        : ''
                                }
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={3} md={3}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.companyCnpj)}
                                type='text'
                                name='companyCnpj'
                                id='companyCnpj'
                                value={handleCpfCnpj(transaction.companyCnpj)}
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>
            <DetailSection title='Detalhes do Pagamento'>
                <Row>
                    <Col lg={6} md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.comissionFeeRate,
                                )}
                                type='text'
                                name='comissionFeeRate'
                                id='comissionFeeRate'
                                value={
                                    new Intl.NumberFormat('pt-BR', {
                                        style: 'percent',
                                        minimumFractionDigits: 2,
                                    }).format(
                                        transaction.comissionFeeRate / 100,
                                    ) || ''
                                }
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6} md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.comissionFeeValue,
                                )}
                                type='text'
                                name='comissionFeeValue'
                                id='comissionFeeValue'
                                value={
                                    new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    }).format(transaction.comissionFeeValue) ||
                                    ''
                                }
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {Object.keys(transaction).length !== 0 &&
                    transaction.paymentsReceived.map((paymentMethod) => {
                        return (
                            <>
                                <p
                                    style={{
                                        color: '#A9A9A9',
                                        paddingBottom: '15px',
                                        paddingLeft: '10px',
                                        borderBottom: '3px solid #A9A9A9',
                                        marginBottom: '10px',
                                    }}
                                />
                                <Row>
                                    <Col lg={6} md={6}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.transactionCode,
                                                )}
                                                type='text'
                                                name='operatorTransactionId'
                                                id='operatorTransactionId'
                                                value={
                                                    paymentMethod.operatorTransactionId ||
                                                    ''
                                                }
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.value,
                                                )}
                                                type='text'
                                                name='value'
                                                id='value'
                                                value={
                                                    new Intl.NumberFormat(
                                                        'pt-BR',
                                                        {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        },
                                                    ).format(
                                                        paymentMethod.paymentValue,
                                                    ) || ''
                                                }
                                                disabled={isReadOnly}
                                                required
                                                errorMessage=' '
                                                validate={{
                                                    pattern: {
                                                        value: `^[+-]?[0-9]{1,3}(?:(?:,[0-9]{3})*(?:.[0-9]{2})?|(?:.[0-9]{3})*(?:,[0-9]{2})?|[0-9]*(?:[.,][0-9]*)?)$`,
                                                        errorMessage: ' ',
                                                    },
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.status,
                                                )}
                                                type='text'
                                                name='status'
                                                id='status'
                                                value={
                                                    transaction.statusDescription ||
                                                    ''
                                                }
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3} md={3}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.paymentMethod,
                                                )}
                                                type='text'
                                                name='paymentMethod'
                                                id='paymentMethod'
                                                value={
                                                    paymentMethodTypeParse(
                                                        paymentMethod.paymentMethodType,
                                                    ) || ''
                                                }
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.operatorId,
                                                )}
                                                type='text'
                                                name='operatorId'
                                                id='operatorId'
                                                value={paymentOperatorParse(
                                                    paymentMethod.paymentOperator,
                                                )}
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.payDate,
                                                )}
                                                type='text'
                                                name='payDate'
                                                id='payDate'
                                                value={
                                                    (paymentMethod.paymentDate &&
                                                        new Date(
                                                            paymentMethod.paymentDate,
                                                        ).toLocaleDateString(
                                                            navigator.language,
                                                        )) ||
                                                    ''
                                                }
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.payTime,
                                                )}
                                                type='text'
                                                name='payTime'
                                                id='payTime'
                                                value={
                                                    (paymentMethod.paymentDate &&
                                                        new Date(
                                                            paymentMethod.paymentDate,
                                                        ).toLocaleTimeString(
                                                            navigator.language,
                                                        )) ||
                                                    ''
                                                }
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3} md={3}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.operatorFeeValue,
                                                )}
                                                type='text'
                                                name='operatorFeeValue'
                                                id='operatorFeeValue'
                                                value={
                                                    new Intl.NumberFormat(
                                                        'pt-BR',
                                                        {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        },
                                                    ).format(
                                                        paymentMethod.operatorFeeValue,
                                                    ) || ''
                                                }
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.operatorFeeRate,
                                                )}
                                                type='text'
                                                name='operatorFeeRate'
                                                id='operatorFeeRate'
                                                value={
                                                    new Intl.NumberFormat(
                                                        'pt-BR',
                                                        {
                                                            style: 'percent',
                                                            minimumFractionDigits: 2,
                                                        },
                                                    ).format(
                                                        paymentMethod.operatorFeeRate /
                                                            100,
                                                    ) || ''
                                                }
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.recordDate,
                                                )}
                                                type='text'
                                                name='operatorPaymentCreatedDate'
                                                id='operatorPaymentCreatedDate'
                                                value={
                                                    (paymentMethod.operatorPaymentCreatedDate &&
                                                        new Date(
                                                            paymentMethod.operatorPaymentCreatedDate,
                                                        ).toLocaleDateString(
                                                            navigator.language,
                                                        )) ||
                                                    ''
                                                }
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.recordTime,
                                                )}
                                                type='text'
                                                name='recordTime'
                                                id='recordTime'
                                                value={
                                                    (paymentMethod.operatorPaymentCreatedDate &&
                                                        new Date(
                                                            paymentMethod.operatorPaymentCreatedDate,
                                                        ).toLocaleTimeString(
                                                            navigator.language,
                                                        )) ||
                                                    ''
                                                }
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {paymentMethod.paymentMethodType === 1 && (
                                    <Row>
                                        <Col lg={3} md={3}>
                                            <FormGroup>
                                                <AvField
                                                    label={intl.formatMessage(
                                                        messages.numberOfInstallments,
                                                    )}
                                                    type='text'
                                                    name='numberOfInstallments'
                                                    id='numberOfInstallments'
                                                    value={
                                                        paymentMethod.numberOfInstallments ||
                                                        ''
                                                    }
                                                    errorMessage=' '
                                                    disabled={isReadOnly}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={9} md={9}>
                                            <FormGroup>
                                                <AvField
                                                    label={intl.formatMessage(
                                                        messages.paymentMethodDetail,
                                                    )}
                                                    type='text'
                                                    name='paymentMethodDetail'
                                                    id='paymentMethodDetail'
                                                    value={
                                                        paymentMethod.paymentMethodDetail ||
                                                        ''
                                                    }
                                                    errorMessage=' '
                                                    disabled={isReadOnly}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        );
                    })}
            </DetailSection>
            <DetailSection title='Informações Adicionais'>
                <Row>
                    <Col lg={12} md={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.devicePayerId,
                                )}
                                type='text'
                                name='devicePayerId'
                                id='devicePayerId'
                                value={transaction.devicePayerId || ''}
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(
                                    messages.transactionPaymentFull,
                                )}
                                type='text'
                                name='transactionPaymentFull'
                                id='transactionPaymentFull'
                                value={
                                    transaction.transactionPaymentFull
                                        ? 'Sim'
                                        : 'Não'
                                }
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </DetailSection>
            <DetailSection title='Recebíveis'>
                {Object.keys(transaction).length !== 0 &&
                    transaction.collections.map((collection, index) => {
                        return (
                            <>
                                {index !== 0 && (
                                    <p
                                        style={{
                                            color: '#A9A9A9',
                                            paddingBottom: '15px',
                                            paddingLeft: '10px',
                                            borderBottom: '3px solid #A9A9A9',
                                            marginBottom: '10px',
                                        }}
                                    />
                                )}
                                <Row>
                                    <Col lg={6} md={6}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.collectionId,
                                                )}
                                                type='text'
                                                name='collectionId'
                                                id='collectionId'
                                                value={collection.collectionId}
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={3} md={3}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.billDischargeValue,
                                                )}
                                                type='text'
                                                name='billDischargeValue'
                                                id='billDischargeValue'
                                                value={
                                                    new Intl.NumberFormat(
                                                        'pt-BR',
                                                        {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        },
                                                    ).format(
                                                        collection.billDischargeValue,
                                                    ) || ''
                                                }
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={1}>
                                        <FormGroup check>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.isPayed,
                                                )}
                                                type='checkbox'
                                                name='isPayed'
                                                id='isPayed'
                                                value={
                                                    transaction.status === 0
                                                        ? false
                                                        : transaction.selectedCollectionIds.indexOf(
                                                              collection.collectionId,
                                                          ) !== -1
                                                }
                                                disabled={isReadOnly}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} md={6}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.referenceDocument,
                                                )}
                                                type='text'
                                                name='referenceDocument'
                                                id='referenceDocument'
                                                value={
                                                    collection.referenceDocument
                                                }
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col lg={3} md={3}>
                                        <FormGroup>
                                            <AvField
                                                label={intl.formatMessage(
                                                    messages.totalBillDischarge,
                                                )}
                                                type='text'
                                                name='totalBillDischarge'
                                                id='totalBillDischarge'
                                                value={
                                                    new Intl.NumberFormat(
                                                        'pt-BR',
                                                        {
                                                            style: 'currency',
                                                            currency: 'BRL',
                                                        },
                                                    ).format(
                                                        collection.totalBillDischarge,
                                                    ) || ''
                                                }
                                                errorMessage=' '
                                                disabled={isReadOnly}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        );
                    })}
            </DetailSection>
        </AvForm>
    );
}

export default injectIntl(TransactionDetailsForm);
