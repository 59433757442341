import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import ReactTable from 'react-table';
import { Row, Col, FormGroup, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../context/AuthContext';
import messages from './UserDetailsForm.intl';
import Loading from '../Loading/Loading';
import EvtSelect from '../EvtSelect/EvtSelect';

function UserDetailsForm(props) {
    const {
        intl,
        canCreateUser,
        canUpdateUser,
        canResetPassword,
        canGenerateNewPassword,
        canSetNewAccess,
        canEditFields,
        billingOffices,
        contractorsId,
        isSystem,
        selectedBillingOffice,
        canDeleteUser,
    } = props;

    const user = props.user || {};
    const accesses = props.accesses || {};

    const isReadOnly = !(canCreateUser || canUpdateUser);

    const blocked = user && user.blocked;

    const canEditUsername = !isReadOnly && !canEditFields;

    const context = useContext(AuthContext);

    const roleLevelFull = context.hasClaim('role-level-full');

    const showSystemUser = !isReadOnly && roleLevelFull;

    const columns = [
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.contractorName)}</span>
                </div>
            ),
            accessor: 'contractorName',
            Cell: (row) => {
                return (
                    <div className='customer-container'>
                        <div className=''>{row.value}</div>
                    </div>
                );
            },
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.roleName)}</span>
                </div>
            ),
            accessor: 'roleName',
            Cell: (row) => {
                return (
                    <div className='customer-container'>
                        <div className=''>{row.value}</div>
                    </div>
                );
            },
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.cpfCnpj)}</span>
                </div>
            ),
            accessor: 'clients',
            Cell: (row) => {
                const company =
                    row.original.companies && row.original.companies[0];
                const client = row.value && row.value[0];
                let colValue = client || company || '';
                colValue = colValue?.replace(/[^\d]+/g, '');

                return (
                    <div className='customer-container'>
                        <div className=''>{colValue}</div>
                    </div>
                );
            },
        },
        {
            Header: () => (
                <div className='header-table-title'>
                    <span>{intl.formatMessage(messages.deleteButton)}</span>
                </div>
            ),
            accessor: 'delete',
            Cell: (row) => {
                return (
                    <div className='customer-container'>
                        {!isReadOnly && (
                            <button
                                style={{ width: '100px' }}
                                type='button'
                                className='btn-delete'
                                onClick={() =>
                                    props.handleRemoveAccessButtonClick(
                                        row.original,
                                    )
                                }
                            >
                                <FontAwesomeIcon icon='trash-alt' />
                            </button>
                        )}
                    </div>
                );
            },
        },
    ];

    const canShowEvtSelect =
        (billingOffices && billingOffices.length > 0) ||
        (isSystem && contractorsId.length > 0);

    const queryEvtSelect =
        contractorsId.length > 0
            ? contractorsId.map(
                  (x, y) => `${y === 0 ? '?' : '&'}contractorsId=${x}`,
              )
            : '';

    return (
        <>
            <Loading loading={props.loading} />
            <AvForm onValidSubmit={props.handleSubmit}>
                <Row>
                    {blocked && (
                        <Col lg={12} md={12} sm={12}>
                            <FormGroup className='mb-5'>
                                <h2>
                                    <Badge
                                        pill
                                        className='method-image'
                                        color='danger'
                                    >
                                        {intl.formatMessage(messages.blocked)}
                                    </Badge>
                                </h2>
                            </FormGroup>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.name)}
                                type='text'
                                name='name'
                                id='name'
                                value={user.name}
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.userName)}
                                type='text'
                                name='username'
                                id='username'
                                value={user.username}
                                errorMessage=' '
                                disabled={canEditUsername}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <AvField
                                label={intl.formatMessage(messages.email)}
                                type='text'
                                name='emailAddress'
                                id='emailAddress'
                                value={user.emailAddress}
                                errorMessage=' '
                                disabled={isReadOnly}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className='text-left mt-3'>
                        {canShowEvtSelect && (
                            <EvtSelect
                                name='billingOfficeId'
                                label={intl.formatMessage(
                                    messages.billingOffice,
                                )}
                                url={`/billingoffice/getallbillingofficesbycontractorid${queryEvtSelect}`.replaceAll(
                                    ',',
                                    '',
                                )}
                                labelKey='displayName'
                                valueKey='id'
                                required={false}
                                async
                                option={
                                    selectedBillingOffice && {
                                        selectedValueOption:
                                            selectedBillingOffice.billingOfficeId,
                                        selectedLabelOption:
                                            selectedBillingOffice.billingOfficeName,
                                    }
                                }
                                canRemoveItem
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className='mt-3'>
                        <ReactTable
                            showPagination={false}
                            noDataText={intl.formatMessage(messages.noDataText)}
                            columns={columns}
                            data={accesses}
                            minRows={0}
                            className='-striped -highlight text-center'
                            previousText={intl.formatMessage(
                                messages.previousButton,
                            )}
                            nextText={intl.formatMessage(
                                messages.nextButtonText,
                            )}
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={6}>
                        {showSystemUser && (
                            <AvField
                                label={intl.formatMessage(messages.systemUser)}
                                type='checkbox'
                                name='systemUser'
                                id='systemUser'
                                value={user.systemUser || ''}
                                checked={user.systemUser}
                                disabled={isReadOnly}
                            />
                        )}
                    </Col>
                    <Col xs={6}>
                        {showSystemUser && (
                            <AvField
                                label={intl.formatMessage(messages.sessionId)}
                                type='text'
                                name='sessionId'
                                id='sessionId'
                                value={user.sessionId || ''}
                                disabled
                            />
                        )}
                    </Col>
                    <Col xs={12}>
                        {!isReadOnly && (
                            <button
                                type='submit'
                                className='btn-submit'
                                disabled={props.loading}
                            >
                                {intl.formatMessage(messages.submitButton)}
                            </button>
                        )}
                        {!isReadOnly && canSetNewAccess && (
                            <button
                                type='button'
                                onClick={props.handleNewAccessButtonClick}
                                disabled={props.loading}
                            >
                                {intl.formatMessage(messages.newAccess)}
                            </button>
                        )}
                        {canResetPassword && (
                            <button
                                type='button'
                                onClick={props.handleNewPasswordButtonClick}
                                disabled={props.loading}
                            >
                                {intl.formatMessage(
                                    messages.newPasswordButtonText,
                                )}
                            </button>
                        )}
                        {canGenerateNewPassword && (
                            <button
                                type='button'
                                onClick={
                                    props.handleGenerateNewPasswordButtonClick
                                }
                                disabled={props.loading}
                            >
                                {intl.formatMessage(
                                    messages.generateNewPasswordButtonText,
                                )}
                            </button>
                        )}
                        {canDeleteUser && (
                            <button
                                type='button'
                                onClick={props.handleDelete}
                                style={{ backgroundColor: 'red' }}
                                disabled={props.loading}
                            >
                                {intl.formatMessage(messages.deleteButton)}
                            </button>
                        )}
                    </Col>
                </Row>
            </AvForm>
        </>
    );
}

export default injectIntl(UserDetailsForm);
