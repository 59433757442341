import React from 'react';
import { injectIntl } from 'react-intl';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, FormGroup } from 'reactstrap';
import messages from './ClientDetailsForm.intl';
import EvtSelect from '../EvtSelect/EvtSelect';
import { handleCpfCnpj } from '../../utils/handleCpfCnpj';

function ClientDetailsForm(props) {
    const {
        intl,
        canCreateClient,
        canUpdateClient,
        isNew,
        canDeleteClient,
    } = props;

    const client = props.client || {};

    const isReadOnly = !(canCreateClient || canUpdateClient);

    return (
        <AvForm onValidSubmit={props.handleSubmit}>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.clientId)}
                            type='text'
                            name='clientERPId'
                            id='clientERPId'
                            value={client.clientERPId}
                            errorMessage=' '
                            disabled={isReadOnly}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.cpfCnpj)}
                            type='text'
                            name='clientCpfCnpj'
                            id='clientCpfCnpj'
                            value={handleCpfCnpj(client.clientCpfCnpj)}
                            errorMessage=' '
                            disabled={isReadOnly}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <AvField
                            label={intl.formatMessage(messages.name)}
                            type='text'
                            name='name'
                            id='name'
                            value={client.name}
                            errorMessage=' '
                            disabled={isReadOnly}
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    {isNew && (
                        <EvtSelect
                            name='contractorId'
                            label={intl.formatMessage(messages.contractorId)}
                            options={props?.contractorsOption}
                            url='/Contractors'
                            labelKey='displayName'
                            valueKey='contractorId'
                            handleSelectedOptionChange={
                                props?.handleContractorChange
                            }
                            key={client && client.contractorId}
                            async
                            disabled={!isNew}
                            required
                            option={
                                props.contractor && {
                                    selectedValueOption:
                                        props.contractor &&
                                        props.contractor.contractorId,
                                    selectedLabelOption:
                                        props.contractor &&
                                        props.contractor.displayName,
                                }
                            }
                        />
                    )}
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col xs={12}>
                    {!isReadOnly && (
                        <button
                            type='submit'
                            className='btn-submit'
                            disabled={props.loading}
                        >
                            {intl.formatMessage(messages.submitButton)}
                        </button>
                    )}
                    {canDeleteClient && (
                        <button
                            type='button'
                            className='btn-delete'
                            onClick={props.handleDelete}
                        >
                            {intl.formatMessage(messages.deleteButton)}
                        </button>
                    )}
                </Col>
            </Row>
        </AvForm>
    );
}

export default injectIntl(ClientDetailsForm);
