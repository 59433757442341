export const viewerModeTypes = {
    POINTER: 1,
    GRAB: 2,
    ADD: 3,
    MOVE: 4,
};

export const formGroupTypes = {
    GENERAL: 1,
    PROVIDER: 2,
    BORROWER: 3,
    MIDDLEWARE: 4,
    SERVICES: 5,
    TAXES: 6,
    OTHERS: 7,
};

export const mapActionTypes = {
    INSERT: 1,
    PAD_LEFT: 2,
    PAD_RIGHT: 3,
    REMOVE: 4,
    REPLACE: 5,
    TRIM: 6,
    TRIM_START: 7,
    TRIM_END: 8,
    KEEP: 9,
};

export const mapActionConditions = {
    REGEX: 1,
    MIN_LENGTH: 2,
    MAX_LENGTH: 3,
};

export const feedbackCodes = {
    SUCCESS: 100,
    MAP_NOT_FOUND: 200,
    EXTERNAL_COMMUNICATION_FAILED: 201,
    FATAL_ERROR: 202,
};

export const auditLogTypes = {
    ADD: 1,
    UPDATE: 2,
    DELETE: 3,
    ERROR: 4,
};

export const technicalLogTypes = {
    DEBUG: 1,
    INFO: 2,
    WARNING: 3,
    ERROR: 4,
    FATAL: 5,
};

export const filterPropertyTypes = {
    TEXT: 1,
    NUMBER: 2,
    DATE: 3,
    ENUM: 4,
};
